import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import { postData } from "../services/api";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { PDFDownloadLink,Document } from "@react-pdf/renderer";
import SuccessionPlanReport from "./pdf/SuccessionPlanReport";
import moment from "moment";
import Select from "../components/form/Select";
import DropdownCheckbox from "../components/form/DropdownCheckbox";

import { useTranslation } from "react-i18next";
//import { Select } from "react-i18next/icu.macro";

const History = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | History");
  const { i18n,t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [concernHeadings,setConcernHeadings] = useState([]);
  const [predictorsHeading,setPredictorsHeading] = useState([]);
  const [successors, setSuccessors] = useState([]);
  const [rawSuccessor,setRawSuccessor] = useState([]);
  const [successorDetails,setSuccessorDetails] = useState([]);
  const [noOfPage, setNoOfPage] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount,setStartDataCount] = useState(0);
  const [endDataCount,setEndDataCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);

  const [predictorWeight, setPredictorWeight] = useState(0);
  const [experienceWeight, setExperienceWeight] = useState(0);
  const [concernsWeight, setConcernWeight] = useState(0);
  const [performanceWeight, setPerformanceWeight] = useState(0);
  const [educationWeight, setEducationWeight] = useState(0);
  const [yearWithCompanyWeight,setYearWithCompany] =  useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [positions, setPositions] = useState([]);

  const heading = [
    {
      name: t('executive_experience'),
      option: t('weight')+': ' + experienceWeight + '%'
    },
    {
      name: t('education'),
      option: t('weight')+': ' + educationWeight + '%'
    },
    {
      name: t('performance_rating'),
      option: t('weight')+': ' + performanceWeight + '%'
    },
    {
      name: t('loyalty'),
      option: t('pass')+'/'+t('fail')
    },
    {
      name : t('years_with_the_company'),
      option : t('weight')+': '+yearWithCompanyWeight+'%',
      overall_score : yearWithCompanyWeight,
    },
    {
      name: t('desire_to_advance'),
      option: t('pass')+'/'+t('fail')
    },
    {
      name: t('retirement_window'),
      option: t('pass')+'/'+t('fail')
    },
    {
      name: t('area_of_concern_score'),
      option: t('weight')+': ' + concernsWeight + '%'
    },
    {
      name: t('potential_predictor_score'),
      option: t('weight')+': ' + predictorWeight + '%'
    },
    {
      name: t('final_succession_score'),
      option: t('weight')+': ' + totalWeight + '%'
    },
    {
      name: t('advancement_readiness_projection'),
      option: ''
    }
  ]

  const readinessHeading = [
    {
      name: t('readiness_timeframe'),
      option: "",
    },
    {
      name: t('competency_fit'),
      option: "",
    },
    {
      name: t('additional_comments'),
      option: "",
    },
    {
      name : t('eliminated_candidate_due_to'),
      option: ""
    }
  ];

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]); 

  useEffect(() => {
    const loaWeights = async () => {
      setIsUpdate(false);
      try {
        const result = await postData("scorecard/list", {
          status: 1,
        });
        if (result.data) {
          let total = 0;
          let pWeight = 0;
          let exWeight = 0;
          let con = 0;
          let pref = 0;
          let edu = 0;
          let ywc = 0;
          result.data.map((value, key) => {
            if (value.alias === "potential_predictor_score") {
              pWeight = value?.company_weight ? value?.company_weight : value?.weight;
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }else if (value.alias === "executive_experience") {
              exWeight = value?.company_weight ? value?.company_weight : value?.weight              
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }else if (value.alias === "concerns") {
              con = value?.company_weight ? value?.company_weight : value?.weight              
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }else if (value.alias === "recent_performance_review") {
              pref = value?.company_weight ? value?.company_weight : value?.weight              
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }else if (value.alias === "highest_level_education") {
              edu = value?.company_weight ? value?.company_weight : value?.weight             
              total =
                total +
                Number(
                  value?.company_weight ? value?.company_weight : value?.weight
                );
            }else if(value.alias  === 'years_with_company'){
              ywc = ((value?.company_weight)?value?.company_weight:value?.weight)
              total = total+Number((value?.company_weight)?value?.company_weight:value?.weight);
            }
            setPredictorWeight(pWeight);
            setExperienceWeight(exWeight);
            setConcernWeight(con);
            setPerformanceWeight(pref);
            setEducationWeight(edu);
            setYearWithCompany(ywc);
            setTotalWeight(total);
            setIsUpdate(true);
            // if(pWeight>0 && exWeight>0 && con>0 && pref>0 && edu>0){   
            //   console.log('reload')           
            //setIsUpdate(true);
            // }else{
            //   console.log('update')   
            //   setIsUpdate(false);
            // }            
          });
        }
        //setTotalDataCount(result.count);
      } catch (err) {
        console.log(err.message);
        setIsUpdate(false);
      }
    };
    loaWeights();

    async function concernHeadingData() {
      try {
        const result = await postData("heading/list", {
          sortQuery: { created_at: 1 },
          status:1,
          type : 'concern'
        });
        if (result.data) {
          setConcernHeadings(result.data);
        } else {
          console.log("Concern heading list message", result.message);
        }
      } catch (error) {
        console.log("Error concern heading list catch", error.message);
      }
    }
    concernHeadingData();

    async function predictorHeadingData() {
      try {
        const result = await postData("heading/list", {
          sortQuery: { created_at: 1 },
          status:1,
          type : 'predictors'
        });
        if (result.data) {
          setPredictorsHeading(result.data);
        } else {
          console.log("Predictors heading list message", result.message);
        }
      } catch (error) {
        console.log("Error predictors heading list catch", error.message);
      }
    }
    predictorHeadingData(); 

    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { 'ordering': 1 },
          type: 1,
          status: 1,
          isStep : 1
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                label: value?.name+' ('+value?.short_name+')',
                isEnabled: false
              }
            })
          )
        } else {
          console.log('position list message', result.message)
        }
      } catch (error) {
        console.log('Error position list catch', error.message)
      }
    }
    positionData();
  },[])

  const positionSelect = async (id, checked) => {
    if(id && id === 'all'){
      setPositions(positions.map((pos) => {
        if (checked) {
          return { ...pos, isEnabled: checked }
        } else {
          return { ...pos, isEnabled: false }
        }
      }))
    }else{
      setPositions(positions.map((pos) => {
        if (pos._id === id) {
          return { ...pos, isEnabled: checked }
        } else {
          return pos;
        }
      }))
    }
  }

  useEffect(() => {
    let SelectedPosition = [];
    // eslint-disable-next-line array-callback-return
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
    
    if (SelectedPosition.length > 0 ) {
      loadSuccessors(SelectedPosition);
    } else {
      loadSuccessors();
    }
  }, [positions]);

  useEffect(() => {
    if(isUpdate){
      loadSuccessors();
    }
  }, [isUpdate]); 

  const loadSuccessors = async (SelectedPosition = []) => {
    setLoading(true);
    try {
      const result = await postData("plan-history/list", {
        limit: limit,
        offset: offset,
        position: SelectedPosition,
      });
      if (result.data){  
        setRawSuccessor(result.data);   
        let successorsDetails = [];
        for (let index = 0; index < result.data.length; index++) { 
          let subRecord = [];
          if(result.data[index].successors && result.data[index].successors.length>0){              
            for (let i = 0; i < result.data[index].successors.length; i++) {
              const item = result.data[index].successors;               
              const element = result.data[index].successors[i];
              let position_details = result.data[index]?.successors_position_details.filter((itm) => itm?._id === element?.designation);
              let experience_details = result.data[index]?.successors_experience_details.filter((itm) => itm?._id === element?.experience);
              let performance_details = result.data[index]?.successors_performance_details.filter((itm) => itm?._id === element?.performance);
              let education_details = result.data[index]?.successors_education_details.filter((itm) => itm?._id === element?.education);
              let desire_details = result.data[index]?.successors_desire_details.filter((itm) => itm?._id === element?.desire_to_advance);
              let loyalty_details = result.data[index]?.successors_loyalty_details.filter((itm) => itm?._id === element?.loyalty);
              let retirement_details = result.data[index]?.successors_retirement_details.filter((itm) => itm?._id === element?.retirement_window);
              let cu_details = result.data[index]?.successors_cu_details.filter((itm) => itm?._id === element?.with_credit_union);
              let timeframe_details = result.data[index]?.successors_timeframe_details.filter((itm) => itm?._id === element?.time_frame);
              let competencyfits_details = result.data[index]?.successors_competencyfits_details.filter((itm) => itm?._id === element?.competency_fit);

              let predictorCalculation =
                  (predictorWeight * element?.overall_weighted_score) / 100;
                let experienceCalculation =
                  (experienceWeight *
                    (experience_details
                      ? experience_details[0]?.point
                      : 0)) /
                  100;
                let concernsCalculation =
                  (concernsWeight * element?.overall_score) / 100;
                let performanceCalculation =
                  (performanceWeight *
                    (performance_details
                      ? performance_details[0]?.point
                      : 0)) /
                  100;
                let educationCalculation =
                  (educationWeight *
                    (education_details
                      ? education_details[0]?.point
                      : 0)) /
                  100;
                let desireResult =
                  desire_details && desire_details.length > 0
                    ? desire_details[0]?.isPass
                      ? 1
                      : 2
                    : null;
                let loyaltyResult =
                  loyalty_details && loyalty_details.length > 0
                    ? loyalty_details[0]?.isPass
                      ? 1
                      : 2
                    : null;
                let RetirementResult =
                  retirement_details &&
                  retirement_details.length > 0
                    ? retirement_details[0]?.isPass
                      ? 1
                      : 2
                    : null;
              subRecord.push({
                ...element,
                position_details: (position_details.length>0)?position_details:null,
                experience_details: (experience_details.length>0)?experience_details:null,
                performance_details: (performance_details.length>0)?performance_details:null,
                education_details: (education_details.length>0)?education_details:null,
                desire_details: (desire_details.length>0)?desire_details:null,
                loyalty_details: (loyalty_details.length>0)?loyalty_details:null,
                retirement_details: (retirement_details.length>0)?retirement_details:null,
                cu_details: (cu_details.length>0)?cu_details:null,
                timeframe_details: (timeframe_details.length>0)?timeframe_details:null,
                competencyfits_details: (competencyfits_details.length>0)?competencyfits_details:null,
                overallWeightScore:
                    desireResult === 2 ||
                    loyaltyResult === 2 ||
                    RetirementResult === 2
                      ? 0.0
                      : (
                          parseFloat(predictorCalculation) +
                          parseFloat(experienceCalculation) +
                          parseFloat(concernsCalculation) +
                          parseFloat(performanceCalculation) +
                          parseFloat(educationCalculation)
                        ).toFixed(2),
                desireResult:desireResult,
                loyaltyResult: loyaltyResult,
                RetirementResult : RetirementResult
              })
            }
          }
          successorsDetails[index] = {
            _id:result.data[index]._id,
            record : subRecord
          };
        } 
        setSuccessorDetails(successorsDetails)   
      }
      setTotalDataCount(result.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  useEffect(() => {
    if(successorDetails.length>0 && rawSuccessor.length>0){
      setSuccessors(
        rawSuccessor.map((item) => {
          let check = successorDetails.filter((itm) => itm?._id === item?._id);
          if(check && check.length>0){
            return {
              _id:item?._id,
              is_approved :item?.is_approved,
              approved_name:item?.approved_name,
              approved_date:item?.approved_date,
              name : item.name,
              position: item.position,
              retirement: item.retirement,
              pos_details : item.pos_details,
              created_retirement_details: item.created_retirement_details,
              successors_details : check[0]?.record,
              conclusion: item.conclusion,
              plan_approve:item.plan_approve,
              date:item.date,
              signature:item.signature,
              conclusion_name:item.conclusion_name,
              isInvitedEmployee: item.isInvitedEmployee,
              designation:item?.designation,
              retirement_window:item?.retirement_window
            } 
          }
        })
      )
    }
    
  },[successorDetails,rawSuccessor])

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number((totalDataCount>0)?1:0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  };  

  const getDownload = async (e) => {
    e.preventDefault();
    if(e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, '_blank')
    }
  }  

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t('history')}</div>
      </div>
      <div className="relative flex gap-5 items-center mb-4">
        <div className="relative">
            <Select 
            xPlacement={'bottomLeft'} 
            dropdownButtonClass={'!bg-white'} 
            selectedValue={limit} 
            dropdownData={[
                { _id: 1000000, label: 'All Items',value: 1000000 }, 
                ...[{ _id: 10, label: '10 Items', value: 10 },
                    { _id: 20, label: '20 Items', value: 20 },
                    { _id: 30, label: '30 Items', value: 30 },
                    { _id: 50, label: '50 Items', value: 50 },
                    { _id: 100, label: '100 Items', value: 100 }]
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
            />
        </div>
        <div className="relative">
          {
            (role && role === 'customer-admin') && 
            <div className="relative z-20">
              <DropdownCheckbox data={positions} functions={positionSelect} />
            </div>
          }
        </div>
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
              &nbsp;
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t('completed_by')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t('completion_date')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                {t('report')}
              </th>
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {(totalDataCount && totalDataCount>0) ? (
              successors.map((item, index) => (
                <tr className="border-b border-slate-200" key={index}>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-sm font-semibold text-slate-600">
                        {t('approved_plan_for')} {item?.position} {t('by')} {item?.name}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">
                      {item?.approved_name}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                  {(item && item?.approved_date)?moment(new Date(item?.approved_date)).format('MMM Do YYYY | hh:mm a'):'N/A'}
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <button
                      type={'button'}
                      className={"flex justify-center items-center gap-2 bg-transparent text-teal-500 rounded text-sm h-8 py-0 px-2 transition-all duration-200 cursor-pointer "}
                      onClick={(e) => getDownload(e)}
                    >
                      <i className={"fa-fw fa-regular fa-download"}></i>
                      <div className={"text-xs font-semibold whitespace-nowrap"}>
                      <PDFDownloadLink document={
                        <Document>
                        <SuccessionPlanReport 
                          successors={[item]} 
                          heading={heading} 
                          readinessHeading={readinessHeading}
                          concernHeadings={concernHeadings}
                          predictorsHeading={predictorsHeading}
                          conclusion={(item.conclusion)?item?.conclusion:[]}
                          planApprove={item?.plan_approve}
                          name={item?.conclusion_name}
                          date={item?.date}
                          trimmedDataURL={item?.signature}
                          is_approved ={item?.is_approved}
                          user={user}
                        />
                        </Document>
                      } fileName="scorecard.pdf">
                          {({ blob, url, loading, error }) => (
                            loading ? t('loading_document') : t('download_succession_plan'))}
                      </PDFDownloadLink>
                      </div>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={4}>
                  {
                    loading?<Searching label={t('searching')} />:<NoDataFound label={t('no_data_found')} />
                  }
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>     
      {(noOfPage > 1) ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      ):(
        <div className="flex justify-center sm:justify-between my-4">
          {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}          
        </div>
      )} 
    </>
  );
};

export default History;
