import React, { useState, useRef, useEffect } from "react";

import { allowedImageExtensions } from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import ButtonFile from "../../components/form/ButtonFile";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import Image from "../../components/elements/Image";
import Select from "../../components/form/Select";
import { postData } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../helpers/MetaTag";
import ChangeEmailPopup from "../../components/popup/ChangeEmailPopup";
import { useTranslation } from "react-i18next";

const ManageStaff = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Staff");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Staff");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const [details, setDetails] = useState({});
  const [image, setImage] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [showLogo, setShowLogo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [commission, setCommission] = useState("");
  const [salesCommission, setSalesCommission] = useState();
  const [consultantCommission, setConsultantCommission] = useState();
  const [appointmentSetterCommission, setAppointmentSetterCommission] = useState();
  const [salesAdminCommission, setSalesAdminCommission] = useState();
  const [position, setPosition] = useState("");
  const [roles, setRoles] = useState([]);
  const [enabledEmailPopup, setEnabledEmailPopup] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [actionValue, setActionValue] = useState({});

  const staffCommissionArray = [
    {
      code: "sales-staff",
      commission: salesCommission,
    },
    {
      code: "consultant",
      commission: consultantCommission,
    },
    {
      code: "appointment-setter",
      commission: appointmentSetterCommission,
    },
    {
      code: "sales-admin",
      commission: salesAdminCommission,
    },
  ];

  useEffect(() => {
    // Plan details
    const loadDetails = async () => {
      try {
        const result = await postData("usertype/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    const loadRoles = async () => {
      try {
        const result = await postData("role/list", {
          sortQuery: { ordering: 1 },
          codes: ["master-admin", "consultant", "sales-admin", "sales-staff", "appointment-setter"],
        });
        if (result.data) {
          setRoles(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadRoles();
  }, []);

  const onSubmit = async () => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "usertype/update";
        payload = {
          id: id,
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          //image: image,
          commission: staffCommissionArray,
          password: password,
          confirm_password: confirmPassword,
          role: position,
        };
      } else {
        path = "usertype/store";
        payload = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          //image: image,
          commission: staffCommissionArray,
          password: password,
          confirm_password: confirmPassword,
          role: position,
        };
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message);
        navigate("/salesstaff");
      } else {
        toastr.error(res.message);
      }
    } catch (err) {
      console.log(err.messege);
    }
    setIsManage(false);
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "edit") {
      setEmail(actionValue.email);
    }
  }, [actionValue]);

  useEffect(() => {
    setFirstName(details?.first_name);
    setLastName(details?.last_name);
    setEmail(details?.email);
    //setCommission(details?.commission);
    setPosition(details?.role);
    setPhone(details?.phone);
    if (details.commission && details.commission.length > 0) {
      let sales = details?.commission.filter((item) => item.code === "sales-staff");
      let consultant = details?.commission.filter((item) => item.code === "consultant");
      let appointmentSetter = details?.commission.filter((item) => item.code === "appointment-setter");
      let salesAdmin = details?.commission.filter((item) => item.code === "sales-admin");
      if (sales && sales.length > 0) {
        setSalesCommission(sales[0].commission ? sales[0].commission : 0);
      }
      if (consultant && consultant.length > 0) {
        setConsultantCommission(consultant[0].commission ? consultant[0].commission : 0);
      }
      if (appointmentSetter && appointmentSetter.length > 0) {
        setAppointmentSetterCommission(appointmentSetter[0].commission ? appointmentSetter[0].commission : 0);
      }
      if (salesAdmin && salesAdmin.length > 0) {
        setSalesAdminCommission(salesAdmin[0].commission ? salesAdmin[0].commission : 0);
      }
    }
    setCurrentId(id);
  }, [details]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-3xl text-slate-800 font-bold"> {id ? t("edit_staff") : t("add_staff")}</div>
      </div>
      <div className="relative grid grid-cols-4 gap-4">
        <div className="col-span-4">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="space-y-4 mt-4">
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    <Input label={t("staff_first_name")} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("enter_first_name")} inputValue={firstName} setInput={setFirstName} />
                  </div>
                  <div className="relative w-full">
                    <Input label={t("staff_last_name")} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("enter_last_name")} inputValue={lastName} setInput={setLastName} />
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    <div className="flex items-center justify-between">
                      <div className={"text-xs font-medium text-slate-500 mb-1"}>{t("staff_email")}</div>
                      {id && <Button buttonClasses={"!p-0 !flex !items-center !justify-center !text-[10px] !bg-transparent !text-slate-400 !w-4 !h-4"} buttonType={"button"} buttonIcon={"fa-regular fa-pencil"} buttonIconPosition={"left"} buttonHasLink={false} functions={() => setEnabledEmailPopup(true)} />}
                    </div>
                    <Input label={""} labelClasses={"!text-xs"} inputType={"email"} inputPlaceholder={t("staff_email_address")} inputValue={email} setInput={setEmail} isDisabled={id ? true : false} />
                  </div>
                  <div className="relative w-full">
                    <Input label={t("staff_phone_number")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("staff_phone_number")} inputValue={phone} setInput={setPhone} />
                  </div>
                </div>
                <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div className="relative w-full">
                    <Input label={t("sales_commission")} labelClasses={"!text-xs"} inputType={"number"} inputPlaceholder={t("enter_sales_commission")} inputValue={salesCommission} setInput={setSalesCommission} />
                  </div>
                  <div className="relative w-full">
                    <Input label={t("consultant_commission")} labelClasses={"!text-xs"} inputType={"number"} inputPlaceholder={t("enter_consultant_commission")} inputValue={consultantCommission} setInput={setConsultantCommission} />
                  </div>
                  <div className="relative w-full">
                    <Input label={t("appointment_setter_commission")} labelClasses={"!text-xs"} inputType={"number"} inputPlaceholder={t("enter_appointment_setter_commission")} inputValue={appointmentSetterCommission} setInput={setAppointmentSetterCommission} />
                  </div>
                  <div className="relative w-full">
                    <Input label={t("sales_admin_commission")} labelClasses={"!text-xs"} inputType={"number"} inputPlaceholder={t("enter_sales_admin_commission")} inputValue={salesAdminCommission} setInput={setSalesAdminCommission} />
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {enabledEmailPopup && (
        <ChangeEmailPopup
          isOpen={enabledEmailPopup}
          setIsOpen={(val) => setEnabledEmailPopup(val)}
          data={currentId}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledEmailPopup(false);
            setCurrentId("");
          }}
          message={""}
        />
      )}
    </>
  );
};

export default ManageStaff;
