import React, { useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import ButtonFile from "../form/ButtonFile";
import Image from "../elements/Image";
import { allowedImageExtensions, parseDate, formatDate } from "../../helpers";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";

import { postData } from "../../services/api";
import moment from "moment";
import toastr from "toastr";

import { useTranslation } from "react-i18next";

const ManageCompanyInfoPopup = ({ isOpen, setIsOpen = () => {}, data, getActionValue = () => {} }) => {
  const { i18n, t } = useTranslation();
  const flatPicker = useRef(null);
  //const timezone =  Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long', timeZone: 'America/Denver' });
  const role = localStorage.getItem("role");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [selectEmployee, setSelectEmployee] = useState("");
  const [NoOfEmployee, setNoOfEmployee] = useState("");
  const [price, setPrice] = useState(null);
  const [designation, setDesignation] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [sales, setSales] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [appointmentSetter, setAppointmentSetter] = useState([]);
  const [salesAdmin, setalesAdmin] = useState([]);
  const [salesId, setSalesId] = useState("");
  const [consultantId, setConsultantId] = useState("");
  const [appointmentSetterId, setAppointmentSetterId] = useState("");
  const [salesAdminId, setSalesAdminId] = useState("");
  const [salesCommission, setSalesCommission] = useState(0);
  const [consultantCommission, setConsultantCommission] = useState(0);
  const [appointmentSetterCommission, setAppointmentSetterCommission] = useState(0);
  const [salesAdminCommission, setSalesAdminCommission] = useState(0);
  const [image, setImage] = useState("");
  const [date, setDate] = useState("");
  const [renewalPrice, setRenewalPrice] = useState("");
  const [salesCommissionPay, setSalesCommissionPay] = useState([]);
  const [consultantCommissionPay, setConsultantCommissionPay] = useState([]);
  const [appointmentSetterCommissionPay, setAppointmentSetterCommissionPay] = useState([]);
  const [salesAdminCommissionPay, setSalesAdminCommissionPay] = useState([]);
  const [accountType, setAccountType] = useState("");
  const [position, setPosition] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState("");
  const staffArray = [
    {
      code: "sales-staff",
      role_id: null,
      staff_id: salesId !== "" ? salesId : null,
      commission: salesCommission,
    },
    {
      code: "consultant",
      role_id: null,
      staff_id: consultantId !== "" ? consultantId : null,
      commission: consultantCommission,
    },
    {
      code: "appointment-setter",
      role_id: null,
      staff_id: appointmentSetterId !== "" ? appointmentSetterId : null,
      commission: appointmentSetterCommission,
    },
    {
      code: "sales-admin",
      role_id: null,
      staff_id: salesAdminId !== "" ? salesAdminId : null,
      commission: salesAdminCommission,
    },
  ];
  const [showLogo, setShowLogo] = useState("");
  const [shoeRenewalPrice, setShowRenewalPrice] = useState("");

  const closeModal = () => {
    setIsOpen(false);
  };

  const capitalizeString = (str) => {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const currentTime = moment(new Date(date + " 23:59:00")).format("YYYY-MM-DD HH:mm:ss");
    const formData = new FormData();
    formData.append("id", data?._id);
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("company_name", companyName);
    formData.append("number_of_employees", NoOfEmployee);
    formData.append("plan_id", selectEmployee !== "" ? selectEmployee : null);
    formData.append("designation", designation !== "" ? designation : null);
    formData.append("price", price);
    formData.append("status", status);
    formData.append("sales_id", salesId !== "" ? salesId : null);
    formData.append("consultant_id", consultantId !== "" ? consultantId : null);
    formData.append("appointment_setter_id", appointmentSetterId !== "" ? appointmentSetterId : null);
    //formData.append("end_date", (date)?new Date(currentTime).toUTCString():"");
    formData.append("account_type", accountType);
    formData.append("image", image);
    staffArray.forEach((obj, index) => {
      const objString = JSON.stringify(obj);
      formData.append(`staffs[${index}]`, objString);
    });
    formData.append("subscription_type", subscriptionType);
    //formData.append("renewal_price", renewalPrice);
    try {
      setLoading(true);
      const result = await postData("subscription/company-update", formData);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({
          button_type: "edit",
          row_id: data?._id,
          current_status: "",
        });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPosition(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();

    async function planData() {
      try {
        const result = await postData("plans/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPlans(result.data);
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    planData();

    const loadStaffes = async () => {
      try {
        const result = await postData("usertype/list", {
          sortQuery: { ordering: 1 },
          //code: ["consultant", "sales-staff", "appointment-setter","sales-admin"],
          isStaff: 1,
          status: 1,
        });
        if (result.status === 200) {
          let sales = [];
          let consultants = [];
          let appointmentSetter = [];
          let salesAdmin = [];
          result.data.map((value) => {
            sales.push(value);
            consultants.push(value);
            appointmentSetter.push(value);
            salesAdmin.push(value);
            // if (
            //   value.role_details &&
            //   value.role_details[0].code === "sales-staff"
            // ) {
            //   sales.push(value);
            // } else if (
            //   value.role_details &&
            //   value.role_details[0].code === "consultant"
            // ) {
            //   consultants.push(value);
            // } else if (
            //   value.role_details &&
            //   value.role_details[0].code === "appointment-setter"
            // ) {
            //   appointmentSetter.push(value);
            // }else if(value.role_details && value.role_details[0].code === 'sales-admin'){
            //   salesAdmin.push(value)
            // }
          });
          setSales(sales);
          setConsultants(consultants);
          setAppointmentSetter(appointmentSetter);
          setalesAdmin(salesAdmin);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadStaffes();
  }, []);

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid image");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    setName(data?.name);
    setEmail(data?.email);
    setPhone(data?.phone);
    setCompanyName(data?.company_name);
    setNoOfEmployee(data?.number_of_employees);
    setPrice(data?.price);
    setStatus(data?.status);
    setShowLogo(data?.image);
    setDesignation(data?.designation);
    setSubscriptionType(data?.subscription_type);
    // let dd = timezone.format(new Date(data?.end_date));    
    //setDate(data?.end_date);
    setAccountType(data?.account_type ? data?.account_type : "");
    if (data?.end_date) {
      setDate(moment(new Date(data?.end_date)).tz("America/Denver").format("YYYY-MM-DD"));
    }
    if (data?.staffs && data?.staffs.length > 0) {
      setSalesId(data?.staffs[0]?.staff_id);
      setConsultantId(data?.staffs[1]?.staff_id);
      setAppointmentSetterId(data?.staffs[2]?.staff_id);
      setSalesAdminId(data?.staffs[3]?.staff_id);
      setSalesCommission(data?.staffs[0]?.commission);
      setConsultantCommission(data?.staffs[1]?.commission);
      setAppointmentSetterCommission(data?.staffs[2]?.commission);
      setSalesAdminCommission(data?.staffs[3]?.commission);
    }
    const index = plans.findIndex((item) => item.name === data?.number_of_employees);
    if (index !== -1) {
      setSelectEmployee(plans[index]._id);
    }
    setShowRenewalPrice(data?.renewal_price);
    setSalesCommissionPay(data?.salesCommissionPay);
    setConsultantCommissionPay(data?.consultantCommissionPay);
    setAppointmentSetterCommissionPay(data?.appointmentSetterCommissionPay);
    setSalesAdminCommissionPay(data?.salesAdminCommissionPay);
  }, [data, plans, sales, consultants, appointmentSetter]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {t("manage_information")}
                  </Dialog.Title>
                  <div className="flex flex-col-reverse sm:grid grid-cols-3 gap-4  mt-4">
                    <div className="relative sm:col-span-2">
                      <div className="grid sm:grid-cols-2 gap-3 mb-3">
                        <div className="relative">
                          <Input label={t("company_name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_company_name")} inputValue={companyName} setInput={setCompanyName} />
                        </div>
                        <div className="relative">
                          <Input label={t("company_administrator_name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_company_administrator")} inputValue={name} setInput={setName} />
                        </div>
                      </div>
                      <div className="grid sm:grid-cols-2 gap-3 mb-3">
                        <div className="relative">
                          <Input
                            label={t("administrator_email")}
                            labelClasses={"!text-xs"}
                            inputType={"text"}
                            inputPlaceholder={t("administrator_email")}
                            inputValue={email}
                            setInput={setEmail}
                            //isDisabled={true}
                          />
                        </div>
                        <div className="relative">
                          <Input label={t("administrator_phone")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_administrator_phone")} inputValue={phone} setInput={setPhone} />
                        </div>
                      </div>
                      <div className="grid sm:grid-cols-2 gap-3 mb-3">
                        <div className="relative">
                          <Select
                            label={t("#_Of_employees")}
                            labelClasses={"!text-xs"}
                            xPlacement={"bottomLeft"}
                            selectedValue={selectEmployee}
                            dropdownClass={"!w-60"}
                            dropdownData={[
                              { _id: "", label: t("select_employees"), value: "" },
                              ...plans.map((item) => ({
                                _id: item?._id,
                                label: item?.name,
                                value: item?._id,
                              })),
                            ]}
                            getSelectedValue={(e) => {
                              setNoOfEmployee(e?.label);
                              setSelectEmployee(e?._id);
                            }}
                          />
                        </div>
                        <div className="relative">
                          <Select
                            label={t("position")}
                            labelClasses={"!text-xs"}
                            xPlacement={"bottomLeft"}
                            selectedValue={designation}
                            dropdownClass={"!w-60"}
                            dropdownData={[
                              { _id: "", label: t("select_position"), value: "" },
                              ...position.map((item) => ({ _id: item?._id, label: item?.name, value: item?._id })),
                              // { _id: "others", label: "Other", value: "others" },
                            ]}
                            getSelectedValue={(e) => {
                              // if(e._id === 'others'){
                              //   setEnabledOtherPositionPopup(true);
                              // }
                              setDesignation(e._id);
                            }}
                          />
                        </div>
                      </div>
                      <div className="grid sm:grid-cols-2 gap-3 mb-3">
                        <div className="relative mb-3">
                          <Input label={t("price")} labelClasses={"!text-xs"} inputType={"number"} inputPlaceholder={t("enter_price")} inputValue={price} setInput={setPrice} errorType={"info"} errorText={"Payment Mode: " + capitalizeString(subscriptionType)} isDisabled={subscriptionType && subscriptionType === "stripe" ? true : false} />
                        </div>
                      </div>
                      <div className="grid sm:grid-cols-2 gap-3 mb-3">
                        <div className={"relative " + (salesCommissionPay && salesCommissionPay.length > 0 ? "pointer-events-none opacity-40" : "")}>
                          <Select
                            label={t("sales")}
                            labelClasses={"!text-xs"}
                            xPlacement={"bottomLeft"}
                            selectedValue={salesId}
                            dropdownClass={"!w-60"}
                            dropdownData={[
                              { _id: "", label: t("select_sales"), value: "" },
                              ...sales.map((item) => {
                                let sales = item?.commission.filter((itm) => itm.code === "sales-staff");
                                return {
                                  _id: item?._id,
                                  label: item?.last_name + "," + item?.first_name + ", " + t("salesperson") + " " + (sales[0].commission ? sales[0].commission : 0) + "%",
                                  value: sales[0].commission ? sales[0].commission : 0,
                                };
                              }),
                              { _id: null, label: t("none"), value: null },
                            ]}
                            getSelectedValue={(e) => {
                              setSalesId(e._id);
                              setSalesCommission(e.value);
                            }}
                          />
                        </div>
                        <div className={"relative " + (consultantCommissionPay && consultantCommissionPay.length > 0 ? "pointer-events-none opacity-40" : "")}>
                          <Select
                            label={t("consultant")}
                            labelClasses={"!text-xs"}
                            xPlacement={"bottomLeft"}
                            selectedValue={consultantId}
                            dropdownClass={"!w-60"}
                            dropdownData={[
                              {
                                _id: "",
                                label: t("select_consultant"),
                                value: "",
                              },
                              ...consultants.map((item) => {
                                let consultant = item?.commission.filter((itm) => itm.code === "consultant");
                                return {
                                  _id: item?._id,
                                  label: item?.last_name + "," + item?.first_name + ", " + t("consultant") + " " + (consultant[0].commission ? consultant[0].commission : 0) + "%",
                                  value: consultant[0].commission ? consultant[0].commission : 0,
                                };
                              }),
                              { _id: null, label: t("none"), value: null },
                            ]}
                            getSelectedValue={(e) => {
                              setConsultantId(e._id);
                              setConsultantCommission(e.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="grid sm:grid-cols-2 gap-3 mb-3">
                        <div className={"relative " + (appointmentSetterCommissionPay && appointmentSetterCommissionPay.length > 0 ? "pointer-events-none opacity-40" : "")}>
                          <Select
                            label={t("appointment_setter")}
                            labelClasses={"!text-xs"}
                            xPlacement={"bottomLeft"}
                            selectedValue={appointmentSetterId}
                            dropdownClass={"!w-60"}
                            dropdownData={[
                              {
                                _id: "",
                                label: t("select_appointment_setter"),
                                value: "",
                              },
                              ...appointmentSetter.map((item) => {
                                let appointmentSetter = item?.commission.filter((itm) => itm.code === "appointment-setter");
                                return {
                                  _id: item?._id,
                                  label: item?.last_name + "," + item?.first_name + ", " + t("appt_setter") + " " + (appointmentSetter[0].commission ? appointmentSetter[0].commission : 0) + "%",
                                  value: appointmentSetter[0].commission ? appointmentSetter[0].commission : 0,
                                };
                              }),
                              { _id: null, label: t("none"), value: null },
                            ]}
                            getSelectedValue={(e) => {
                              setAppointmentSetterId(e._id);
                              setAppointmentSetterCommission(e.value);
                            }}
                          />
                        </div>
                        <div className={"relative " + (salesAdminCommissionPay && salesAdminCommissionPay.length > 0 ? "pointer-events-none opacity-40" : "")}>
                          <Select
                            label={t("sales_admin")}
                            labelClasses={"!text-xs"}
                            xPlacement={"bottomLeft"}
                            selectedValue={salesAdminId}
                            dropdownClass={"!w-60"}
                            dropdownData={[
                              { _id: "", label: t("select_sales_admin"), value: "" },
                              ...salesAdmin.map((item) => {
                                let salesAdmin = item?.commission.filter((itm) => itm.code === "sales-admin");
                                return {
                                  _id: item?._id,
                                  label: item?.last_name + "," + item?.first_name + ", " + t("sales_admin") + " " + (salesAdmin[0].commission ? salesAdmin[0].commission : 0) + "%",
                                  value: salesAdmin[0].commission ? salesAdmin[0].commission : 0,
                                };
                              }),
                              { _id: null, label: t("none"), value: null },
                            ]}
                            getSelectedValue={(e) => {
                              setSalesAdminId(e._id);
                              setSalesAdminCommission(e.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="border-t border-slate-200 my-4"></div>
                      <div className="grid sm:grid-cols-2 gap-3">
                        <div className="relative">
                          <Select
                            xPlacement={"bottomLeft"}
                            selectedValue={accountType}
                            dropdownClass={"!w-60"}
                            dropdownButtonLabelClass={"!font-bold"}
                            dropdownData={[
                              { _id: "", label: t("select_account_type"), value: "" },
                              { _id: "business", label: capitalizeString("business"), value: "business" },
                              { _id: "demonstration", label: capitalizeString("demonstration"), value: "demonstration" },
                              { _id: "consultant", label: capitalizeString("consultant"), value: "consultant" },
                              { _id: "developer", label: capitalizeString("developer"), value: "developer" },
                              { _id: "training", label: capitalizeString("training"), value: "training" },
                            ]}
                            dropdownOptionBold={true}
                            getSelectedValue={(e) => setAccountType(e._id)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="relative">
                      <div className="border border-slate-200 rounded overflow-hidden">
                        <div className="w-full overflow-hidden rounded-md flex items-center">
                          <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_image")} buttonClasses={"!w-full !absolute !opacity-0 !z-0 !top-0 !left-0"} functions={onImgChange} accepts={"image/*"} />
                          <Image src={showLogo} alt={"Admin"} width={"100%"} effect={"blur"} classes={"object-fit"} id={"image1"} />
                        </div>
                        <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                          <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_image")} buttonClasses={"!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"} functions={onImgChange} accepts={"image/*"} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex justify-center border-t border-slate-200 mt-4 pt-4 gap-4">
                    <Button buttonClasses="!bg-teal-500" buttonLabelClasses="" buttonType={"button"} buttonIcon={loading ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("confirm")} functions={onSubmit} buttonHasLink={false} />
                    <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={"fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={closeModal} buttonHasLink={false} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ManageCompanyInfoPopup;
