import React, { useState, useEffect } from "react";
import { postData } from "../../services/api";
import ActionDiv from "../elements/actionDiv";
import Searching from "../Searching";
import NoDataFound from "../NoDataFound";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

const SecFaq = () => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [faqs, setfaqs] = useState([]);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      const result = await postData("/faq/list", {
        sortQuery: sortQuery,
      });
      if (result.data) {
        setfaqs(
          result.data.map((item, index) => {
            if (index === 0) {
              return {
                ...item,
                isOpen: true,
              };
            } else {
              return {
                ...item,
                isOpen: false,
              };
            }
          })
        );
        setLoading(false);
      }
    };
    loadList();
  }, [sortQuery, isUpdate]);

  const handelUpdate = async (id) => {
    setfaqs(
      faqs.map((itm) => {
        if (itm?._id === id) {
          return {
            ...itm,
            isOpen: true,
          };
        } else {
          return {
            ...itm,
            isOpen: false,
          };
        }
      })
    );
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("faq/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = faqs.findIndex((item) => item._id === actionValue?.row_id);
            if (index !== -1) {
              faqs.splice(index, 1);
              setfaqs(
                faqs.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    }
  }, [actionValue]);

  return (
    <div className="grid divide-y divide-neutral-200 mx-auto mt-8">
      {faqs.length > 0 ? (
        faqs.map((item, index) => (
          <div className="py-5" key={index}>
            <div className="group">
              <div
                className="flex justify-between items-center font-medium cursor-pointer list-none space-x-2"
                onClick={
                  () => handelUpdate(item?._id)
                  //setAccordionOpen(!accordionOpen)
                }
              >
                <span className="font-bold text-teal-500 text-base !leading-tight flex-shrink">{item?.question?.[i18n?.language]}</span>
                <div className="flex items-center justify-end">
                  {role && role === "master-admin" && window.location.pathname !== "/frequently-asked-questions" && (
                    <ActionDiv
                      rowId={item?._id}
                      editUrl={"/edit-faq"}
                      isDeleteEnabled={true}
                      getActionValue={(obj) => {
                        setActionValue(obj);
                      }}
                    />
                  )}
                  <span className={"transition-all duration-300 w-6 h-6 flex-shrink-0 " + (item?.isOpen ? "rotate-0" : "-rotate-90")}>
                    <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24">
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </div>
              </div>
              {item?.answer && Object.keys(item?.answer).length > 0 && (
                <div className={"divide-y divide-slate-100 transition-all duration-300 overflow-hidden " + (item?.isOpen ? "max-h-[2000px] opacity-100 visible" : "max-h-0 opacity-0 invisible")}>
                  <div className="text-neutral-600 text-sm py-3">{item?.answer?.[i18n?.language]}</div>
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="py-5">{loading ? <Searching label={"Searching"} /> : <NoDataFound label={"Please make a selection from the dropdown to generate report"} />}</div>
      )}
    </div>
  );
};

export default SecFaq;
