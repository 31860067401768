import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Input from '../form/Input';
import Textarea from '../form/Textarea';

import { postData } from '../../services/api';
import toastr from 'toastr';
import { useTranslation } from "react-i18next";

const ChangeEmailPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {}, 
  type,
}) => { 
  const { i18n,t } = useTranslation();
  const role = localStorage.getItem("role");  
  const [step,setStep] = useState(1);
  const [email,setEmail] = useState('');
  const [loading,setLoading] = useState(false);
  const [otp,setOtp] = useState('');
  const [isVerify,setIsVerify] = useState(false);
  const [countDown,setCountDown] = useState('(00:00)');
  const [isDisabled,setIsDisabled] = useState(false);
  const [isResendOtp,setIsResendOtp] = useState(false);

  const closeModal = () =>{ setIsOpen(false) } 
  
  const onSubmit = async () => {
      try {
        setLoading(true);
        const result = await postData("email/verify", {
          email : email,
          _id : data,
        });
        if (result.status && result.status === 200) {  
          if(role && role === 'master-admin'){
            getActionValue({
              button_type: "edit",
              row_id: data,
              current_status: "",
              email:email
            });
            setIsOpen(false);
          }else{
            setStep(2);          
            otpCounter();
          }
          setLoading(false);
          toastr.success(result.message);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setLoading(false);
      }
  } 

  const onVerify = async (e) => {
    e.preventDefault();
    setIsVerify(true);
    try {
      const userData = await postData("email/update", {
        email: email,
        otp : otp,
      });
      if (userData.status && userData.status === 200) {
        setIsVerify(false);
        getActionValue({
          button_type: "edit",
          row_id: data,
          current_status: "",
          email:email
        });
        setIsOpen(false);
        toastr.success(userData.message);
      } else {
        toastr.error(userData.message);
        setIsVerify(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsVerify(false);
    }
  };
  
  const onResendOtp = async (e) => {
    e.preventDefault();
    setIsResendOtp(true);
    try {
      const userData = await postData("email/verify", {
        email: email,
      });
      if (userData.status && userData.status === 200) {
        setIsResendOtp(false);
        otpCounter();
        toastr.success(userData.message);
      } else {
        toastr.error(userData.message);
        setIsResendOtp(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsResendOtp(false);
    }
  };

  const otpCounter = () => {
    setIsDisabled(true);
    const count_time5 = add_minutes(new Date(), 0.5).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
    const count_time20 = new Date(count_time5).getTime();
    const x20 = setInterval(function() {
      const timezone20 = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      const now20 = new Date(timezone20).getTime();
      const distance20 = count_time20 - now20;
      const days20 = Math.floor(distance20 / (1000 * 60 * 60 * 24));
      const hours20 = Math.floor((distance20 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes20 = Math.floor((distance20 % (1000 * 60 * 60)) / (1000 * 60));
      const seconds20 = Math.floor((distance20 % (1000 * 60)) / 1000);
      // Output the result in an element with id="demo"
      setCountDown('('+ ('0'+minutes20).slice(-2) + ":" + ('0'+seconds20).slice(-2) +')');
      // If the count down is over, write some text 
      if (distance20 < 0) {
          clearInterval(x20);
          setCountDown('(00:00)');
          setIsDisabled(false);
      }
    }, 1000);
  } 
  
  const add_minutes = (dt2, minutes2) => {
    return new Date(dt2.getTime() + minutes2*60000);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('change_email')}
                    </Dialog.Title>
                    {
                      (step && step === 1)?
                      <>
                      <div className="relative mt-4">
                        <div className="relative mb-3">
                          <Input 
                          label={t('email')} 
                          labelClasses={'!text-xs'} 
                          inputType={'text'}                   
                          inputPlaceholder={t('enter_email')}
                          inputValue={email}  
                          setInput={setEmail} 
                          />
                        </div>                                   
                      </div>
                      <div className="relative flex justify-center mb-3 mt-6 gap-4">
                          <Button
                          buttonClasses='!bg-teal-500'
                          buttonLabelClasses='' 
                          buttonType={'button'} 
                          buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                          buttonIconPosition={'left'} 
                          buttonLabel={t('confirm')}
                          functions={onSubmit}
                          buttonHasLink={false}
                          buttonDisabled={loading}
                          />
                          <Button 
                          buttonLabelClasses=''
                          buttonType={'button'} 
                          buttonIcon={'fa-light fa-times'} 
                          buttonIconPosition={'left'} 
                          buttonLabel={t('cancel')} 
                          buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                          functions={closeModal}
                          buttonHasLink={false}
                          />
                      </div>
                      </>:
                      <>
                      <div className="relative">
                        <Input
                            label={"Verification Code"}
                            labelClasses={"!text-xs"}
                            inputType={"number"}
                            inputPlaceholder={"Enter Verification Code"}
                            inputValue={otp}
                            setInput={setOtp}
                            errorType={"info"}
                            errorText={countDown}
                        />
                      </div>
                      <div className="relative flex justify-center gap-4">
                        <Button
                            buttonClasses=""
                            buttonLabelClasses=""
                            buttonType={"button"}
                            buttonIcon={(isVerify)?"fa-light fa-spinner fa-spin":"fa-light fa-arrow-right-to-arc"}
                            buttonIconPosition={"left"}
                            buttonLabel={"Submit"}
                            functions={onVerify}
                            buttonHasLink={false}
                            buttonDisabled={isVerify}
                        />
                        <Button
                            buttonLabelClasses=""
                            buttonClasses={(isDisabled)?'!bg-slate-200 !text-slate-600 border border-slate-300 pointer-events-none':''} 
                            buttonType={"button"}
                            buttonIcon={(isResendOtp)?"fa-light fa-spinner fa-spin":"fa-light fa-clock"}
                            buttonIconPosition={"left"}
                            buttonLabel={"Resend OTP"}
                            functions={onResendOtp}
                            buttonHasLink={false}
                            buttonDisabled={isDisabled}
                        />
                      </div>
                      </>
                    }                    
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default ChangeEmailPopup;
