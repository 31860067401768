import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import ReactPaginate from "react-paginate";
import { postData } from "../services/api";
import SuccessorPopup from "../components/popup/SuccessorPopup";
import DeletePopup from "../components/DeletePopup";
import NoDataFound from "../components/NoDataFound";
import Searching from "../components/Searching";
import moment from 'moment';
import toastr from "toastr";

import { useTranslation } from "react-i18next";

const NonRatedEmployees = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Non-rated Employees");
  const { i18n,t } = useTranslation();
  const role = localStorage.getItem("role");  
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [successor, setSuccessor] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount,setStartDataCount] = useState(0);
  const [endDataCount,setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [enabledPopup, setEnabledPopup] = useState(false);
  const [editData, setEditData] = useState({});
  const [enabledDeletePopup, setenabledDeletePopup] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [message, setMessage] = useState("");
  const [isChange, setIsChage] = useState(false);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadPosition = async () => {
      setLoading(true);
      try {
        const result = await postData("successor/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          status: 1,
          is_interim: 1,
        });
        if (result.data) {
          setSuccessor(result.data);
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPosition();
  }, [limit, offset,actionValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number((totalDataCount>0)?1:0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  };  

  useEffect(() => {
    const loadDeleteRecord = async () => {
      setIsChage(false);
      try {
        const statusData = await postData("successor/delete", {
          id: actionValue?.row_id,
        });
        if (statusData.status === 200) {
          const index = successor.findIndex(
            (item) => item._id === actionValue?.row_id
          );
          if (index !== -1) {
            successor.splice(index, 1);
            setSuccessor(
              successor.map((it) => {
                return it;
              })
            );
          }
          setIsChage(true);
        } else {
          toastr.error(statusData.message);
        }
      } catch (error) {
        toastr.error(error.message);
      }
    };
    if (actionValue && actionValue.button_type === "delete") {
      loadDeleteRecord();
    }
  }, [actionValue]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t('non_rated_employees')}</div>
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
              {t('name')}
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                {t('date_added')}
              </th>
              {/* <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">
                Considered Position(s)
              </th> */}
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
              {t('action')}
              </th>
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {totalDataCount > 0 ? (
              successor.map((item, index) => (
                <tr className="border-b border-slate-200" key={index}>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-sm font-semibold text-slate-600">
                        {(item?.name)?item?.name:''}
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">
                        {(item && item?.created_at)?moment(new Date(item?.created_at)).format('Do MMM YYYY'):'N/A'}
                      </div>
                    </div>
                  </td>
                  {/* <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">
                        {item?.position_details? item?.position_details[0]?.short_name : "N/A"}
                      </div>
                    </div>
                  </td> */}
                  <td className="py-3 px-2 text-left max-w-[240px] flex justify-start">
                    <Button
                      buttonClasses={"!p-0 !flex !items-center !justify-center !text-[10px] !bg-transparent !text-slate-400 !w-4 !h-4"}
                      buttonType={"button"}
                      buttonIcon={"fa-regular fa-pencil"}
                      buttonIconPosition={"left"}
                      buttonHasLink={false}
                      functions = {() => {
                        setEnabledPopup(true);
                        setEditData(item)
                      }}
                    />
                    <Button
                      buttonClasses={"!p-0 !flex !items-center !justify-center !text-sm !bg-transparent !text-rose-500 !w-4 !h-4"
                      }
                      buttonType={"button"}
                      buttonIcon={"fa-regular fa-xmark"}
                      buttonIconPosition={"left"}
                      buttonHasLink={false}
                      functions={() => {
                        setenabledDeletePopup(true);
                        setCurrentId(item?._id);
                        setMessage(
                          t('you_are_about_to_delete_the_employee') + item?.name +
                            t('please_click_confirm_to_delete_this_successor')
                        );
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={3}>
                  {
                    loading?<Searching label={t('searching')} />:<NoDataFound label={t('no_data_found')} />
                  }
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {(noOfPage > 1) ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      ):(
        <div className="flex justify-center sm:justify-between my-4">
          {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}          
        </div>
      )}
      {enabledPopup && (
        <SuccessorPopup
          isOpen={enabledPopup}
          setIsOpen={(val) => setEnabledPopup(val)}
          data={editData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></SuccessorPopup>
      )}
      {enabledDeletePopup && (
        <DeletePopup
          isOpen={enabledDeletePopup}
          setIsOpen={(val) => setenabledDeletePopup(val)}
          currentId={currentId}
          getActionValue={(obj) => {
            setActionValue(obj);
            setenabledDeletePopup(false);
            setCurrentId("");
          }}
          message={message}
        />
      )}
    </>
  );
};

export default NonRatedEmployees;
