import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from "../../logo-succession-now.png";
import moment from 'moment/moment';

const ConclusionIndividualReport = ({ successors, heading, user,users }) => {
  const styles = StyleSheet.create({
    page: { padding: 20, color: '#334155' },
    tableHeading: {
      fontSize: 12,
      width: '20%',
      padding: 5
    },
    tableData: {
      fontSize: 10,
      width: '20%',
      fontSize: 10,
      padding: 5
    },
    tableOtherData: {
      fontSize: 10
    },
    image: {
      width: 200,
      height: 40,
      objectFit: 'contain',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: 'contain',
    },
    section: { textAlign: 'center', }

  });
  return (
    <Document>
      <Page size="A4" orientation='landscape' style={styles.page} >
        <View style={[styles.section, { color: 'white' }]}>
          <Text>Section #1</Text>
        </View>

        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
            // src='https://successionnow.iosx.in/logo-succession-now.png'
            />
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 20, textTransform: 'capitalize' }]}>Company Name: {user?.company_name}</Text>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16, textTransform: "capitalize" }]}>
              Succession Plan for {user?.title}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by :</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{user?.name} ({user?.title})</Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
        {successors && successors.length > 0 &&
          successors.map((row, key) => {
            return (
              <>
                {(row.conclusion && row.conclusion.length > 0) &&
                  <View style={{ marginTop: 12 }}>
                    <View style={[styles.tableOther, { display: "flex", flexDirection: "row", columnGap: 20, marginTop: 5, marginBottom: 5, }]} key={key}>
                      <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12 }]}>{row.position} : {row.name}</Text>
                      <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12 }]}>Retirement Window : {(row?.retirement)?row?.retirement?.replace(/(Eliminates this candidate)/gi,'').replace(/[{()}]/g, ''):''}</Text>
                    </View>
                    <View style={{ border: '1px solid #e2e8f0', borderRadius: 4, overflow: 'hidden' }} key={key}>
                      <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f8fafc', overflow: 'hidden' }}>
                        <Text style={[{ fontWeight: 600, fontSize: 12, width: '20%', padding: 5 }]}>Choices</Text>
                        <View style={[{ fontWeight: 600,  fontSize: 12, width: '20%', padding: 5 }]}>
                          <Text style={{ fontWeight: 600 }}>Time Horizon </Text>
                          <Text style={{ fontSize: 8 }}>(If 0-1 Year)</Text>
                        </View>
                        <View style={[{ fontWeight: 600,  fontSize: 12, width: '20%', padding: 5 }]}>
                          <Text style={{ fontWeight: 600 }}>Time Horizon </Text>
                          <Text style={{ fontSize: 8 }}>(If 1-3 Year)</Text>
                        </View>
                        <View style={[{ fontWeight: 600,  fontSize: 12, width: '20%', padding: 5 }]}>
                          <Text style={{ fontWeight: 600 }}>Time Horizon </Text>
                          <Text style={{ fontSize: 8 }}>(If 3-5 Year)</Text>
                        </View>
                        <View style={[{ fontWeight: 600, display: 'flex', backgroundColor: '#ef4444', flexDirection: 'row', color: 'white', flexWrap: 'wrap', fontSize: 12, width: '20%', padding: 5 }]}>
                          <Text style={{ fontWeight: 600 }}>Emergency or Interim</Text>
                          <Text style={{ fontSize: 8 }}>&nbsp;</Text>
                        </View>
                      </View>

                      {row.conclusion && row.conclusion.length > 0 && row.conclusion.map((item, index) => {
                        let zero_to_one = users.filter((itm) => itm?._id === item?.time_horizon_zero_to_one)
                        let one_to_three = users.filter((itm) => itm?._id === item?.time_horizon_one_to_three)
                        let three_to_five = users.filter((itm) => itm?._id === item?.time_horizon_three_to_five)
                        return (
                          <View key={index} style={{ display: 'flex', flexDirection: 'row', borderTop: "1px solid #e2e8f0" }}>
                            <Text style={[styles.tableData, { fontWeight: 600 }]}>{item.name}</Text>
                            <View style={[styles.tableData, { fontWeight: 600, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }]}>
                              <Text style={{ fontWeight: 600 }}>{item.isComment ? item?.comment_zero_to_one : ((zero_to_one && zero_to_one.length>0) || item?.time_horizon_zero_to_one === 'external-hire')?item?.time_horizon_zero_to_one_text:''}</Text>
                            </View>
                            <View style={[styles.tableData, { fontWeight: 600, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }]}>
                              <Text style={{ fontWeight: 600 }}>{item.isComment ? item?.comment_one_to_three : ((one_to_three && one_to_three.length>0) || item?.time_horizon_one_to_three === 'external-hire')?item?.time_horizon_one_to_three_text:''}</Text>
                            </View>
                            <View style={[styles.tableData, { fontWeight: 600, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }]}>
                              <Text style={{ fontWeight: 600 }}>{item.isComment ? item?.comment_three_to_five : ((three_to_five && three_to_five.length>0) || item?.time_horizon_three_to_five === 'external-hire')?item?.time_horizon_three_to_five_text:''}</Text>
                            </View>
                            <View style={[styles.tableData, { fontWeight: 600, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }]}>
                              <Text style={{ fontWeight: 600 }}>{item.isComment ? item?.comment_replacement : item?.replacement_text}</Text>
                            </View>
                          </View>
                        )
                      }
                      )}
                    </View>
                  </View>
                }
              </>
            )
          })
        }
      </Page>
    </Document>
  );
};

export default ConclusionIndividualReport;