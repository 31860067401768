import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../helper/useWindowDimensions";
import { classNames } from "../../helper/classNames";

const SidebarSubMenuItem = ({ 
  menuIcon, 
  menuIconColor, 
  menuLabel, 
  submenuData, 
  isMenuOpen, 
  setIsMenuOpen,
  isStepMenu, 
  isCollapse,
  ...props }) => {
  const { i18n,t } = useTranslation();
  const [submenuToggle, setSubmenuToggle] = useState(false);
  const { height, width } = useWindowDimensions();

  const handleToggle = () => {
    setSubmenuToggle(!submenuToggle);
  };

  const handleChange = () => {
    if (width < 1025) {
      setIsMenuOpen(!isMenuOpen);
    }
  }
  
  useEffect(()=>{
    if(isCollapse){
      setSubmenuToggle(true);
    }else{
      setSubmenuToggle(false);
    }
  },[isCollapse,window.location.pathname])
  return (
    <>
      <button
        type="button"
        className={"group flex w-full items-center gap-1 text-left hover:bg-primary-500 rounded overflow-hidden p-1 transition-all duration-200"}
        onClick={handleToggle}
        title={menuLabel}
      >
        <div className={classNames("w-8 min-w-[32px] h-8 flex justify-center items-center group-hover:text-white text-lg transition-all duration-200", menuIconColor)}>
          <i className={classNames("fa-light fa-fw", menuIcon)}></i>
        </div>
        {width > 1024 ? (!isMenuOpen && <div className="text-white w-full text-sm">{menuLabel}</div>) : (<div className="text-white w-full text-sm">{menuLabel}</div>)}
        <div className={classNames(
          "w-8 min-w-[32px] h-8 flex justify-center items-center text-white/50 text-xs transition-all duration-200", 
          submenuToggle ? "rotate-90" : ""
          )}>
          <i className="fa-light fa-fw fa-chevron-right"></i>
        </div>
      </button>
      <div
        className={classNames(
          "space-y-0.5 transition-all duration-200 border-white/10",
          submenuToggle ? "opacity-100 visible max-h-96 !mb-4" : "opacity-0 invisible max-h-0 !my-0",
          width > 1024 ? (!isMenuOpen ? "ml-4 pl-2 border-l " : ""):""
        )}
      >
        {submenuData?.map((items, index) => (
          <NavLink
            key={items._id}
            to={items.menuLink}
            className={({ isActive }) => classNames(
              "group flex items-center gap-2 hover:bg-teal-500 rounded overflow-hidden p-1 transition-all duration-200", 
              isActive ?' !bg-teal-500':'',
              items.isLocked ?' opacity-20 cursor-not-allowed pointer-events-none':''
          )}
            //{"text-white font-light flex items-center text-sm px-3 py-2 rounded opacity-50 hover:opacity-100 transition-all duration-200 hover:bg-primary-500/50 "+ (items.isLocked ?' opacity-20 cursor-not-allowed pointer-events-none':'')}
            title={items.menuLabel}
            onClick={handleChange}
          >
          {({ isActive }) => (
          <>
                <div className={classNames(
                  "w-8 h-8 min-w-[32px] flex justify-center items-center group-hover:text-white text-lg transition-all duration-200", 
                  items.isLocked?"text-slate-200":items.menuIconColor, 
                  isActive?' !text-white':''
                )}>
            {items.isStepMenu ? (
              <>
                <div className={classNames(
                  "flex flex-col justify-center items-center rounded-full border border-slate-700 group-hover:border-white/40 w-full h-full", 
                  isActive?' !border-white/40':''
                )}>
                  <div className="text-[8px] uppercase leading-none">{t('step')}</div>
                  <div className="text-[11px] leading-none">{index + 1}</div>
                </div>
              </>
            ) : (
              <>{items.isLocked ? <i className={"fa-light fa-fw fa-lock"}></i> : <i className={"fa-light fa-fw " + menuIcon}></i>}</>
            )}
          </div>
          {width > 1024 ? 
          (!isMenuOpen ? <div className="text-white text-sm">{items.menuLabel}</div> : "") :
          (<div className="text-white text-sm">{items.menuLabel}</div>)
          }
          </>
          )}
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default SidebarSubMenuItem;
