import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link } from "react-router-dom";

const CmsTab = ({ xPlacement, ...props }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const segment = pathname.substring(pathname.lastIndexOf("/") + 1);

  const dropdownData = [
    {
      _id: 1,
      label: "Positions",
      icon: "fa-users-crown",
      link: "/positions",
      type: "link",
      current: segment && segment === "positions" ? true : false,
    },
    {
      _id: 2,
      label: "Experiences",
      icon: "fa-level-up",
      link: "/experiences",
      type: "link",
      current: segment && segment === "experiences" ? true : false,
    },
    {
      _id: 3,
      label: "Educations",
      icon: "fa-school",
      link: "/educations",
      type: "link",
      current: segment && segment === "educations" ? true : false,
    },
    {
      _id: 4,
      label: "Desire to Advance",
      icon: "fa-arrow-up",
      link: "/desires",
      type: "link",
      current: segment && segment === "desires" ? true : false,
    },
    {
      _id: 5,
      label: "Loyalty",
      icon: "fa-trophy",
      link: "/loyalties",
      type: "link",
      current: segment && segment === "loyalties" ? true : false,
    },
    {
      _id: 6,
      label: "Retirement Window",
      icon: "fa-clock",
      link: "/returements",
      type: "link",
      current: segment && segment === "returements" ? true : false,
    },
    {
      _id: 7,
      label: "Performance",
      icon: "fa-bar-chart",
      link: "/performances",
      type: "link",
      current: segment && segment === "performances" ? true : false,
    },
    {
      _id: 8,
      label: "Concern Heading",
      icon: "fa-shield-exclamation",
      link: "/concern-headings",
      type: "link",
      current: segment && segment === "concern-headings" ? true : false,
    },
    {
      _id: 9,
      label: "Predictor Headings",
      icon: "fa-user-check",
      link: "/predictor-headings",
      type: "link",
      current: segment && segment === "predictor-headings" ? true : false,
    },
  ];

  return (
    <>
      <ul
        className="w-full flex flex-row list-none border-b-2 pl-0 mb-4 border-slate-200 space-x-6 overflow-x-auto overflow-y-hidden scroll-smooth scrollbar"
        id="tabs-tab"
        role="tablist"
      >
        {dropdownData.map((item, index) => (
          <li className="-mb-[2px]" role="presentation" key={index}>
            <Link
              to={item.link}
              className={
                "nav-link flex items-center justify-center font-semibold text-xs leading-tight uppercase h-10 text-slate-400 border-b-2 border-transparent whitespace-nowrap " +
                (item.current ? "!border-teal-500 !text-teal-500" : "")
              }
              id="tabs-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#tabs-home"
              role="tab"
              aria-controls="tabs-home"
              aria-selected="true"
            >
              {item?.label}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};
export default CmsTab;
