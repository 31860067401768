import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";
import NoDataFound from "../NoDataFound";
import Searching from "../Searching";

import { postData } from "../../services/api";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

const ManagePermissionPopup = ({ isOpen, setIsOpen = () => {}, data, getActionValue = () => {} }) => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userPermission, setUserPermission] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);
  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const result = await postData("usertype/manage-permission", {
        id: data?._id,
        permission: userPermission,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        getActionValue({ button_type: "edit", row_id: data?._id, current_status: "" });
        setIsOpen(false);
        setLoading(false);
      } else {
        toastr.error(result.message);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setUserPermission(data?.permission);
    async function RolePermissionData() {
      setProcessing(true);
      try {
        const results = await postData("role-permission/list", {
          sortQuery: { ordering: 1 },
          status: 1,
        });
        if (results.data) {
          setRolePermission(
            results.data.map((item, key) => {
              if (item._details && item._details.length > 0) {
                let _details = item._details.map((value, index) => {
                  let matchCode = data?.permission.filter((item) => item === value?.code);
                  if (matchCode && matchCode.length > 0) {
                    return {
                      _id: value?._id,
                      title: value?.title,
                      code: value?.code,
                      isEnabled: true,
                    };
                  } else {
                    return {
                      _id: value?._id,
                      title: value?.title,
                      code: value?.code,
                      isEnabled: false,
                    };
                  }
                });
                return {
                  type_name: item.type_name,
                  _id: item._id,
                  _details: _details,
                };
              } else {
                return {
                  type_name: item.type_name,
                  _id: item._id,
                  _details: [],
                };
              }
            })
          );
          setProcessing(false);
        } else {
          console.log("role list message", results.message);
          setProcessing(false);
        }
      } catch (error) {
        console.log("Error role list catch", error.message);
        setProcessing(false);
      }
    }
    RolePermissionData();
  }, [data]);

  const updateHandler = (code, event) => {
    if (event) {
      setUserPermission((userPermission) => [...userPermission, code]);
      setRolePermission(
        rolePermission.map((item, key) => {
          if (item._details && item._details.length > 0) {
            let _details = item._details.map((value, index) => {
              if (value?.code === code) {
                return {
                  ...value,
                  isEnabled: true,
                };
              } else {
                return value;
              }
            });
            return {
              type_name: item.type_name,
              _id: item._id,
              _details: _details,
            };
          } else {
            return {
              type_name: item.type_name,
              _id: item._id,
              _details: [],
            };
          }
        })
      );
    } else {
      setUserPermission((userPermission) => userPermission.filter((item) => item !== code));
      setRolePermission(
        rolePermission.map((item, key) => {
          if (item._details && item._details.length > 0) {
            let _details = item._details.map((value, index) => {
              if (value?.code === code) {
                return {
                  ...value,
                  isEnabled: false,
                };
              } else {
                return value;
              }
            });
            return {
              type_name: item.type_name,
              _id: item._id,
              _details: _details,
            };
          } else {
            return {
              type_name: item.type_name,
              _id: item._id,
              _details: [],
            };
          }
        })
      );
    }
  };

  const selectAllHandler = (type, event) => {
    if (event) {
      const index = rolePermission.findIndex((item) => item._id === type);
      let codes = [];
      if (index !== -1) {
        rolePermission.map((item, key) => {
          if (key === index) {
            item._details.length > 0 &&
              item._details.map((value) => {
                codes.push(value?.code);
              });
          }
        });
        setUserPermission((userPermission) => [...userPermission, ...codes]);
      }
      setRolePermission(
        rolePermission.map((item, key) => {
          if (item._details && item._details.length > 0) {
            let _details;
            if (index !== -1 && index === key) {
              _details = item._details.map((value, index) => {
                return {
                  ...value,
                  isEnabled: true,
                };
              });
            } else {
              _details = item._details;
            }
            return {
              type_name: item.type_name,
              _id: item._id,
              _details: _details,
            };
          } else {
            return {
              type_name: item.type_name,
              _id: item._id,
              _details: [],
            };
          }
        })
      );
    } else {
      const index = rolePermission.findIndex((item) => item._id === type);
      let codes = [];
      if (index !== -1) {
        rolePermission.map((item, key) => {
          if (key === index) {
            item._details.length > 0 &&
              item._details.map((value) => {
                codes.push(value?.code);
              });
          }
        });

        // setUserPermission(
        //   userPermission.map((item) => {
        //     if(codes.filter((itm) => itm === item)){
        //       return '';
        //     }else{
        //       return item;
        //     }
        //   }).filter((it) => it)
        // );
      }
      setRolePermission(
        rolePermission.map((item, key) => {
          if (item._details && item._details.length > 0) {
            let _details;
            if (index !== -1 && index === key) {
              _details = item._details.map((value, index) => {
                return {
                  ...value,
                  isEnabled: false,
                };
              });
            } else {
              _details = item._details;
            }
            return {
              type_name: item.type_name,
              _id: item._id,
              _details: _details,
            };
          } else {
            return {
              type_name: item.type_name,
              _id: item._id,
              _details: [],
            };
          }
        })
      );
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {t("manage_permission")}
                  </Dialog.Title>
                  <div className="relative mt-4 w-full">
                    {rolePermission.length > 0 ? (
                      rolePermission.map((item, index) => (
                        <div className="flex flex-col items-center gap-3 py-2  " key={index}>
                          <div className="w-full ">
                            <div className=" items-center gap-3">
                              <div className="w-full flex items-center justify-between">
                                <div className="text-slate-800 font-semibold">{item?.type_name}</div>
                                {/* <Checkbox
                                    checkboxLableClass={''}
                                    checkboxInputClass={''}
                                    getCheckboxValue={(code,event) => selectAllHandler(item?._id,event.target.checked) }
                                  /> */}
                              </div>
                              <div className="flex items-start gap-y-2 gap-x-4 mt-2 flex-wrap">
                                {" "}
                                {item &&
                                  item._details.length > 0 &&
                                  item._details.map((itm, idx) => {
                                    // let isCheck = (userPermission.length>0)?userPermission.filter((item) => item === itm?.code)?true:false:false;
                                    return (
                                      <div key={idx}>
                                        <Checkbox checkboxLableClass={""} checkboxInputClass={""} checkboxName={itm?.code} isChecked={itm?.isEnabled} checkboxLabel={itm?.title} checkboxValue={itm?.code} getCheckboxValue={(code, event) => updateHandler(code, event.target.checked)} />
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          <div className="w-full"></div>
                        </div>
                      ))
                    ) : processing ? (
                      <Searching label={"Searching"} />
                    ) : (
                      <NoDataFound label={"No Data Found"} />
                    )}
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button buttonClasses="!bg-teal-500" buttonLabelClasses="" buttonType={"button"} buttonIcon={loading ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("confirm")} functions={onSubmit} buttonHasLink={false} buttonDisabled={loading} />
                    <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={"fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={closeModal} buttonHasLink={false} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ManagePermissionPopup;
