import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import Button from "../../components/form/Button";
import Table from "../../components/elements/table/Table";
import Search from "../../components/form/Search";
import { postData } from "../../services/api";
import { reorder } from "../../helpers";
import moment from "moment";
import toastr from "toastr";
import ReactPaginate from "react-paginate";

import { useTranslation } from "react-i18next";

export default function Banner() {
  setTitle("Succession Planning Software for your Business with Succession Now | Banners");
  const { i18n, t } = useTranslation();
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [noOfPage, setNoOfPage] = useState(0);
  const [banner, setBanner] = useState("");
  const [sortQuery, setSortQuery] = useState("");
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount,setStartDataCount] = useState(0);
  const [endDataCount,setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(true);

  const tableHeadData = [
    { _id: 1, width: 15, name: t("image"), value: "image", align: "left", isSort: true, isFilter: false },
    { _id: 2, width: 40, name: t("title"), value: "title", align: "left", isSort: false, isFilter: false },
    { _id: 3, width: 15, name: t("sub_title"), value: "Short Description", align: "left", isSort: true, isFilter: false },
    { _id: 4, width: 20, name: t("created_date"), value: "created_at", align: "left", isSort: false, isFilter: false },
    { _id: 5, width: 20, name: t("status"), value: "status", align: "left", isShort: false, isFilter: false },
    { _id: 6, width: 20, name: t("action"), value: "action", align: "left", isShort: false, isFilter: false },
  ];

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      const result = await postData("banner/list", {
        keyword: keyword,
        offset: offset,
        sortQuery: sortQuery,
      });
      if (result.data) {
        setBanner(
          result.data.map((value, key) => {
            return {
              _id: value?._id,
              rowData: [
                { _id: 1, width: 40, type: "image", data: "", image: value?.image },
                { _id: 2, width: 15, type: "text", data: value?.name },
                { _id: 3, width: 15, type: "text", data: value?.sub_title },
                { _id: 4, width: 20, type: "text", data: value && value?.created_at ? moment(new Date(value?.created_at)).format("Do MMM YYYY") : "N/A" },
                { _id: 5, width: 15, type: "status", statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"), statusType: value?.status && value?.status === 1 ? "success" : "warning" },
                { _id: 6, width: 10, type: "action", statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"), statusType: value?.status && value?.status === 1 ? "success" : "warning" },
              ],
            };
          })
        );
        setTotalDataCount(result.count);
        setLoading(false);
      }
    };
    loadList();
  }, [keyword, limit.offset, sortQuery, isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number((totalDataCount>0)?1:0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  };

  //   edit and status
  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("banner/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setBanner(
              banner.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
            toastr.success(statusData.message);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue?.row_id && actionValue?.button_type === "delete") {
      // delete
      const deleteBanner = async () => {
        const statusData = await postData("banner/delete", {
          id: actionValue?.row_id,
        });

        if (statusData.status === 200) {
          setBanner((bannerData) => bannerData.filter((item) => item._id !== actionValue.row_id));
          toastr.success(statusData.message);
        } else {
          toastr.error(statusData.message);
        }
      };
      deleteBanner();
    }
  }, [actionValue]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("banners")}</div>
        <div className="sm:ml-auto flex justify-end items-center">
          <Button buttonType={"button"} buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonLabel={t("add_banner")} buttonHasLink={true} buttonLink={"/add-banner"} />
        </div>
      </div>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-2">
        <div className="relative">
          <Select
            xPlacement={"bottomLeft"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        <div className="relative w-full sm:w-auto sm:ml-auto flex sm:justify-end flex-wrap sm:flex-nowrap items-center gap-1 sm:gap-3">
          <div className="relative w-full sm:w-auto">
            <Search placeholder={t("search")} setInput={setKeyword} />
          </div>
        </div>
      </div>
      <div className="w-full bg-white rounded-md shadow overflow-hidden">
        <Table tableData={banner} tableHeadData={tableHeadData} loading={loading} totalRecord={totalDataCount} editUrl={"/edit-banner"} isDraggable={false} isDeleteEnabled isStatusUpdate={true} getActionValue={(obj) => setActionValue(obj)} />
      </div>
      {noOfPage > 1 ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>
          <ReactPaginate breakLabel="..." breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>} nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" onPageChange={handlePageClick} pageRangeDisplayed={3} pageCount={noOfPage} containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2" activeClassName="hover:bg-teal-500 bg-teal-500 text-white" pageLinkClassName="flex justify-center items-center w-full h-full" previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>} pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" renderOnZeroPageCount={null} />
        </div>
      ):(
        <div className="flex justify-center sm:justify-between my-4">
          {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}          
        </div>
      )}
    </>
  );
}
