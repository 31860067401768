import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Image from '../elements/Image';
import { classNames } from "../../helper/classNames";

const ImagePopup = ({isOpen, setIsOpen = () => {},image, size, getActionValue = () => {}}) => { 
  const closeModal = () =>{ setIsOpen(false) } 
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className={classNames("w-full transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all", size?size:"max-w-sm")}>
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {/* Please confirm your information */}
                    </Dialog.Title>
                    <div className="absolute top-2 right-2 z-10">
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={''} 
                        buttonClasses={'!bg-black/10 !text-white !w-10 !h-10 !p-0 !rounded-full border border-white/20'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    <div className="relative overflow-hidden rounded-lg flex">
                    <Image
                        src={image}
                        alt={"Admin"}
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"object-cover"}
                        />
                    </div>
                    
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default ImagePopup;
