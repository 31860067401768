import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const CommissionReport = ({ results,code, user }) => {
  const { i18n,t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'RobotoRegular',
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: '100%',
      padding: 8,

    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: '100%',
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });    

  return (
    <Document>
      <Page size="A4"  orientation='landscape' style={styles.page}>
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
            />
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16 , textTransform:'capitalize'}]}>{t('commission_report')}</Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by :</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{user?.name}</Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
        {
          results && results.length > 0 &&   
          results.map((item,index) => {
            let salesId = '';
            let salesPersion = '';
            let salesCommission = 0;
            let consultantId = 0;
            let salesPrice = 0;
            let consultantPersion = '';
            let consultantCommission = 0;
            let consultantPrice = 0; 
            let appointmentSetterId = '';          
            let appointmentSetterPersion = '';
            let appointmentSetterCommission = 0;
            let appointmentSetterPrice = 0;
            let salesAdminId = '';
            let salesAdminPersion = '';
            let salesAdminPrice = 0;
            let salesAdminCommission = 0;
            let sales = item?.staffs.length>0 && item?.staffs.filter((item) => item.code === 'sales-staff');
            if(sales && sales.length>0){
              salesId = sales[0].staff_id;
              let salesPersionArray = item?.staff_details.filter((itm) => itm._id === salesId);              
              if(salesPersionArray && salesPersionArray.length>0){
                let finalSalesCommission = salesPersionArray[0]?.commission?.filter(sale => sale.code === 'sales-staff')[0]?.commission;
                salesPersion = salesPersionArray[0].first_name+' '+salesPersionArray[0].last_name;
                salesCommission = (sales[0].commission)?sales[0].commission:finalSalesCommission;
                salesPrice = (Number(item?.original_price)*Number(salesCommission))/100;
              }                      
            }
            let consultant = item?.staffs.length>0 && item?.staffs.filter((item) => item.code === 'consultant');
            if(consultant && consultant.length>0){
              consultantId = consultant[0].staff_id;
              let consultantPersionArray = item?.staff_details.filter((itm) => itm._id === consultantId);
              if(consultantPersionArray && consultantPersionArray.length>0){
                let finalConsultantCommission = consultantPersionArray[0]?.commission?.filter(consult => consult.code === 'consultant')[0]?.commission;
                consultantPersion = consultantPersionArray[0].first_name+' '+consultantPersionArray[0].last_name;
                consultantCommission = (consultant[0].commission)?consultant[0].commission:finalConsultantCommission;
                consultantPrice = (Number(item?.original_price)*Number(consultantCommission))/100;
              }
            }
            let appointmentSetter = item?.staffs.length>0 && item?.staffs.filter((item) => item.code === 'appointment-setter');
            if(appointmentSetter && appointmentSetter.length>0){
              appointmentSetterId = appointmentSetter[0].staff_id;
              let appointmentSetterPersionArray = item?.staff_details.filter((itm) => itm._id === appointmentSetterId);
              if(appointmentSetterPersionArray && appointmentSetterPersionArray.length>0) {
                let finalAppointmentSetterCommission = appointmentSetterPersionArray[0]?.commission?.filter(appt => appt.code === 'appointment-setter')[0]?.commission;
                appointmentSetterPersion = appointmentSetterPersionArray[0].first_name+' '+appointmentSetterPersionArray[0].last_name;
                appointmentSetterCommission = (appointmentSetter[0].commission)?appointmentSetter[0].commission:finalAppointmentSetterCommission;
                appointmentSetterPrice = (Number(item?.original_price)*Number(appointmentSetterCommission))/100; 
              }
            }
            let salesAdmin = item?.staffs.length>0 && item?.staffs.filter((item) => item.code === 'sales-admin');
            if(salesAdmin && salesAdmin.length>0){
              salesAdminId = salesAdmin[0].staff_id;
              let salesAdminPersionArray = item?.staff_details.filter((itm) => itm._id === salesAdminId);
              if(salesAdminPersionArray && salesAdminPersionArray.length>0) {
                let finalSalesAdminCommission = salesAdminPersionArray[0]?.commission?.filter(salesAdmin => salesAdmin.code === 'sales-admin')[0]?.commission;
                salesAdminPersion = salesAdminPersionArray[0].first_name+' '+salesAdminPersionArray[0].last_name;
                salesAdminCommission = (salesAdmin[0].commission)?salesAdmin[0].commission:finalSalesAdminCommission;
                salesAdminPrice = (Number(item?.original_price)*Number(salesAdminCommission))/100; 
              }
            }
            let salesCommissionPay = item?.staff_commission.length>0 && item?.staff_commission.filter((item) => item.staff_code === 'sales-staff');
            let consultantCommissionPay = item?.staff_commission.length>0 && item?.staff_commission.filter((item) => item.staff_code === 'consultant');
            let appointmentSetterCommissionPay = item?.staff_commission.length>0 && item?.staff_commission.filter((item) => item.staff_code === 'appointment-setter');
            let salesAdminCommissionPay = item?.staff_commission.length>0 && item?.staff_commission.filter((item) => item.staff_code === 'sales-admin');  
            let primaryId='';
            let primaryName='';
            let primaryPrice = 0;
            if(code && code === 'sales-staff'){
              primaryId = salesId;
              primaryName = (salesPersion)?salesPersion:'';
              primaryPrice = (salesPersion)?salesPrice:0
            }else if(code && code === 'consultant'){
              primaryId = consultantId;
              primaryName = (consultantPersion)?consultantPersion:'';
              primaryPrice = (consultantPersion)?consultantPrice:0
            }else if(code && code === 'appointment-setter'){
              primaryId = appointmentSetterId;
              primaryName = (appointmentSetterPersion)?appointmentSetterPersion:'';
              primaryPrice = (appointmentSetterPersion)?appointmentSetterPrice:0
            }else if(code && code === 'sales-admin'){
              primaryId = salesAdminId;
              primaryName = (salesAdminPersion)?salesAdminPersion:'';
              primaryPrice = (salesAdminPersion)?salesAdminPrice:0
            }
            return (
              <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30}} key={index}>
                <View style={{backgroundColor:"#e2e8f0",padding:"8px 10px"}}>
                  <View style={[styles.tableOther, { display: "flex", flexDirection: "row", justifyContent:"space-between",padding:"4px 10px"}]}>
                    <View style={{display:"flex", flexDirection:"row", width:"70%", gap:5}}>
                      <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12, }]}>{t('company')}:</Text>
                      <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12}]}>
                        {item?.company_name}
                      </Text>
                    </View>  
                    <View style={{display:"flex", flexDirection:"row", width:"30%", gap:5}}>
                      <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12, }]}>{t('price')}:</Text>
                      <Text style={[styles.tableOtherData, {fontFamily: "RobotoRegular", fontSize: 12,color:"#14B8A6"}]}>
                        {'$'+Number(item?.original_price).toFixed(2)} 
                      </Text>
                    </View>
                  </View> 
                  <View style={[styles.tableOther, { display: "flex", flexDirection: "row", justifyContent:"space-between",padding:"4px 10px"}]}>
                    <View style={{display:"flex", flexDirection:"row", width:"70%", gap:5}}>
                      <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12, }]}>{t('start_date')}:</Text>
                      <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12}]}>
                      {(item?.created_at)? moment(new Date(item?.created_at)).format("MMM Do YYYY"):'N/A'}
                      </Text>
                    </View>                  
                    <View style={{display:"flex", flexDirection:"row", width:"30%", gap:5}}>
                      <Text style={[styles.tableOtherData, {fontFamily: "RobotoMedium", fontSize: 12, }]}>{t('total_commission')}:</Text>
                      <Text style={[styles.tableOtherData, {fontFamily: "RobotoRegular", fontSize: 12,color:"#14B8A6"}]}>
                      {'$'+Number(((salesPrice)?salesPrice:0)+((consultantPrice)?consultantPrice:0)+((appointmentSetterPrice)?appointmentSetterPrice:0)+((salesAdminPrice)?salesAdminPrice:0)).toFixed(2)}
                      </Text>
                    </View>
                  </View> 
                </View> 
                <View style={{ overflow: "hidden" }}>
                  <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
                    <View style={[styles.tableHeading, {}]}>
                      <Text style={[styles.tableHeadingText, {}]}>{t('role')}</Text>
                    </View>
                    <View style={[styles.tableHeading, {}]}>
                      <Text style={[styles.tableHeadingText, {}]}>{t('name')}</Text>
                    </View>
                    <View style={[styles.tableHeading, {}]}>
                      <Text style={[styles.tableHeadingText, {}]}>{t('percentage')}</Text>
                    </View>
                    <View style={[styles.tableHeading, {}]}>
                      <Text style={[styles.tableHeadingText, {}]}>{t('amount')}</Text>
                    </View>
                    <View style={[styles.tableHeading, {}]}>
                      <Text style={[styles.tableHeadingText, {}]}>{t('status')}</Text>
                    </View>
                  </View>
                  <View style={[{border: "1px solid #e2e8f0" }]} key={index}>
                    <View style={[ { display: "flex", flexDirection: "row" }]}>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                            {t('salesperson')}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {(salesPersion)?salesPersion:t('none')}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {((salesCommission)?salesCommission:0)+'%'}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {'$'+((salesPrice)?salesPrice:0).toFixed(2)}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          {(
                            (salesCommissionPay && salesCommissionPay.length>0)?(
                              <Text style={{ fontSize: 9,color:"#14B8A6" }}>
                                {(salesCommissionPay && salesCommissionPay.length>0)?t('paid'):t('unpaid')}
                              </Text> 
                            ):(
                              <Text style={{ fontSize: 9,color:'red' }}>
                                {(salesCommissionPay && salesCommissionPay.length>0)?t('paid'):t('unpaid')}
                              </Text>
                            )
                          )}                       
                      </View>
                    </View>
                    <View style={[ { display: "flex", flexDirection: "row" }]}>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                            {t('consultant')}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {(consultantPersion)?consultantPersion:t('none')}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {((consultantCommission)?consultantCommission:0)+'%'}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {'$'+((consultantPrice)?consultantPrice:0).toFixed(2)}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          {(
                            (consultantCommissionPay && consultantCommissionPay.length>0)?(
                              <Text style={{ fontSize: 9,color:"#14B8A6" }}>
                                {(consultantCommissionPay && consultantCommissionPay.length>0)?t('paid'):t('unpaid')}
                              </Text> 
                            ):(
                              <Text style={{ fontSize: 9,color:'red' }}>
                                {(consultantCommissionPay && consultantCommissionPay.length>0)?t('paid'):t('unpaid')}
                              </Text>
                            )
                          )}                       
                      </View>
                    </View>
                    <View style={[ { display: "flex", flexDirection: "row" }]}>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                            {t('appt_setter')}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {(appointmentSetterPersion)?appointmentSetterPersion:t('none')}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {((appointmentSetterCommission)?appointmentSetterCommission:0)+'%'}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {'$'+((appointmentSetterPrice)?appointmentSetterPrice:0).toFixed(2)}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          {(
                            (appointmentSetterCommissionPay && appointmentSetterCommissionPay.length>0)?(
                              <Text style={{ fontSize: 9,color:"#14B8A6" }}>
                                {(appointmentSetterCommissionPay && appointmentSetterCommissionPay.length>0)?t('paid'):t('unpaid')}
                              </Text> 
                            ):(
                              <Text style={{ fontSize: 9,color:'red' }}>
                                {(appointmentSetterCommissionPay && appointmentSetterCommissionPay.length>0)?t('paid'):t('unpaid')}
                              </Text>
                            )
                          )}                       
                      </View>
                    </View>
                    <View style={[ { display: "flex", flexDirection: "row" }]}>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                            {t('sales_admin')}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {(salesAdminPersion)?salesAdminPersion:t('none')}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {((salesAdminCommission)?salesAdminCommission:0)+'%'}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          <Text key={index} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                          {'$'+((salesAdminPrice)?salesAdminPrice:0).toFixed(2)}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={index}
                        >
                          {(
                            (salesAdminCommissionPay && salesAdminCommissionPay.length>0)?(
                              <Text style={{ fontSize: 9,color:"#14B8A6" }}>
                                {(salesAdminCommissionPay && salesAdminCommissionPay.length>0)?t('paid'):t('unpaid')}
                              </Text> 
                            ):(
                              <Text style={{ fontSize: 9,color:'red' }}>
                                {(salesAdminCommissionPay && salesAdminCommissionPay.length>0)?t('paid'):t('unpaid')}
                              </Text>
                            )
                          )}                       
                      </View>
                    </View>
                  </View>              
                </View>
              </View>
            )
          })       
        }
      </Page>
    </Document>
  )
};
export default CommissionReport;