import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import moment from 'moment/moment';
import { useTranslation } from "react-i18next";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });


const AvgCommissionReport = ({ 
  results, 
  user,
  code,
  type
}) => {
  const { i18n,t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 8,
      width: "33.33%",
      paddingHorizontal: 8,
      paddingVertical: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    subText: {
      fontSize: 8,
      marginTop: 2,
    },
    tableData: {
      fontSize: 9,
      width: "33.33%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    tableText: {
      fontSize: 7,
    },
    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 10,
      lineHeight: 1,
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    tablePart: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    section: { textAlign: "center" },
  });
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.section, { color: "white" }]}>
          <Text>Section #1</Text>
        </View>        
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
            />
          </View>          
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16, textTransform: "capitalize" }]}>
              {t('commission_report')}
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by :</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{user?.name}</Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
        {results &&  results.length > 0 &&
          <View style={[ { marginTop: 10, marginBottom: 10 }]}>            
            <View style={{ border: "1px solid #e2e8f0", borderRadius: 4, overflow: "hidden" }}>
              <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
                <View style={[styles.tableHeading, {}]}>
                  <Text style={[styles.tableHeadingText, {}]}>{t('name')}</Text>
                </View>
                <View style={[styles.tableHeading, {}]}>
                  <Text style={[styles.tableHeadingText, {}]}>{t('no_of_sale')}</Text>
                </View>
                <View style={[styles.tableHeading, {}]}>
                  <Text style={[styles.tableHeadingText, {}]}>{t('percentage')}</Text>
                </View>
                <View style={[styles.tableHeading, {}]}>
                  <Text style={[styles.tableHeadingText, {}]}>{t('total_commission')}</Text>
                </View>
              </View>
              {
                results.map((row, key) => {
                  let salesCommission = 0;
                  let consultantCommission = 0;
                  let appointmentSetterCommission = 0;
                  let salesAdminCommission = 0;
                  let salesPrice = 0;
                  let consultantPrice = 0; 
                  let appointmentSetterPrice = 0;
                  let salesAdminPrice = 0;
                  if(row?.commission && Object.keys(row?.commission).length>0){
                    let sales = row?.commission.filter((itm) => itm.code === 'sales-staff');
                    if(sales && sales.length>0){
                      salesCommission = (sales[0].commission)?sales[0].commission:0;
                      salesPrice = (Number(row?.original_price)*Number(salesCommission))/100;
                    } 
                    let consultant = row?.commission.filter((item) => item.code === 'consultant');
                    if(consultant && consultant.length>0){
                      consultantCommission = (consultant[0].commission)?consultant[0].commission:0;
                      consultantPrice = (Number(row?.original_price)*Number(consultantCommission))/100;
                    }
                    let appointmentSetter = row?.commission.filter((item) => item.code === 'appointment-setter');
                    if(appointmentSetter && appointmentSetter.length>0){
                      appointmentSetterCommission = (appointmentSetter[0].commission)?appointmentSetter[0].commission:0;
                      appointmentSetterPrice = (Number(row?.original_price)*Number(appointmentSetterCommission))/100; 
                    }
                    let salesAdmin = row?.commission.filter((item) => item.code === 'sales-admin');
                    if(salesAdmin && salesAdmin.length>0){
                      salesAdminCommission = (salesAdmin[0].commission)?salesAdmin[0].commission:0;
                      salesAdminPrice = (Number(row?.original_price)*Number(salesAdminCommission))/100; 
                    }
                  } 
                  let commission = 0;
                  if(row?.staff_details && row?.staff_details.length>0) {
                    for (let index = 0; index < row?.staff_details.length; index++) {
                      let element = row?.staff_details[index];
                      let price = row?.staff_details[index]?.original_price;
                      let salesComm = 0;
                      let consultantComm = 0;
                      let appointmentSetterComm = 0;
                      let salesAdminComm = 0;
                      if(element?.staffs && Object.keys(element?.staffs).length>0){
                        let sales1 = element?.staffs.filter((itm) => itm.code === 'sales-staff');
                        if(sales1 && sales1.length>0){
                          salesComm = (sales1[0].commission)?sales1[0].commission:0;
                          commission = commission + (Number(price)*Number(salesComm))/100;
                        } 
                        let consultant1 = element?.staffs.filter((item) => item.code === 'consultant');
                        if(consultant1 && consultant1.length>0){
                          consultantComm = (consultant1[0].commission)?consultant1[0].commission:0;
                          commission = commission + (Number(price)*Number(consultantComm))/100;
                        }
                        let appointmentSetter1 = element?.staffs.filter((item) => item.code === 'appointment-setter');
                        if(appointmentSetter1 && appointmentSetter1.length>0){
                          appointmentSetterComm = (appointmentSetter1[0].commission)?appointmentSetter1[0].commission:0;
                          commission = commission + (Number(price)*Number(appointmentSetterComm))/100; 
                        }
                        let salesAdmin1 = element?.staffs.filter((item) => item.code === 'sales-admin');
                        if(salesAdmin1 && salesAdmin1.length>0){
                          salesAdminComm = (salesAdmin1[0].commission)?salesAdmin1[0].commission:0;
                          commission = commission + (Number(price)*Number(salesAdminComm))/100; 
                        }
                      }
                    }
                  }
                  return (
                  <View style={[{borderTop: "1px solid #e2e8f0" }]} key={key}>
                    <View style={[ { display: "flex", flexDirection: "row" }]}>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={key}
                        >
                          <Text key={key} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                            {row?.first_name+' '+row?.last_name}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={key}
                        >
                          <Text key={key} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                            {row?.count_staff}
                          </Text>                            
                      </View>
                      <View style={{ fontSize: 8, width: "33.33%",paddingHorizontal: 8,paddingVertical: 8}} key={key}>
                        <View style={{ display: "flex",flexDirection: "row", fontWeight:"semibold",color:"#475569",display:'flex',justifyContent:"space-between",marginBottom:5}}>
                          <Text style={{ fontSize: 9, }}>{t('sales_staff')}</Text> 
                          <Text style={{}}>{salesCommission+'%'}</Text> 
                        </View>   
                        <View style={{ display: "flex",flexDirection: "row", fontWeight:"semibold",color:"#475569",display:'flex',justifyContent:"space-between",marginBottom:5}}>
                          <Text style={{ fontSize: 9, }}>{t('consultant')}</Text> 
                          <Text style={{}}>{consultantCommission+'%'}</Text> 
                        </View>
                        <View style={{ display: "flex",flexDirection: "row", fontWeight:"semibold",color:"#475569",display:'flex',justifyContent:"space-between",marginBottom:5}}>
                          <Text style={{ fontSize: 9, }}>{t('appointment_setter')}</Text> 
                          <Text style={{}}>{appointmentSetterCommission+'%'}</Text> 
                        </View>
                        <View style={{display: "flex", flexDirection: "row", fontWeight:"semibold",color:"#475569",display:'flex',justifyContent:"space-between",marginBottom:5}}>
                          <Text style={{ fontSize: 9, }}>{t('sales_admin')}</Text> 
                          <Text style={{}}>{salesAdminCommission+'%'}</Text> 
                        </View>                 
                      </View>
                      <View style={{ fontSize: 8,
                            width: "33.33%",
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",}}
                            key={key}
                        >
                          <Text key={key} style={{ fontSize: 9, width: "100%", marginTop: 2 }}>
                            {'$'+Number(commission).toFixed(2)}
                          </Text>                            
                      </View>
                    </View>
                  </View>
                  )
                })
              }              
            </View>
          </View>
        }
      </Page>
    </Document>
  );
};

export default AvgCommissionReport;
