import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { postData } from "../../../services/api";
import BannerSkeleton from "../../loader/BannerSkeleton";
import TestimonialSkeleton from "../../loader/TestimonialSkeleton";
import Image from "../../elements/Image";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const [loading, setLoading] = useState(false);
  const [testimonialData, setTestimonial] = useState([]);
  const { i18n,t } = useTranslation();

  useEffect(() => {
    const testimonialList = async () => {
      setLoading(true);
      try {
        const result = await postData("testimonial/list", {
          status: 1,
        });
        if (result.data) {
          setTestimonial(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };

    testimonialList();
  }, []);

  return (
    <>
      <section
        className="relative flex items-center w-full bg-slate-50 py-20 overflow-hidden"
        id="testimonials"
      >
        <div className="w-full max-w-[1400px] mx-auto px-5">
          <div className="relative">
            <h2 className="mb-6 text-center">
              <span className="block xl:text-5xl text-3xl font-bold text-slate-700">{t('testimonials')}</span>
              <span className="inline-block text-sm uppercase text-slate-400 font-normal tracking-widest border-t-2 border-teal-300 py-1 my-1">Succession Now</span>
            </h2>
          </div>

          <div className="testimonial relative pb-10">
              <>
                {/* <Swiper
                  spaceBetween={10}
                  slidesPerView={1}
                  loop={true}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                  }}
                > */}
                {loading ? (
                  // <SwiperSlide>
                    <TestimonialSkeleton />
                  // </SwiperSlide>
                ):(
                  Array.isArray(testimonialData) &&
                    testimonialData.length > 0 &&
                    testimonialData.map((item) => {
                      return (
                        // <SwiperSlide key={item?._id}>
                          <div className="bg-white rounded-xl shadow p-10 my-4 mx-1 text-center flex justify-center items-center">
                            <div className="w-full max-w-md">
                              <div className="font-medium text-dark-teal-400">
                                {item?.name?.[i18n?.language]}
                              </div>
                              <div className="text-xs text-slate-400 font-light leading-tight">
                                {item?.designation?.[i18n?.language]}
                              </div>
                              <div className="text-xs text-slate-400 font-light leading-tight">
                                {item?.company?.[i18n?.language]}
                              </div>
                              <div className="text-sm font-extralight text-slate-500 mt-5">
                                {item?.comments?.[i18n?.language]}
                              </div>
                            </div>
                          </div>
                        // </SwiperSlide>
                      );
                    })
                  )}
                {/* </Swiper> */}
              </>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
