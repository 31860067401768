import React from 'react';
import PageBanner from "../components/section/PageBanner";
import Testimonial from "../components/section/Shared/Testimonial";
import IntroContent from "../components/section/Intro/IntroContent";

const Intro = ({type,segment}) => {
  return (
    <>
      {/* <PageBanner sectionClass="!h-40"/> */}
      
      <IntroContent type={type} segment={segment}/>
      {!type === 4 &&
        <Testimonial/>
      }
    </>
  );
};

export default Intro;