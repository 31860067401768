import React from 'react';
import successbuild from "../../../src/assets/image/step-3.webp"
const Plan = () => {

  const PlanData = {
    title:"Step 3: Generate Your Plan",
  
    description:"<p>SuccessionNow scores each candidate and generates a report giving you a clear side-by-side comparison.</p>",
    image:successbuild,
  }
  return (
    <div className="relative">
        <div className="block xl:flex w-full items-center justify-between space-y-5 xl:space-y-0">
              <div className="w-full xl:w-5/12">
                  <div className="flex flex-col space-y-5">
                        <div className="capitalize text-white text-base xs:text-xl lg:text-2xl xl:text-3xl font-PlusJakartaSans font-bold" dangerouslySetInnerHTML={{__html:PlanData.title}}></div>
                        <div className="font-Inter text-xs xs:text-base font-medium text-white" dangerouslySetInnerHTML={{__html:PlanData.description}}></div>
                  </div>
              </div>
              <div className="w-full xl:w-7/12 flex justify-end">
                  {/* <div className="w-full aspect-video flex lg:justify-start xl:justify-end">
                      <iframe className="w-[494px]" width="412" height="260" src="https://www.youtube.com/embed/a1hEY3Ii70I?si=roQv6cXgfYYZOArt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div> */}
                  <div className="max-w-md w-full ">
                      <img src={PlanData.image} alt="Home Banner" className="w-full object-cover "/>
                  </div>
              </div>
        </div>
    </div>
  );
};

export default Plan;