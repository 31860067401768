import React, { useState, useEffect, Fragment,useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import ButtonFile from "../form/ButtonFile";
import Image from "../elements/Image";
import { allowedImageExtensions } from "../../helpers";
import 'flatpickr/dist/flatpickr.css';

import { postData } from "../../services/api";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

const SuccessorEditPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
}) => {
  const { i18n,t } = useTranslation();
  const role = localStorage.getItem("role");  
  const [loading, setLoading] = useState(false);  

  const [position,setPosition] = useState([]);
  const [experience,setExperience] = useState([]);
  const [creditUnion,setCreditUnion] = useState([]);
  const [education,setEducation] = useState([]);
  const [performance,setPerformance] = useState([]);
  const [loyalty,setLoyalty] = useState([]);
  const [desire,setDesire] = useState([]);
  const [retirementWindow,setRetirementWindow] = useState([]);

  const [name, setName] = useState("");  
  const [designation,setDesignation] = useState('');
  const [selectExperience,setSelectExperience] = useState('');
  const [selectCreditUnion,setSelectCreditUnion] = useState('');
  const [selectEducation,setSelectEducation] = useState('');
  const [selectPerformance,setSelectPerformance] = useState('');
  const [selectLoyalty,setSelectLoyalty] = useState('');
  const [selectDesire,setSelectDesire] = useState('');
  const [selectRetirementWindow,setSelectRetirementWindow] = useState('');
  const [additionalComments,setAdditionalComments] = useState('');
  const [image, setImage] = useState("");  
  const [showLogo, setShowLogo] = useState("");

  const [loyaltyPass,setLoyaltyPass] = useState(true);
  const [desirePass,setDesirePass] = useState(true);
  const [retirementPass,setRetirementPass] = useState(true);
  const [performancePass,setPerformancePass] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };  

  useEffect(() => {   
    async function positionData() {
        try {
        const result = await postData("position/list", {
            sortQuery:{'created_at':1},
            status:1
        });
        if (result.data) {
            setPosition(result.data);
        } else {
            console.log('Position list message',result.message)
        }
        } catch (error) {
        console.log('Error position list catch',error.message)
        }
    }
    positionData();

    async function experienceData() {
        try {
        const result = await postData("experience/list", {
            sortQuery:{'created_at':1},
            type:1,
            status:1,
            is_visabled:1,
        });
        if (result.data) {
            setExperience(result.data);
        } else {
            console.log('Experience list message',result.message)
        }
        } catch (error) {
        console.log('Error Experience list catch',error.message)
        }
    }
    experienceData();

    async function creditUnionData() {
        try {
        const result = await postData("experience/list", {
            sortQuery:{'created_at':1},
            type:2,
            status:1,
            is_visabled:1,
        });
        if (result.data) {
            setCreditUnion(result.data);
        } else {
            console.log('Company list message',result.message)
        }
        } catch (error) {
        console.log('Error Company list catch',error.message)
        }
    }
    creditUnionData();

    async function educationList() {
        try {
        const result = await postData("education/list", {
            sortQuery:{'created_at':1},
            status:1
        });
        if (result.data) {
            setEducation(result.data);
        } else {
            console.log('Education message',result.message)
        }
        } catch (error) {
        console.log('Error education catch',error.message)
        }
    }
    educationList();

    async function performanceList() {
        try {
        const result = await postData("performance/list", {
            sortQuery:{'created_at':1},
            status:1
        });
        if (result.data) {
            setPerformance(result.data);
        } else {
            console.log('Performance message',result.message)
        }
        } catch (error) {
        console.log('Error performance catch',error.message)
        }
    }
    performanceList();

    async function loyaltyList() {
        try {
        const result = await postData("loyalty/list", {
            sortQuery:{'created_at':1},
            status:1
        });
        if (result.data) {
            setLoyalty(result.data);
        } else {
            console.log('Loyalty message',result.message)
        }
        } catch (error) {
        console.log('Error loyalty catch',error.message)
        }
    }
    loyaltyList();

    async function desireList() {
        try {
        const result = await postData("desire/list", {
            sortQuery:{'created_at':1},
            status:1
        });
        if (result.data) {
            setDesire(result.data);
        } else {
            console.log('Desire message',result.message)
        }
        } catch (error) {
        console.log('Error desire catch',error.message)
        }
    }
    desireList();

    async function retirementWindowData() {
        try {
        const result = await postData("experience/list", {
            sortQuery:{'created_at':1},
            type:3,
            status:1,
            is_visabled:1,
        });
        if (result.data) {
            setRetirementWindow(result.data);
        } else {
            console.log('Retirement window list message',result.message)
        }
        } catch (error) {
        console.log('Error retirement window list catch',error.message)
        }
    }
    retirementWindowData();
}, []);

  const onSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
        const formData = new FormData();
        formData.append("id", data?._id);
        formData.append("name", name);
        formData.append("designation", designation);
        formData.append("experience", selectExperience);
        formData.append("with_credit_union", selectCreditUnion);
        formData.append("education", selectEducation);
        formData.append("performance", selectPerformance);
        formData.append("loyalty", selectLoyalty);
        formData.append("desire_to_advance", selectDesire);
        formData.append("retirement_window", selectRetirementWindow);
        formData.append("additional_comments", additionalComments);
        formData.append("image", image);
      if(designation !== data?.designation){
        const result = await postData("successor/update-all", formData
        // {
        //   id: data?._id,
        //   name: name,
        //   designation:designation,
        //   experience : selectExperience,
        //   with_credit_union:selectCreditUnion,
        //   education:selectEducation,
        //   performance:selectPerformance,
        //   loyalty:selectLoyalty,
        //   desire_to_advance:selectDesire,
        //   retirement_window:selectRetirementWindow,
        //   additional_comments:additionalComments,
        //   image : image,
        // }
        );
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          getActionValue({
            button_type: "edit",
            row_id: data?._id,
            current_status: "",
          });
          setIsOpen(false);
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } else {
          toastr.error('Please change your position before update');
          setLoading(false);
      }      
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }      
  };
  
  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid image");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
            URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0])
      }
    }
  };  

  useEffect(() => {
    setName(data?.name);
    setDesignation(data?.designation);
    setSelectExperience(data?.experience);
    setSelectCreditUnion(data?.with_credit_union);
    setSelectEducation(data?.education);
    setSelectPerformance(data?.performance);
    setSelectLoyalty(data?.loyalty);
    setSelectDesire(data?.desire_to_advance);
    setSelectRetirementWindow(data?.retirement_window)
    setShowLogo(data?.image);
  }, [data]);

  useEffect(() => {
    if(selectLoyalty){
      const index = loyalty.findIndex((item) => item._id === selectLoyalty);
      if(index !== -1){
        setLoyaltyPass(loyalty[index].isPass);
      }
    }
    if(selectDesire){
      const index = desire.findIndex((item) => item._id === selectDesire);
      if(index !== -1){
        setDesirePass(desire[index].isPass);
      }
    }
    if(selectRetirementWindow){
      const index = retirementWindow.findIndex((item) => item._id === selectRetirementWindow);
      if(index !== -1){
        setRetirementPass(retirementWindow[index].isPass);
      }
    }
    if(selectPerformance){
      const index = performance.findIndex((item) => item._id === selectPerformance);
      if(index !== -1){
        setPerformancePass(performance[index].isPass);
      }          
    }
  },[selectLoyalty,selectDesire,selectRetirementWindow,selectPerformance])  

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t('manage_information')}
                  </Dialog.Title>
                  <div className="grid grid-cols-3 gap-4  mt-4">
                    <div className="relative col-span-2">
                      <div className="grid grid-cols-2 gap-3">
                        <div className="relative mb-3">
                          <Input 
                            label={t('full_name')} 
                            labelClasses={'!text-xs'} 
                            inputType={'text'}                   
                            inputPlaceholder={t('enter_name')}
                            inputValue={name}  
                            setInput={setName} 
                          />
                        </div>
                        <div className="relative mb-3">
                          <Select 
                            label={t('current_position')}
                            labelClasses={'!text-xs'}
                            xPlacement={'bottomLeft'} 
                            selectedValue={designation} 
                            dropdownClass={'!w-60'} 
                            dropdownData={
                              [
                                { _id: "", label: t('select_position'),value:''} ,
                                ...position.map((item) => (
                                    { _id: item?._id, label: item?.name, value: item?._id }
                                )),
                                (role && ((role === 'customer-admin' || role ==='customer-subadmin' || role === 'customer-user')))?{ _id: "others", label: t('other'), value: "" }:'',
                              ].filter((item)=> item)
                            }
                            getSelectedValue={(e) => {
                              // if(e._id === 'others'){
                              //   setEnabledOtherPositionPopup(true);
                              // }
                              setDesignation(e._id)
                            }}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-3">
                        <div className="relative mb-3">
                          <Select 
                          label={t('years_of_senior_management_experience')}
                          labelClasses={'!text-xs'}
                          xPlacement={'bottomLeft'} 
                          selectedValue={selectExperience}
                          dropdownClass={'!w-60'} 
                          dropdownData={
                              [
                                  { _id: ' ', label: t('select_experience') ,value:''} ,
                                  ...experience.map((item) => (
                                      { _id: item?._id, label: item?.name, value: item?._id }
                                  ))
                              ]
                          }
                          getSelectedValue={(e) => setSelectExperience(e._id)}
                          />
                        </div>
                        <div className="relative mb-3">
                          <Select 
                            label={t('years_with_company')}
                            labelClasses={'!text-xs'}
                            xPlacement={'bottomLeft'} 
                            selectedValue={selectCreditUnion} 
                            dropdownClass={'!w-60'} 
                            dropdownData={
                                [
                                    { _id: ' ', label: t('select_years_with_company'),value:''} ,
                                    ...creditUnion.map((item) => (
                                        { _id: item?._id, label: item?.name, value: item?._id }
                                    ))
                                ]
                            }
                            getSelectedValue={(e) => setSelectCreditUnion(e._id)}
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-3">
                        <div className="relative mb-3">
                          <Select 
                            label={t('highest_level_education')}
                            labelClasses={'!text-xs'}
                            xPlacement={'bottomLeft'} 
                            selectedValue={selectEducation} 
                            dropdownClass={'!w-60'} 
                            dropdownData={
                                [
                                    { _id: ' ', label: t('select_education'),value:''} ,
                                    ...education.map((item) => (
                                        { _id: item?._id, label: item?.name, value: item?._id }
                                    ))
                                ]
                                
                            }
                            getSelectedValue={(e) => setSelectEducation(e._id)}
                          />
                        </div>
                        <div className="relative mb-3">
                          <Select 
                            label={t('recent_performance_review')}
                            labelClasses={'!text-xs '}
                            dropdownButtonLabelClass={(!performancePass)?"!text-rose-500 !font-semibold":""}
                            xPlacement={'bottomLeft'} 
                            selectedValue={selectPerformance} 
                            dropdownClass={'!w-60'} 
                            dropdownData={
                                [
                                    { _id: ' ', label: t('select_performance'),value:''} ,
                                    ...performance.map((item) => ({ 
                                        _id: item?._id, 
                                        label: ((item?.alt_name)?item?.alt_name:item?.name)+' '+((item.number)?'('+item.number+')':''), 
                                        value: item?._id,
                                        isPass : item?.isPass
                                        }
                                    ))
                                ]
                            }
                            getSelectedValue={(e) => setSelectPerformance(e._id)}
                          />
                        </div>                        
                      </div>
                      <div className="grid grid-cols-2 gap-3">
                        <div className="relative mb-3">
                          <Select 
                            label={t('loyalty_to_company')}
                            labelClasses={'!text-xs'}
                            dropdownButtonLabelClass={(!loyaltyPass)?"!text-rose-500 !font-semibold":""}
                            xPlacement={'bottomLeft'} 
                            selectedValue={selectLoyalty} 
                            dropdownClass={'!w-60'} 
                            dropdownData={
                              [
                                { _id: ' ', label: t('select_loyalty'),value:''} ,
                                ...loyalty.map((item) => (
                                    { _id: item?._id, label: item?.name, value: item?._id,isPass : item?.isPass }
                                ))
                              ]
                            }
                            getSelectedValue={(e) => setSelectLoyalty(e._id)}
                          />
                        </div>
                        <div className={"relative mb-3"}>
                          <Select 
                            label={t('desire_to_advance')}
                            labelClasses={'!text-xs'}
                            dropdownButtonLabelClass={(!desirePass)?"!text-rose-500 !font-semibold":""}
                            xPlacement={'bottomLeft'} 
                            selectedValue={selectDesire} 
                            dropdownClass={'!w-60'} 
                            dropdownData={
                                [
                                    { _id: ' ', label: t('select_desire_to_advance'),value:''} ,
                                    ...desire.map((item) => (
                                        { _id: item?._id, label: item?.name, value: item?._id,isPass : item?.isPass }
                                    ))
                                ]
                                
                            }
                            getSelectedValue={(e) => setSelectDesire(e._id)}                        
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-3">                        
                        <div className={"relative mb-3"}>
                          <Select 
                            label={t('retirement_window')}
                            labelClasses={'!text-xs'}
                            dropdownButtonLabelClass={(!retirementPass)?"!text-rose-500 !font-semibold":""}
                            xPlacement={'bottomLeft'} 
                            selectedValue={selectRetirementWindow} 
                            dropdownClass={'!w-60'} 
                            dropdownData={
                                [
                                    { _id: ' ', label: t('select_retirement_window'),value:''} ,
                                    ...retirementWindow.map((item) => (
                                        { _id: item?._id, label: item?.name, value: item?._id,isPass : item?.isPass }
                                    ))
                                ]
                            }
                            getSelectedValue={(e) => setSelectRetirementWindow(e._id)}
                          />
                        </div>
                      </div>                      
                      <div className="border-t border-slate-200 my-4"></div>                      
                    </div>
                    <div className="relative">
                      <div className="border border-slate-200 rounded overflow-hidden">
                        <div className="w-full overflow-hidden rounded-md flex items-center">
                          <ButtonFile
                            buttonType={"button"}
                            buttonIcon={"fa-light fa-image"}
                            buttonIconPosition={"left"}
                            buttonLabel={t('upload_image')}
                            buttonClasses={
                              "!h-full !w-full !absolute !opacity-0 !z-10 !top-0 !left-0"
                            }
                            functions={onImgChange}
                            accepts={"image/*"}
                          />
                          <Image
                            src={showLogo}
                            alt={"Admin"}
                            width={"100%"}
                            effect={"blur"}
                            classes={"object-fit"}
                            id={"image1"}
                          />
                        </div>
                        <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                          <ButtonFile
                            buttonType={"button"}
                            buttonIcon={"fa-light fa-image"}
                            buttonIconPosition={"left"}
                            buttonLabel={t('upload_image')}
                            buttonClasses={
                              "!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"
                            }
                            functions={onImgChange}
                            accepts={"image/*"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4">
                    <Button
                      buttonClasses="!bg-teal-500"
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={
                        loading
                          ? "fa-light fa-spinner fa-spin"
                          : "fa-light fa-check"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={t('confirm')}
                      functions={onSubmit}
                      buttonHasLink={false}
                      buttonDisabled={loading}
                    />
                    <Button
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-times"}
                      buttonIconPosition={"left"}
                      buttonLabel={t('cancel')}
                      buttonClasses={
                        "!bg-slate-200 !text-slate-600 border border-slate-300"
                      }
                      functions={closeModal}
                      buttonHasLink={false}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SuccessorEditPopup;
