import React from 'react';
const TestimonialItem = ({data}) => {
  return (
      <div className="relative shadow-sm bg-white border-2 border-[#F4F7FA] px-6 flex flex-col items-center  rounded-lg space-y-5 min-h-96 md:min-h-[410px] h-full py-10 ">
            <div className="flex items-start lg:items-center gap-3">
                {/* <div className="w-14 h-14 rounded-full overflow-hidden">
                    <img src={data.image} alt={data.name} className="w-full h-full object-cover"/>
                </div> */}
                <div className="relative">
                    <div className="font-PlusJakartaSans font-bold text-base text-dark-teal-700 capitalize">{data.name}</div>
                    <div className="font-Inter text-xs text-slate-700 capitalize">{data.designation}</div>
                </div>
            </div>
            <div dangerouslySetInnerHTML={{__html:data.description}} className="text-sm xl:text-base font-Inter text-slate-800 italic font-medium"></div>
      </div>
  );
};

export default TestimonialItem;