import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Textarea from "../components/form/Textarea";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import toastr from "toastr";
import { useSelector } from "react-redux";
import Accordian from "../components/Common/Accordian";
import Search from "../components/form/Search";

import { postData } from "../services/api";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CompetenciesReport from "./pdf/CompetenciesReport";
import TrialAlertPopup from "../components/popup/TrialAlertPopup";

import { useTranslation } from "react-i18next";

const CompetenciesAlt = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Succession Plan Position Competencies");
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const isTrial = localStorage.getItem("isTrial");
  const [loading, setLoading] = useState(true);
  const [competenciesData, setCompetenciesData] = useState([]);
  const [competenciesResetData, setCompetenciesResetData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [reload, setReload] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [allData, setAllData] = useState([]);
  const [enabledTrialPopup, setEnabledTrialPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});

  useEffect(() => {
    async function positionListFun() {
      setLoading(true);
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          status: 1,
        });
        if (result.data) {
          setCompetenciesData(
            result.data
              .sort((a, b) => {
                if (a.company_id === null && b.company_id !== null) {
                  return -1; // 'a' comes before 'b'
                }
              })
              .map((value) => {
                return {
                  ...value,
                  position_competencies: value?.company_position_competencies ? value?.company_position_competencies : value?.position_competencies,
                  different_competencies: value?.different_competencies ? value?.different_competencies : "",
                };
              })
          );
          setCompetenciesResetData(result.data);
          setLoading(false);
        } else {
          console.log("Position message", result.message);
          setLoading(false);
        }
      } catch (error) {
        console.log("Error role catch", error.message);
        setLoading(false);
      }
    }
    positionListFun();
  }, [reload || ""]);

  const handelUpdate = async (id, index, value, field) => {
    let temp = [...competenciesData];
    if (field === "position_competencies") {
      temp[index].position_competencies = value;
      setCompetenciesData(temp);
    }
    if (field === "different_competencies") {
      temp[index].different_competencies = value;
      setCompetenciesData(temp);
    }
  };

  const onCancel = async (e) => {
    setIsCancel(true);
    setCompetenciesData(
      competenciesResetData.map((value, key) => {
        return {
          ...value,
          position_competencies: value?.company_position_competencies ? value?.company_position_competencies : value?.position_competencies,
          different_competencies: value?.different_competencies ? value?.different_competencies : "",
        };
      })
    );
    setIsCancel(false);
  };

  const onUpdate = async (e) => {
    setIsUpdate(true);
    setReload(false);
    try {
      const result = await postData("competency/update", {
        competencies_data: competenciesData,
      });
      if (result.data && result.status === 200) {
        // setCompetenciesData(
        //   result.data.map((value, key) => {
        //     return {
        //       ...value,
        //       position_competencies: value?.position_competencies
        //         ? value?.position_competencies
        //         : "",
        //       different_competencies: value?.different_competencies
        //         ? value?.different_competencies
        //         : "",
        //     };
        //   })
        // );
        // setCompetenciesResetData(result.data);
        toastr.success(result.message);
        setIsUpdate(false);
        setReload(true);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
        setReload(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsUpdate(false);
      setReload(false);
    }
  };

  const getDownload = (e) => {
    console.log(e.target);
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };

  useEffect(() => {
    if (keyword) {
      const filteredArray = competenciesData.filter((item) => item?.name.toLowerCase().includes(keyword.toLowerCase()));
      setAllData(filteredArray.sort((a, b) => a.name.localeCompare(b.name)));
    } else {
      setAllData(competenciesData.sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [competenciesData, keyword]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("succession_plan_position_competencies")} {user?.company_name}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          {isTrial !== 1 ? (
            <>
              <button type={"button"} className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"} onClick={(e) => getDownload(e)}>
                <i className={"fa-fw fa-light fa-download"}></i>
                <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
                  <PDFDownloadLink document={<CompetenciesReport data={competenciesData} user={user} />} fileName="competencies.pdf">
                    {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_pdf"))}
                  </PDFDownloadLink>
                </div>
              </button>
              <Button buttonType={"button"} buttonIcon={isUpdate ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onUpdate} buttonHasLink={false} buttonDisabled={isUpdate} />
            </>
          ) : (
            <>
              <Button
                buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
                buttonLabel={t("download_pdf")}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-download"}
                buttonIconPosition={"left"}
                buttonHasLink={false}
                functions={() => {
                  setEnabledTrialPopup(true);
                }}
              />
              <Button
                buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
                buttonLabel={t("save")}
                buttonType={"button"}
                buttonIcon={"fa-light fa-check"}
                buttonIconPosition={"left"}
                buttonHasLink={false}
                functions={() => {
                  setEnabledTrialPopup(true);
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className="relative flex justify-between items-center mb-4">
        <div className="relative ml-auto flex justify-end items-center gap-3 w-full sm:w-auto">
          <div className="relative w-full">
            <Search placeholder={"Search"} setInput={setKeyword} />
          </div>
        </div>
      </div>
      <div className="relative bg-white shadow rounded">
        <div className="">
          <form className="relative divide-y divide-slate-200">
            {allData?.length > 0 ? (
              allData?.map((item, index) => (
                <Accordian key={index} id={index} title={item.name} isOpen={openAccordionId === index} toggleAccordion={toggleAccordion}>
                  <div className="text-sm md:text-base font-Inter text-slate-700">
                    <div className="relative space-y-4">
                      <div className="relative">
                        <Textarea inputClasses={"whitespace-pre-line !h-80"} label={t("document_position_competencies_in_section_below")} isAddBullet={true} inputValue={item?.position_competencies} setTextarea={(val) => handelUpdate(item._id, index, val, "position_competencies")} />
                      </div>
                    </div>
                  </div>
                </Accordian>
                // <React.Fragment key={item._id}>
                //   <div className="px-4 py-5">
                //     <div className="text-xl text-slate-800 font-bold mb-2">
                //       {item.name}
                //     </div>
                //     <div className="relative space-y-4">
                //       <div className="relative">
                //         <Textarea
                //           inputClasses={"whitespace-pre-line"}
                //           label={
                //             "Document position competencies in section below."
                //           }
                //           isAddBullet={true}
                //           inputValue={item?.position_competencies}
                //           setTextarea={(val) =>
                //             handelUpdate(
                //               item._id,
                //               index,
                //               val,
                //               "position_competencies"
                //             )
                //           }
                //         />
                //       </div>
                //       {/* <div className="relative">
                //         <Textarea
                //           inputClasses={"whitespace-pre-line"}
                //           label={
                //             "If desired, enter additional or different competencies below:"
                //           }
                //           inputValue={item?.different_competencies}
                //           setTextarea={(val) =>
                //             handelUpdate(
                //               item._id,
                //               index,
                //               val,
                //               "different_competencies"
                //             )
                //           }
                //         />
                //       </div> */}
                //     </div>
                //   </div>
                // </React.Fragment>
              ))
            ) : loading ? (
              <Searching label={"Searching"} />
            ) : (
              <NoDataFound label={"No Data Found"} />
            )}
          </form>
        </div>

        {competenciesData?.length > 0 && (
          <div className="py-3 px-3 border-t border-slate-200">
            <div className="flex items-center justify-end gap-4">
              {isTrial !== 1 ? (
                <>
                  <button type={"button"} className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"} onClick={(e) => getDownload(e)}>
                    <i className={"fa-fw fa-light fa-download"}></i>
                    <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
                      <PDFDownloadLink document={<CompetenciesReport data={competenciesData} user={user} />} fileName="competencies.pdf">
                        {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_pdf"))}
                      </PDFDownloadLink>
                    </div>
                  </button>
                  <Button buttonType={"button"} buttonIcon={isUpdate ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onUpdate} buttonHasLink={false} buttonDisabled={isUpdate} />
                </>
              ) : (
                <>
                  <Button
                    buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
                    buttonLabel={t("download_pdf")}
                    buttonType={"button"}
                    buttonIcon={"fa-regular fa-download"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    functions={() => {
                      setEnabledTrialPopup(true);
                    }}
                  />
                  <Button
                    buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
                    buttonLabel={t("save")}
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-check"}
                    buttonIconPosition={"left"}
                    buttonHasLink={false}
                    functions={() => {
                      setEnabledTrialPopup(true);
                    }}
                  />
                </>
              )}
              {/* <button 
                type={'button'} 
                className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "} 
                onClick={(e) => getDownload(e)}
                >
                <i className={"fa-fw fa-light fa-download"}></i> 
                <div className={"text-sm font-medium whitespace-nowrap"}>
                  <PDFDownloadLink document={<CompetenciesReport data={competenciesData}/>} fileName="competencies.pdf">
                    {({ blob, url, loading, error }) => (
                      loading ? t('loading_document') : t('download_pdf'))}
                  </PDFDownloadLink>
                </div> 
              </button>
              <Button
                buttonType={"button"}
                buttonIcon={
                  isUpdate ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"
                }
                buttonIconPosition={"left"}
                buttonLabel={t('save')}
                functions={onUpdate}
                buttonHasLink={false}
                buttonDisabled={isUpdate}
              /> */}
            </div>
          </div>
        )}
      </div>
      {enabledTrialPopup && (
        <TrialAlertPopup
          isOpen={enabledTrialPopup}
          setIsOpen={(val) => setEnabledTrialPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        />
      )}
    </>
  );
};

export default CompetenciesAlt;
