import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Checkbox from '../form/Checkbox';
import { Tooltip } from 'react-tooltip';
import Searching from '../Searching';
import NoDataFound from '../NoDataFound';

import { postData } from '../../services/api';
import toastr from 'toastr';

import { useTranslation } from "react-i18next";

const DefaultHeadingManagePopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {}, 
  type,
}) => { 
  const { i18n,t } = useTranslation();
  const role = localStorage.getItem("role"); 
  const [processing,setProcesing] = useState(false)
  const [loading,setLoading] = useState(false);
  const [concerns,setConcerns] = useState([]);
  const [predictors,setPredictors] = useState([]);

  const closeModal = () =>{ setIsOpen(false) } 
  
  const onSubmit = async () => {
      try {
        setLoading(true);
        let payload;
        if(type === 'concern') {
          payload = {
            ids : concerns
          }
        } else if(type === 'predictors') {
          payload = {
            ids : predictors,
          }
        }
        const result = await postData("heading/set-default", payload);
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          getActionValue({ button_type : 'set-default',row_id : data?._id,current_status : ''});
          setIsOpen(false)
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setLoading(false);
      }
  } 
  
  useEffect(() => {     
    const loadHeadingList = async () => {
      setProcesing(true)
      try {
        const result = await postData("heading/list", {
          sortQuery: { ordering: 1 },
          type : type
        });
        if (result.data) {
          let _concerns = [];
          let _predictors = [];
          result.data.map((item) => {
            if(item?.type === 'concern'){
              _concerns.push({
                _id : item?._id,
                name : item?.name,
                description:item?.description,
                alias : item?.alias,
                weight : item?.weight,
                created_at: new Date(),
                isDefault : (item?.isDefault)?item?.isDefault:false,
                disabled : false,
              })
            }else if(item?.type === 'predictors'){
              _predictors.push({
                _id : item?._id,
                name : item?.name,
                description:item?.description,
                alias : item?.alias,
                weight : item?.weight,
                created_at: new Date(),
                isDefault : (item?.isDefault)?item?.isDefault:false,
                disabled : false,
              })
            }
          })
          setConcerns(_concerns);
          setPredictors(_predictors);
          setProcesing(false)
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadHeadingList();
  }, []);

  const updateHandler = (heading_id, event,type) => {
    if(event){   
      if(type === 'concern'){
        let enabledConcerns = concerns.length>0 && concerns.filter(it => it?.isDefault)?.length;
        if(Number(enabledConcerns+1) === 8){
          setConcerns(
            concern => concern.map((item, i) => {
              if(item?._id === heading_id){
                return {
                  ...item,
                  isDefault : true,
                }
              } else {
                if(item?.isDefault === false){
                  return {
                    ...item,
                    disabled : true
                  }
                } else {
                  return item;
                }
              }
            }
          )); 
        }else{
          setConcerns(
            concern => concern.map((item, i) => {
              if(item?._id === heading_id){
                return {
                  ...item,
                  isDefault : true,
                }
              } else {
                return item;
              }
            }
          ));
        }
      }else if(type === 'predictors'){
        let enabledPredictors = predictors.length>0 && predictors.filter(it => it?.isDefault)?.length;
        if(Number(enabledPredictors+1) === 8){
          setPredictors(
            predictors => predictors.map((item, i) => {
              if(item?._id === heading_id){
                return {
                  ...item,
                  isDefault : true
                }
              } else {
                if(item?.isDefault === false){
                  return {
                    ...item,
                    disabled : true
                  }
                } else {
                  return item;
                }
              }
            }
          ));
        }else{
          setPredictors(
            predictors => predictors.map((item, i) => {
              if(item?._id === heading_id){
                return {
                  ...item,
                  isDefault : true
                }
              } else {
                return item;
              }
            }
          ));
        }
      }
    } else {
      if(type === 'concern'){
        setConcerns(
          concern => concern.map((item, i) => {
            if(item?._id === heading_id){
              return {
                ...item,
                isDefault : false
              }
            } else {
              if(item?.isDefault === false){
                return {
                  ...item,
                  disabled : false
                }
              } else {
                return item;
              }
            }
          }
        )); 
      }else if(type === 'predictors'){
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(item?._id === heading_id){
              return {
                ...item,
                isDefault : false
              }
            } else {
              if(item?.isDefault === false){
                return {
                  ...item,
                  disabled : false
                }
              } else {
                return item;
              }
            }
          }
        ));
      }
    }
  };  

  useEffect(() => {
    let enabledConcerns = concerns.length>0 && concerns.filter(it => it?.isDefault)?.length;
    if(enabledConcerns >0 && type === 'concern'){ 
      if(enabledConcerns === 8){
        setConcerns(
          concern => concern.map((item, i) => {
            if(item?.isDefault === false){
              return {
                ...item,
                disabled : true
              }
            } else {
              return item;
            }
          }
        )); 
      }else{
        setConcerns(
          concern => concern.map((item, i) => {
            if(item?.isDefault === false){
              return {
                ...item,
                disabled : false
              }
            } else {
              return item;
            }
          }
        ));
      }
    }
  },[concerns,type])

  useEffect(() => {
    let enabledPredictors = predictors.length>0 && predictors.filter(it => it?.isDefault)?.length;
    if(enabledPredictors>0 && type === 'predictors'){
      if(enabledPredictors === 8){
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(item?.isDefault === false){
              return {
                ...item,
                disabled : true
              }
            } else {
              return item;
            }
          }
        ));
      }else{
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(item?.isDefault === false){
              return {
                ...item,
                disabled : false
              }
            } else {
              return item;
            }
          }
        ));
      }
    }    
  },[predictors,type])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('manage_default')} 
                    </Dialog.Title>
                    <div className="relative mt-4">
                      <div className="py-4 px-4 sm:py-8 sm:px-6 flex flex-col">
                        <div className="grid grid-cols-1 gap-10">
                          {
                            (type && type === 'concern') &&
                            <div className="relative">
                              <div className="flex items-center justify-start">
                                <div className="text-sm font-bold text-slate-800">{t('conditions_of_concern_library')}</div>
                              </div>
                              <div className="space-y-4 mt-4">
                                {
                                  concerns.length> 0 ? (
                                    concerns.map((it,idx) => {
                                    return (
                                      <div className="flex items-center justify-between gap-2">
                                        <Checkbox 
                                          checkboxLableClass={"text-slate-800"} 
                                          checkboxInputClass={""} 
                                          checkboxClass={"!rounded-full"}
                                          divClass={"!items-start"}
                                          checkboxName={it?._id} 
                                          isChecked={it?.isDefault} 
                                          checkboxLabel={it?.name} 
                                          checkboxValue={it?._id} 
                                          getCheckboxValue={(val, event) => updateHandler(val,event.target.checked,"concern")} 
                                          isDisabled={it?.disabled}
                                      />
                                      <div className="flex-shrink-0" data-tooltip-id={"info-tooltip-"+idx} data-tooltip-content={it?.description} data-tooltip-position-strategy="fixed">
                                      {
                                        (it?.description) && (
                                          <>
                                          <Tooltip id={'info-tooltip-'+idx} place="top" style={{
                                            maxWidth: "400px",
                                            wordBreak: "break-word",
                                            overflowWrap: "break-word",
                                            whiteSpace: "normal",
                                            padding: "5px",
                                            backgroundColor: "#333",
                                            color: "#fff",
                                            borderRadius: "4px",
                                            zIndex:2
                                          }}></Tooltip>
                                          <i className="fa-light fa-circle-info" style={{color:"#14b8a6"}}></i>
                                          </>
                                        )
                                      }
                                      </div>
                                      </div>
                                    )
                                    })
                                  ):(
                                    (processing)?<Searching label={"Searching"}/>:<NoDataFound label={"No Data Found"}/>
                                  )
                                }
                              </div>
                            </div>
                          }
                          {
                            (type && type === 'predictors') &&
                             <div className="relative">
                                <div className="flex items-center justify-start">
                                  <div className="text-sm font-bold text-slate-800">{t('potential_predictor_library')}</div>
                                </div>
                                <div className="space-y-4 mt-4">
                                  {
                                    predictors.length> 0 ? (
                                      predictors.map((itm,inx) => {
                                        return (
                                          <div className="flex items-center justify-between gap-2">
                                            <Checkbox 
                                              checkboxLableClass={"text-slate-800"} 
                                              checkboxInputClass={""} 
                                              checkboxClass={"!rounded-full"}
                                              divClass={"!items-start"}
                                              checkboxName={itm?._id} 
                                              isChecked={itm?.isDefault} 
                                              checkboxLabel={itm?.name} 
                                              checkboxValue={itm?._id} 
                                              getCheckboxValue={(val, event) => updateHandler(val,event.target.checked,'predictors')} 
                                              isDisabled={itm?.disabled}
                                            />
                                            <div className="flex-shrink-0" data-tooltip-id={"info-tooltip-"+inx} data-tooltip-content={itm?.description} data-tooltip-position-strategy="fixed">
                                              {
                                                (itm?.description) && (
                                                  <>
                                                  <Tooltip id={'info-tooltip-'+inx} place="top" style={{
                                                    maxWidth: "400px",
                                                    wordBreak: "break-word",
                                                    overflowWrap: "break-word",
                                                    whiteSpace: "normal",
                                                    padding: "5px",
                                                    backgroundColor: "#333",
                                                    color: "#fff",
                                                    borderRadius: "4px",
                                                    zIndex:2
                                                  }}></Tooltip>
                                                  <i className="fa-light fa-circle-info" style={{color:"#14b8a6"}}></i>
                                                  </>
                                                )
                                              }
                                            </div>
                                          </div>
                                        )
                                      })
                                    ):(
                                      (processing)?<Searching label={"Searching"}/>:<NoDataFound label={"No Data Found"}/>
                                    )
                                  }
                                </div>
                              </div>
                          }
                        </div>
                      </div>                                           
                    </div>
                    <div className="relative flex justify-center mb-3 mt-6 gap-4">
                        <Button
                        buttonClasses='!bg-teal-500'
                        buttonLabelClasses='' 
                        buttonType={'button'} 
                        buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('confirm')}
                        functions={onSubmit}
                        buttonHasLink={false}
                        buttonDisabled={loading}
                        />
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('cancel')} 
                        buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default DefaultHeadingManagePopup;
