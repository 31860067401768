import React,{useState,useEffect} from 'react';
import PriceingPlanItem from "../Items/PriceingPlanItem";
import { useTranslation } from "react-i18next";

import { postData } from '../../services/api';

const AnnualPlan = ({
  plans,
  buttonLink=true,
  getFunction=()=>{}
}) => {
  const { i18n,t } = useTranslation();
  const [loading,setLoading] = useState(false);

  const createPayment = async (id,price,interval) => {
    try {
      const result = await postData('stripe/subscription-create', {
        planId : id,
        price : price,
        interval : interval
      });
      if (result.data) {
        window.location.href = result?.data?.redirect_url;
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }
  return (
    <>
      {plans.length>0 && plans.map((item, index) => {
        let price = 0;
        let monthlyPrice = 0;
        let features = [];
        if(item.pricing?.rates.length>0){
          price = item.pricing?.rates.filter((it) => it?.interval === 'year')[0]?.amount;
          monthlyPrice = item.pricing?.rates.filter((it) => it?.interval === 'month')[0]?.amount;
        }
        if(item?.features?.length>0){
          features = item.features.filter((_it) => _it?.headingType === 'number-of-plans');
        }  
        let regularPrice = Number(monthlyPrice*12);
        let approxSaving = Math.round(Number((regularPrice-price)*100/regularPrice));
        if(buttonLink){
          return (
            <PriceingPlanItem 
              key={index} 
              data={{
                _id: item?._id,
                title : item?.title,
                price: price,
                recurring: "yr*",
                btnname: t('buy_plan'),
                buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
                buttonHasLink:true,
                link: '//calendly.com/successionnow/30-min-succession-now-onboarding-call',
                features: features.length>0 && features.map((row,key) => {
                  return {
                    _id: Number(key+1), name: ((row?.inputValue>1000)?'Unlimited':row?.inputValue)+' '+row?.name
                  }
                }),
                annualPriceSaving: approxSaving
              }}
            />
          ) 
        }else{
          return (
            <PriceingPlanItem 
              key={index} 
              data={{
                _id: item?._id,
                title : item?.title,
                price: price,
                recurring: "yr*",
                btnname: t('buy_plan'),
                buttonClasses: '!bg-dark-teal-500 !text-base xl:!text-lg lg:h-10 xl:h-14 !px-6',
                buttonHasLink:false,
                getFunction: getFunction(),
                features: features.length>0 && features.map((row,key) => {
                  return {
                    _id: Number(key+1), name: ((row?.inputValue>1000)?'Unlimited':row?.inputValue)+' '+row?.name
                  }
                }),
                annualPriceSaving: approxSaving
              }}
              getFunction={(id,price) => createPayment(id,price,'year')}
            />
          )
        }       
      })}
    </>
    
  );
};

export default AnnualPlan;
