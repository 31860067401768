import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Input from '../form/Input';
import Textarea from '../form/Textarea';
import Select from '../form/Select';

import { postData } from '../../services/api';
import toastr from 'toastr';
import AlertPopup from './AlertPopup';
import { useTranslation } from "react-i18next";

const OtherRatingOptionPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {}, 
}) => { 
  const { i18n,t } = useTranslation();
  const role = localStorage.getItem("role");  
  const [label,setLabel] = useState(null);
  const [value,setValue] = useState(null);
  const [description,setDescription] = useState(null)
  const [loading,setLoading] = useState(false); 
  
  const [actionValue,setActionValue] = useState({});

  const closeModal = () =>{ setIsOpen(false) } 

  const onSubmit = async () => {
    setLoading(true);
    if(!label && !value && !description){
      toastr.error(t('all_field_is_empty'));
      setLoading(false);
    }
     try {          
       const result = await postData("employee-rating-option/add-individual-row", {
         questionId : data?.questionId,
         label : label,
         value:value,
         description : description
       });
       if (result.status && result.status === 200) {
         toastr.success(result.message);
         getActionValue({ button_type : 'add_other_answer',row_id : data?.questionId,result : result?.data});
         setIsOpen(false)
         setLoading(false);
       } else {
         toastr.error(result.message);
         setLoading(false);
       }
     } catch (error) {
       toastr.error(error.message);
       setLoading(false);
     }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('other_option')}
                    </Dialog.Title>
                    <div className="relative mt-4">
                      <div className="divide-y divide-slate-200">
                        <div className="py-2">
                          <div className="items-end gap-4 mb-4">
                            <div className="grid sm:grid-cols-2 gap-4 relative pr-8 sm:pr-10">
                              <div className="relative w-full">
                                <Input 
                                  label={t("label")} 
                                  labelClasses={"!text-xs"} 
                                  inputType={"text"} 
                                  inputPlaceholder={t("enter_label")} 
                                  inputValue={label} 
                                  setInput={setLabel} 
                                />
                              </div>
                              <div className="relative w-full">
                                <Input 
                                  label={t("value")} 
                                  labelClasses={"!text-xs"} 
                                  inputType={"text"} 
                                  inputPlaceholder={t("enter_value")} 
                                  inputValue={value} 
                                  setInput={setValue} 
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-start gap-4 relative pr-8 sm:pr-10">
                            <div className="relative w-full">
                              <Textarea 
                                label={t("description")} 
                                inputClasses={"!h-16"} 
                                labelClasses={"!text-xs"} 
                                inputValue={description} 
                                inputPlaceholder={t("enter_description")} 
                                setTextarea={setDescription} 
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex justify-center mb-3 mt-6 gap-4">
                        <Button
                        buttonClasses='!bg-teal-500'
                        buttonLabelClasses='' 
                        buttonType={'button'} 
                        buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('save')}
                        functions={onSubmit}
                        // functions={() => {
                        //   if(!label){
                        //     toastr.error(t('label_field_is_required'));
                        //   }else if(!description){
                        //     toastr.error(t('description_field_is_required'));
                        //   }
                        // }}
                        buttonHasLink={false}
                        buttonDisabled={loading}
                        />
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('cancel')} 
                        buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}                        
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default OtherRatingOptionPopup;
