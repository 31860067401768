import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Input from '../form/Input';
import Textarea from '../form/Textarea';

import { postData } from '../../services/api';
import toastr from 'toastr';
import { useTranslation } from "react-i18next";

const OtherIndustryPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {}, 
  type,
}) => { 
  const { i18n,t } = useTranslation();
  const role = localStorage.getItem("role");  
  const [name,setName] = useState('');
  const [loading,setLoading] = useState(false);

  const closeModal = () =>{ setIsOpen(false) } 
  
  const onSubmit = async () => {
      try {
        setLoading(true);
        const result = await postData("industry/create", {
          id:data?._id,
          name : name
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          getActionValue({ button_type : 'industry_add',row_id : result?.data?._id,result : result?.data});
          setIsOpen(false)
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setLoading(false);
      }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('manage_industry')}
                    </Dialog.Title>
                    <div className="relative mt-4">
                      <div className="relative mb-3">
                        <Input 
                        label={t('name')} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={t('enter_name')}
                        inputValue={name}  
                        setInput={setName} 
                        />
                      </div>                                   
                    </div>
                    <div className="relative flex justify-center mb-3 mt-6 gap-4">
                        <Button
                        buttonClasses='!bg-teal-500'
                        buttonLabelClasses='' 
                        buttonType={'button'} 
                        buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('confirm')}
                        functions={onSubmit}
                        buttonHasLink={false}
                        buttonDisabled={loading}
                        />
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('cancel')} 
                        buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default OtherIndustryPopup;
