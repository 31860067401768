import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const SalesTeamReport = ({ results,header, user }) => {
  const { i18n,t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'RobotoRegular',
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 10,
      width: "20%",
      maxWidth: '100%',
      padding: 8,

    },
    tableData: {
      fontSize: 9,
      width: "20%",
      maxWidth: '100%',
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });    

  return (
    <Document>
      <Page size="A4"  orientation='landscape' style={styles.page}>
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
            />
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16 , textTransform:'capitalize'}]}>{t('sales_tracking_report')}</Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by :</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{user?.name}</Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
       
        {
          results && results.length > 0 &&   
          <View style={[ { marginTop: 10, marginBottom: 10 }]}>            
            <View style={{ border: "1px solid #e2e8f0", borderRadius: 4, overflow: "hidden" }}>
              <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
              {
                header.length>0 && header.map((item,index) =>(
                  <View style={[styles.tableHeading, {}]} key={index}>
                    <Text style={[styles.tableHeadingText, {textAlign: "center"}]}>{item.name}</Text>
                  </View>
                ))
              }
              </View>
              <View style={[{borderTop: "1px solid #e2e8f0",  flexDirection: "column", width:"100%",}]}>
                {
                  results.map((item,index) => {  
                    return (
                    <View style={[{flexDirection: "row", width:"100%",}]} key={index}>
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8, width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.created_at)? moment(new Date(item?.created_at)).format("MMM Do YYYY"):'N/A'}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{item?.company_name}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.salesperson)?item?.salesperson : ""}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.invoice_send && item?.invoice_send === 1)?t('yes') : t('no')}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.purchase_agreement_received && item?.purchase_agreement_received === 1)?t('yes') : t('no')}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.account_setup && item?.account_setup === 1)?t('yes') : t('no')}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.payment_received && item?.payment_received === 1)?t('yes') : t('no')}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.traning_schedule && item?.traning_schedule === 1)?t('yes') : t('no')}</Text>
                      </View> 
                      <View style={{ paddingHorizontal: 8,paddingVertical: 8,width:"100%"}}>
                        <Text style={{ fontSize: 8,  width: "100%", marginTop: 2, textAlign:"center" }}>{(item?.traning_complete && item?.traning_complete === 1)?t('yes') : t('no')}</Text>
                      </View>
                    </View>
                    )
                  })
                }   
              </View>              
            </View>
          </View>
        }
      </Page>
    </Document>
  )
};
export default SalesTeamReport;