import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import translationEN from "../src/locales/en/translation.json";
import translationFR from "../src/locales/fr/translation.json";


i18next.use(HttpApi).use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    // Add more languages as needed
  },
  lng: 'en',
  fallbackLng: 'en', // Default language
  ns: ['translation'],
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>    
  </Provider>,
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
