import React from 'react';
import PageBanner from "../components/section/PageBanner";
import ResourcesContent from "../components/section/Resources/ResourcesContent";
import { setTitle } from '../helpers/MetaTag';
import { useTranslation } from "react-i18next";

const Resources = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Resources");
  const { i18n,t } = useTranslation();
  return (
    <>
      <PageBanner title={t('resources')}/>
      <ResourcesContent notId={''} />
    </>
  );
};

export default Resources;