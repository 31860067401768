import React, { useState, useRef, useEffect } from 'react'
import { allowedImageExtensions } from "../../helpers";
import toastr from 'toastr';
import moment from 'moment';
import ButtonFile from '../../components/form/ButtonFile';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import Image from '../../components/elements/Image';
import { postData } from '../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { setTitle } from "../../helpers/MetaTag";
import { useTranslation } from "react-i18next";

const ManageCompany = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Company");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Company");
  }
  const { i18n,t } = useTranslation();
  const navigate = useNavigate()
  const editorRef = useRef(null);

  const [details, setDetails] = useState({})
  const [image, setImage] = useState('') 
  const [isManage, setIsManage] = useState(false)
  const [showLogo, setShowLogo] = useState("");
  const [name,setName] = useState('');
  const [company_name,setCompanyName] = useState('');
  const [number_of_employees,setNumberOfEmployees] = useState('');
  const [email, setEmail] = useState("");
  const [password,setPassword] = useState('');
  const [confirmPassword,setConfirmPassword] = useState('');
  const [promoCode,setPrromoCode] = useState('');

  useEffect(() => {
    // Plan details
    const loadDetails = async () => {
      try {
        const result = await postData("blog/details", {
          id: id
        });
        if (result.data) {
          setDetails(result.data)
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);
  

  const onSubmit = async () => {
    setIsManage(true)
    try {
      let path = '';
      let payload = '';
      if (id) {
        path = 'usertype/update';
        payload = {
          id: id,
          name: name,
          email: email,
          company_name: company_name,
          //image: image,
          number_of_employees: number_of_employees,
          password:password,
          confirm_password:confirmPassword,
          code: 'customer-admin'
        }
      } else {
        path = "usertype/create"
        payload = {
          name: name,
          email: email,
          company_name: company_name,
          //image: image,
          number_of_employees: number_of_employees,
          password:password,
          confirm_password:confirmPassword,
          code: 'customer-admin',
          promo_code : promoCode
        }
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message)
        navigate('/companies')
      } else {
        toastr.error(res.message)
      }
    } catch (err) {
      console.log(err.messege)
    }
    setIsManage(false)
  }

  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold"> {(id) ? t('edit_company') : t('add_company')}</div>
      </div>
      <div className="relative grid grid-cols-4 gap-4">        
        <div className="col-span-4">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="space-y-4 mt-4">
                <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Input
                        label={t('company_name')}
                        labelClasses={"!text-xs"}
                        inputType={"input"}
                        inputPlaceholder={t('company_name')}
                        inputValue={company_name}
                        setInput={setCompanyName}
                      />
                    </div>
                    <div className="relative w-full">
                      <Input
                        label={t('your_full_name')}
                        labelClasses={"!text-xs"}
                        inputType={"input"}
                        inputPlaceholder={t('enter_full_name')}
                        inputValue={name}
                        setInput={setName}
                      />
                    </div>
                  </div>
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Input
                        label={t('work_email')}
                        labelClasses={"!text-xs"}
                        inputType={"email"}
                        inputPlaceholder={t('work_email_address')}
                        inputValue={email}
                        setInput={setEmail}
                      />
                    </div>
                    <div className="relative w-full">
                      <Input
                          label={t('number_of_employees')}
                          labelClasses={"!text-xs"}
                          inputType={"input"}
                          inputPlaceholder={t('number_of_employees')}
                          inputValue={number_of_employees}
                          setInput={setNumberOfEmployees}
                      />
                    </div>
                  </div>
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Input
                        label={t('password')}
                        labelClasses={"!text-xs"}
                        inputType={"password"}
                        inputPlaceholder={t('your_password')}
                        inputValue={password}
                        setInput={setPassword}
                      /> 
                    </div>
                    <div className="relative w-full">
                      <Input
                        label={t('confirm_password')}
                        labelClasses={"!text-xs"}
                        inputType={"password"}
                        inputPlaceholder={t('confirm_your_password')}
                        inputValue={confirmPassword}
                        setInput={setConfirmPassword}
                      />
                    </div>
                    <div className="relative w-full">
                      <Input
                        label={t('promo_code')}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={t('enter_promo_code_if_available')}
                        inputValue={promoCode}
                        setInput={setPrromoCode}
                      />
                    </div>
                  </div>
                </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button
                  buttonClasses=''
                  buttonLabelClasses=''
                  buttonType={'button'}
                  buttonIcon={(isManage) ? 'fa-light fa-spinner fa-spin' : 'fa-light fa-check'}
                  buttonIconPosition={'left'}
                  buttonLabel={t('save')}
                  functions={onSubmit}
                  buttonHasLink={false}
                  buttonDisabled={isManage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default ManageCompany;
