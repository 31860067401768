import React,{useState,useEffect} from 'react';
import Button from "../../../form/Button";

import { useTranslation } from "react-i18next";

const BusinessFutureSection= ({title,description}) => {
  const { i18n,t } = useTranslation();
  const BuildSuccessionData = {
    title:title,
    subtitle:t('second_thoughts_dont_worry'),
    description:"<p>"+description+"</p>",  
  }
  return (
    <section className="py-10 relative  w-full" id="businessfuture-section">
        <div className="w-full max-w-[90%] xs:max-w-lg lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto bg-black rounded-xl px-6 lg:px-12 py-10">
            <div className="block lg:flex items-center justify-between space-y-5 lg:space-y-0">
                
                <div className="w-full lg:w-7/12  relative">
                      <div className="flex flex-col space-y-5">
                          <div className="text-base xs:text-lg xl:text-xl 2xl:text-2xl text-white font-semibold font-PlusJakartaSans max-w-full lg:max-w-[600px] w-full text-center lg:text-left" dangerouslySetInnerHTML={{__html:BuildSuccessionData.subtitle}}></div>
                          <div className="font-PlusJakartaSans font-extrabold text-2xl xs:text-3xl lg:text-4xl xl:text-5xl  text-white max-w-full lg:max-w-[600px] w-full text-center lg:text-left" dangerouslySetInnerHTML={{__html:BuildSuccessionData.title}}></div>
                          <div className=" text-[#E4E4E4] text-sm xs:text-base xl:text-lg font-Inter font-medium text-center lg:text-left" dangerouslySetInnerHTML={{__html:BuildSuccessionData.description}}></div>
                          
                      </div>
                </div>
                <div className="w-full lg:w-5/12 relative">
                    <div className="relative flex justify-center lg:justify-end">
                              <Button
                                  buttonLabel={t('lets_get_started')}
                                  buttonLabelClasses="capitalize !text-dark-teal-500 font-PlusJakartaSans !font-bold tracking-wider !text-base xl:!text-lg"
                                  buttonClasses="lg:h-10 xl:h-14 rounded !bg-white !px-10 !text-dark-teal-500 !text-sm xs:!text-base xl:!text-lg"
                                  buttonIconPosition="right"
                                  buttonIcon="fa-solid fa-arrow-right"
                                  buttonHasLink={true}
                                  buttonTarget="_blank"
                                  buttonLink="//calendly.com/successionnow/30-min-succession-now-onboarding-call"
                              />
                    </div>
                </div>
            </div>
             
        </div>
    </section>
  );
};

export default BusinessFutureSection