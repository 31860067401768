import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import Checkbox from "../../components/form/Checkbox";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
const ManagePricing = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Plan & Pricing");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Plan & Pricing");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [planFeatures, setPlanfeatures] = useState({});
  const [name, setName] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [price, setPrice] = useState("");
  const [interval, setInterval] = useState("");
  const [intervalsCount, setIntervalsCount] = useState(1);
  const [features, setFeatures] = useState([]);
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [pricing,setPricing] = useState({
    type: "standard",
    rates: [
      {
        interval: "month",
        amount: 0,
      },
    ],
  })

  const rateArray = [
    {
      interval: "month",
      title: "monthly",
    },
    {
      interval: "year",
      title: "yearly",
    },
    // {
    //   interval: "quarter",
    //   title: "quarterly",
    // },
  ]

  const capitalizeString = (str) => {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  };


  useEffect(() => {
    // Plan Features list
    const loadFeatureList = async () => {
      try {
        const result = await postData("plan-feature/list", {
          status: 1,
        });
        if (result.data) {
          setPlanfeatures(result.data);
          setFeatures(
            result.data.map((value, key) => {
              return {
                name: value?.name,                
                code: value?.code,
                fieldType : value?.fieldType,
                headingType : value?.headingType,
                inputValue:"",
                checkboxValue: false,
              };
            })
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadFeatureList();
    // Plan details
    const loadDetails = async () => {
      try {
        const result = await postData("plan/details", {
          id: id,
        });
        if (result.status === 200) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    setPrice(details?.price);
    setSubTitle(details?.sub_title);
    if(details && details?.pricing){
      setPricing(details?.pricing)
    } 
    if (details && details?.features) {
      setFeatures(
        features.map((it, idx) => {
          const index = details?.features?.findIndex((item) => item.code === it.code);
          if (idx === index) {
            if(details.features[index].fieldType === 'input'){
              return {
                ...it,
                inputValue: details?.features[index]?.inputValue,
                note : details?.features[index]?.note
              };
            }else{
              return {
                ...it,
                checkboxValue: details.features[index].checkboxValue,
                note : details?.features[index]?.note
              };
            }
            
          } else {
            return it;
          }
        })
      );
    }
  }, [details]);  

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setSubTitle(details?.sub_title);
      setPrice(details?.price);
      // setInterval(details?.interval);
      // setIntervalsCount(details?.interval_count);
    } else {
      setName("");
      setSubTitle("");
      setPrice("");
      // setInterval("");
      // setIntervalsCount("");
    }
    setIsCancel(false);
    navigate("/pricing");
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "plan/update";
        payload = {
          id: id,
          name: name,
          sub_title: subTitle,
          price: price,
          pricing: pricing,
          // interval: interval,
          // interval_count: intervalsCount ? intervalsCount : 1,
          features: features,
        };
      } else {
        path = "plan/create";
        payload = {
          name: name,
          sub_title: subTitle,
          price: price,
          pricing: pricing,
          // interval: interval,
          // interval_count: intervalsCount ? intervalsCount : 1,
          features: features,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/pricing");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  const updateHandler = async (code, value,fieldType,field) => {
    setFeatures(
      features.map((it) => {       
        if (it?.code === code) {
          if(fieldType !== 'input' || fieldType !== 'checkbox'){
            return {
              ...it,
              [field]: value,
            };
          }else{ 
            return {
              ...it,
              [field]: value,
            };
          }          
        } else {
          return it;
        }
      })
    );
  };  

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{id ? t("edit_plan_&_pricing") : t("add_plan_&_pricing")}</div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-4 px-4 sm:py-8 sm:px-6 flex flex-col">
              <div className="space-y-4 mt-4">
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    <Input label={t("name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_name")} inputValue={name} setInput={setName} />
                  </div>
                  <div className="relative w-full">
                    <Input label={t("subtitle")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_name")} inputValue={subTitle} setInput={setSubTitle} />
                  </div>
                  {/* <div className="relative w-full">
                    <Input label={t("price")} labelClasses={"!text-xs"} inputType={"number"} inputPlaceholder={t("enter_price")} inputValue={price} setInput={setPrice} isDisabled={false} />
                  </div> */}
                </div>
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    {
                      pricing && pricing?.rates?.map((item,index) => (
                        <div className="flex gap-2 mb-2" key={index}>
                          <div className="flex-shrink w-full">
                            <Input 
                              label={`${capitalizeString(rateArray?.find((_itm) => _itm?.interval === item?.interval)?.title)} Amount`} 
                              labelClasses={"!text-xs"} 
                              inputType={"number"} 
                              inputPlaceholder={t("enter_price")} 
                              inputName={`${item?.interval}-amount`}
                              inputValue={item?.amount || ""} 
                              setInput={(val) => {
                                setPricing((prevData) => ({
                                  type: prevData?.type,
                                  rates: prevData?.rates.map((itm) => {
                                    return {
                                      ...itm, 
                                      amount: itm?.interval === item?.interval ? val : itm?.amount
                                    }                                     
                                  }),
                                }))
                              }} 
                              isDisabled={false} 
                            />
                          </div>
                          {
                            pricing?.rates?.length > 1 && (
                              <div className="flex-shrink-0 flex items-end justify-center">
                                <Button
                                  buttonIconPosition={"right"}
                                  buttonIcon={"fa-regular fa-xmark"}                                  
                                  buttonClasses={"!w-7 !h-7 !rounded-full !items-center !justify-center !flex !bg-transparent !text-rose-600 border border-slate-300"}
                                  functions={() => {
                                    setPricing((prevData) => ({
                                      type: prevData?.type,
                                      rates: prevData?.rates?.filter((_item) => _item?.interval !== item.interval)
                                    }))
                                  }} 
                                  buttonHasLink={false}
                                />
                              </div>
                            )
                          }
                        </div>
                      ))
                    }             
                    <div className="flex mt-2 space-x-2">
                      {
                        rateArray?.filter((item) => !pricing?.rates?.find((price) => price.interval === item?.interval))?.map((rate, index) => {
                          return (
                            <Button
                              buttonLabel={"Add "+rate.title}
                              buttonIconPosition={"left"}
                              buttonIcon={"fa-regular fa-plus"}
                              buttonClasses={"!capitalize"}
                              functions={() => {
                                setPricing((prevData) => ({
                                  type: prevData?.type,
                                  rates: [
                                    ...prevData?.rates,
                                    {
                                      interval: rate.interval,
                                      amount: "",
                                    },
                                  ],
                                }))
                              }} 
                              key={index}
                            />
                          )
                        })
                      } 
                    </div>
                  </div>                  
                </div>
                {/* <div className="flex items-start gap-4">
                  <div className="relative w-full">
                    <Select
                      label={t("interval")}
                      labelClasses={"!text-xs"}
                      xPlacement={"bottomLeft"}
                      selectedValue={interval}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: " ", label: t("select_interval"), value: "" },
                        { _id: "year", label: t("year"), value: "year" },
                        { _id: "month", label: t("month"), value: "month" },
                        { _id: "week", label: t("week"), value: "week" },
                        { _id: "day", label: t("day"), value: "day" },
                      ]}
                      getSelectedValue={(e) => setInterval(e._id)}
                    />
                  </div>
                  <div className="relative w-full">
                    <Input label={interval ? +t("per") + " " + interval[0].toUpperCase() + interval.slice(1) : t("interval_count")} labelClasses={"!text-xs"} inputType={"number"} inputPlaceholder={t("enter_interval_count")} inputValue={intervalsCount} setInput={setIntervalsCount} />
                  </div>
                </div> */}
                <div className="font-medium text-slate-500 text-xs">{t("plan_features")}</div>
                <div className="grid grid-cols-1 gap-4">
                  {planFeatures &&
                    planFeatures.length > 0 &&
                    planFeatures.map((item, index) => (
                      <div className="flex justify-between items-center" key={index}>
                        <div className="text-sm text-slate-500 flex-shrink">{item?.name}</div>
                        <div className="flex-shrink-0 flex gap-3">
                          {
                            (item?.fieldType && item?.fieldType === 'checkbox')?
                            <Checkbox 
                              checkboxName={item?.name} 
                              isChecked={features[index]?.checkboxValue} 
                              checkboxLabel={''} 
                              checkboxValue={item?.code} 
                              getCheckboxValue={(code, event) => updateHandler(code, event.target.checked,item?.fieldType,'checkboxValue')} 
                            />:
                            <Input 
                              inputType={"number"} 
                              inputClasses={"h-10 max-w-[80px] w-full"}
                              inputPlaceholder={t("enter_value")} 
                              inputValue={features[index]?.inputValue} 
                              setInput={(val) => updateHandler(item?.code, val,item?.fieldType,'inputValue')} 
                            />  
                          }      
                          <div className="relative">
                            <Input 
                              inputType={"text"} 
                              inputClasses={"h-10 max-w-[200px] w-full"}
                              inputPlaceholder={t("enter_note")}
                              inputValue={features[index]?.note} 
                              setInput={(val) => updateHandler(item?.code, val,item?.fieldType,'note')} 
                            />
                          </div>                    
                        </div>                        
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="py-3 sm:py-3 px-4 sm:px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePricing;
