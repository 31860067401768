import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Input from '../form/Input';
import Textarea from '../form/Textarea';
import Select from '../form/Select';
import { useSelector } from 'react-redux';
import { postData } from '../../services/api';
import toastr from 'toastr';

import { useTranslation } from "react-i18next";

const HelpPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {}, 
  type,
}) => {   
  const { i18n,t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const role = localStorage.getItem("role");
  const [subject,setSubject] = useState('');
  const [description,setDescription] = useState('');
  const [email,setEmail] =  useState('');
  const [loading,setLoading] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const closeModal = () =>{ setIsOpen(false) } 

  const onSubmit = async () => {
      try {
        setLoading(true);        
        const result = await postData("enquery/create", {
          subject : subject,
          message : description,
          email: email,
          type:100
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          getActionValue({ button_type : 'edit',row_id : data?._id,current_status : ''});
          setIsOpen(false)
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setLoading(false);
      }
  } 

  useEffect(() => {
    setEmail(user?.email);
  },[user])

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>{}}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                {t('how_can_we_help_you')}
              </Dialog.Title>
              <div className="relative mt-4">                      
                <div className="relative mb-3">
                  <Input 
                    label={t('subject')} 
                    labelClasses={'!text-xs'} 
                    inputType={'text'}                   
                    inputPlaceholder={t('enter_subject')}
                    inputValue={subject}  
                    setInput={setSubject} 
                  />
                </div> 
                <div className="relative mb-3">
                  <Textarea 
                    label={t('description')} 
                    inputClasses={"!h-40"} 
                    labelClasses={"!text-xs"} 
                    inputValue={description} 
                    inputPlaceholder={t("please_include_any_additional_information")} 
                    setTextarea={setDescription} 
                    //isAddBullet={true}
                  />
                </div> 
                <div className="relative mb-3">
                  <Input 
                    label={t('contact_email')} 
                    labelClasses={'!text-xs'} 
                    inputType={'text'}                   
                    inputPlaceholder={t('enter_email')}
                    inputValue={email}  
                    setInput={setEmail} 
                  />
                </div>                                
              </div>                   
              <div className="relative flex justify-center mb-3 mt-6 gap-4">
                <Button
                  buttonClasses='!bg-teal-500'
                  buttonLabelClasses='' 
                  buttonType={'button'} 
                  buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                  buttonIconPosition={'left'} 
                  buttonLabel={t('send')}
                  functions={onSubmit}
                  buttonHasLink={false}
                />
                <Button 
                  buttonLabelClasses=''
                  buttonType={'button'} 
                  buttonIcon={'fa-light fa-times'} 
                  buttonIconPosition={'left'} 
                  buttonLabel={t('cancel')} 
                  buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                  functions={closeModal}
                  buttonHasLink={false}
                />
              </div>
              </Dialog.Panel>
            </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default HelpPopup;
