import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import toastr from 'toastr';
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { postData } from "../services/api";
import { useTranslation } from "react-i18next";

const ManageIndustry = () => {
    const { id } = useParams();
    if(id){
        setTitle("Succession Planning Software for your Business with Succession Now | Edit Industry");
    }else{
        setTitle("Succession Planning Software for your Business with Succession Now | Add Industry");
    }  
    const { i18n,t } = useTranslation();    
    const navigate = useNavigate();
    const [details,setDetails] = useState({});
    const [name,setName] = useState('');
    const [alias,setAlias] = useState();
    const [isManage,setIsManage] = useState(false);
    const [isCancel,setIsCancel] = useState(false);

    useEffect(() => {
        // Industry details
        const loadDetails = async () => {
          try {
            const result = await postData("industry/details",{
              id: id
            });
            if(result.data) {
                setDetails(result.data)
            }
          } catch (err) {
            console.log(err.message);
          }
        };
        loadDetails();
    }, [id]);

    useEffect(() => {
        setName(details?.name);
        setAlias(details?.alias);
    }, [details]);

    const onCancel = async (e) => {
        setIsCancel(true);
        if(id){
            setName(details?.name);
            setAlias(details?.point);
        }else{
            setName('');
            setAlias('');  
        }     
        setIsCancel(false);
    }

    const onSubmit = async (e) => {
        setIsManage(true);
        try {
            let path = '';
            let payload = '';
            if(id){
                path = 'industry/update';
                payload = {
                    id:id,
                    name : name,
                }
            }else{
                path = 'industry/create';
                payload = {
                    name : name,
                    alias: alias,
                }
            }

            //console.log("payload",payload)
            
            const result = await postData(path, payload);
            if (result.status && result.status === 200) {        
                toastr.success(result.message);
                setIsManage(false);
                return navigate("/industries");
            } else {
                toastr.error(result.message);
                setIsManage(false);
            }
        } catch (error) {
            toastr.error(error.message);
            setIsManage(false);
        }
    }

    return(
        <>
          <div className="relative flex justify-between items-center mb-4">
            <div className="text-3xl text-slate-800 font-bold">{ (id)?t('edit_industry'):t('add_industry')} </div>
          </div>
          <div className="relative grid grid-cols-2 gap-4">           
            <div className="col-span-2">
              <div className="relative bg-white rounded shadow divide-y divide-slate-200">
                <div className="py-8 px-6 flex flex-col">
                  {/* <div className="text-xl font-bold text-slate-900">Successor Information</div> */}
                  <div className="space-y-4 mt-4">
                    <div className="flex items-start gap-4">
                      <div className="relative w-full">
                        <Input 
                        label={t('name')} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={t('enter_name')}
                        inputValue={name}  
                        setInput={(val) => {
                            setName(val);
                            setAlias(val.replaceAll(' ', '_').toLowerCase())
                        }} 
                        />
                      </div>
                      {/* <div className="relative w-full">
                        <Input 
                        label={'Alias'} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={'Enter Point'}
                        inputValue={alias}  
                        setInput={setAlias} 
                        isDisabled={true}
                        />
                      </div> */}
                    </div>                    
                  </div>
                </div>
                <div className="py-3 px-3 border-t border-slate-200">
                  <div className="flex items-center justify-end gap-4">
                    <Button 
                    buttonLabelClasses=''
                    buttonType={'button'} 
                    buttonIcon={(isCancel)?'fa-light fa-spinner fa-spin':'fa-light fa-times'} 
                    buttonIconPosition={'left'} 
                    buttonLabel={t('cancel')} 
                    buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                    functions={onCancel}
                    buttonHasLink={false}
                    buttonDisabled={isCancel}
                    />
                    <Button
                    buttonClasses=''
                    buttonLabelClasses='' 
                    buttonType={'button'} 
                    buttonIcon={(isManage)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                    buttonIconPosition={'left'} 
                    buttonLabel={t('save')}
                    functions={onSubmit}
                    buttonHasLink={false}
                    buttonDisabled={isManage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    )

}

export default ManageIndustry;