import React, { useState, useEffect,Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import Button from "../form/Button";
import { useTranslation } from "react-i18next";

const SurveyConfirmationPopup = ({
    isOpen,
    setIsOpen = () => {},
    currentId,
    getActionValue = () => {}, 
    message,
}) => {
    const { i18n,t } = useTranslation();
    const closeModal = () =>{
        getActionValue({
          button_type : 'confirmation-survay',
          row_id : '',
          current_status : 2
        })
        setIsOpen(false)
    } 

    const onSubmit = (status) => {
        getActionValue({
            button_type : 'confirmation-survay',
            row_id : '',
            current_status : 1
        })
        setIsOpen(false)
    }

    return (
        <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-xs transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('confirmation')}
                    </Dialog.Title>
                    <div className="py-8 px-4">
                        <p className="text-sm text-gray-500 text-center">
                        {(message)?message:t('you_are_trying_to_save_all_updated_record_click_yes_below')}                        
                        </p>
                    </div>

                    <div className="flex items-center justify-center gap-4 mt-2">
                        <Button
                        buttonClasses={'!bg-teal-500'}
                        buttonLabelClasses='' 
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-check'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('yes')}
                        functions={onSubmit}
                        buttonHasLink={false}
                        />
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('no')} 
                        buttonClasses={'!bg-rose-500'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
        </>
    )
};
export default SurveyConfirmationPopup;