import React, { useState, useRef, useEffect } from 'react';
import { setTitle } from '../helpers/MetaTag';
import Image from '../components/elements/Image';
import { useParams } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { postData } from '../services/api';

const CompanyDetails = () => {
  const { id } = useParams();
  setTitle("Succession Planning Software for your Business with Succession Now | Company Details");
  const { i18n,t } = useTranslation();
  const [details,setDetails] = useState('');
  const [salesCommission,setSalesCommission] = useState(0);
  const [consultantCommission,setConsultantCommission] = useState(0);
  const [appointmentSetterCommission,setAppointmentSetterCommission] = useState(0);
  const [salesAdminCommission,setSalesAdminCommission] = useState(0);


  useEffect(()=>{
    const loadDetails = async () => {
      try {
        const result = await postData("usertype/details", {
          id : id
        });
        if (result.data) {
          setDetails(result.data);
          if(result.data?.staffs && Object.keys(result.data?.staffs).length>0){
            let sales = result.data?.staffs.filter((itm) => itm.code === 'sales-staff');
            if(sales && sales.length>0){
              setSalesCommission((sales[0].commission)?sales[0].commission:0);
            } 
            let consultant = result.data?.staffs.filter((item) => item.code === 'consultant');
            if(consultant && consultant.length>0){
              setConsultantCommission((consultant[0].commission)?consultant[0].commission:0);
            }
            let appointmentSetter = result.data?.staffs.filter((item) => item.code === 'appointment-setter');
            if(appointmentSetter && appointmentSetter.length>0){
              setAppointmentSetterCommission((appointmentSetter[0].commission)?appointmentSetter[0].commission:0);
            }
            let salesAdmin = result.data?.staffs.filter((item) => item.code === 'sales-admin');
            if(salesAdmin && salesAdmin.length>0){
              setSalesAdminCommission((salesAdmin[0].commission)?salesAdmin[0].commission:0);
            }
          } 
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  },[])

  

  return (
    <>
    <div className="relative flex justify-between items-center mb-4">
      <div className="text-3xl text-slate-800 font-bold">{t('company_details')}</div>
    </div>
    <div className="relative grid grid-cols-4 gap-4">
      <div className="col-span-3">
        <div className="relative bg-white rounded shadow divide-y divide-slate-200">
          <div className="  divide-y divide-slate-200">
                <div className="relative w-full py-3 px-6">
                  <div className="text-md flex items-center">
                    <div className="text-base max-w-[240px] w-full font-semibold">{t('company_name')} :</div>
                    <div className="text-base  ml-2">{details?.company_name}</div>
                  </div>
                </div>
                <div className="relative w-full py-3 px-6">
                    <div className="text-md flex items-center">
                        <div className="text-base max-w-[240px] w-full font-semibold">{t('company_administrator_name')} :</div>
                        <div className="text-base  w-full ml-2">{details?.name}</div>
                    </div>
                </div>
                <div className="relative w-full py-3 px-6">
                        <div className="text-md flex items-center">
                          <div className="text-base max-w-[240px] w-full font-semibold">{t('administrator_email')} :</div>
                          <div className="text-base ml-2">{details?.email}</div>
                        </div>
                </div>
                <div className="relative w-full py-3 px-6">
                    <div className="text-md flex items-center">
                      <div className="text-base max-w-[240px] w-full font-semibold">{t('administrator_phone_number')} :</div>
                      <div className="text-base  ml-2">{details?.phone}</div>
                    </div>
                </div>
                <div className="relative w-full py-3 px-6">
                    <div className="text-md flex items-center">
                      <div className="text-base max-w-[240px] w-full font-semibold">{t('number_of_employees')} :</div>
                      <div className="text-base  ml-2">{details?.number_of_employees}</div> 
                    </div>
                </div>
                <div className="relative w-full py-3 px-6">
                    <div className="text-md flex items-center">
                      <div className="text-base max-w-[240px] w-full font-semibold">{t('sales')} :</div>
                      <div className="text-base ml-2">{salesCommission+'%'}</div>
                    </div>
                </div>
                <div className="relative w-full py-3 px-6">
                    <div className="text-md flex items-center">
                      <div className="text-base max-w-[240px] w-full font-semibold">{t('consultant')} :</div>
                      <div className="text-base ml-2">{consultantCommission+'%'}</div>
                    </div>                   
                  </div>
                  <div className="relative w-full py-3 px-6">
                    <div className="text-md flex items-center">
                      <div className="text-base max-w-[240px] w-full font-semibold">{t('appointment_setter')} :</div>
                      <div className="text-base ml-2">{appointmentSetterCommission+'%'}</div>
                    </div>
                  </div>
                  <div className="relative w-full py-3 px-6">
                    <div className="text-md flex items-center">
                      <div className="text-base max-w-[240px] w-full font-semibold">{t('sales_admin')} :</div>
                      <div className="text-base ml-2">{salesAdminCommission+'%'}</div>
                    </div>
                  </div>
         
          </div>
        </div>
      </div>
      <div className="col-span-1 space-y-4">
        <div className="relative bg-white rounded shadow flex flex-col h-full max-h-64">
          <div className="py-3 px-6 flex flex-col space-y-4 relative ">
              <div className="text-base font-semibold text-black mb-1">{t('add_a_profile_picture_for_this_account_optional')}</div>
              <div className="w-full h-40 overflow-hidden rounded-md flex items-center cursor-pointer">              
                <Image
                  src={details?.image} 
                  alt={"Admin"}
                  width={"100%"}
                  classes={"object-contain"}
                  id={"image1"}
                />
              </div>
          </div>
        </div>
      </div> 
    </div>
    </>
  );
};

export default CompanyDetails;