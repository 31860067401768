import React from 'react';
import ResourceDetailsContent from "../components/section/Resources/ResourceDetailsContent";

const ResourceDetails = () => {
  return (
    <>
      <ResourceDetailsContent/>
    </>
  );
};

export default ResourceDetails;