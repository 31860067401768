import React, { useEffect, useState } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import { postData } from "../../services/api";
import moment from "moment";
import { Link } from "react-router-dom";
import "chart.js/auto";
import { Chart, Line } from "react-chartjs-2";
import ExpensesPopup from "../../components/popup/ExpensesPopup";

import { useTranslation } from "react-i18next";
import { groupArray } from "../../helpers";

const DashboardStatistics = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Dashboard");
  const { i18n,t } = useTranslation();
  const role = localStorage.getItem("role");
  const [admins, setAdmins] = useState(0);
  const [companies, setCompanies] = useState(0);
  const [totalSuccessionPlan, setTotalSuccessionPlan] = useState(0);
  const [currentMonthSuccessionPlan, setCurrentMonthSuccessionPlan] =
    useState(0);
  const [lastMonthSuccessionPlan, setLastMonthSuccessionPlan] = useState(0);
  const [currentYearSuccessionPlan, setCurrentYearSuccessionPlan] = useState(0);
  const [lastYearSuccessionPlan, setLastYearSuccessionPlan] = useState(0);

  const [earning, setEarning] = useState(0);
  const [currentMonthEarning, setCurrentMonthEarning] = useState(0);
  const [lastMonthEarning, setLastMonthEarning] = useState(0);
  const [currentYearEarning, setCurrentYearEarning] = useState(0);
  const [lastYearEarning, setLastYearEarning] = useState(0);
  const [totalDemoRequest, setTotalDemoRequest] = useState(0);
  const [currentMonthDemoRequest, setCurrentMonthDemoRequest] = useState(0);
  const [lastMonthDemoRequest, setLastMonthDemoRequest] = useState(0);
  const [currentYearDemoRequest, setCurrentYearDemoRequest] = useState(0);
  const [lastYearDemoRequest, setLastYearDemoRequest] = useState(0);
  const [totalCancellations, setTotalCancellations] = useState(0);
  const [currentMonthCancellations, setCurrentMonthCancellations] = useState(0);
  const [lastMonthCancellations, setLastMonthCancellations] = useState(0);
  const [currentYearCancellations, setCurrentYearCancellations] = useState(0);
  const [lastYearCancellations, setLastYearCancellations] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const [currentMonthExpenses, setCurrentMonthExpenses] = useState(0);
  const [lastMonthExpenses, setLastMonthExpenses] = useState(0);
  const [currentYearExpenses, setCurrentYearExpenses] = useState(0);
  const [lastYearExpenses, setLastYearExpenses] = useState(0);

  const [commission, setCommission] = useState(0);
  const [currentMonthCommission, setCurrentMonthCommission] = useState(0);
  const [lastMonthCommission, setLastMonthCommission] = useState(0);
  const [currentYearCommission, setCurrentYearCommission] = useState(0);
  const [lastYearCommission, setLastYearCommission] = useState(0);

  const [totalSale, setTotalSale] = useState(0);
  const [currentMonthSale, setCurrentMonthSale] = useState(0);
  const [lastMonthSale, setLastMonthSale] = useState(0);
  const [currentYearSale, setCurrentYearSale] = useState(0);
  const [lastYearSale, setLastYearSale] = useState(0);

  const [totalRenewal,setTotalRenewal] = useState(0);
  const [currentMonthRenewal,setCurrentMonthRenewal] = useState(0);
  const [lastMonthRenewal,setLastMonthRenewal] = useState(0);
  const [currentYearRenewal,setCurrentYearRenewal] = useState(0);
  const [lastYearRenewal,setLastYearRenewal] = useState(0);

  const [salesReport,setSalesReport] = useState([])

  const [names, setNames] = useState([]);
  const [points, setPoints] = useState([
    10, 20, 30, 15, 18, 45, 10, 22, 24, 24, 15, 18,
  ]);
  const [renewing, setRenewing] = useState([
    10, 12, 25, 16, 18, 15, 25, 15, 25, 10, 18, 20,
  ]);

  const [enabledExpensesPopup, setEnabledExpensesPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});

  const [results,setResults] = useState([]);
  const [salespersions, setSalepersions] = useState([]);
  const [leadTracking,setLeadTracking] = useState([]);
  const [leadTrackingCount,setLeadTrackingCount] = useState(0);
  const [customerProfile,setCustomerProfile] = useState({});
  const [nonInvitedEmployee,setNonInvitedEmplyee] = useState([]);
  const [code,setCode] = useState('sales-staff');

  const source = [
    { _id: "gartner", label: "Gartner", value: "gartner" },
    { _id: "google", label: "Google", value: "google" },
    { _id: "g2", label: "G2", value: "g2" },    
    { _id: "growbots", label: "GrowBots", value: "growbots" },
    { _id: "linkedin", label: "LinkedIn", value: "linkedin" },
    { _id: "youtube", label: "YouTube", value: "youtube" },
    { _id: "website-organic", label: "Website (Organic)", value: "website-organic" },
    { _id: "referral_email_nick", label: "Referral, Email (Nick)", value: "referral_email_nick" },
    { _id: "hr_perfommance_solutions", label: "HR Performance Solutions", value: "hr_perfommance_solutions" },
    { _id: "other", label: "Other", value: "other" }
  ]

  const staffArray = [
    {
      code: 'sales-staff',
      label : 'Sales Person'
    },
    {
      code: 'consultant',
      label : 'Consultant'
    },
    {
      code: 'appointment-setter',
      label : 'Appointment Setter'
    },
    {
      code: 'sales-admin',
      label : 'Sales Admin'
    }
  ]
  
  useEffect(() => {
    if(results.length>0){
      var result = [];
      results.reduce(function(res, value) {
      let key = value._id
      if (!res[value._id]) {
          res[value._id] = { 
              _id: value._id, 
              name : value.name,
              total : value.total,
              price : value.price,
              qty: 0 
          };
          result.push(res[value._id])
      }else{
        res[value._id].name = value.name;
        res[value._id].total += value.total;
        res[value._id].price += value.price;        
      }      
      res[value._id].qty += value.qty;
      return res;
      }, {});
      setSalesReport(result.filter((item) => item?._id))
    }
  },[results])

  useEffect(() => {
    // Admin List Data
    const loadList = async () => {
      try {
        const result = await postData("usertype/list", {
          code: "master-admin",
        });
        if (result.data) {
          setAdmins(result.count);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadList();

    // Admin List Data
    const loadCompanyList = async () => {
      try {
        const result = await postData("usertype/list", {
          code: "customer-admin",
        });
        if (result.data) {
          setCompanies(result.count);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadCompanyList();

    // Admin List Data
    const loadCountSles = async () => {
      try {
        const result = await postData("usertype/count-sales", {
          //code: ["sales-staff"],
        });
        if (result.data) {
          setSalepersions((result?.data)?result?.data[0]?.paginatedResults:[]);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadCountSles();

    // Succession Plan List Data
    const loadPlanList = async () => {
      try {
        const result = await postData("usertype/list", {
          code: "customer-user",
        });
        if (result.status === 200) {
          let cmSuccessionPlan = 0;
          let lmSuccessionPlan = 0;
          let cySuccessionPlan = 0;
          let lySuccessionPlan = 0;
          result.data.map((value, key) => {
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmSuccessionPlan = cmSuccessionPlan + 1;
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmSuccessionPlan = lmSuccessionPlan + 1;
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cySuccessionPlan = cySuccessionPlan + 1;
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lySuccessionPlan = lySuccessionPlan + 1;
            }
          });
          setTotalSuccessionPlan(result.count);
          setCurrentMonthSuccessionPlan(cmSuccessionPlan);
          setLastMonthSuccessionPlan(lmSuccessionPlan);
          setCurrentYearSuccessionPlan(cySuccessionPlan);
          setLastYearSuccessionPlan(lySuccessionPlan);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPlanList();

    // Admin Dashboard Data
    const loadDashboardList = async () => {
      try {
        const result = await postData("dashboard/list", {});
        if (result.data) {
          let price = 0;
          let cmPrice = 0;
          let lmPrice = 0;
          let cyPrice = 0;
          let lyPrice = 0;
          result.data.map((value, key) => {
            price = price + Number(value?.original_price);
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmPrice = cmPrice + Number(value?.original_price);
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmPrice = lmPrice + Number(value?.original_price);
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cyPrice = cyPrice + Number(value?.original_price);
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyPrice = cyPrice + Number(value?.original_price);
            }
          });
          setEarning(price);
          setCurrentMonthEarning(cmPrice);
          setLastMonthEarning(lmPrice);
          setCurrentYearEarning(cyPrice);
          setLastYearEarning(lyPrice);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDashboardList();

    // Expenses list Data
    const loadExpensesList = async () => {
      try {
        const result = await postData("expenses/list", {});
        if (result.data) {
          let price = 0;
          let cmPrice = 0;
          let lmPrice = 0;
          let cyPrice = 0;
          let lyPrice = 0;
          result.data.map((value, key) => {
            price = price + Number(value?.amount);
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmPrice = cmPrice + Number(value?.amount);
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmPrice = lmPrice + Number(value?.amount);
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cyPrice = cyPrice + Number(value?.amount);
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyPrice = cyPrice + Number(value?.amount);
            }
          });
          setExpenses(price);
          setCurrentMonthExpenses(cmPrice);
          setLastMonthExpenses(lmPrice);
          setCurrentYearExpenses(cyPrice);
          setLastYearExpenses(lyPrice);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadExpensesList();    

    // Demo request List Data
    const loadDemoRequestList = async () => {
      try {
        const result = await postData("enquery/list", { type: 2 });
        if (result.status === 200) {
          let cmDemo = 0;
          let lmDemo = 0;
          let cyDemo = 0;
          let lyDemo = 0;
          result.data.map((value, key) => {
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmDemo = cmDemo + 1;
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmDemo = lmDemo + 1;
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cyDemo = cyDemo + 1;
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyDemo = lyDemo + 1;
            }
          });
          setTotalDemoRequest(result.count);
          setCurrentMonthDemoRequest(cmDemo);
          setLastMonthDemoRequest(lmDemo);
          setCurrentYearDemoRequest(cyDemo);
          setLastYearDemoRequest(lyDemo);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDemoRequestList();

    // Subscription List Data
    const loadSubscriptionList = async () => {
      try {
        const result = await postData("subscription/list", {
          accountType : 'business'
        });
        if (result.status === 200) {
          let cmCancel = 0;
          let lmCancel = 0;
          let cyCancel = 0;
          let lyCancel = 0;
          let totalC = 0;

          let totalSale = 0;
          let cmSale = 0;
          let lmSale = 0;
          let cySale = 0;
          let lySale = 0;

          result.data.map((value, key) => {
            let salesPersion = '';
            let consultantPersion = '';
            let appointmentSetterPersion = '';
            let sales = value?.user_details.length>0 && value?.user_details[0].staffs && value?.user_details[0].staffs.filter((item) => item.code === 'sales-staff');
            if(sales && sales.length>0){
              let salesId = sales[0].staff_id;
              let salesPersionArray = value?.staff_details.filter((itm) => itm._id === salesId);
              if(salesPersionArray && salesPersionArray.length>0){
                salesPersion = salesPersionArray[0].first_name+' '+salesPersionArray[0].last_name;
              }                      
            }
            let consultant = value?.user_details.length>0 && value?.user_details[0].staffs && value?.user_details[0].staffs.filter((item) => item.code === 'consultant');
            if(consultant && consultant.length>0){
              let consultantId = consultant[0].staff_id;
              let consultantPersionArray = value?.staff_details.filter((itm) => itm._id === consultantId);
              if(consultantPersionArray && consultantPersionArray.length>0){
                consultantPersion = consultantPersionArray[0].first_name+' '+consultantPersionArray[0].last_name;
              }
            }
            let appointmentSetter = value?.user_details.length>0 && value?.user_details[0].staffs && value?.user_details[0].staffs.filter((item) => item.code === 'appointment-setter');
            if(appointmentSetter && appointmentSetter.length>0){
              let appointmentSetterId = appointmentSetter[0].staff_id;
              let appointmentSetterPersionArray = value?.staff_details.filter((itm) => itm._id === appointmentSetterId);
              if(appointmentSetterPersionArray && appointmentSetterPersionArray.length>0){
                appointmentSetterPersion = appointmentSetterPersionArray[0].first_name+' '+appointmentSetterPersionArray[0].last_name;
              }
            }
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              if (value?.status === 2) {
                cmCancel = cmCancel + 1;
              }

              if (value?.status === 1 && (salesPersion || consultantPersion || appointmentSetterPersion)) {
                cmSale = Number(cmSale + value?.original_price);
              }
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              if (value?.status === 2) {
                lmCancel = lmCancel + 1;
              }
              if (value?.status === 1 && (salesPersion || consultantPersion || appointmentSetterPersion)) {
                lmSale = Number(lmSale + value?.original_price);
              }
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              if (value?.status === 2) {
                cyCancel = cyCancel + 1;
              }
              if (value?.status === 1 && (salesPersion || consultantPersion || appointmentSetterPersion)) {
                cySale = Number(cySale + value?.original_price);
              }
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              if (value?.status === 2) {
                lyCancel = lyCancel + 1;
              }
              if (value?.status === 1 && (salesPersion || consultantPersion || appointmentSetterPersion)) {
                lySale = Number(lySale + value?.original_price);
              }
            }
            if (value?.status === 2) {
              totalC = totalC + 1;
            }
            if (value?.status === 1 && (salesPersion || consultantPersion || appointmentSetterPersion)) {
              totalSale = Number(totalSale + value?.original_price);
            }
          });
          setTotalCancellations(totalC);
          setCurrentMonthCancellations(cmCancel);
          setLastMonthCancellations(lmCancel);
          setCurrentYearCancellations(cyCancel);
          setLastYearCancellations(lyCancel);

          setTotalSale(totalSale);
          setCurrentMonthSale(cmSale);
          setLastMonthSale(lmSale);
          setCurrentYearSale(cySale);
          setLastYearSale(lySale);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadSubscriptionList();

    // Renewal List Data
    const loadRenewalList = async () => {
      try {
        const result = await postData("subscription/renewal-list", {});
        if (result.status === 200) {
          let cmRenewal = 0;
          let lmRenewal = 0;
          let cyRenewal = 0;
          let lyRenewal = 0;
          let totalRenewal = 0;      
          let points = [];

          result.data.map((value, key) => {
            if (
              moment(new Date()).format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              cmRenewal = cmRenewal + 1;
            }
            if (
              moment(new Date()).subtract(1, "month").format("YYYY-MM") ===
              moment(new Date(value?.created_at)).format("YYYY-MM")
            ) {
              lmRenewal = lmRenewal + 1;
            }
            if (
              moment(new Date()).format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              cyRenewal = cyRenewal + 1;
            }
            if (
              moment(new Date()).subtract(1, "year").format("YYYY") ===
              moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyRenewal = lyRenewal + 1;
            }
            totalRenewal = totalRenewal + 1;

            var today = new Date();
            var d;
            var l;

            for(var i = 12; i > 0; i -= 1) {
              d = new Date(today.getFullYear(), today.getMonth() - i, 1);
              l = new Date(today.getFullYear(), (today.getMonth() + 1) - i, 0);
              let firstDay = moment(d).format('YYYY-MM-DD');
              //let lastDate = moment(l).format('YYYY-MM-DD');
              //console.log('firstDay',firstDay)
              //console.log('lastDate',lastDate)
            }
          });
          setTotalRenewal(totalRenewal);
          setCurrentMonthRenewal(cmRenewal);
          setLastMonthRenewal(lmRenewal);
          setCurrentYearRenewal(cyRenewal);
          setLastYearRenewal(lyRenewal);          
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    loadRenewalList()

    // Renewal List Data
    const loadLeadTracking = async () => {
      try {
        const result = await postData("lead/tracking", {
          for : 'dasnboard'
        });
        if (result.status === 200) {
          setLeadTracking(result?.data);  
          setLeadTrackingCount(result?.count)   
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    loadLeadTracking()

    // Customer Profile Data
    const loadCustomerProfile = async () => {
      try {
        const result = await postData("customer/profile", {});
        if (result.status === 200) {
          let total = 0;
          let price = 0;
          let noOfEmployee = [];
          let numberOfSuccessionPlan = 0;
          let isTrial = 0;
          result?.data.map((value, key) => {
            total = total+1;            
            price = price + value?.price;
            noOfEmployee.push({
              name : value?.number_of_employees, 
              value: value?.number_of_employees
            });
            numberOfSuccessionPlan = numberOfSuccessionPlan + value?.number_of_succession_plan;
            if(value?.payment_status && value?.payment_status === 3) {
              isTrial = isTrial+1;
            }
          })
          setCustomerProfile({
            total : total,
            price : price,
            number_of_employees : noOfEmployee,
            number_of_succession_plan : numberOfSuccessionPlan,
            is_trial : isTrial
          });     
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    loadCustomerProfile()
  }, []);

  useEffect(()=>{
    // Commission list Data
    const loadCommissionList = async () => {
      try {
        const result = await postData("commission/list", {
          code: ["customer-admin"],
        });
        if (result.data) {
          let price = 0;
          let cmPrice = 0;
          let lmPrice = 0;
          let cyPrice = 0;
          let lyPrice = 0;
          let cmResult = [];
          result.data.map((value, key) => {
            let salesCommission = 0;
            let consultantCommission = 0;
            let appointmentSetterCommission = 0;
            let salesAdminCommission = 0;
            let salesPersion = '';
            let consultantPersion = '';
            let appointmentSetterPersion = '';
            let salesAdminPersion = '';
            let salesId = '';
            let consultantId = '';
            let appointmentSetterId = '';
            let salesAdminId = '';
            let sales = value?.staffs.length > 0 && value?.staffs.filter((item) => item.code === "sales-staff");
            if (sales && sales.length > 0) {
              salesId = sales[0].staff_id;
              let salesPersionArray = value?.staff_details.filter(
                (itm) => itm._id === salesId
              );              
              if (salesPersionArray && salesPersionArray.length > 0) {
                salesPersion = salesPersionArray[0].first_name+' '+salesPersionArray[0].last_name;
                salesCommission = (sales[0].commission)?sales[0].commission:salesPersionArray[0]?.commission[0]?.commission;
              }
            }

            let consultant = value?.staffs.length > 0 && value?.staffs.filter((item) => item.code === "consultant");
            if (consultant && consultant.length > 0) {
              consultantId = consultant[0].staff_id;
              let consultantPersionArray = value?.staff_details.filter(
                (itm) => itm._id === consultantId
              );
              if (consultantPersionArray && consultantPersionArray.length > 0) {
                consultantPersion = consultantPersionArray[0].first_name+' '+consultantPersionArray[0].last_name;
                consultantCommission = (consultant[0].commission)?consultant[0]?.commission:consultantPersionArray[0]?.commission[0]?.commission;
              }
            }

            let appointmentSetter = value?.staffs.length > 0 && value?.staffs.filter((item) => item.code === "appointment-setter");
            if (appointmentSetter && appointmentSetter.length > 0) {
              appointmentSetterId = appointmentSetter[0].staff_id;
              let appointmentSetterPersionArray = value?.staff_details.filter(
                (itm) => itm._id === appointmentSetterId
              );
              if (appointmentSetterPersionArray && appointmentSetterPersionArray.length > 0) {
                appointmentSetterPersion = appointmentSetterPersionArray[0].first_name+' '+appointmentSetterPersionArray[0].last_name;
                appointmentSetterCommission = (appointmentSetter[0].commission)?appointmentSetter[0]?.commission:
                  appointmentSetterPersionArray[0]?.commission[0]?.commission;
              }
            }

            let salesAdmin = value?.staffs.length>0 && value?.staffs.filter((item) => item.code === 'sales-admin');
            if(salesAdmin && salesAdmin.length>0){
              salesAdminId = salesAdmin[0].staff_id;
              let salesAdminPersionArray = value?.staff_details.filter((itm) => itm._id === salesAdminId);
              if(salesAdminPersionArray && salesAdminPersionArray.length>0) {
                salesAdminPersion = salesAdminPersionArray[0].first_name+' '+salesAdminPersionArray[0].last_name;
                salesAdminCommission = (salesAdmin[0].commission)?salesAdmin[0]?.commission:salesAdminPersionArray[0]?.commission[0]?.commission;
              }
            }

            let salesPrice = (Number(value?.original_price) * Number(salesCommission)) / 100;
            let consultantPrice = (Number(value?.original_price) * Number(consultantCommission)) / 100;
            let appointmentSetterPrice = (Number(value?.original_price) * Number(appointmentSetterCommission)) /
                100;
            let salesAdminPrice = (Number(value?.original_price) * Number(salesAdminCommission)) /
                100;

            if (moment(new Date()).format("YYYY-MM") === moment(new Date(value?.created_at)).format("YYYY-MM")) { 
              cmPrice =
                cmPrice +
                (Number(salesPrice) +
                  Number(consultantPrice) +
                  Number(appointmentSetterPrice) +
                  Number(salesAdminPrice));    

                let primaryId='';
                let primaryName='';
                let primaryPrice = 0;
                if(code && code === 'sales-staff'){
                  primaryId = salesId;
                  primaryName = (salesPersion)?salesPersion:'';
                  primaryPrice = (salesPersion)?salesPrice:0
                }else if(code && code === 'consultant'){
                  primaryId = consultantId;
                  primaryName = (consultantPersion)?consultantPersion:'';
                  primaryPrice = (consultantPersion)?consultantPrice:0
                }else if(code && code === 'appointment-setter'){
                  primaryId = appointmentSetterId;
                  primaryName = (appointmentSetterPersion)?appointmentSetterPersion:'';
                  primaryPrice = (appointmentSetterPersion)?appointmentSetterPrice:0
                }else if(code && code === 'sales-admin'){
                  primaryId = salesAdminId;
                  primaryName = (salesAdminPersion)?salesAdminPersion:'';
                  primaryPrice = (salesAdminPersion)?salesAdminPrice:0
                }
                cmResult.push({
                  _id : primaryId,
                  name : primaryName,
                  total : Number(value?.original_price),
                  price : Number(primaryPrice),
                  qty : 1
                })
            }
            
            price =
              price +
              (Number(salesPrice) +
                Number(consultantPrice) +
                Number(appointmentSetterPrice) +
                Number(salesAdminPrice));
            if ( moment(new Date()).subtract(1, "month").format("YYYY-MM") === moment(new Date(value?.created_at)).format("YYYY-MM")) {
              lmPrice =
                lmPrice +
                (Number(salesPrice) +
                  Number(consultantPrice) +
                  Number(appointmentSetterPrice)+ 
                  Number(salesAdminPrice));
            }
            if ( moment(new Date()).format("YYYY") === moment(new Date(value?.created_at)).format("YYYY")) {
              cyPrice =
                cyPrice +
                (Number(salesPrice) +
                  Number(consultantPrice) +
                  Number(appointmentSetterPrice)+ 
                  Number(salesAdminPrice));
            }
            if ( moment(new Date()).subtract(1, "year").format("YYYY") === moment(new Date(value?.created_at)).format("YYYY")
            ) {
              lyPrice =
                cyPrice +
                (Number(salesPrice) +
                  Number(consultantPrice) +
                  Number(appointmentSetterPrice)+ 
                  Number(salesAdminPrice));
            }
          });
          setCommission(price);
          setCurrentMonthCommission(cmPrice);
          setLastMonthCommission(lmPrice);
          setCurrentYearCommission(cyPrice);
          setLastYearCommission(lyPrice);
          setResults(cmResult);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadCommissionList();
  },[code || ''])

  const groupBy = (array, key) => {
    return array.reduce(function(acc, obj) {
      const keyValue = obj[key];
      if (!acc[keyValue]) {
          acc[keyValue] = 1;
      } else {
          acc[keyValue]++;
      }
      return acc;
    }, {});
  }

 // console.log('salespersions',salespersions)

  const barData = {
    labels: names,
    datasets: [
      {
        label: "Renewing",
        borderRadius: 0,
        data: points,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(20, 184, 166, 1)",
        //barThickness: 100,
        fill: true,
      },
      {
        label: "Canceling",
        borderRadius: 0,
        data: renewing,
        backgroundColor: "rgba(255,0,127,0.2)",
        borderColor: "rgba(244,63,93,1)",
        //barThickness: 100,
        fill: true,
      },
    ],
  };

  // const barOptions = {
  //   plugins: {
  //     legend: {
  //       display:false,
  //       position: "top",
  //       align: "start",
  //       maxWidth: 500,
  //       labels: {
  //         boxWidth: 20,
  //         usePointStyle: true,
  //         pointStyle: "circle",
  //         padding: 20,
  //         font: {
  //           size: 12, weight: 600, lineHeight: 100,
  //         }
  //       },
  //       title: {
  //         display: false,
  //       },
  //     },

  //     tooltip: {
  //         //intersect: false,
  //         displayColors: false,
  //         filter: function (context) {
  //           if (context.formattedValue > 0) {
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         },
  //         callbacks: {
  //             label: function(context) {
  //                 if(context.formattedValue > 0){
  //                   return [`position: ${positions[context.dataIndex]}`, `Point: ${context.formattedValue}`];
  //                 }else {
  //                   return;
  //                   //return `Eliminated because of questionable loyalty, desire to advance, or short-term retirement window.`
  //                 }
  //             }
  //         }
  //     }
  //   },
  //   scales: {
  //     x: {
  //       ticks: {
  //         callback: function(value, index, values) {
  //           return this.getLabelForValue(value).split(';')[0];
  //         }
  //       },
  //       grid: {
  //         display: false,
  //       },
  //     },
  //     // x2: {
  //     //   ticks: {
  //     //     font: {
  //     //       size: 13,
  //     //       weight: '600',
  //     //   },
  //     //     callback: function(value, index, values) {
  //     //       return positions[index];
  //     //     }
  //     //   },
  //     //   grid: {
  //     //     display: false,
  //     //   },
  //     // },
  //     // x3: {
  //     //   ticks: {
  //     //     color: 'red', beginAtZero: true,
  //     //     callback: function(value, index, values) {
  //     //       if(points[index] > 0){
  //     //       }else{
  //     //         return ['Eliminated because of questionable', 'loyalty, desire to advance,', 'or short-term retirement window.']
  //     //       }
  //     //     }
  //     //   },
  //     //   grid: {
  //     //     display: false,
  //     //   },
  //     // },
  //   }
  // }

  useEffect(() => {
    var monthName = new Array(
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    );
    var d = new Date();
    let data = [];
    d.setDate(1);
    for (let i = 0; i <= 11; i++) {
      data.push(monthName[d.getMonth()] + " " + d.getFullYear());
      d.setMonth(d.getMonth() - 1);
    }
    setNames(data.reverse());
  }, []);

  useEffect(()=>{
    if(customerProfile?.number_of_employees && customerProfile?.number_of_employees.length>0){      
      let _groupBy = groupBy(customerProfile?.number_of_employees,'value');
      let array = Object.entries(_groupBy).map(([key, value]) => ({ key, value }));
      setNonInvitedEmplyee(array);
    }
  },[customerProfile])   

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t('dashboard')}</div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          {/* <Button
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={"Create Demo Account"}
            buttonHasLink={true}
            buttonLink={"/new-account"}
          /> */}
        </div>
      </div>
      <div className="grid grid-cols-12 mb-5">
        {role && (role === "master-admin" || role === "basic-administrator") && (
          <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
            <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
              <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                {t('net_revenue')}
                <Link
                  to="/reports?type=net-revenue&sub_type=total"
                  className="text-xs capitalize underline font-semibold text-teal-500"
                >
                  {t('report')}
                </Link>
              </div>
              <Link to="/subscriptions">
                <div className="divide-y space-y-2">
                  <div className="flex items-center justify-between ">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-green-500">
                      <i className="fal fa-money-bill fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('total_net_revenue')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        $
                        {(
                          Number(earning) -
                          (Number(expenses) + Number(commission))
                        ).toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-yellow-500">
                      <i className="fal fa-money-bill fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_year_net_revenue_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        $
                        {(
                          Number(currentYearEarning) -
                          (Number(currentYearExpenses) +
                            Number(currentYearCommission))
                        ).toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-blue-500">
                      <i className="fal fa-money-bill fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_year_net_revenue_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        $
                        {(
                          Number(lastYearEarning) -
                          (Number(lastYearExpenses) +
                            Number(lastYearCommission))
                        ).toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-purple-500">
                      <i className="fal fa-money-bill fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_month_net_revenue')}{" "}
                        {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        $
                        {(
                          Number(currentMonthEarning) -
                          (Number(currentMonthExpenses) +
                            Number(currentMonthCommission))
                        ).toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-red-500">
                      <i className="fal fa-money-bill fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_month_net_revenue')}{" "}
                        {"(" +
                          moment(new Date())
                            .subtract(1, "month")
                            .format("MMM YYYY") +
                          ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        $
                        {(
                          Number(lastMonthEarning) -
                          (Number(lastMonthExpenses) +
                            Number(lastMonthCommission))
                        ).toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
        {role && (role === "master-admin" || role === "basic-administrator") && (
          <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
            <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
              <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                {t('revenue')}
                <Link
                  to="/reports?type=revenue&sub_type=total"
                  className="text-xs capitalize underline font-semibold text-teal-500"
                >
                  {t('report')}
                </Link>
              </div>
              <Link to="/subscriptions">
                <div className="divide-y space-y-2">
                  <div className="flex items-center justify-between ">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-green-500">
                      <i className="fal fa-money-bill-trend-up fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('total_revenue')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${earning.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-yellow-500">
                      <i className="fal fa-money-bill-trend-up fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_year_revenue_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${currentYearEarning.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-blue-500">
                      <i className="fal fa-money-bill-trend-up fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_year_revenue_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${lastYearEarning.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-purple-500">
                      <i className="fal fa-money-bill-trend-up fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_month_revenue')}{" "}
                        {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${currentMonthEarning.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-red-500">
                      <i className="fal fa-money-bill-trend-up fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_month_revenue')}{" "}
                        {"(" +
                          moment(new Date())
                            .subtract(1, "month")
                            .format("MMM YYYY") +
                          ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${lastMonthEarning.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
        {role && (role === "master-admin" || role === "basic-administrator") && (
          <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
            <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
              <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                {t('operating_expenses')}
                <Button
                  buttonType={"button"}
                  buttonClasses={
                    "!absolute !top-0 !right-2 !p-0 !h-6 !w-6 !bg-transparent !text-teal-500"
                  }
                  buttonIcon={"fa-solid fa-plus"}
                  buttonIconPosition={"left"}
                  buttonLabel={""}
                  buttonHasLink={false}
                  functions={() => setEnabledExpensesPopup(true)}
                  //buttonLink={"/new-account"}
                />
                <Link
                  to="/reports?type=expenses&sub_type=total"
                  className="text-xs capitalize underline font-semibold text-teal-500"
                >
                  {t('report')}
                </Link>
              </div>
              <Link to="/subscriptions">
                <div className="divide-y space-y-2">
                  <div className="flex items-center justify-between ">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-green-500">
                      <i className="fal fa-circle-dollar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('total_expenses')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${expenses.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-yellow-500">
                      <i className="fal fa-circle-dollar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_year_expenses_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${currentYearExpenses.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-blue-500">
                      <i className="fal fa-circle-dollar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_year_expenses_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${lastYearExpenses.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-purple-500">
                      <i className="fal fa-circle-dollar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_month_expenses')}{" "}
                        {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${currentMonthExpenses.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 mr-2 flex items-center justify-center text-md text-red-500">
                      <i className="fal fa-circle-dollar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-1">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_month_expenses')}{" "}
                        {"(" +
                          moment(new Date())
                            .subtract(1, "month")
                            .format("MMM YYYY") +
                          ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">
                        ${lastMonthExpenses.toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
        {role && (role === "master-admin" || role === "basic-administrator" || role === "sales-admin" ||role === "sales-staff") && (
            <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
              <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
                <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                  {t('sales')}
                  <Link
                    to="/reports?type=sales&sub_type=total"
                    className="text-xs capitalize underline font-semibold text-teal-500"
                  >
                    {t('report')}
                  </Link>
                </div>
                <Link to="/dashboard-statistics">
                  <div className="divide-y space-y-2">
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-green-500">
                        <i className="fal fa-handshake fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('total_sales')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${Number(totalSale).toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-yellow-500">
                        <i className="fal fa-handshake fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_year_sales_total')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${Number(currentYearSale).toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-blue-500">
                        <i className="fal fa-handshake fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_year_sales_total')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${Number(lastYearSale).toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-purple-500">
                        <i className="fal fa-handshake fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_month_sales')}{" "}
                          {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${Number(currentMonthSale).toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-red-500">
                        <i className="fal fa-handshake fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_month_sales')}{" "}
                          {"(" +
                            moment(new Date())
                              .subtract(1, "month")
                              .format("MMM YYYY") +
                            ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${Number(lastMonthSale).toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
        {role && (role === "master-admin" || role === "basic-administrator" || role === "sales-admin" || role === "sales-staff") && (
            <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
              <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
                <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                  {t('renewals')}
                  <Link
                    to="/reports?type=renewals&sub_type=total"
                    className="text-xs capitalize underline font-semibold text-teal-500"
                  >
                    {t('report')}
                  </Link>
                </div>
                <Link to="/subscriptions">
                  <div className="divide-y space-y-2 ">
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-green-500">
                        <i className="fal fa-history fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('total_number_of_renewals')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">{totalRenewal}</h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-yellow-500">
                        <i className="fal fa-history fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_year_renewals')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">{currentYearRenewal}</h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-blue-500">
                        <i className="fal fa-history fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_year_renewals')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">{lastYearRenewal}</h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-purple-500">
                        <i className="fal fa-history fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_month_renewals')}{" "}
                          {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">{currentMonthRenewal}</h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-red-500">
                        <i className="fal fa-history fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_month_renewals')}{" "}
                          {"(" +
                            moment(new Date())
                              .subtract(1, "month")
                              .format("MMM YYYY") +
                            ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">{lastMonthRenewal}</h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
        {role && (role === "master-admin" || role === "basic-administrator" || role === "sales-admin" || role === "sales-staff") && (
            <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
              <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
                <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                  {t('commissions')}
                  <Link
                    to="/reports?type=commissions&sub_type=total"
                    className="text-xs capitalize underline font-semibold text-teal-500"
                  >
                    {t('report')}
                  </Link>
                </div>
                <Link to="/dashboard-statistics">
                  <div className="divide-y space-y-2">
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-green-500">
                        <i className="fal fa-money-bill-transfer fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('total_commissions')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${commission.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-yellow-500">
                        <i className="fal fa-money-bill-transfer fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_year_commissions_total')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${currentYearCommission.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-blue-500">
                        <i className="fal fa-money-bill-transfer fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_year_commissions_total')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${lastYearCommission.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-purple-500">
                        <i className="fal fa-money-bill-transfer fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_month_commissions')}{" "}
                          {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${currentMonthCommission.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-red-500">
                        <i className="fal fa-money-bill-transfer fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_month_commissions')}{" "}
                          {"(" +
                            moment(new Date())
                              .subtract(1, "month")
                              .format("MMM YYYY") +
                            ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          ${lastMonthCommission.toFixed(2)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
        {role &&
          (role === "master-admin" || role === "basic-administrator" || 
            role === "sales-admin" ||
            role === "sales-staff") && (
            <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
              <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
                <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                  {t('succession_plans')}
                  <Link
                    to="/reports?type=succession-plan&sub_type=total"
                    className="text-xs capitalize underline font-semibold text-teal-500"
                  >
                    {t('report')}
                  </Link>
                </div>
                <Link to="/companies">
                  <div className="divide-y space-y-2">
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-green-500">
                        <i className="fal fa-list-ol fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('total_succession_plans_created')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {totalSuccessionPlan}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-yellow-500">
                        <i className="fal fa-list-ol fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_year_plan_creation_total')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {currentYearSuccessionPlan}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-blue-500">
                        <i className="fal fa-list-ol fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_year_plan_creation_total')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {lastYearSuccessionPlan}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-purple-500">
                        <i className="fal fa-list-ol fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_month_plan_creation')}{" "}
                          {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {currentMonthSuccessionPlan}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-red-500">
                        <i className="fal fa-list-ol fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_month_plan_creation')}{" "}
                          {"(" +
                            moment(new Date())
                              .subtract(1, "month")
                              .format("MMM YYYY") +
                            ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {lastMonthSuccessionPlan}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
        {/* {role && (role === "master-admin" ||
            role === "sales-admin" ||
            role === "sales-staff") && (
            <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
              <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
                <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                  {t('demos')}
                </div>
                <Link to="/enquiries">
                  <div className="divide-y space-y-2 ">
                    <div className="flex items-center justify-between pt-2 ">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-green-600">
                        <i className="fal fa-wallet fa-2x fa-fw "></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('total_number_of_demos_requested')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {totalDemoRequest}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-yellow-600">
                        <i className="fal fa-wallet fa-2x fa-fw "></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_year_demo_total')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {currentYearDemoRequest}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-blue-600">
                        <i className="fal fa-wallet fa-2x fa-fw "></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_year_demo_total')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {lastYearDemoRequest}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-purple-600">
                        <i className="fal fa-wallet fa-2x fa-fw "></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_month_demo_total')}{" "}
                          {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {currentMonthDemoRequest}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-red-600">
                        <i className="fal fa-wallet fa-2x fa-fw "></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_month_demo_total')}{" "}
                          {"(" +
                            moment(new Date())
                              .subtract(1, "month")
                              .format("MMM YYYY") +
                            ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {lastMonthDemoRequest}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
        {role && role === "master-admin" && (
          <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
            <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
              <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                {t('appointments')}
                
              </div>
              <Link to="/subscriptions">
                <div className="divide-y space-y-2 ">
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-green-600">
                      <i className="fal fa-calendar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('total_appointments')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-yellow-600">
                      <i className="fal fa-calendar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_year_appointment_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-blue-600">
                      <i className="fal fa-calendar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_year_appointment_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-purple-600">
                      <i className="fal fa-calendar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_month_appointment_total')}{" "}
                        {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-red-600">
                      <i className="fal fa-calendar fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_month_appointment_total')}{" "}
                        {"(" +
                          moment(new Date())
                            .subtract(1, "month")
                            .format("MMM YYYY") +
                          ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
        {role && role === "master-admin" && (
          <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
            <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
              <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                {t('referrals')}
                
              </div>
              <Link to="/subscriptions">
                <div className="divide-y space-y-2 ">
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-green-500">
                      <i className="fal fa-link fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('total_referrals')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-yellow-500">
                      <i className="fal fa-link fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_year_referral_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-blue-500">
                      <i className="fal fa-link fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_year_referral_total')}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-purple-500">
                      <i className="fal fa-link fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('current_month_referral_total')}{" "}
                        {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-2">
                    <div className="w-10 h-10 flex items-center justify-center text-md text-red-500">
                      <i className="fal fa-link fa-2x fa-fw"></i>
                    </div>
                    <div className="flex flex-col text-right gap-2">
                      <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                        {t('previous_month_referral_total')}{" "}
                        {"(" +
                          moment(new Date())
                            .subtract(1, "month")
                            .format("MMM YYYY") +
                          ")"}
                      </h5>
                      <h3 className="text-slate-800 text-xl font-bold">0</h3>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )} */}
        {role &&
          (role === "master-admin" || role === "basic-administrator" ||
            role === "sales-admin" ||
            role === "sales-staff") && (
            <div className="relative col-span-12 sm:col-span-6 lg:col-span-4">
              <div className="relative bg-white shadow rounded border border-slate-200 border-solid p-4 h-full">
                <div className="2xl:text-2xl text-xl text-center text-slate-800 uppercase border-b mb-4 pb-4 font-bold relative flex flex-col items-center justify-center">
                  {t('cancellations')}
                  <Link
                    to="/reports?type=cancellation&sub_type=total"
                    className="text-xs capitalize underline font-semibold text-teal-500"
                  >
                    {t('report')}
                  </Link>
                </div>
                <Link to="/subscriptions">
                  <div className="divide-y space-y-2 ">
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-green-600">
                        <i className="fal fa-ban fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('total_number_of_cancellations')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {totalCancellations}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-yellow-600">
                        <i className="fal fa-ban fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_year_cancellations')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {currentYearCancellations}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-blue-600">
                        <i className="fal fa-ban fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_year_cancellations')}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {lastYearCancellations}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-purple-600">
                        <i className="fal fa-ban fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('current_month_cancellations')}{" "}
                          {"(" + moment(new Date()).format("MMM YYYY") + ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {currentMonthCancellations}
                        </h3>
                      </div>
                    </div>
                    <div className="flex items-center justify-between pt-2">
                      <div className="w-10 h-10 flex items-center justify-center text-md text-red-600">
                        <i className="fal fa-ban fa-2x fa-fw"></i>
                      </div>
                      <div className="flex flex-col text-right gap-2">
                        <h5 className="text-slate-500 text-[11px] uppercase leading-none">
                          {t('previous_month_cancellations')}{" "}
                          {"(" +
                            moment(new Date())
                              .subtract(1, "month")
                              .format("MMM YYYY") +
                            ")"}
                        </h5>
                        <h3 className="text-slate-800 text-xl font-bold">
                          {lastMonthCancellations}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
      </div>
      <div className="relative bg-white rounded shadow divide-y divide-slate-200 mb-3">
        <div className="py-4 px-4 flex flex-col">
          <div className="flex justify-between items-center pb-3 mb-3 border-b border-slate-200">
            <div className="text-base text-slate-800 font-bold leading-none">
              {t('sales_report')}
            </div>
            <div className="flex space-x-2 items-center">
              <div className="relative">
                <Select 
                  xPlacement={'right'} 
                  dropdownButtonClass={'!bg-white'} 
                  transitionClass={'!w-auto'}
                  selectedValue={code} 
                  dropdownData={[
                    { _id: '', label: t('select') },
                    ...staffArray.map((val) => (
                      { _id: val?.code, label: val?.label, value: val?.code }
                    ))
                  ]}
                  getSelectedValue={(e) => setCode(e.value)}
                />
              </div>
              <Link
                to="/reports?type=avg-sales&sub_type=total"
                className="text-xs capitalize underline font-semibold text-teal-500 justify-end"
              >
                {t('report')}
              </Link>
            </div>
          </div>
          <div className="overflow-auto scroll-smooth scrollbar">
            <div className="flex w-full">
              <div className="grow min-w-[120px] w-1/4 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('salesperson')}
                </div>
                <div className="text-[10px] font-bold text-slate-600 leading-none">
                  {t('name')}
                </div>
              </div>
              <div className="min-w-[120px] w-1/4 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('no_of_sale')}
                </div>
                <div className="text-[10px] font-bold text-slate-600 leading-none">
                  {t('no_of_sales_in_the_current_month')}
                </div>
              </div>
              <div className="min-w-[120px] w-1/4 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('total_sale_amount')}
                </div>
                <div className="text-[10px] font-bold text-slate-600 leading-none">
                  {t('total_amount_of_sales_in_the_current_month')}
                </div>
              </div>
              <div className="min-w-[120px] w-1/4 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('total_commission_amount')}
                </div>
                <div className="text-[10px] font-bold text-slate-600 leading-none">
                  {t('total_commission_for_the_current_month_sales')}
                </div>
              </div>
            </div>
            <div className="relative">
              {
                (salesReport && salesReport.length > 0)?
                salesReport.map((item,index) => {
                  return (
                    <div className="flex items-center w-full" key={index}>
                      <div className="grow min-w-[120px] w-1/4 py-3 px-2 border-b border-slate-200">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                          {item?.name}
                        </div>
                      </div>
                      <div className="min-w-[120px] w-1/4 py-3 px-2 border-b border-slate-200">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                        {(item?.qty)?item?.qty:0}
                        </div>
                      </div>
                      <div className="min-w-[120px] w-1/4 py-3 px-2 border-b border-slate-200">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                          ${item?.total}
                        </div>
                      </div>
                      <div className="min-w-[120px] w-1/4 py-3 px-2 border-b border-slate-200">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                          ${item?.price}
                        </div>
                      </div>
                    </div>
                  )
                }):
                <div className="flex items-center w-full">
                  <div className="grow w-full py-10">
                    <div className="text-sm font-semibold text-rose-600 leading-none text-center">
                      {t('no_data_found')}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
         
        </div>
      </div>

      <div className="relative bg-white rounded shadow divide-y divide-slate-200 mb-3">
        <div className="py-4 px-4 flex flex-col">
          <div className="flex justify-between items-center pb-3 mb-3 border-b border-slate-200">
            <div className="text-base text-slate-800 font-bold leading-none">
              {t('lead_tracking')}              
            </div>
            <Link
              to="/reports?type=lead-tracking&sub_type=total"
              className="text-xs capitalize underline font-semibold text-teal-500 justify-end"
            >
              {t('report')}
            </Link>
          </div>
          <div className="overflow-auto scroll-smooth scrollbar">
            <div className="flex w-full">
              <div className="grow min-w-[240px] w-2/6 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  &nbsp;
                </div>
              </div>
              <div className="min-w-[120px] w-1/6 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('leads')}
                </div>
              </div>
              <div className="min-w-[120px] w-1/6 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('sales')}($$$)
                </div>
              </div>
              <div className="min-w-[120px] w-1/6 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('conversion_rate')}
                </div>
              </div>
              <div className="min-w-[120px] w-1/6 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('cost_per_lead')}
                </div>
              </div>
              <div className="min-w-[120px] w-1/6 px-2 py-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('cost_per_sale')}
                </div>
              </div>
            </div>
            <div className="relative">
              {
                (source && source.length > 0)?
                source.map((item,index) => {
                  let leads = leadTracking.length>0 && leadTracking.filter((itm,idx) => itm?._id === item?._id);
                  let conversationRate = 0;
                  let costPerLead = 0;
                  let costPerSale = 0;
                  if(leads && leads.length>0) {
                    conversationRate =  (Number(leads[0]?.total*100)/leadTrackingCount).toFixed(2);
                    costPerLead = Number(leads[0]?.price/leads[0]?.total).toFixed(2);
                    costPerSale = Number(leads[0]?.price/leadTrackingCount).toFixed(2);
                  }               
                  return (
                    <div className="flex w-full" key={index}>
                      <div className="grow min-w-[240px] w-2/6 py-3 border-b border-slate-200 px-2">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                          {item?.label}
                        </div>
                      </div>
                      <div className="min-w-[120px] w-1/6 py-3 border-b border-slate-200 px-2">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                        {(leads && leads.length>0)?leads[0]?.total:'--'}
                        </div>
                      </div>
                      <div className="min-w-[120px] w-1/6 py-3 border-b border-slate-200 px-2">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                        {(leads && leads.length>0)?'$'+Number(leads[0]?.price).toFixed(2):'--'}
                        </div>
                      </div>
                      <div className="min-w-[120px] w-1/6 py-3 border-b border-slate-200 px-2">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                          {(conversationRate && conversationRate>0)?conversationRate+'%':'--'}
                        </div>
                      </div>
                      <div className="min-w-[120px] w-1/6 py-3 border-b border-slate-200 px-2">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                        {(costPerLead && costPerLead>0)?costPerLead:'--'}
                        </div>
                      </div>
                      <div className="min-w-[120px] w-1/6 py-3 border-b border-slate-200 px-2">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                        {(costPerSale && costPerSale>0)?costPerSale:'--'}
                        </div>
                      </div>
                    </div>
                  )
                }).slice(0, 5):
                <div className="flex items-center w-full">
                  <div className="grow w-full py-10">
                    <div className="text-sm font-semibold text-rose-600 leading-none text-center">
                      {t('no_data_found')}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
         
        </div>
      </div>

      <div className="relative bg-white rounded shadow divide-y divide-slate-200 mb-3">
        <div className="py-4 px-4 flex flex-col">
          <div className="flex justify-between items-center pb-3 mb-3 border-b border-slate-200">
            <div className="text-base text-slate-800 font-bold leading-none">
              {t('customer_profile')}
            </div>
          </div>
          <div className="overflow-auto scroll-smooth scrollbar">
            <div className="flex w-full">
              <div className="grow w-2/6 min-w-[240px] py-2 px-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('current_active_customers')}
                </div>
              </div>
              <div className="w-1/6 min-w-[120px] py-2 px-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('average_sale_amount')}
                </div>
              </div>
              <div className="w-1/6 min-w-[120px] py-2 px-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('average_company_size')}
                </div>
              </div>
              <div className="w-1/6 min-w-[120px] py-2 px-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('average_plans_created_per_company')}
                </div>
              </div>
              <div className="w-1/6 min-w-[120px] py-2 px-2 border-b border-slate-200">
                <div className="text-[14px] font-bold text-slate-600 leading-none">
                  {t('current_trial_accounts')}
                </div>
              </div>
            </div>
            <div className="relative">
              {
                (Object.keys(customerProfile).length > 0)?
                  <div className="flex w-full">
                    <div className="grow w-2/6 min-w-[240px] px-2 py-2 border-b border-slate-200">
                      <div className="text-sm font-semibold text-slate-600 leading-none">
                        {customerProfile?.total}
                      </div>
                    </div>
                    <div className="w-1/6 min-w-[120px] px-2 py-2 border-b border-slate-200">
                      <div className="text-sm font-semibold text-slate-600 leading-none">
                      ${Number(customerProfile?.price/customerProfile?.total).toFixed(2)}
                      </div>
                    </div>
                    <div className="w-1/6 min-w-[120px] px-2 py-2 border-b border-slate-200">             
                      {
                        (nonInvitedEmployee && nonInvitedEmployee.length > 0) && 
                        nonInvitedEmployee.map((item) => {
                          return (
                            <div className="text-sm font-semibold text-slate-600 leading-none flex justify-between mb-2">
                              <span className="font-semibol">{item?.key}:</span> {item?.value}
                            </div>
                          )
                        })
                      }                    
                    </div>
                    <div className="w-1/6 min-w-[120px] px-2 py-2 border-b border-slate-200">
                      <div className="text-sm font-semibold text-slate-600 leading-none">
                        {Number(customerProfile?.number_of_succession_plan/customerProfile?.total).toFixed(2)}
                      </div>
                    </div>
                    <div className="w-1/6 min-w-[120px] px-2 py-2 border-b border-slate-200">
                      <div className="text-sm font-semibold text-slate-600 leading-none">
                        <div className="flex items-center">
                          {customerProfile?.is_trial}
                        {/* {
                          (item?.payment_status && item?.payment_status === 3)?
                          <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 bg-green-100 text-green-600"}>{t('yes')}</div>:
                          <div className={"text-[10px] font-medium leading-tight rounded py-1 px-2 bg-rose-100 text-rose-600"}>{t('no')}</div>
                        } */}
                        </div>
                      </div>
                    </div>
                  </div>
                :
                <div className="flex items-center w-full">
                  <div className="grow w-full py-10">
                    <div className="text-sm font-semibold text-rose-600 leading-none text-center">
                      {t('no_data_found')}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
         
        </div>
      </div>

      <div className="relative bg-white rounded shadow divide-y divide-slate-200 mb-3">
        <div className="py-4 px-4 flex flex-col">
          <div className="flex justify-between items-center pb-3 mb-3 border-b border-slate-200">
            <div className="text-base text-slate-800 font-bold leading-none">
              {t('commissions')}
            </div>
            <Link
              to="/reports?type=avg-commission&sub_type=total"
              className="text-xs capitalize underline font-semibold text-teal-500 justify-end"
            >
              {t('report')}
            </Link>
          </div>
          <div className="overflow-auto scroll-smooth scrollbar">
            <div className="flex w-full">
              <div className="grow w-3/6 min-w-[240px] py-2 px-2 border-b border-slate-200">
                <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">
                  {t('name')}
                </div>
              </div>
              <div className="w-1/6 min-w-[120px] py-2 px-2 border-b border-slate-200">
                <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">
                  {t('no_of_sale')}
                </div>
              </div>
              <div className="w-1/6 min-w-[180px] py-2 px-2 border-b border-slate-200">
                <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">
                {t('percentage')}
                </div>
              </div>
              <div className="w-1/6 min-w-[120px] py-2 px-2 border-b border-slate-200">
                <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">
                  {t('total_commission')}
                </div>
              </div>
            </div>
            <div className="relative">
              {
                (salespersions && salespersions.length > 0) &&
                salespersions.sort((a, b) => b.count_staff - a.count_staff).map((item,index) => {
                  let salesCommission = 0;
                  let consultantCommission = 0;
                  let appointmentSetterCommission = 0;
                  let salesAdminCommission = 0;
                  let salesPrice = 0;
                  let consultantPrice = 0; 
                  let appointmentSetterPrice = 0;
                  let salesAdminPrice = 0;
                  if(item?.commission && Object.keys(item?.commission).length>0){
                    let sales = item?.commission.filter((itm) => itm.code === 'sales-staff');
                    if(sales && sales.length>0){
                      salesCommission = (sales[0].commission)?sales[0].commission:0;
                      salesPrice = (Number(item?.original_price)*Number(salesCommission))/100;
                    } 
                    let consultant = item?.commission.filter((item) => item.code === 'consultant');
                    if(consultant && consultant.length>0){
                      consultantCommission = (consultant[0].commission)?consultant[0].commission:0;
                      consultantPrice = (Number(item?.original_price)*Number(consultantCommission))/100;
                    }
                    let appointmentSetter = item?.commission.filter((item) => item.code === 'appointment-setter');
                    if(appointmentSetter && appointmentSetter.length>0){
                      appointmentSetterCommission = (appointmentSetter[0].commission)?appointmentSetter[0].commission:0;
                      appointmentSetterPrice = (Number(item?.original_price)*Number(appointmentSetterCommission))/100; 
                    }
                    let salesAdmin = item?.commission.filter((item) => item.code === 'sales-admin');
                    if(salesAdmin && salesAdmin.length>0){
                      salesAdminCommission = (salesAdmin[0].commission)?salesAdmin[0].commission:0;
                      salesAdminPrice = (Number(item?.original_price)*Number(salesAdminCommission))/100; 
                    }
                  } 
                  let commission = 0;
                  if(item?.staff_details && item?.staff_details.length>0) {
                    for (let index = 0; index < item?.staff_details.length; index++) {
                      let element = item?.staff_details[index];
                      let price = item?.staff_details[index]?.original_price;
                      let salesComm = 0;
                      let consultantComm = 0;
                      let appointmentSetterComm = 0;
                      let salesAdminComm = 0;
                      if(element?.staffs && Object.keys(element?.staffs).length>0){
                        let sales1 = element?.staffs.filter((itm) => itm.code === 'sales-staff');
                        if(sales1 && sales1.length>0){
                          salesComm = (sales1[0].commission)?sales1[0].commission:0;
                          commission = commission + (Number(price)*Number(salesComm))/100;
                        } 
                        let consultant1 = element?.staffs.filter((item) => item.code === 'consultant');
                        if(consultant1 && consultant1.length>0){
                          consultantComm = (consultant1[0].commission)?consultant1[0].commission:0;
                          commission = commission + (Number(price)*Number(consultantComm))/100;
                        }
                        let appointmentSetter1 = element?.staffs.filter((item) => item.code === 'appointment-setter');
                        if(appointmentSetter1 && appointmentSetter1.length>0){
                          appointmentSetterComm = (appointmentSetter1[0].commission)?appointmentSetter1[0].commission:0;
                          commission = commission + (Number(price)*Number(appointmentSetterComm))/100; 
                        }
                        let salesAdmin1 = element?.staffs.filter((item) => item.code === 'sales-admin');
                        if(salesAdmin1 && salesAdmin1.length>0){
                          salesAdminComm = (salesAdmin1[0].commission)?salesAdmin1[0].commission:0;
                          commission = commission + (Number(price)*Number(salesAdminComm))/100; 
                        }
                      }
                    }
                  }
                  return (
                    <div className="flex w-full" key={index}>
                      <div className="grow w-3/6 min-w-[240px] px-2 py-2 border-b border-slate-200">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                          {item?.first_name} {item?.last_name}
                        </div>
                      </div>
                      <div className="w-1/6 min-w-[120px] px-2 py-2 border-b border-slate-200">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                        {item?.count_staff}
                        </div>
                      </div>
                      <div className="w-1/6 min-w-[180px] px-2 py-2 border-b border-slate-200">
                        <div className="text-sm font-semibold text-slate-600 leading-none flex justify-between mb-2">
                          <span className="font-semibol">Sales staff:</span> {salesCommission+'%'}
                        </div>
                        <div className="text-sm font-semibold text-slate-600 leading-none flex justify-between mb-2">
                          <span className="font-semibol">Consultant:</span> {consultantCommission+'%'}
                        </div>
                        <div className="text-sm font-semibold text-slate-600 leading-none flex justify-between mb-2">
                          <span className="font-semibol">Appointment Setter:</span> {appointmentSetterCommission+'%'}
                        </div>
                        <div className="text-sm font-semibold text-slate-600 leading-none flex justify-between mb-2">
                          <span className="font-semibol">Sales Admin:</span> {salesAdminCommission+'%'}
                        </div>
                      </div>
                      <div className="w-1/6 min-w-[120px] px-2 py-2 border-b border-slate-200">
                        <div className="text-sm font-semibold text-slate-600 leading-none">
                          {'$'+Number(commission).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
         
        </div>
      </div>      

      {/* <div className="relative flex justify-between items-center mb-4">
        <div className="text-2xl text-slate-800 font-bold">{t('retention_rate')}</div>
      </div>
      <div className="relative bg-white shadow rounded mb-4">
        <div className="py-10 px-3">
          <div className="relative w-full max-w-5xl" id="div2PDF">            
            <Line data={barData} />
          </div>
        </div>
      </div> */}
      {enabledExpensesPopup && (
        <ExpensesPopup
          isOpen={enabledExpensesPopup}
          setIsOpen={(val) => setEnabledExpensesPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledExpensesPopup(false);
          }}
        />
      )}
    </>
  );
};

export default DashboardStatistics;
