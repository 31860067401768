import React from 'react';
const EvaluationTools = ({data}) => {
  return (
    <>
    <div className="relative border border-slate-900 py-5 xl:py-10 px-4 xl:px-8 text-center">
      <div className="mb-8 lg:mb-12 xl:mb-16">
        {data?.title && 
         <div className="text-2xl lg:text-3xl xl:text-4xl font-bold text-slate-950 capitalize">{data?.title}</div>
        }
        {data?.image &&
          <div className="w-full h-8 xl:h-10 overflow-hidden">
            <img src={data?.image} alt={data?.title} className="object-contain w-full h-full"/>
          </div>
        }
        {data?.comingsoon &&
          <div className="text-slate-950 text-sm capitalize">{data?.comingsoon}</div>
        }
        
      </div>
       <div className="text-sm lg:text-base text-slate-900 space-y-5 max-w-[256px] m-auto font-medium" dangerouslySetInnerHTML={{__html:data?.description}}></div>
    </div>
   
    </>
    
  );
};

export default EvaluationTools;