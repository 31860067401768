import React,{useEffect,useRef, useState} from 'react';
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";
import Button from '../form/Button';
import { Link } from 'react-router-dom';
import DeletePopup from '../DeletePopup';

import { useTranslation } from "react-i18next";

const CardAction = ({
  xPlacement,  
  getActionValue = () => { },  
  data,
  dropdownData = [],
  ...prop
}) => {
  const { i18n,t } = useTranslation();
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [enabledDeletePopup, setenabledDeletePopup] = useState(false);
  const [currentId, setCurrentId] = useState('');

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const updateFunction = async (button_type, row_id, current_status) => {
    if (button_type === 'delete') {
      setenabledDeletePopup(true);
      setCurrentId(row_id)
    }else{
      getActionValue({
        button_type: button_type,
        row_id: row_id,
        current_status: current_status
      })
    }    
    //  else if (button_type === 'others') {
    //   getActionValue({
    //     button_type: button_type,
    //     row_id: row_id,
    //     current_status: current_status
    //   })
    // } else {
    //   getActionValue({
    //     button_type: button_type,
    //     row_id: row_id,
    //     current_status: current_status
    //   })
    // }
  }

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div className="relative w-full h-10 flex items-center justify-center focus:ring-0 transition-all duration-200">
                <div className="text-base text-slate-400 w-6 h-6 flex items-center justify-center">
                  <i className="fa-regular fa-fw fa-ellipsis"></i>
                </div>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75" leaveFrom="opacity-100" leaveTo="opacity-0" className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-40 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0">
                <div className="py-1 max-h-80 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar">
                  {dropdownData.sort((a, b) => a.ordering - b.ordering).map((item, index) => (
                    <React.Fragment key={index}>
                      {item.type === 'link' ?
                        <Menu.Item as="div">
                          <Link to={item.link} className={"flex items-center py-2 px-3 gap-3 font-medium transition-all duration-200 " + (item.standout ? "text-rose-500 hover:bg-rose-100" : "text-slate-600 hover:bg-slate-100")}>
                            <div className="w-5 h-5 flex items-center justify-center text-base">
                              <i className={"fa-regular fa-fw " + (item.icon)}></i>
                            </div>
                            <div className="text-xs">{item.label}</div>
                          </Link>
                        </Menu.Item> : ''
                      }
                      {item.type === 'button' ?
                        <Menu.Item as="div">
                          <Button
                            buttonLabel={item?.label}
                            buttonClasses={`!w-full !flex !items-center !justify-start !py-2 !px-3 !gap-3 !rounded-none !text-base !font-medium !bg-white ${item.standout ? '!text-rose-500 hover:!bg-rose-100' : '!text-slate-600 hover:!bg-slate-100'}`}
                            buttonType={'button'}
                            buttonIcon={
                              `${(item.buttonType && item.buttonType === 'status') ? 'fa-regular ' + item?.icon : ''}
                               ${(item.buttonType && item.buttonType === 'delete') ? 'fa-regular ' + item?.icon : ''}
                               ${(item.buttonType && item.buttonType) ? 'fa-regular ' + item?.icon : ''}`
                            }
                            buttonIconPosition={'left'}
                            buttonHasLink={false}
                            buttonLabelClasses={'!text-xs !font-semibold'}
                            functions={() => updateFunction(item?.buttonType, item?.value, 2)}
                         
                          />
                        </Menu.Item> : ''
                      }
                    </React.Fragment>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {
        enabledDeletePopup &&
        <DeletePopup
          isOpen={enabledDeletePopup}
          setIsOpen={(val) => setenabledDeletePopup(val)}
          currentId={currentId}
          getActionValue={(obj) => {
            getActionValue(obj)
            setenabledDeletePopup(false);
            setCurrentId('')
          }}
        />
      }
    </>
  );

};

export default CardAction;