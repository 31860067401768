import React, { useState, useEffect } from 'react';
import { setTitle } from '../helpers/MetaTag';
import DropdownCheckbox from '../components/form/DropdownCheckbox';
import Searching from '../components/Searching';
import NoDataFound from '../components/NoDataFound';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CompanyReport from './pdf/CompanyReport';
import { useSelector } from 'react-redux';

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import moment from "moment";

const IndividualCompanyReport = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Company Report");
  const { i18n,t } = useTranslation();
  const user = useSelector((state) => state.auth?.user)
  const [loading, setLoading] = useState(false);
  const [successors, setSuccessors] = useState([]);
  const [positions, setPositions] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  const [education,setEducation] = useState([]);
  const [creditUnion,setCreditUnion] = useState([]);
  const [avgCreditUnion,setAvgCreditUnion] = useState(0);
  const [creditUnionAvgLabel,setCreditUnionAvgLabel] = useState('');
  const [avgExperience,setAvgExperience] = useState(0);
  const [experienceAvgLabel,setExperienceAvgLabel] = useState('');
  const [experience,setExperience] = useState([]);
  const [avgRetirement,setAvgRetirement] = useState(0);
  const [retirementAvgLabel,setRetirementAvgLabel] = useState('');
  const [retirementWindow,setRetirementWindow] = useState([]);

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { 'ordering': 1 },
          type: 1,
          status: 1,
          isStep : 1
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                label: value?.name+' ('+value?.short_name+')',
                isEnabled: true
              }
            })
          )
        } else {
          console.log('position list message', result.message)
        }
      } catch (error) {
        console.log('Error position list catch', error.message)
      }
    }
    positionData();

    async function educationList() {
        try {
        const result = await postData("education/list", {
            sortQuery:{'created_at':1},
            status:1
        });
        if (result.data) {
            setEducation(result.data);
        } else {
            console.log('Education message',result.message)
        }
        } catch (error) {
        console.log('Error education catch',error.message)
        }
    }
    educationList();

    async function creditUnionData() {
        try {
        const result = await postData("experience/list", {
            sortQuery:{'ordering':-1},
            type:2,
            status:1,
            is_visabled:1,
        });
        if (result.data) {
            setCreditUnion(result.data);
        } else {
            console.log('Company list message',result.message)
        }
        } catch (error) {
        console.log('Error Company list catch',error.message)
        }
    }
    creditUnionData();

    async function experienceData() {
      try {
      const result = await postData("experience/list", {
          sortQuery:{'ordering':-1},
          type:1,
          status:1,
          is_visabled:1,
      });
      if (result.data) {
          setExperience(result.data);
      } else {
          console.log('Experience list message',result.message)
      }
      } catch (error) {
        console.log('Error Experience list catch',error.message)
      }
    }
    experienceData();

    async function retirementWindowData() {
      try {
      const result = await postData("experience/list", {
          sortQuery:{'created_at':1},
          type:3,
          status:1,
          is_visabled:1,
      });
      if (result.data) {
          setRetirementWindow(result.data);
      } else {
          console.log('Retirement window list message',result.message)
      }
      } catch (error) {
        console.log('Error retirement window list catch',error.message)
      }
    }
    retirementWindowData();
  }, []);

  const positionSelect = async (id, checked) => { 
    if(id && id === 'all'){
      setPositions(positions.map((pos) => {
        if (checked) {
          return { ...pos, isEnabled: checked }
        } else {
          return { ...pos, isEnabled: false }
        }
      }))
    }else{
      setPositions(positions.map((pos) => {
        if (pos._id === id) {
          return { ...pos, isEnabled: checked }
        } else {
          return pos;
        }
      }))
    }    
  }

  useEffect(() => {
    let SelectedPosition = [];
    let SelectedUser = [];
    // eslint-disable-next-line array-callback-return
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
    if (users) {
      users.map((usr) => {
        if (usr.isEnabled) {
          SelectedUser.push(usr._id);
        }
      });
    }
    if (SelectedPosition.length > 0 || SelectedUser.length > 0) {
      loadSuccessors(SelectedPosition, SelectedUser);
    } else {
      setSuccessors([])
    }

    // if(positions && SelectedPosition.length > 0){
    //   loadUsers(SelectedPosition);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions, users]);

  const loadSuccessors = async (SelectedPosition = [], SelectedUser = []) => {
    setLoading(true);
    setTotalDataCount(0)
    try {
      const result = await postData("highrated-successor/list", {
        sortQuery: { created_at: 1 },
        position: SelectedPosition,
        created_by: SelectedUser,
        isReport: true,
        is_interim: 2,
      });
      if (result.data) {
        setSuccessors(
          result.data.map((item, key) => {
            if (item.successors_details && item.successors_details.length > 0) {
              let successors_details = item.successors_details.map((value, index) => {
                return {
                  ...value,
                };
              })
              return {
                name: item.name,
                position: item.position,
                retirement: item.retirement,
                pos_details: item.pos_details,
                created_retirement_details: item.created_retirement_details,
                successors_details: successors_details
              }
            } else {
              return {};
            }
          })
        );
        // setEducationCount(
        //   result.data.map(person => person.successors_details).flat().map(successor => successor.education_details).flat().reduce((counts, education) => { 
        //     counts[education._id] = (counts[education._id] || 0) + 1; return counts; }, {})
        // )
      }
      setTotalDataCount(result.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, '_blank');
    }
  }  

  useEffect(() => {
    // Years of Service
    if(creditUnion && creditUnion.length>0){
      const _isEmptyObject = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
      let _finalSuccessor = successors.filter(obj => !_isEmptyObject(obj));
      let totalPoint =0;
      let totalSuccessor = 0;
      creditUnion.map((item) => {
        let yearCount = _finalSuccessor.length >0 && _finalSuccessor.map(person => person.successors_details).flat().map(successor => successor.cu_details).flat().filter(credit => credit._id === item?._id).length;
        totalPoint += Number(yearCount*item?.point);
        totalSuccessor += Number(yearCount);
      });
      let avg = Number(totalPoint / totalSuccessor) ? Number(totalPoint / totalSuccessor): 0;
      let finalAvg = Math.round(avg);
      let avgReview = finalAvg && creditUnion.filter((it)=> it.point === finalAvg)[0]?.name;
      setAvgCreditUnion(Number(avg).toFixed(2))
      setCreditUnionAvgLabel(avgReview)
    }

    // Management Experience
    if(experience && experience.length>0){
      const _isEmptyObjectExp = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
      let _finalExpSuccessor = successors.filter(obj => !_isEmptyObjectExp(obj));
      let _totalPoint = 0;
      let _totalSuccessor = 0;
      experience.map((item) => {
        let expCount = _finalExpSuccessor.length >0 && _finalExpSuccessor.map(person => person.successors_details).flat().map(successor => successor.experience_details).flat().filter(experience => experience._id === item?._id).length; 
        _totalPoint += Number(expCount*item?.point);
        _totalSuccessor += Number(expCount);
        let _avg = Number(_totalPoint / _totalSuccessor) ? Number(_totalPoint / _totalSuccessor): 0;
        let _finalAvg = Math.round(_avg);
        let _avgReview = _finalAvg && experience.filter((it)=> it.point === _finalAvg)[0]?.name;
        setAvgExperience(Number(_avg).toFixed(2))
        setExperienceAvgLabel(_avgReview)
      })      
    }

    // Retirement Window
    if(retirementWindow && retirementWindow.length>0){
      const _isEmptyObjectExp = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
      let _finalRetirementSuccessor = successors.filter(obj => !_isEmptyObjectExp(obj));
      let _totalRetirementPoint = 0;
      let _totalRetirementSuccessor = 0;
      retirementWindow.map((item) => {
        let retirementCount = _finalRetirementSuccessor.length >0 && _finalRetirementSuccessor.map(person => person.successors_details).flat().map(successor => successor.retirement_details).flat().filter(retirement => retirement._id === item?._id).length; 
        _totalRetirementPoint += Number(retirementCount*item?.point);
        _totalRetirementSuccessor += Number(retirementCount);
        let _retirementAvg = Number(_totalRetirementPoint / _totalRetirementSuccessor) ? Number(_totalRetirementPoint / _totalRetirementSuccessor): 0;
        let _retirementFinalAvg = Math.round(_retirementAvg);
        let _retirementAvgReview = _retirementFinalAvg && retirementWindow.filter((it)=> it.point === _retirementFinalAvg)[0]?.name;
        setAvgRetirement(Number(_retirementAvg).toFixed(2))
        setRetirementAvgLabel(_retirementAvgReview)
      })
    }
  },[creditUnion,experience,successors,retirementWindow])

  return (
    <>
     <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div>
          <div className="text-xl md:text-3xl text-slate-800 font-bold">{user?.company_name+' '+t('overview')}</div>
          <div className="text-sm text-slate-800 font-bold">{t('select_an_option_to_filter_the_report')}</div>
          <div className="text-sm text-teal-600 font-bold">{moment(new Date()).format("MMM, DD, YYYY")}</div>
        </div>
        <div className="sm:ml-auto flex justify-end items-center">
          <button
            type={'button'}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink document={
                <CompanyReport 
                  successors={successors} 
                  user={user} 
                  educations={education}
                  creditUnion={creditUnion}
                  experiences={experience}
                  retirementWindow={retirementWindow}
                  creditUnionAvgLabel={creditUnionAvgLabel}
                  experienceAvgLabel={experienceAvgLabel}
                  retirementAvgLabel={retirementAvgLabel}
                />
              } fileName="profile_report.pdf">
                {({ blob, url, loading, error }) => (
                  loading ? t('loading_document') : t('download_report'))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div> 
      <div className="relative flex justify-start items-center mb-4 space-x-2">
        <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div>
        {/* <div className="relative z-20">
          <DropdownCheckbox data={users} functions={userSelect} />
        </div> */}
      </div>

      {/* Average Section */}
      <div className="relative mt-2">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-4">            
            <div className="w-full bg-white rounded shadow mt-3">
              <div className="w-full px-2 py-4 bg-slate-900 rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-base leading-none text-center">
                  {t("average_years_of_service")}
                </div>
              </div>
              <div className="w-full relative">
                <div className={"border-b border-slate-200  w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      {/* <div className="text-base font-bold text-slate-600 text-center">
                        {avgCreditUnion}
                      </div> */}
                      <div className="text-base font-medium text-slate-500 text-center">
                        {creditUnionAvgLabel}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="w-full bg-white rounded shadow mt-3">
              <div className="w-full px-2 py-4 bg-slate-900 rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-base leading-none text-center">
                  {t("management_experience_average")}
                </div>
              </div>
              <div className="w-full relative">
                <div className={"border-b border-slate-200  w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      {/* <div className="text-base font-bold text-slate-600 text-center">
                        {avgExperience}
                      </div> */}
                      <div className="text-base font-medium text-slate-500 text-center">
                        {experienceAvgLabel}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="w-full bg-white rounded shadow mt-3">
              <div className="w-full px-2 py-4 bg-slate-900 rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-base leading-none text-center">
                  {t("average_retirement_window")}
                </div>
              </div>
              <div className="w-full relative">
                <div className={"border-b border-slate-200  w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">                      
                      <div className="text-base font-medium text-slate-500 text-center">
                        {retirementAvgLabel}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar">
        <table className="min-w-max w-full table-auto">
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {
              totalDataCount > 0 ? (
                successors.map((row) => {
                  return (
                    <>
                      {
                        row.successors_details && (
                          <>
                            <tr className="bg-slate-100">
                              <td className="py-3 px-2" colSpan={12}></td>
                            </tr>
                            <tr className="border-b border-slate-200 bg-slate-300">
                              <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tl-md" colSpan={3}>
                                {row.position} : {row.name}
                              </td>
                              <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tr-md" colSpan={9}>
                                {t('retirement_window')} : {row.retirement}
                              </td>
                            </tr>
                          </>
                        )
                      }
                      {
                        row.successors_details && (
                        <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
                          <th className="py-4 px-2 text-left max-w-[240px] z-10 bg-slate-900 rounded-tl-md" colSpan={3}>{t('potential_successors')}</th>                          
                          <th className="py-4 px-2 text-left max-w-[240px] z-10 bg-slate-900" colSpan={3}>{t('education_summary')}</th>
                          <th className="py-4 px-2 text-left max-w-[240px] z-10 bg-slate-900" colSpan={2}>{t('year_of_service')}</th>
                          <th className="py-4 px-2 text-left max-w-[240px] z-10 bg-slate-900" colSpan={2}>{t('senior_management_experience')}</th>
                          <th className="py-4 px-2 text-left max-w-[240px] z-10 bg-slate-900 rounded-tr-md" colSpan={2}>{t('retirement_window')}</th>
                        </tr>
                        )
                      }                      
                      {
                        row.successors_details && row.successors_details.map((item, index) => (
                          <tr className="border-b border-slate-200" key={index} >
                            <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top" colSpan={3}>
                              {item?.name}
                              <span className="block text-xs font-bold leading-tight text-teal-500">
                              {(item?.position_details) ? item?.position_details[0]?.short_name : 'N/A'}
                              </span>
                            </td>
                            <td className={"py-3 px-2 text-left max-w-[240px] "} colSpan={3}>
                              {item?.education_details &&
                                item?.education_details.length > 0
                                ? item?.education_details[0]?.name
                                : "N/A"}
                            </td>
                            <td className={"py-3 px-2 text-left max-w-[240px] "} colSpan={2}>
                              {item?.cu_details &&
                                item?.cu_details.length > 0
                                ? item?.cu_details[0]?.name
                                : "N/A"}
                            </td>
                            <td className={"py-3 px-2 text-left max-w-[240px] "} colSpan={2}>
                              {item?.experience_details &&
                                item?.experience_details.length > 0
                                ? item?.experience_details[0]?.name
                                : "N/A"}
                            </td>
                            <td className={"py-3 px-2 text-left max-w-[240px] "+((item?.retirement_details.length > 0 && item?.retirement_details[0].isPass)? '': 'text-red-600')} colSpan={2}>
                              {item?.retirement_details &&
                                item?.retirement_details.length > 0
                                ? item?.retirement_details[0]?.name
                                : "N/A"}
                            </td>
                          </tr>
                        ))
                      }
                    </>
                  )
                })
              ) : (
                <tr className="border-b border-slate-200">
                  <td className="py-3 px-2 text-left" colSpan={20}>
                    {
                      loading ? <Searching label={t('searching')} /> : <NoDataFound label={t('please_make_a_selection_from_the_dropdown_to_generate_report')} />
                    }
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>

      {/* Count section */}
      <div className="relative mt-2">
        <div className="grid grid-cols-12 gap-2">          
          <div className="col-span-3">
            <div className="w-full">
              <div className="text-base font-bold text-black py-2">{t('education_summary')}</div>
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="min-w-max w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                        {t("level_of_education")}
                      </th>
                      <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white">
                    {education && education.length > 0 ? (
                      education.map((item, index) => {
                        const isEmptyObject = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
                        let finalSuccessor = successors.filter(obj => !isEmptyObject(obj));
                        let educationCount = finalSuccessor.length >0 && finalSuccessor.map(person => person.successors_details).flat().map(successor => successor.education_details).flat().filter(education => education._id === item?._id).length;
                        return (
                          <tr className={"border-b border-slate-200"} key={index}>
                            <td className={"py-3 px-2 text-left max-w-[240px]"}>
                              <div className="block">
                                <div className="text-sm font-medium text-slate-600">
                                  {item?.name}
                                </div>
                                <div className="text-xs text-slate-400">
                                  {item?.option}
                                </div>
                              </div>
                            </td>
                            <td className={"py-3 px-2 text-right max-w-[240px]"}>
                              <div className="block">
                                <div className="text-sm font-medium text-slate-600">
                                  {(educationCount)?educationCount:0}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? (
                            <Searching label={"Searching"} />
                          ) : (
                            <NoDataFound label={"No Data Found"} />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="w-full">
              <div className="text-base font-bold text-black py-2">{t('years_of_service')}</div>
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="min-w-max w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                        {t("years_with_company")}
                      </th>
                      <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white">
                    {creditUnion && creditUnion.length > 0 ? (
                      creditUnion.map((item, index) => {
                        const _isEmptyObject = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
                        let _finalSuccessor = successors.filter(obj => !_isEmptyObject(obj));
                        let yearCount = _finalSuccessor.length >0 && _finalSuccessor.map(person => person.successors_details).flat().map(successor => successor.cu_details).flat().filter(credit => credit._id === item?._id).length;               
                        return (
                          <tr className={"border-b border-slate-200"} key={index}>
                            <td className={"py-3 px-2 text-left max-w-[240px]"}>
                              <div className="block">
                                <div className="text-sm font-medium text-slate-600">
                                  {item?.name}
                                </div>
                                <div className="text-xs text-slate-400">
                                  {item?.option}
                                </div>
                              </div>
                            </td>
                            <td className={"py-3 px-2 text-right max-w-[240px]"}>
                              <div className="block">
                                <div className="text-sm font-medium text-slate-600">
                                  {(yearCount)?yearCount:0}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );                        
                      })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? (
                            <Searching label={"Searching"} />
                          ) : (
                            <NoDataFound label={"No Data Found"} />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="w-full">
              <div className="text-base font-bold text-black py-2">{t('management_experience')}</div>
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="min-w-max w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                        {t("management_experience")}
                      </th>
                      <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white">
                    {experience && experience.length > 0 ? (
                      experience.map((item, index) => {
                        const _isEmptyObjectExp = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
                        let _finalExpSuccessor = successors.filter(obj => !_isEmptyObjectExp(obj));
                        let expCount = _finalExpSuccessor.length >0 && _finalExpSuccessor.map(person => person.successors_details).flat().map(successor => successor.experience_details).flat().filter(experience => experience._id === item?._id).length;               
                        return (
                          <tr className={"border-b border-slate-200"} key={index}>
                            <td className={"py-3 px-2 text-left max-w-[240px]"}>
                              <div className="block">
                                <div className="text-sm font-medium text-slate-600">
                                  {item?.name}
                                </div>
                                <div className="text-xs text-slate-400">
                                  {item?.option}
                                </div>
                              </div>
                            </td>
                            <td className={"py-3 px-2 text-right max-w-[240px]"}>
                              <div className="block">
                                <div className="text-sm font-medium text-slate-600">
                                  {(expCount)?expCount:0}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );                        
                      })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? (
                            <Searching label={"Searching"} />
                          ) : (
                            <NoDataFound label={"No Data Found"} />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="w-full">
              <div className="text-base font-bold text-black py-2">{t('retirement_summary')}</div>
              <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
                <table className="min-w-max w-full table-auto">
                  <thead>
                    <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
                      <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                        {t("retirement_window")}
                      </th>
                      <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">
                        &nbsp;
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-slate-700 text-sm font-light bg-white">
                    {retirementWindow && retirementWindow.length > 0 ? (
                      retirementWindow.map((item, index) => {
                        const _isEmptyObjectExp = obj => Object.keys(obj).length === 0 && obj.constructor === Object;
                        let _finalRetirementSuccessor = successors.filter(obj => !_isEmptyObjectExp(obj));
                        let retirementCount = _finalRetirementSuccessor.length >0 && _finalRetirementSuccessor.map(person => person.successors_details).flat().map(successor => successor.retirement_details).flat().filter(retirement => retirement._id === item?._id).length;  
                        let isPass = item?.isPass;           
                        return (
                          <tr className={"border-b border-slate-200"} key={index}>
                            <td className={"py-3 px-2 text-left max-w-[240px]"}>
                              <div className="block">
                                <div className={"text-sm font-medium "+((isPass)?'text-slate-600':'text-rose-600')}>
                                  {item?.name}
                                </div>
                                <div className={"text-xs "+((isPass)?'text-slate-400':'text-rose-400')}>
                                  {item?.option}
                                </div>
                              </div>
                            </td>
                            <td className={"py-3 px-2 text-right max-w-[240px]"}>
                              <div className="block">
                                <div className={"text-sm font-medium "+((isPass)?'text-slate-600':'text-rose-600')}>
                                  {(retirementCount)?retirementCount:0}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );                        
                      })
                    ) : (
                      <tr className="border-b border-slate-200">
                        <td className="py-3 px-2 text-left" colSpan={20}>
                          {loading ? (
                            <Searching label={"Searching"} />
                          ) : (
                            <NoDataFound label={"No Data Found"} />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>      
      {/* Average Section */}
      <div className="relative mt-2">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-4">            
            <div className="w-full bg-white rounded shadow mt-3">
              <div className="w-full px-2 py-4 bg-slate-900 rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-base leading-none text-center">
                  {t("average_years_of_service")}
                </div>
              </div>
              <div className="w-full relative">
                <div className={"border-b border-slate-200  w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      {/* <div className="text-base font-bold text-slate-600 text-center">
                        {avgCreditUnion}
                      </div> */}
                      <div className="text-base font-medium text-slate-500 text-center">
                        {creditUnionAvgLabel}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="w-full bg-white rounded shadow mt-3">
              <div className="w-full px-2 py-4 bg-slate-900 rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-base leading-none text-center">
                  {t("management_experience_average")}
                </div>
              </div>
              <div className="w-full relative">
                <div className={"border-b border-slate-200  w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">
                      {/* <div className="text-base font-bold text-slate-600 text-center">
                        {avgExperience}
                      </div> */}
                      <div className="text-base font-medium text-slate-500 text-center">
                        {experienceAvgLabel}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="w-full bg-white rounded shadow mt-3">
              <div className="w-full px-2 py-4 bg-slate-900 rounded-t-lg sticky flex items-center justify-center">
                <div className="text-white font-bold text-base leading-none text-center">
                  {t("average_retirement_window")}
                </div>
              </div>
              <div className="w-full relative">
                <div className={"border-b border-slate-200  w-full flex"}>
                  <div className={"py-3 px-2 text-left w-[100%]"}>
                    <div className="block">                      
                      <div className="text-base font-medium text-slate-500 text-center">
                        {retirementAvgLabel}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndividualCompanyReport;