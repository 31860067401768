import React from 'react';
import { InlineWidget } from 'react-calendly';
const PricingContactV2 = () => {
  return (
    <div className="relative mt-32">
      <InlineWidget url="https://calendly.com/successionnow/30-min-succession-now-onboarding-call" />
    </div>
  );
};

export default PricingContactV2;