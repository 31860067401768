import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/section/PageBanner";
import { postData } from "../services/api";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Terms & Conditions");
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [termsData, setTermsData] = useState({});

  useEffect(() => {
    const loadBannerList = async () => {
      setLoading(true);
      try {
        const result = await postData("/page/view", {
          alias: "terms-and-conditions",
        });
        if (result.data) {
          setTermsData(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };

    loadBannerList();
  }, []);
  return (
    <>
      <PageBanner title={t("terms_and_conditions")} />
      <section className="w-full h-full overflow-hidden xl:p-8 p-2">
        <div className="w-full lg:max-w-[1400px] xl:max-w-[1400px] py-8 mx-auto">
          <div className="flex flex-wrap  w-full ">
            <div className="relative px-5 w-full ml-auto">
              <div className="relative">
                <h2 className="mb-10 text-center">
                  <span className="block xl:text-5xl text-3xl font-bold text-slate-700">{t("terms_and_conditions")}</span>
                  <span className="inline-block text-sm uppercase text-slate-400 font-normal tracking-widest border-t-2 border-teal-300 py-1 my-1">Succession Now</span>
                </h2>
                <div className="text-slate-500 font-light space-y-4 leading-tight" dangerouslySetInnerHTML={{ __html: termsData?.description }}></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
