import React,{ useState, useEffect,Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Input from '../form/Input';
import Checkbox from '../form/Checkbox';
import Radio from '../form/Radio';
import Select from '../form/Select';
import { Tooltip } from 'react-tooltip';
import Searching from '../Searching';
import NoDataFound from '../NoDataFound';
import ConfirmationPopup from './ConfirmationPopup';

import { postData } from '../../services/api';
import toastr from 'toastr';
import { questionTiers } from '../../helpers';
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { classNames } from '../../helper/classNames';

const ManageTiersPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {}, 
}) => { 
  const { i18n,t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const tires = questionTiers();  
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [step,setStep] = useState(1);  
  const [processing,setProcesing] = useState(false)
  const [loading,setLoading] = useState(false);
  const [isContinue,seIsContinue] = useState(false);
  const [concerns,setConcerns] = useState([]);
  const [predictors,setPredictors] = useState([]);
  const [isEnabledTires,setIsEnabledTires] = useState();
  const [companyTires,setCompanyTires] = useState(tires);
  const [tierDetails,setTierDetails] = useState([]);
  const [isUpdateConcern,setIsUpdateConcern] = useState(false);
  const [isUpdatePredictor,setIsUpdatePredictor] = useState(false);
  const [isRemoveTires,setIsRemoveTires] = useState(false);
  const [isLoadingDeleteTires,setIsLoadingDeleteTires] = useState(false);
  const [availableConcerns,setAvailableConcerns] = useState([]);
  const [availablePredictor,setAvailablePredictor] = useState([]);
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] = useState(false);
  const [message,setMessage] = useState('');
  const [actionValue, setActionValue] = useState({});

  
  const tabHeading = [
    {
      title: t('conditions_of_concern_library'),
      description : ''
    },
    {
      title: t('potential_predictor_library'),
      description : ''
    }
  ]

  const closeModal = () => { setIsOpen(false) } 

  useEffect(() => {
    if (actionValue && actionValue.button_type === "confirm") {
      const onUpdate = async () => {
        try {
          setLoading(true);
          const result = await postData("heading/update-user-tire", {
            concerns : concerns,
            predictors : predictors,
          });
          if (result.status && result.status === 200) {
            toastr.success(result.message);
            setIsOpen(false)
            setLoading(false);
          } else {
            toastr.error(result.message);
            setLoading(false);
          }
        } catch (error) {
          toastr.error(error.message);
          setLoading(false);
        }
      }
      onUpdate();
    } 
  }, [actionValue]);
  
  const onSubmit = async () => {
    try {
      setLoading(true);
      const res = await postData("heading/checking-user-tire", {
        concerns : concerns,
        predictors : predictors,
      });
      if(res.status === 200){
        const result = await postData("heading/update-user-tire", {
          concerns : concerns,
          predictors : predictors,
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setIsOpen(false)
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } else {
        setMessage(res.message);
        setEnabledConfirmationPopup(true);
        setLoading(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setLoading(false);
    }
  } 

  useEffect(() => {
    const loadTierDetails = async () => {
      setProcesing(true)
      try {
        const result = await postData("company-session/details", {});
        if (result.data) {
          setTierDetails(result?.data?.evaluationFrequency);
          setIsEnabledTires(result?.userData?.isEnabledTires.toString());
          // setAvailableConcerns(result?.userData?.availableConcerns);
          // setAvailablePredictor(result?.userData?.availablePredictor);
          if(result?.data?.evaluationFrequency && result?.data?.evaluationFrequency.length>0){
            setStep(2);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadTierDetails();
  },[])
  
  useEffect(() => {     
    const loadHeadingList = async () => {
      setProcesing(true)
      try {
        const result = await postData("heading/list", {
          sortQuery: { ordering: 1 },
          isGlobal : true
        });
        if (result.data) {
          let _concerns = [];
          let _predictors = [];
          result.data.map((item) => {
            let tierId =  (item?.company_headings && item?.company_headings.length>0)?item?.company_headings[0]?.tierId:null ;  
            if(item?.type === 'concern'){                    
              _concerns.push({
                _id : item?._id,
                name : item?.name,
                description:item?.description,
                alias : item?.alias,
                weight : item?.weight,
                created_at: new Date(),
                headingId : (item?.headingId)?item?.headingId:item?._id,
                isDefault : (item?.company_headings && item?.company_headings.length>0)?true:false,
                disabled : (item?.company_headings && item?.company_headings.length>0)?false:true,
                tierId: tierId,
              })
            }else if(item?.type === 'predictors'){          
              _predictors.push({
                _id : item?._id,
                name : item?.name,
                description:item?.description,
                alias : item?.alias,
                weight : item?.weight,
                created_at: new Date(),
                headingId : (item?.headingId)?item?.headingId:item?._id,
                isDefault : (item?.company_headings && item?.company_headings.length>0)?true:false,
                disabled : (item?.company_headings && item?.company_headings.length>0)?false:true,
                tierId: tierId,
              })
            }
          })
          setConcerns(_concerns);
          setPredictors(_predictors);
          setIsUpdatePredictor(true)
          setIsUpdateConcern(true)
          setProcesing(false)
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadHeadingList();    
  }, []);  

  const updateHandler = (heading_id, event,type,subType,subTypeId) => {
    if(event){   
      if(type === 'concern'){
        let enabledConcerns = concerns.length>0 && concerns.filter(it => it?.isDefault)?.length;
        if(Number(enabledConcerns+1) === 8){
          setConcerns(
            concern => concern.map((item, i) => {
              if(subType === 'tires' && item?._id === heading_id){
                return {
                  ...item,
                  tierId : subTypeId
                }
              }else if(item?._id === heading_id){
                return {
                  ...item,
                  isDefault : true,
                }
              } else {
                if(item?.isDefault === false){
                  return {
                    ...item,
                    disabled : true
                  }
                } else {
                  return item;
                }
              }
            }
          )); 
          setIsUpdateConcern(true);
        }else{
          setConcerns(
            concern => concern.map((item, i) => {
              if(subType === 'tires' && item?._id === heading_id){
                return {
                  ...item,
                  tierId : subTypeId
                }
              }else if(item?._id === heading_id){
                return {
                  ...item,
                  isDefault : true,
                }
              } else {
                return item;
              }
            }
          ));
          setIsUpdateConcern(true);
        }
      }else if(type === 'predictors'){
        let enabledPredictors = predictors.length>0 && predictors.filter(it => it?.isDefault)?.length;
        if(Number(enabledPredictors+1) === 8){
          setPredictors(
            predictors => predictors.map((item, i) => {
              if(subType === 'tires' && item?._id === heading_id){
                return {
                  ...item,
                  tierId : subTypeId
                }
              }else if(item?._id === heading_id){
                return {
                  ...item,
                  isDefault : true
                }
              } else {
                if(item?.isDefault === false){
                  return {
                    ...item,
                    disabled : true
                  }
                } else {
                  return item;
                }
              }
            }
          ));
          setIsUpdatePredictor(true)
        }else{
          setPredictors(
            predictors => predictors.map((item, i) => {
              if(subType === 'tires' && item?._id === heading_id){
                return {
                  ...item,
                  tierId : subTypeId
                }
              }else if(item?._id === heading_id){
                return {
                  ...item,
                  isDefault : true
                }
              } else {
                return item;
              }
            }
          ));
          setIsUpdatePredictor(true)
        }
      }
    } else {
      if(type === 'concern'){
        setConcerns(
          concern => concern.map((item, i) => {
            if(subType === 'tires' && item?._id === heading_id){
              return {
                ...item,
                tierId : subTypeId
              }
            }else if(item?._id === heading_id){
              return {
                ...item,
                isDefault : false
              }
            } else {
              if(item?.isDefault === false){
                return {
                  ...item,
                  disabled : false
                }
              } else {
                return item;
              }
            }
          }
        )); 
        setIsUpdateConcern(true);
      }else if(type === 'predictors'){
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(subType === 'tires' && item?._id === heading_id){
              return {
                ...item,
                tierId : subTypeId
              }
            }else if(item?._id === heading_id){
              return {
                ...item,
                isDefault : false
              }
            } else {
              if(item?.isDefault === false){
                return {
                  ...item,
                  disabled : false
                }
              } else {
                return item;
              }
            }
          }
        ));
        setIsUpdatePredictor(true)
      }
    }
  };  

  const onManageTiers =  async () => {
    try {
      seIsContinue(true);
      const result = await postData("company-session/create", {
        companyTires : (isEnabledTires === "1")?companyTires:[],
        isEnabledTires : isEnabledTires,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setTierDetails(result?.data?.evaluationFrequency);
        setStep(2);
        seIsContinue(false);
      } else {
        toastr.error(result.message);
        seIsContinue(false);
      }
    } catch (error) {
      toastr.error(error.message);
      seIsContinue(false);
    }
  }

  const onDelete = async () => {
    try {
      setIsLoadingDeleteTires(true);
      const result = await postData("company-session/remove", {});
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setStep(1);
        setIsLoadingDeleteTires(false);
      } else {
        toastr.error(result.message);
        setIsLoadingDeleteTires(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsLoadingDeleteTires(false);
    }
  }

  useEffect(() => {
    let enabledConcerns = concerns.length>0 && concerns.filter(it => it?.isDefault)?.length;
    if(enabledConcerns >0){ 
      if(enabledConcerns === 8){
        setConcerns(
          concern => concern.map((item, i) => {
            if(item?.isDefault === false){
              return {
                ...item,
                disabled : true
              }
            } else {
              return item;
            }
          }
        )); 
        setIsUpdateConcern(false);
      }else{
        setConcerns(
          concern => concern.map((item, i) => {
            if(item?.isDefault === false){
              return {
                ...item,
                disabled : false
              }
            } else {
              return item;
            }
          }
        ));
        setIsUpdateConcern(false);
      }
    }
  },[isUpdateConcern])

  useEffect(() => {
    let enabledPredictors = predictors.length>0 && predictors.filter(it => it?.isDefault)?.length;
    if(enabledPredictors>0){
      if(enabledPredictors === 8){
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(item?.isDefault === false){
              return {
                ...item,
                disabled : true
              }
            } else {
              return item;
            }
          }
        ));
        setIsUpdatePredictor(false)
      }else{
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(item?.isDefault === false){
              return {
                ...item,
                disabled : false
              }
            } else {
              return item;
            }
          }
        ));
        setIsUpdatePredictor(false)
      }
    }    
  },[isUpdatePredictor])  

  const inputHandel = (value,index) => {
    setCompanyTires(
      companyTires => companyTires.map((it, i) => {
        if(i === index){
          return value
        } else {
          return it;
        }
      })
    )
  }
  console.log('concerns',concerns)

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('manage_tiers')} 
                    </Dialog.Title>
                    <div className="absolute top-2 right-2 z-10">
                      <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={''} 
                        buttonClasses={'!bg-rose-500 !text-white !w-10 !h-10 !p-0 !rounded-full border border-white/20'} 
                        functions={closeModal}
                        buttonHasLink={false}
                      />
                    </div>
                    <div className="relative mt-4 divide-y divide-slate-200">
                      <div className='pb-4'>
                        {
                          (step === 2 && isEnabledTires === "1") && 
                          <div className='space-y-2'>
                            <Checkbox 
                              checkboxLableClass={"text-slate-800"} 
                              checkboxInputClass={""} 
                              divClass={"!items-start"}
                              checkboxName={'remove-tires'} 
                              isChecked={isRemoveTires} 
                              checkboxLabel={'Are you delete tires?'} 
                              checkboxValue={'remove-tires'} 
                              getCheckboxValue={(code, event) => {
                                if(event.target.checked === true ){
                                  setIsRemoveTires(true)
                                }else{
                                  setIsRemoveTires(false)   
                                }
                              }}
                            />
                            <Button
                              buttonClasses={classNames("!h-8", !isRemoveTires?'!bg-slate-200 !text-slate-500':'!bg-rose-500')}
                              buttonLabelClasses='' 
                              buttonType={'button'} 
                              buttonIcon={isLoadingDeleteTires?'fa-light fa-spinner fa-spin':'fa-light fa-trash'} 
                              buttonIconPosition={'left'} 
                              buttonLabel={t('remove_tiers')}
                              functions={onDelete}
                              buttonHasLink={false}
                              buttonDisabled={isRemoveTires?false:true}
                            />
                          </div>
                        }
                      </div>
                      <div className="py-4 px-4 sm:py-8 sm:px-6 flex flex-col">                        
                        {
                          (step && step === 1)?(
                            <>
                            <div className="relative mb-5">
                              <p className="text-sm text-gray-500 text-start mb-3">{t("enabled_tires")}</p>
                              <div className="sm:flex gap-2 space-y-2 sm:space-y-0">
                                <Radio 
                                  radioName={"type"} 
                                  radioLabel={t("yes")} 
                                  radioInputClass={""} 
                                  isChecked={isEnabledTires === 1 ? true : false} 
                                  radioLableClass={""} 
                                  radioValue={1} 
                                  getRadioValue={(event) => setIsEnabledTires(event)} 
                                />
                                <Radio 
                                  radioName={"type"} 
                                  radioLabel={t("no")} 
                                  radioInputClass={""} 
                                  isChecked={isEnabledTires === 2 ? true : false} 
                                  radioLableClass={""} 
                                  radioValue={2} 
                                  getRadioValue={(event) => setIsEnabledTires(event)} 
                                />
                              </div>
                            </div>
                            {
                              (isEnabledTires === "1" && companyTires.length>0) && 
                              companyTires.map((item,idx) => {
                                return (
                                  <div className="relative mb-5" key={idx}>                          
                                    <Input 
                                      label={(idx === 0)?t("tires"):''} 
                                      labelClasses={"!text-xs"} 
                                      inputType={"email"} 
                                      inputPlaceholder={t("enter_value")} 
                                      inputValue={item} 
                                      setInput={(val) => inputHandel(val,idx)} 
                                    />
                                  </div>
                                )
                              })
                            }
                            </>
                          ):(
                            (step && step === 2)?(
                              (isEnabledTires && isEnabledTires === "1")?(                                
                                <div className="grid grid-cols-1 gap-10">
                                  <div className="relative">
                                    <ul className="flex flex-wrap text-sm font-medium text-center border-b-4 border-slate-200 gap-4" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
                                      {tabHeading &&
                                        tabHeading.length > 0 &&
                                        tabHeading.map((item, index) => (
                                          <li className="" role="presentation" key={index}>
                                            <button className={"inline-block py-2 border-b-4 rounded-t-lg transition-all duration-200 -mb-1 " + (index === activeTab ? "text-teal-500 hover:text-teal-500 border-teal-500" : "text-slate-400 border-slate-200 hover:text-slate-500 hover:border-slate-300")} id={"tab-" + item?.value} data-tabs-target={"#" + item?.value} type="button" role="tab" aria-controls={item?.value} aria-selected="false" onClick={() => setActiveTab(index)}>
                                              {item?.title}
                                            </button>
                                          </li>
                                        ))}
                                    </ul>
                                    <div id="default-tab-content">
                                      {
                                        tabHeading && tabHeading.length > 0 &&
                                        tabHeading.map((item, index) => (
                                        <>
                                          <div className={(index === 0 && index === activeTab)? "show" : "hidden"} id={item?.value} role="tabpanel" aria-labelledby={"tab-" + item?.value} key={index}>
                                            <div className="space-y-4 mt-4">
                                              {
                                                concerns.length> 0 ? (
                                                  concerns.map((it,idx) => {
                                                  return (
                                                    <div className="flex items-center justify-between gap-4" key={idx}>
                                                      <div className='flex-shrink flex-grow'>
                                                      <Checkbox 
                                                        checkboxLableClass={"text-slate-800"} 
                                                        checkboxInputClass={""} 
                                                        checkboxClass={"!rounded-full"}
                                                        divClass={"!items-start"}
                                                        checkboxName={it?._id} 
                                                        isChecked={it?.isDefault} 
                                                        checkboxLabel={it?.name} 
                                                        checkboxValue={it?._id} 
                                                        getCheckboxValue={(val, event) => updateHandler(val,event.target.checked,"concern","")} 
                                                        isDisabled={it?.disabled}
                                                      />
                                                      </div>
                                                      {
                                                        !it?.disabled &&
                                                        <div className='flex-shrink-0'>
                                                          <Select
                                                            label={''}
                                                            labelClasses={"!text-xs"}
                                                            xPlacement={"bottomRight"}
                                                            selectedValue={it?.tierId}
                                                            dropdownClass={"!w-60"}
                                                            dropdownData={[
                                                              { _id: "", label: t("select_tires"), value: null },
                                                              ...tierDetails?.map((item,index) => ({
                                                                _id: item?._id,
                                                                label: item?.title,
                                                                value: item?._id,
                                                              })),
                                                            ]}
                                                            getSelectedValue={(e) => updateHandler(it?._id,"","concern",'tires',e?._id)}
                                                          />
                                                        </div>
                                                      }                                              
                                                      <div className="flex-shrink-0" data-tooltip-id={"info-tooltip-"+idx} data-tooltip-content={it?.description} data-tooltip-position-strategy="fixed">
                                                      {
                                                        (it?.description) && (
                                                          <>
                                                          <Tooltip id={'info-tooltip-'+idx} place="top" style={{
                                                            maxWidth: "400px",
                                                            wordBreak: "break-word",
                                                            overflowWrap: "break-word",
                                                            whiteSpace: "normal",
                                                            padding: "5px",
                                                            backgroundColor: "#333",
                                                            color: "#fff",
                                                            borderRadius: "4px",
                                                            zIndex:2
                                                          }}></Tooltip>
                                                          <i className="fa-light fa-circle-info" style={{color:"#14b8a6"}}></i>
                                                          </>
                                                        )
                                                      }
                                                      </div>
                                                    </div>
                                                  )
                                                  })
                                                ):(
                                                  (processing)?<Searching label={"Searching"}/>:<NoDataFound label={"No Data Found"}/>
                                                )
                                              }
                                            </div>
                                          </div>
                                          <div className={(index === 1 && index === activeTab)? "show" : "hidden"} id={item?.value} role="tabpanel" aria-labelledby={"tab-" + item?.value} key={index}>
                                            <div className="space-y-4 mt-4">
                                              {
                                                predictors.length> 0 ? (
                                                  predictors.map((itm,inx) => {
                                                    return (
                                                      <div className="flex items-center justify-between gap-4" key={inx}>
                                                        <div className='flex-shrink flex-grow'>
                                                          <Checkbox 
                                                            checkboxLableClass={"text-slate-800"} 
                                                            checkboxInputClass={""} 
                                                            checkboxClass={"!rounded-full"}
                                                            divClass={"!items-start"}
                                                            checkboxName={itm?._id} 
                                                            isChecked={itm?.isDefault} 
                                                            checkboxLabel={itm?.name} 
                                                            checkboxValue={itm?._id} 
                                                            getCheckboxValue={(val, event) => updateHandler(val,event.target.checked,'predictors',"")} 
                                                            isDisabled={itm?.disabled}
                                                          />
                                                        </div>
                                                        {
                                                          !itm?.disabled && 
                                                          <div className='flex-shrink-0'>
                                                            <Select
                                                            label={''}
                                                            labelClasses={"!text-xs"}
                                                            xPlacement={"bottomRight"}
                                                            selectedValue={itm?.tierId}
                                                            dropdownClass={"!w-60"}
                                                            dropdownData={[
                                                              { _id: "", label: t("select_tires"), value: null },
                                                              ...tierDetails?.map((item,index) => ({
                                                                _id: item?._id,
                                                                label: item?.title,
                                                                value: item?._id,
                                                              })),
                                                            ]}
                                                            getSelectedValue={(e) => updateHandler(itm?._id,"",'predictors','tires',e?._id)}
                                                          />
                                                          </div>
                                                        }
                                                        <div className="flex-shrink-0" data-tooltip-id={"info-tooltip-"+inx} data-tooltip-content={itm?.description} data-tooltip-position-strategy="fixed">
                                                          {
                                                            (itm?.description) && (
                                                              <>
                                                              <Tooltip id={'info-tooltip-'+inx} place="top" style={{
                                                                maxWidth: "400px",
                                                                wordBreak: "break-word",
                                                                overflowWrap: "break-word",
                                                                whiteSpace: "normal",
                                                                padding: "5px",
                                                                backgroundColor: "#333",
                                                                color: "#fff",
                                                                borderRadius: "4px",
                                                                zIndex:2
                                                              }}></Tooltip>
                                                              <i className="fa-light fa-circle-info" style={{color:"#14b8a6"}}></i>
                                                              </>
                                                            )
                                                          }
                                                        </div>
                                                      </div>
                                                    )
                                                  })
                                                ):(
                                                  (processing)?<Searching label={"Searching"}/>:<NoDataFound label={"No Data Found"}/>
                                                )
                                              }
                                            </div>
                                          </div>
                                        </>
                                        ))
                                      }
                                    </div>                                    
                                  </div>
                                </div>
                              ):(
                                <div className="grid grid-cols-2 gap-10">
                                  <div className="relative">
                                    <div className="flex items-center justify-start">
                                      <div className="text-sm font-bold text-slate-800">{t('conditions_of_concern_library')}</div>
                                    </div>
                                    <div className="space-y-4 mt-4">
                                      {
                                        concerns.length> 0 ? (
                                          concerns.map((it,idx) => {
                                          return (
                                            <div className="flex items-center justify-between gap-2">
                                              <Checkbox 
                                                checkboxLableClass={"text-slate-800"} 
                                                checkboxInputClass={""} 
                                                checkboxClass={"!rounded-full"}
                                                divClass={"!items-start"}
                                                checkboxName={it?._id} 
                                                isChecked={it?.isDefault} 
                                                checkboxLabel={it?.name} 
                                                checkboxValue={it?._id} 
                                                getCheckboxValue={(val, event) => updateHandler(val,event.target.checked,"concern","")} 
                                                isDisabled={it?.disabled}
                                            />
                                            <div className="flex-shrink-0" data-tooltip-id={"info-tooltip-"+idx} data-tooltip-content={it?.description} data-tooltip-position-strategy="fixed">
                                            {
                                              (it?.description) && (
                                                <>
                                                <Tooltip id={'info-tooltip-'+idx} place="top" style={{
                                                  maxWidth: "400px",
                                                  wordBreak: "break-word",
                                                  overflowWrap: "break-word",
                                                  whiteSpace: "normal",
                                                  padding: "5px",
                                                  backgroundColor: "#333",
                                                  color: "#fff",
                                                  borderRadius: "4px",
                                                  zIndex:2
                                                }}></Tooltip>
                                                <i className="fa-light fa-circle-info" style={{color:"#14b8a6"}}></i>
                                                </>
                                              )
                                            }
                                            </div>
                                            </div>
                                          )
                                          })
                                        ):(
                                          (processing)?<Searching label={"Searching"}/>:<NoDataFound label={"No Data Found"}/>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div className="relative">
                                    <div className="flex items-center justify-start">
                                      <div className="text-sm font-bold text-slate-800">{t('potential_predictor_library')}</div>
                                    </div>
                                    <div className="space-y-4 mt-4">
                                      {
                                        predictors.length> 0 ? (
                                          predictors.map((itm,inx) => {
                                            return (
                                              <div className="flex items-center justify-between gap-2">
                                                <Checkbox 
                                                  checkboxLableClass={"text-slate-800"} 
                                                  checkboxInputClass={""} 
                                                  checkboxClass={"!rounded-full"}
                                                  divClass={"!items-start"}
                                                  checkboxName={itm?._id} 
                                                  isChecked={itm?.isDefault} 
                                                  checkboxLabel={itm?.name} 
                                                  checkboxValue={itm?._id} 
                                                  getCheckboxValue={(val, event) => updateHandler(val,event.target.checked,'predictors',"")} 
                                                  isDisabled={itm?.disabled}
                                                />
                                                <div className="flex-shrink-0" data-tooltip-id={"info-tooltip-"+inx} data-tooltip-content={itm?.description} data-tooltip-position-strategy="fixed">
                                                  {
                                                    (itm?.description) && (
                                                      <>
                                                      <Tooltip id={'info-tooltip-'+inx} place="top" style={{
                                                        maxWidth: "400px",
                                                        wordBreak: "break-word",
                                                        overflowWrap: "break-word",
                                                        whiteSpace: "normal",
                                                        padding: "5px",
                                                        backgroundColor: "#333",
                                                        color: "#fff",
                                                        borderRadius: "4px",
                                                        zIndex:2
                                                      }}></Tooltip>
                                                      <i className="fa-light fa-circle-info" style={{color:"#14b8a6"}}></i>
                                                      </>
                                                    )
                                                  }
                                                </div>
                                              </div>
                                            )
                                          })
                                        ):(
                                          (processing)?<Searching label={"Searching"}/>:<NoDataFound label={"No Data Found"}/>
                                        )
                                      }
                                    </div>
                                  </div>
                                </div>
                              )
                            ):('')
                          )
                        }
                      </div>                                           
                    </div>
                    <div className="relative flex justify-center mb-3 mt-6 gap-4">
                      {
                        (step && step === 1)?
                        <Button
                          buttonClasses='!bg-teal-500'
                          buttonLabelClasses='' 
                          buttonType={'button'} 
                          buttonIcon={(isContinue)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                          buttonIconPosition={'left'} 
                          buttonLabel={t('continue')}
                          functions={() => {
                            let hasEmptyString = companyTires.some(item => !item);
                            if (hasEmptyString && isEnabledTires === "1") {
                              toastr.error('There have empty input');
                            } else {
                              onManageTiers();
                            }                            
                          }}
                          buttonHasLink={false}
                          buttonDisabled={isContinue}
                        />:
                        <Button
                          buttonClasses='!bg-teal-500'
                          buttonLabelClasses='' 
                          buttonType={'button'} 
                          buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
                          buttonIconPosition={'left'} 
                          buttonLabel={t('confirm')}
                          functions={onSubmit}
                          buttonHasLink={false}
                          buttonDisabled={loading}
                        />
                      }                       
                      <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('cancel')} 
                        buttonClasses={'!bg-rose-500 !text-white border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}
                      />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
        {enabledConfirmationPopup && (
          <ConfirmationPopup
            isOpen={enabledConfirmationPopup}
            title={t("confirmation")}
            message={message}
            confirmButtonLabel={t("confirm")}
            cancelButtonLabel={t("cancel")}
            setIsOpen={(val) => setEnabledConfirmationPopup(val)}
            getActionValue={(obj) => {
              setActionValue(obj);
            }}
          />
        )}
    </>
  );
};

export default ManageTiersPopup;
