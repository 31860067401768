import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import Checkbox from "../../components/form/Checkbox";
import Textarea from "../../components/form/Textarea";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";

const ManagePlanFeature = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Plan Feature");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Plan Feature");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [fieldtype, setFieldtype] = useState("");
  const [headingtype,setHeadingtype] = useState('');
  const [shortDescription, setShortDescription] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const createAlias = (text) => {
    let result = text.toLowerCase().replace(/\s+/g, '-');
    setCode(result);
  }

  useEffect(() => {   
    // Plan  feature details
    const loadDetails = async () => {
      try {
        const result = await postData("plan-feature/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    setFieldtype(details?.fieldType);
    setCode(details?.code);   
    setShortDescription(details?.short_description); 
    setHeadingtype(details?.headingType);
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setCode(details?.sub_title);
      setFieldtype(details?.fieldType);
      setShortDescription(details?.short_description);
      setHeadingtype(details?.headingType);
    } else {
      setName("");
      setCode("");
      setFieldtype("");
      setShortDescription(""); 
      setHeadingtype("");
    }
    setIsCancel(false);
    navigate("/plan-features");
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "plan-feature/update";
        payload = {
          id: id,
          name: name,
          fieldType: fieldtype,
          shortDescription:shortDescription,
          headingType:headingtype
        };
      } else {
        path = "plan-feature/create";
        payload = {
          name: name,
          code: code,
          fieldType: fieldtype,
          shortDescription:shortDescription,
          headingType : headingtype
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/plan-features");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };
  
  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{id ? t("edit_plan_feature") : t("add_plan_feature")}</div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-4 px-4 sm:py-8 sm:px-6 flex flex-col">
              <div className="space-y-4 mt-4">
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    <Input 
                      label={t("name")} 
                      labelClasses={"!text-xs"} 
                      inputType={"text"} 
                      inputPlaceholder={t("enter_name")} 
                      inputValue={name} 
                      setInput={(value) => {
                        setName(value);
                        createAlias(value)
                      }} 
                    />
                  </div>
                  {
                    !id &&
                    <div className="relative w-full">
                      <Input 
                        label={t("code")} 
                        labelClasses={"!text-xs"} 
                        inputType={"text"} 
                        inputPlaceholder={t("enter_code")} 
                        inputValue={code} 
                        setInput={setCode} 
                      />
                    </div>
                  }
                </div>
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    <Select
                      label={t("field_type")}
                      labelClasses={"!text-xs"}
                      xPlacement={"bottomLeft"}
                      selectedValue={fieldtype}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: "", label: t("select_field_type"), value: "" },
                        { _id: "input", label: t("input"), value: "input" },
                        { _id: "checkbox", label: t("checkbox"), value: "checkbox" }
                      ]}
                      getSelectedValue={(e) => setFieldtype(e._id)}
                    />
                  </div>
                  <div className="relative w-full">
                    <Select
                      label={t("heading_type")}
                      labelClasses={"!text-xs"}
                      xPlacement={"bottomLeft"}
                      selectedValue={headingtype}
                      dropdownClass={"!w-60"}
                      dropdownData={[
                        { _id: "", label: t("select_heading_type"), value: "" },
                        { _id: "key-features", label: t("key_features"), value: "key-features" },
                        { _id: "number-of-plans", label: t("number_of_plans"), value: "number-of-plans" },
                        { _id: "additional-tools", label: t("additional_tools"), value: "additional-tools" }
                      ]}
                      getSelectedValue={(e) => setHeadingtype(e._id)}
                    />
                  </div>
                </div>
                <div className="relative w-full">
                  <Textarea 
                    label={t("tooltip_content")} 
                    labelClasses={"!text-xs"} 
                    inputValue={shortDescription} 
                    inputName={"enter_tooltip_content"} 
                    inputPlaceholder={t("enter_tooltip_content")} 
                    setTextarea={setShortDescription} 
                  />
                </div>
              </div>
            </div>

            <div className="py-3 sm:py-3 px-4 sm:px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagePlanFeature;
