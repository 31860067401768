import React from 'react';
const CompanyItem = ({data}) => {
  return (
    // <div className="w-full max-w-60 h-40 verflow-hidden grayscale">
    //   <img src={data.image} alt={data.name} className="w-full h-full object-contain"/>
    // </div> 
    <a href={data?.link} target='_blank' rel="noopener noreferrer" >
    <div className="relative w-full h-full bg-white border border-gray-200 rounded-lg shadow overflow-hidden">
      <div className="flex flex-col items-center py-5 px-4 space-y-4">
        <div className="w-16 h-16 overflow-hidden bg-slate-100 rounded-full relative border border-slate-300">
          <img className="w-full h-full object-contain" src={data?.image} alt="" />
        </div>
        <div className="text-center">
          <h5 className="text-sm lg:text-base xl:text-lg font-bold text-gray-900 capitalize !leading-none max-w-[144px] w-full line-clamp-3">{data?.name}</h5>
        </div>
      </div>
    </div>
  </a>
  );
};

export default CompanyItem;