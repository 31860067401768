import React from 'react';
import PageBanner from "../components/section/PageBanner";
import Testimonial from "../components/section/Shared/Testimonial";
import FreeTrialContent from "../components/section/FreeTrial/FreeTrialContent";
import AboutService from '../components/section/Aboutus/AboutService';

const Freetrial = ({type,segment}) => {  
  return (
    <>
      {/* <PageBanner sectionClass="!h-40"/> */}
      
      <FreeTrialContent type={type} segment={segment}/>
      <AboutService 
        headingOne={true} 
        headingTwo={true} 
        headingThree={true}
      />
      <Testimonial/>
    </>
  );
};

export default Freetrial;