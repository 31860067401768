import React, { useEffect,useState } from 'react';
import Accordian from "../../components/Common/Accordian";
import Searching from '../../components/Searching';
import NoDataFound from '../../components/NoDataFound';

import { postData } from '../../services/api';
import { useTranslation } from "react-i18next";


const FaqV2 = () => {
  const { i18n,t } = useTranslation();
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const role = localStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [faqs, setfaqs] = useState([]);

  const toggleAccordion = (id) => {
    setOpenAccordionId(openAccordionId === id ? null : id);
  };

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      const result = await postData("/faq/list", {
        sortQuery: sortQuery,
      });
      if (result.data) {
        setfaqs(
          result.data.map((item,index)=>{
            return {
              title : item?.question?.[i18n?.language],
              content : item?.answer?.[i18n?.language]
            }
          })
        );
        setLoading(false);
      }
    };
    loadList();
  }, []);

  const FaqData = {
    title:t('frequently_asked_questions'),
    Faq : faqs
    // Faq:[
    //   {
    //     title:"Is there a limit to the number of succession plans or candidates that can be managed in the system?",
    //     content:"SuccessionNow offers unlimited management of succession plans and candidates, catering to comprehensive succession needs across all organizational levels. This approach ensures that companies are not restricted to C-suite planning alone, enabling a more inclusive and thorough strategy.",
    //   },
    //   {
    //     title:"Don’t employees feel threatened when you talk about succession planning. Like the company is training someone to take their job?",
    //     content:"While employees might initially feel unsettled by succession planning, clear and open communication can alleviate these concerns. Emphasizing that succession planning is aimed at the company's stability and preparing employees for growth, rather than just job replacement, can change this perception. SuccessionNow provides businesses the ability to apply the centered approach, which includes the individual whos plans are being creating in the process, opening communication and employing empathy through their involvement.",
       
    //   },
    //   {
    //     title:"Can SuccessionNow handle succession planning for different levels of management and staff?",
    //     content:"SuccessionNow is adept at managing succession planning for various levels, from top management to entry-level positions. This flexibility is crucial to maintain operational continuity and ensure that all parts of the organization are considered in the planning process.",
    //   },
    //   {
    //     title:"How user-friendly is the interface for non-technical staff?",
    //     content:"The platform boasts a user-friendly interface, especially designed for non-technical staff. This ease of use stems from the extensive experience in succession planning by its co-founders, making it accessible and effective for anyone involved in succession planning.",
    //   },
    //   {
    //     title:"What kind of customer support and training does Succession Now provide?",
    //     content:"SuccessionNow provides extensive customer support and training. This includes online resources, responsive customer service, and detailed training modules, all aimed at maximizing the platform’s utility for clients.",
    //   },


    //   {
    //     title:"How does SuccessionNow ensure data security and privacy?",
    //     content:"SuccessionNow employs industry-standard data security practices, safeguarding confidential information. This approach is crucial to maintain trust and protect sensitive succession planning data.",
    //   },
    //   {
    //     title:"Can the software generate reports and analytics for succession planning?",
    //     content:"SuccessionNow streamlines report generation, offering user-friendly reports suitable for presentations to senior management or direct sharing for approvals. The focus is on simplifying reporting and decision-making processes in succession planning.",
    //   },
    //   {
    //     title:"How customizable are the succession planning templates and tools?",
    //     content:"The platform's templates and tools are highly customizable, allowing specific job criteria, qualifying questions, and weightings to be tailored to each company's unique needs. This flexibility ensures that succession plans are relevant and effective for every organization.",
       
    //   },
    //   {
    //     title:"Is SuccessionNow suitable for small to medium-sized businesses or just large corporations?",
    //     content:"SuccessionNow caters to businesses of all sizes, from small family-owned companies to large corporations. Its versatility makes it suitable for various contexts, including managing retirements in small firms or complex succession scenarios in larger organizations.",
    //   },
    //   {
    //     title:"How user-friendly is the interface for non-technical staff?",
    //     content:"The platform boasts a user-friendly interface, especially designed for non-technical staff. This ease of use stems from the extensive experience in succession planning by its co-founders, making it accessible and effective for anyone involved in succession planning.",
    //   },
    //   {
    //     title:"What is the pricing structure, and are there different tiers or packages available?",
    //     content:"Pricing is based on the number of employees, with a structure that accommodates different business sizes. For detailed pricing, refer to the platform's pricing calculator.",
    //   },
    //   {
    //     title:"How does SuccessionNow facilitate the transition process when a new leader is appointed?",
    //     content:"The platform offers comprehensive plans and leadership development features to guide the transition process, including emergency action plans for unexpected leadership changes.",
    //   },
    //   {
    //     title:"Is there a trial period to test the software before committing to a purchase?",
    //     content:"While there's no trial period, SuccessionNow offers a 30-day money-back guarantee, ensuring customer satisfaction with the software.",
    //   },
    //   {
    //     title:"How does SuccessionNow compare to other succession planning tools currently available on the market?",
    //     content:"SuccessionNow is set apart from traditional consultant-driven tools by providing a more cost-effective, in-house solution. It is designed for rapid updates and implementation, ensuring that succession plans for any position within the company remain current and useful. This approach offers significant advantages in both time and efficiency.",
    //   },
    // ]
  }
  return (
      <>          
          <section className="relative w-full py-10 lg:py-14 xl:py-16 2xl:py-20 bg-white ">
              <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
                  <div className="max-w-3xl w-full mx-auto text-center mb-5 xs:mb-10 xl:mb-20 lg:space-y-10 xl:space-y-16">
                        <h2 className="capitalize text-slate-950 text-2xl xs:text-3xl !leading-tight lg:text-4xl xl:text-5xl font-extrabold font-PlusJakartaSans" dangerouslySetInnerHTML={{__html:FaqData.title}}></h2>
                  </div>        
                  {(FaqData.Faq && FaqData.Faq.length>0)?
                  FaqData.Faq.map((item, index) => (
                    <Accordian
                      key={index}
                      id={index}
                      title={item.title}
                      isOpen={openAccordionId === index}
                      toggleAccordion={toggleAccordion}
                    >
                      <div className="text-sm md:text-base font-Inter text-slate-700">{item.content}</div>
                    </Accordian>
                  )):loading ? <Searching label={'Searching'} /> : <NoDataFound label={'No Data Found'}/> }               
              </div>
          </section>
      </>
  );
};

export default FaqV2;