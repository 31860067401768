import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({
  buttonTarget,
  buttonClasses, 
  buttonType, 
  buttonIcon, 
  buttonIconPosition, 
  buttonLabel, 
  buttonLabelClasses, 
  buttonHasLink,
  buttonLink,
  functions,
  buttonDisabled,
  tooltipContent='',
  ...props
}) => {
  
  return (
    <>
      { buttonHasLink?
        (
          <Link to={buttonLink}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 " + (buttonClasses)}
            data-tooltip-id="button-tooltip" 
            data-tooltip-content={tooltipContent}
            target={buttonTarget}
          >
            {buttonIconPosition === 'left' &&
              <i className={"fa-fw " + (buttonIcon)}></i>
            }
            {buttonLabel &&
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap " + (buttonLabelClasses)}>{buttonLabel}</div>
            }
            {buttonIconPosition === 'right' &&
              <i className={"fa-fw " + (buttonIcon)}></i>
            }
            {/* {
              props?.valueCount && 
              <span class="inline-flex items-center justify-center w-4 h-4 text-xs font-semibold text-black bg-slate-200 rounded-full">{props?.valueCount}</span>
            }             */}
          </Link>
        ):
        (
          <button 
            type={buttonType} 
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer " + (buttonClasses)} 
            onClick={functions}
            disabled={buttonDisabled}
            data-tooltip-id="button-tooltip" 
            data-tooltip-content={tooltipContent}
          >
            {buttonIconPosition === 'left' &&
              <i className={"fa-fw " + (buttonIcon)}></i>
            }
            {buttonLabel &&
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap " + (buttonLabelClasses)}>{buttonLabel}</div>
            }
            {buttonIconPosition === 'right' &&
              <i className={"fa-fw " + (buttonIcon)}></i>
            }
            {/* {
              props?.valueCount && 
              <span class="inline-flex items-center justify-center w-4 h-4 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">{props?.valueCount}</span>
            } */}
          </button>
        )
      }
    </>
  );
};

export default Button;