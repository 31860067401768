import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "animate.css";
import BannerSkeleton from "../../loader/BannerSkeleton";
import Image from "../../elements/Image";

import { postData } from "../../../services/api";
import { useTranslation } from "react-i18next";

const HomeBanner = () => {
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const { i18n,t } = useTranslation();

  useEffect(() => {
    // Banner List Data
    const loadBannerList = async () => {
      setLoading(true);
      try {
        const result = await postData("banner/list", {
          status: 1,
        });
        if (result.data) {
          setBanners(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };

    loadBannerList();
  }, []);  

  return (
    <>
      <section className="relative flex items-center w-full bg-slate-50 hero">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop={false}
            navigation={true}
            modules={[Navigation, Autoplay, EffectFade]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            effect={"fade"}
            className="w-full"
          >
            {loading ? (
              <SwiperSlide>
                <BannerSkeleton />
              </SwiperSlide>
              ):(
              Array.isArray(banners) && banners.length > 0 &&
              banners.map((item) => {
              return(
                <SwiperSlide key={item?._id}>
                  <div className="w-full h-screen overflow-hidden flex items-center pt-20 z-0">
                    <div className="w-full h-full overflow-hidden absolute top-0 left-0 -z-[1] bg-black">
                      <div className="w-full h-full opacity-50">
                        <Image 
                          src={item?.image} 
                          alt={''} 
                          width={'100%'}
                          height={'100%'} 
                          effect={'blur'} 
                          classes={'object-cover'}
                          id={""}
                        />
                      </div>
                    </div>
                    <div className="w-full max-w-[1400px] mx-auto px-5">
                      <div className="relative z-10 w-full max-w-[720px]">
                        <h4 className="sub-title text-3xl text-white font-extralight animated m-0">
                          {item?.sub_title}
                        </h4>
                        <h1 className="title block text-3xl xl:text-7xl text-white font-extrabold animated border-y-2 border-teal-300 py-4 my-4">
                          {item?.name}
                        </h1>
                        <div className="text text-2xl text-white font-thin mt-6 max-w-xl animated">
                          {item?.description}
                        </div>
                        <div className="flex mt-10">
                          <Link to={'/request-demo'} className="flex justify-center items-center gap-2 bg-dark-teal-400 hover:bg-dark-teal-600 text-white rounded-full text-base md:text-xl h-14 py-0 px-10 transition-all duration-200 cursor-pointer">{t('request_a_demo')}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
              }))}
          </Swiper> 
      </section>
    </>
  );
};

export default HomeBanner;
