import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import Button from "../../components/form/Button";
import Table from "../../components/elements/table/Table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder, stringToWord } from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";

const LeadTrackings = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | New Sale");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const flatPicker = useRef(null);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("ordering");
  const [sortValue, setSortValue] = useState(1);
  const [results, setResults] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount,setStartDataCount] = useState(0);
  const [endDataCount,setEndDataCount] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [actionValues, setActionValues] = useState({});
  const [checkboxValue, setCheckboxValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const extraData = [];

  const tableHeadData = [
    { _id: 1, width: 40, name: t("initial_contact_date"), value: "created_at", align: "left", isSort: false, isFilter: false },
    { _id: 2, width: 15, name: t("company_name"), value: "price", align: "left", isSort: false, isFilter: false },
    { _id: 3, width: 20, name: t("lead_source"), value: "created_at", align: "left", isSort: false, isFilter: false },
    { _id: 4, width: 15, name: t("responder"), value: "sales_id", align: "left", isSort: false, isFilter: false },
    { _id: 5, width: 10, name: t("status"), value: "salesperson_commission_amount", align: "left", isSort: false, isFilter: false },
    { _id: 6, width: 10, name: t("action"), value: "", align: "left", isSort: false, isFilter: false },
  ];

  useEffect(() => {
    const loadSalesteamList = async () => {
      setLoading(true);
      try {
        const result = await postData("salesteam/lead-list", {
          sortQuery: { ["created_at"]: -1 },
          limit: limit,
          offset: offset,
        });
        if (result.status === 200) {
          let totalResult = [];
          result.data.map((value, key) => {
            totalResult.push({
              _id: value?._id,
              rowData: [
                { _id: 1, width: 15, type: "text", data: value && value?.contact_date ? moment(new Date(value?.contact_date)).format("MMM Do YYYY") : "N/A", isColor: "" },
                { _id: 2, width: 15, type: "text", data: value?.company_name, isColor: "" },
                { _id: 3, width: 15, type: "text", data: stringToWord(value?.lead_source), isColor: "" },
                { _id: 4, width: 20, type: "text", data: value?.responder, isColor: "" },
                { _id: 5, width: 20, type: "status", statusLabel: value?.status === 1 ? "Sale" : value?.status === 2 ? t("no_sale") : t("pending"), statusType: value?.status === 1 ? "success" : value?.status === 2 ? "warning" : "info" },
                { _id: 6, width: 20, type: "action" },
              ],
            });
          });
          setTotalDataCount(result.count);
          setResults(totalResult);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadSalesteamList();
  }, [isUpdate || ""]);

  useEffect(() => {
    if (checkboxValue && checkboxValue.isEnabled) {
      const handelUpdateStatus = async () => {
        setIsUpdate(false);
        try {
          const payData = await postData("salesteam/update-status", {
            id: checkboxValue?.id,
            [checkboxValue?.code]: 1,
          });
          if (payData.data) {
            toastr.success(payData.message);
            setIsUpdate(true);
          } else {
            toastr.error(payData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      handelUpdateStatus();
    }
  }, [checkboxValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number((totalDataCount>0)?1:0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem)
  };

  const handleOnDragEnd = async (result) => {
    //console.log("result",result);
    const items = reorder(results, result.source.index, result.destination.index);
    setResults(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };
  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("lead_tracking")}</div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow mb-4">
          <Table
            tableData={results}
            tableHeadData={tableHeadData}
            totalRecord={results.length}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            editUrl={"/edit-lead-tracking"}
            isDeleteEnabled={false}
            isDraggable={false}
            getActionValue={(obj) => {
              setActionValues(obj);
            }}
            extraData={extraData}
            updateCheckboxHandler={(obj) => {
              setCheckboxValue(obj);
            }}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>
          <ReactPaginate breakLabel="..." breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>} nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" onPageChange={handlePageClick} pageRangeDisplayed={3} pageCount={noOfPage} containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2" activeClassName="hover:bg-teal-500 bg-teal-500 text-white" pageLinkClassName="flex justify-center items-center w-full h-full" previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>} pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" renderOnZeroPageCount={null} />
        </div>
      ):(
        <div className="flex justify-center sm:justify-between my-4">
          {(totalDataCount > 0) ? <div><p>{t('showing')}: {startDataCount} to {endDataCount} of {totalDataCount}</p></div>:''}  
        </div>
      )}
    </>
  );
};

export default LeadTrackings;
