import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Input from "../components/form/Input";
import Textarea from "../components/form/Textarea";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { postData } from "../services/api";

import { useTranslation } from "react-i18next";

const ManageOption = () => {
  const { heading_id, id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Option");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Option");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [headingDetails, setHeadingDetails] = useState({});
  const [type, setType] = useState("");
  const [headingName, setHeadingName] = useState("");

  const [name, setName] = useState("");
  const [point, setPoint] = useState();
  const [note, setNote] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  let pointArray = [];
  if (type === "concern") {
    pointArray = [
      { _id: 1, label: "1 " + t("point"), value: 1 },
      { _id: 2, label: "2 " + t("points"), value: 2 },
      { _id: 3, label: "3 " + t("points"), value: 3 },
      { _id: 4, label: "4 " + t("points"), value: 4 },
      { _id: 5, label: "5 " + t("points"), value: 5 },
    ];
  } else {
    pointArray = [
      { _id: 2.0, label: "2.0 " + t("point"), value: 2.0 },
      { _id: 2.5, label: "2.5 " + t("points"), value: 2.5 },
      { _id: 3.0, label: "3.0 " + t("points"), value: 3.0 },
      { _id: 3.5, label: "3.5 " + t("points"), value: 3.5 },
      { _id: 4.0, label: "4.0 " + t("points"), value: 4.0 },
      { _id: 4.5, label: "4.5 " + t("points"), value: 4.5 },
      { _id: 5.0, label: "5.0 " + t("points"), value: 5.0 },
    ];
  }

  useEffect(() => {
    // Position details
    const loadDetails = async () => {
      try {
        const result = await postData("option/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();

    const loadHeadingDetails = async () => {
      try {
        const result = await postData("heading/details", {
          id: heading_id,
        });
        if (result.data) {
          setHeadingDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadHeadingDetails();
  }, [id, heading_id]);

  useEffect(() => {
    if (details) {
      setName(details?.name);
      setPoint(details?.point);
      setNote(details?.note);
    }
    if (headingDetails) {
      setHeadingName(headingDetails?.name);
      setType(headingDetails?.type);
    }
  }, [details, headingDetails]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setPoint(details?.point);
    } else {
      setName("");
      setPoint("");
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "option/update";
        payload = {
          id: id,
          name: name,
          point: point,
          note: note,
          heading_id: heading_id,
          type: type,
        };
      } else {
        path = "option/create";
        payload = {
          name: name,
          point: point,
          note: note,
          heading_id: heading_id,
          type: type,
        };
      }

      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/options/" + heading_id);
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {id ? t("edit_option") : t("add_option")} {headingName ? " " + t("for") + " " + headingName : ""}
        </div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              {/* <div className="text-xl font-bold text-slate-900">Successor Information</div> */}
              <div className="space-y-4 mt-4">
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    <Input label={t("name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_name")} inputValue={name} setInput={setName} />
                  </div>
                  <div className="relative w-full">
                    <Select label={t("point")} labelClasses={"!text-xs"} xPlacement={"bottomLeft"} dropdownButtonClass={"!bg-white"} selectedValue={point} dropdownData={[{ _id: "", label: t("select_point"), value: "" }, ...pointArray]} getSelectedValue={(e) => setPoint(e.value)} />
                  </div>
                </div>
                {type && type === "predictors" && (
                  <div className="flex items-start gap-4">
                    <div className="relative w-full">
                      <Textarea label={t("note")} labelClasses={"!text-xs "} inputValue={note} inputClasses={"!h-10"} inputPlaceholder={t("enter_note")} setTextarea={setNote} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ManageOption;
