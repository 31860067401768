import React from 'react';


const ClientSkeleton = () => {
    return (
        <>
        <div className="h-16 flex gap-6">
            <div className='w-full h-full bg-slate-100 animate-skeleton rounded-xl'></div>
            <div className='w-full h-full bg-slate-100 animate-skeleton rounded-xl'></div>
            <div className='w-full h-full bg-slate-100 animate-skeleton rounded-xl'></div>
            <div className='w-full h-full bg-slate-100 animate-skeleton rounded-xl'></div>
            <div className='w-full h-full bg-slate-100 animate-skeleton rounded-xl'></div>
        </div>
        </>
    );
};

export default ClientSkeleton;