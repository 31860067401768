import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Search from "../../components/form/Search";
import Select from "../../components/form/Select";
import Table from "../../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import toastr from "toastr";
import { useParams } from "react-router-dom";
import TransactionReport from "../pdf/TransactionReport";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";

const Transactions = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Transactions");
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    // Transactions List
    const loadTransactions = async () => {
      setLoading(true);
      try {
        const result = await postData("transaction/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          status: status,
          subscription_id: id,
        });
        if (result.data) {
          // setTransactions(
          //   result.data.map((value, key) => {
          //     let details;
          //     if (value.isCommission === 1) {
          //       let staffCode = "";
          //       if (value?.staff_code && value?.staff_code === "sales-staff") {
          //         staffCode = t("sales_person");
          //       } else if (value?.staff_code && value?.staff_code === "consultant") {
          //         staffCode = t("consultant");
          //       } else if (value?.staff_code && value?.staff_code === "appointment-setter") {
          //         staffCode = t("appointment_setter");
          //       } else if (value?.staff_code && value?.staff_code === "sales-admin") {
          //         staffCode = t("sales_admin");
          //       }
          //       details = t("commission_has_been_given_to") + " " + value?.staff_name + " " + t("as_a") + " " + staffCode;
          //     }
          //     return {
          //       ...value,
          //       details: details,
          //     };
          //   })
          // );
          setSubscriptions(
            result.data.map((value, key) => {
              let details;
              if (value.isCommission === 1) {
                let staffCode = "";
                if (value?.staff_code && value?.staff_code === "sales-staff") {
                  staffCode = t("sales_person");
                } else if (value?.staff_code && value?.staff_code === "consultant") {
                  staffCode = t("consultant");
                } else if (value?.staff_code && value?.staff_code === "appointment-setter") {
                  staffCode = t("appointment_setter");
                } else if (value?.staff_code && value?.staff_code === "sales-admin") {
                  staffCode = t("sales_admin");
                }
                details = t("commission_has_been_given_to") + " " + value?.staff_name + " " + t("as_a") + " " + staffCode;
              }
              return {
                _id: value?._id,
                rowData: [
                  { _id: 1, width: 15, type: "text", data: value?.invoice_number },
                  { _id: 2, width: 40, type: "text", data: "$" + value?.price.toFixed(2) },
                  { _id: 3, width: 15, type: "text", data: value && value?.start_date ? moment(new Date(value?.start_date)).format("MMM Do YYYY") : "N/A" },
                  { _id: 4, width: 20, type: "text", data: value && value?.end_date ? moment(new Date(value?.end_date)).format("MMM Do YYYY") : "N/A" },
                  { _id: 5, width: 40, type: "text", data: value.isCommission === 1 ? details : value?.purpose ? value?.purpose : "N/A" },
                  { _id: 6, width: 40, type: "text", data: value?.email ? value?.email : "N/A" },
                  { _id: 7, width: 40, type: "text", data: value?.name ? value?.name : "N/A" },
                  { _id: 8, width: 15, type: "status", statusLabel: value?.status && value?.status === 1 ? t("active") : t("expire"), statusType: value?.status && value?.status === 1 ? "success" : "warning" },
                  // { _id:6, width:10, type:'action' }
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadTransactions();
  }, [keyword, limit, offset, sortQuery, status, id]);

  useEffect(() => {
    // Transactions Report List
    const loadReportData = async () => {
      setLoading(true);
      try {
        const result = await postData("transaction/list", {
          keyword: keyword,
          sortQuery: sortQuery,
          status: status,
          subscription_id: id,
        });
        if (result.data){
          setTransactions(
            result.data.map((value, key) => {
              let details;
              if (value.isCommission === 1) {
                let staffCode = "";
                if (value?.staff_code && value?.staff_code === "sales-staff") {
                  staffCode = t("sales_person");
                } else if (value?.staff_code && value?.staff_code === "consultant") {
                  staffCode = t("consultant");
                } else if (value?.staff_code && value?.staff_code === "appointment-setter") {
                  staffCode = t("appointment_setter");
                } else if (value?.staff_code && value?.staff_code === "sales-admin") {
                  staffCode = t("sales_admin");
                }
                details = t("commission_has_been_given_to") + " " + value?.staff_name + " " + t("as_a") + " " + staffCode;
              }
              return {
                ...value,
                details: details,
              };
            })
          );
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadReportData();
  }, [keyword, sortQuery, status, id]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
  };

  const header = [
    {
      name: t("invoice"),
    },
    {
      name: t("price"),
    },
    {
      name: t("start_date"),
    },
    {
      name: t("end_date"),
    },
    {
      name: t("purpose"),
    },
    {
      name: t("email"),
    },
    {
      name: t("name"),
    },
    {
      name: t("status"),
    },
  ];

  const tableHeadData = [
    { _id: 1, width: 15, name: t("invoice"), value: "invoice", align: "left", isSort: false, isFilter: false },
    { _id: 2, width: 40, name: t("price"), value: "price", align: "left", isSort: false, isFilter: false },
    { _id: 3, width: 15, name: t("start_date"), value: "start_date", align: "left", isSort: false, isFilter: false },
    { _id: 4, width: 20, name: t("end_date"), value: "end_date", align: "left", isSort: false, isFilter: false },
    { _id: 5, width: 40, name: t("purpose"), value: "purpose", align: "left", isSort: false, isFilter: false },
    { _id: 6, width: 40, name: t("email"), value: "email", align: "left", isSort: false, isFilter: false },
    { _id: 7, width: 40, name: t("name"), value: "name", align: "left", isSort: false, isFilter: false },
    { _id: 8, width: 15, name: t("status"), value: "status", align: "left", isSort: false, isFilter: false },
    // {_id:6, width:10, name:'Action',value:'', align:'right', isSort:false, isFilter:false},
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(subscriptions, result.source.index, result.destination.index);
    setSubscriptions(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("transactions")}</div>
        <div className="sm:ml-auto flex justify-end items-center">
          <button type={"button"} className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"} onClick={(e) => getDownload(e)}>
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink document={<TransactionReport records={transactions} header={header} user={user} />} fileName="tranasction.pdf">
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("export_data"))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-2">
        <div className="relative">
          <Select
            xPlacement={"bottomLeft"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        <div className="relative w-full sm:w-auto sm:ml-auto flex sm:justify-end flex-wrap sm:flex-nowrap items-center gap-1 sm:gap-3">
          <div className="relative w-full sm:w-auto">
            <Search placeholder={t("search")} setInput={setKeyword} />
          </div>
          <div className="relative">
            <Select
              xPlacement={"bottomRight"}
              dropdownButtonClass={"!bg-white"}
              transitionClass={"!w-auto"}
              selectedValue={status}
              dropdownData={[
                { _id: "", label: t("select_status") },
                { _id: 1, label: t("active"), value: 1 },
                { _id: 2, label: t("expire"), value: 2 },
              ]}
              getSelectedValue={(e) => setStatus(e.value)}
            />
          </div>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow">
          <Table tableData={subscriptions} tableHeadData={tableHeadData} totalRecord={totalDataCount} loading={loading} getSortValue={setSortValue} getSortKey={setSortKey} detailsUrl={"/transactions"} isDraggable={false} />
        </div>
      </DragDropContext>
      {noOfPage > 1 && (
        <div className="flex justify-center sm:justify-end my-4">
          <ReactPaginate breakLabel="..." breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>} nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" onPageChange={handlePageClick} pageRangeDisplayed={3} pageCount={noOfPage} containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2" activeClassName="hover:bg-teal-500 bg-teal-500 text-white" pageLinkClassName="flex justify-center items-center w-full h-full" previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>} pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200" renderOnZeroPageCount={null} />
        </div>
      )}
    </>
  );
};

export default Transactions;
