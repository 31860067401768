import React, { useState,useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import FreeAuthHeader from '../../shared/v2/FreeAuthHeader';
import FreeAuthFooter from '../../shared/v2/FreeAuthFooter';
import { postData } from '../../services/api';

const FreeAuthLayout = () => {
  const [settings, setSettings] = useState({});
  useEffect(() => {
    async function settingsData() {
      try {
        const result = await postData("setting/details", {});
        if (result.data) {
          setSettings(result.data);
        } else {
          console.log("settings list message", result.message);
        }
      } catch (error) {
        console.log("Error settings list catch", error.message);
      }
    }
    settingsData();
  }, []);

  return (
    <>
    <FreeAuthHeader settings={settings}/>
    <Outlet/>
    <FreeAuthFooter settings={settings}/>
    </>
  );
};

export default FreeAuthLayout;