import React, { useEffect, useState } from 'react';
import Button from "../../form/Button";
import { useLocation } from 'react-router-dom';
import BannerSkeleton from "../../loader/BannerSkeleton";

import { postData } from "../../../services/api";
import { useNavigate } from 'react-router-dom';
import Image from "../../elements/Image";
import { useRef } from 'react';
import { classNames } from "../../../helper/classNames";
import { useTranslation } from "react-i18next";

const HomeAboutUs = () => {
  const { i18n,t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [aboutData, setAboutData] = useState({});
  const aboutUsDiv = useRef(null);
  let location = useLocation();

  useEffect(() => {
    const loadBannerList = async () => {
      setLoading(true);
      try {
        const result = await postData("/about-us/details", {
          status: 1,
        });
        if (result.data) {
          setAboutData(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadBannerList();
  }, []);

  const scrollToBottom = () => {
    aboutUsDiv.current.scrollIntoView({
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    if (location.hash === '#aboutUsDiv' && aboutUsDiv) {
      setTimeout(() => {
        scrollToBottom()
      }, 500);
    }
    if (location.pathname.includes('/about-us/')) {
      console.log(location.pathname);
    }

  }, [location, aboutUsDiv]) 

  return (
    <>
      <section className={classNames("relative w-full bg-white", location.pathname.includes('/about-us/') ?"!pb-16 py-10 xl:p-16":"py-20 xl:p-32")} ref={aboutUsDiv}>
        <div className="w-full max-w-[1400px] mx-auto px-5">
          <div className="relative">
            <h2 className={"mb-6"}>
              <span className="block xl:text-5xl text-3xl font-bold text-slate-700">{t('about_us')}</span>
              <span className="inline-block text-sm uppercase text-slate-400 font-normal tracking-widest border-t-2 border-teal-300 py-1 my-1">Succession Now</span>
            </h2>
          </div>
          {loading ? (
            <BannerSkeleton />
          ) : (
            <>
              {location.pathname.includes('/about-us/') ? (
                <>
                  <div className="lg:flex lg:flex-wrap xl:flex xl:flex-wrap -mx-5">
                    <div className="relative px-5 w-full lg:w-3/5 mx-auto">
                      {
                        aboutData?.description &&
                        <div className="text-slate-500 font-light space-y-4 whitespace-pre-line">
                          {/* <p>{aboutData?.description?.[i18n?.language]}</p> */}
                        </div>
                      }                      
                    </div>
                    <div className="relative px-5 w-full mt-5 lg:mt-0 lg:w-2/5 mx-auto">
                      <div className="w-full h-full rounded-3xl overflow-hidden">
                        <Image
                          src={aboutData?.image}
                          alt={''}
                          width={'100%'}
                          height={'100%'}
                          effect={'blur'}
                          classes={'object-cover'}
                          id={""}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="lg:flex lg:flex-wrap xl:flex xl:flex-wrap -mx-5">
                    <div className="relative px-5 w-full lg:w-1/2 xl:w-1/2">
                      <div className="text-slate-500 font-light space-y-4 whitespace-pre-line">
                        <p>{aboutData?.description?.[i18n?.language]}</p>
                      </div>
                      {/* <div className="flex mt-10">
                      <Button
                        buttonClasses={'!px-8 rounded-full hover:bg-slate-500'}
                        buttonLabel={'Read More'} 
                        buttonLabelClasses={''} 
                        buttonHasLink={false}
                        functions={()=>{
                          navigate('/about-us')
                        }}
                      />
                    </div> */}
                    </div>
                    <div className="relative px-5 w-full mt-5 lg:w-1/2 xl:w-1/2">
                      <div className="w-full h-full rounded-3xl overflow-hidden">
                        <Image
                          src={aboutData?.image}
                          alt={''}
                          width={'100%'}
                          height={'100%'}
                          effect={'blur'}
                          classes={'object-cover'}
                          id={""}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )
          }

        </div>
      </section>
    </>
  );
};

export default HomeAboutUs;