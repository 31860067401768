import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import Checkbox from "../components/form/Checkbox";
import Textarea from "../components/form/Textarea";
import toastr from "toastr";
import { postData } from "../services/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ManageEmergencyReplacementPlan = () => {
  setTitle("Succession Now | Emergency/Interim Replacement Policy & Plan");
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [involuntaryTermination, setInvoluntaryTermination] = useState("");
  const [voluntaryTermination, setVoluntaryTermination] = useState("");
  const [voluntaryTerminationOptions, setVoluntaryTerminationOptions] = useState([{ title: "", description: "" }]);
  const [actionPlan, setActionPlan] = useState([
    {
      title: "",
      subTitle: [
        {
          responsibility: "",
          responsible_party: "",
        },
      ],
    },
  ]);
  const [externalRecruitment, setExternalRecruitment] = useState("");
  const [externalRecruitmentOptions, setExternalRecruitmentOptions] = useState([
    {
      title: "",
      isResponsibility: false,
      subTitle: [
        {
          responsibility: "",
          responsible_party: "",
        },
      ],
    },
  ]);
  const [guidelines, setGuidelines] = useState([""]);
  const [details, setDetails] = useState({});
  const [id, setId] = useState("");
  const [isManage, setIsManage] = useState(false);

  const addRow = async () => {
    setVoluntaryTerminationOptions([...voluntaryTerminationOptions, { title: "", description: "" }]);
  };
  const removeRow = async (position) => {
    voluntaryTerminationOptions.splice(position, 1);
    setVoluntaryTerminationOptions(
      voluntaryTerminationOptions.map((it, idx) => {
        return it;
      })
    );
  };
  const handelUpdate = async (value, field, position) => {
    voluntaryTerminationOptions[position][field] = value;
    setVoluntaryTerminationOptions(
      voluntaryTerminationOptions.map((it, idx) => {
        if (idx === position) {
          return {
            ...it,
            [field]: value,
          };
        } else {
          return it;
        }
      })
    );
  };

  const addActionRow = async () => {
    setActionPlan([
      ...actionPlan,
      {
        title: "",
        subTitle: [
          {
            responsibility: "",
            responsible_party: "",
          },
        ],
      },
    ]);
  };

  const removeActionRow = async (index) => {
    actionPlan.splice(index, 1);
    setActionPlan(
      actionPlan.map((it, idx) => {
        return it;
      })
    );
  };

  const handelActionUpdate = async (value, field, index) => {
    actionPlan[index][field] = value;
    setActionPlan(
      actionPlan.map((it, idx) => {
        if (idx === index) {
          return {
            ...it,
            [field]: value,
          };
        } else {
          return it;
        }
      })
    );
  };

  const addSubActionRow = async (index) => {
    setActionPlan(
      actionPlan.map((itm, idx) => {
        if (idx === index) {
          return {
            ...itm,
            subTitle: [
              ...itm?.subTitle,
              {
                responsibility: "",
                responsible_party: "",
              },
            ],
          };
        } else {
          return itm;
        }
      })
    );
  };

  const removeSubActionRow = async (index, key) => {
    actionPlan[index].subTitle.splice(key, 1);
    setActionPlan(
      actionPlan.map((it, idx) => {
        if (idx === index) {
          return {
            ...it,
            subTitle: [...it?.subTitle],
          };
        } else {
          return it;
        }
      })
    );
  };

  const handelSubActionUpdate = async (index, value, field, key) => {
    actionPlan[index].subTitle[field] = value;
    setActionPlan(
      actionPlan.map((it, idx) => {
        if (idx === index) {
          let sub = it.subTitle.map((i, k) => {
            if (k === key) {
              return {
                ...i,
                [field]: value,
              };
            } else {
              return i;
            }
          });
          return {
            ...it,
            subTitle: sub,
          };
        } else {
          return it;
        }
      })
    );
  };

  const addExternalRecruitmentRow = async () => {
    setExternalRecruitmentOptions([
      ...externalRecruitmentOptions,
      {
        title: "",
        subTitle: [
          {
            responsibility: "",
            responsible_party: "",
          },
        ],
      },
    ]);
  };

  const removeExternalRecruitmentRow = async (index) => {
    externalRecruitmentOptions.splice(index, 1);
    setExternalRecruitmentOptions(
      externalRecruitmentOptions.map((it, idx) => {
        return it;
      })
    );
  };

  const handelExternalRecruitmentUpdate = async (value, field, index) => {
    externalRecruitmentOptions[index][field] = value;
    setExternalRecruitmentOptions(
      externalRecruitmentOptions.map((it, idx) => {
        if (idx === index) {
          return {
            ...it,
            [field]: value,
          };
        } else {
          return it;
        }
      })
    );
  };

  const addSubExternalRecruitmentRow = async (index) => {
    setExternalRecruitmentOptions(
      externalRecruitmentOptions.map((itm, idx) => {
        if (idx === index) {
          return {
            ...itm,
            subTitle: [
              ...itm?.subTitle,
              {
                responsibility: "",
                responsible_party: "",
              },
            ],
          };
        } else {
          return itm;
        }
      })
    );
  };

  const removeSubExternalRecruitmentRow = async (index, key) => {
    externalRecruitmentOptions[index].subTitle.splice(key, 1);
    setExternalRecruitmentOptions(
      externalRecruitmentOptions.map((it, idx) => {
        if (idx === index) {
          return {
            ...it,
            subTitle: [...it?.subTitle],
          };
        } else {
          return it;
        }
      })
    );
  };

  const handelSubExternalRecruitmentUpdate = async (index, value, field, key) => {
    externalRecruitmentOptions[index].subTitle[field] = value;
    setExternalRecruitmentOptions(
      externalRecruitmentOptions.map((it, idx) => {
        if (idx === index) {
          let sub = it.subTitle.map((i, k) => {
            if (k === key) {
              return {
                ...i,
                [field]: value,
              };
            } else {
              return i;
            }
          });
          return {
            ...it,
            subTitle: sub,
          };
        } else {
          return it;
        }
      })
    );
  };

  const handelCheckbox = async (index, event) => {
    setExternalRecruitmentOptions(
      externalRecruitmentOptions.map((it, idx) => {
        if (idx === index) {
          return {
            ...it,
            isResponsibility: event.target.checked,
          };
        } else {
          return it;
        }
      })
    );
  };

  const addGuidelinesRow = async () => {
    setGuidelines([...guidelines, ""]);
  };

  const removeGuidelinesRow = async (index) => {
    guidelines.splice(index, 1);
    setGuidelines(
      guidelines.map((it) => {
        return it;
      })
    );
  };

  const handelGuidelines = async (value, index) => {
    guidelines[index] = value;
    setGuidelines(
      guidelines.map((it, idx) => {
        if (idx === index) {
          return value;
        } else {
          return it;
        }
      })
    );
  };

  useEffect(() => {
    const detailsFun = async () => {
      try {
        const result = await postData("emergency-replacement/details", {});
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    detailsFun();
  }, []);

  useEffect(() => {
    if (details) {
      setId(details?._id);
      setInvoluntaryTermination(details?.involuntary_termination);
      setVoluntaryTermination(details?.voluntary_termination);
      setVoluntaryTerminationOptions(details?.voluntary_ermination_options);
      setActionPlan(details?.action_plan);
      setExternalRecruitment(details?.external_recruitment);
      setExternalRecruitmentOptions(details?.external_recruitment_options);
      setGuidelines(details?.guidelines);
    }
  }, [details]);

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "emergency-replacement/update";
        payload = {
          id: id,
          involuntary_termination: involuntaryTermination,
          voluntary_termination: voluntaryTermination,
          voluntary_ermination_options: voluntaryTerminationOptions,
          action_plan: actionPlan,
          external_recruitment: externalRecruitment,
          external_recruitment_options: externalRecruitmentOptions,
          guidelines: guidelines,
        };
      } else {
        path = "emergency-replacement/create";
        payload = {
          involuntary_termination: involuntaryTermination,
          voluntary_termination: voluntaryTermination,
          voluntary_ermination_options: voluntaryTerminationOptions,
          action_plan: actionPlan,
          external_recruitment: externalRecruitment,
          external_recruitment_options: externalRecruitmentOptions,
          guidelines: guidelines,
        };
      }

      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/emergency-replacement-plan");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("emergency_interim_replacement_policy_&_plan")}</div>
      </div>
      <div className="relative grid grid-cols-4 gap-4">
        <div className="col-span-4">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200 h-full">
            <div className="py-4 sm:py-8 px-3 sm:px-6 flex flex-col">
              <div className="space-y-4 mt-4">
                <div className="relative">
                  {/* border-b border-gray-400 border-dashed pb-4 */}
                  <Textarea label={t("involuntary_termination")} labelClasses={"!text-xs"} inputValue={involuntaryTermination} inputPlaceholder={t("enter_details")} setTextarea={setInvoluntaryTermination} />
                </div>
                <div className="relative">
                  <Textarea label={t("voluntary_termination")} labelClasses={"!text-xs"} inputValue={voluntaryTermination} inputPlaceholder={t("enter_details")} setTextarea={setVoluntaryTermination} />
                </div>
                {voluntaryTerminationOptions &&
                  voluntaryTerminationOptions.map((row, key) => (
                    <div className="grid sm:grid-cols-2 gap-4 relative pr-8 sm:pr-10">
                      <div className="w-full">
                        <Input label={""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_title")} inputValue={row?.title} setInput={(val) => handelUpdate(val, "title", key)} />
                      </div>
                      <div className="w-full">
                        <Textarea label={""} labelClasses={"!text-xs"} inputValue={row?.description} inputPlaceholder={t("enter_details")} setTextarea={(val) => handelUpdate(val, "description", key)} />
                      </div>
                      {key === 0 ? <Button buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 absolute top-0 right-0"} buttonType={"button"} functions={(e) => addRow()} buttonHasLink={false} /> : <Button buttonIcon={"fa-light fa-minus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500 absolute top-0 right-0"} buttonType={"button"} functions={(e) => removeRow(key)} buttonHasLink={false} />}
                    </div>
                  ))}
                {actionPlan &&
                  actionPlan.map((item, index) => (
                    <>
                      <div className="grid sm:grid-cols-2 gap-4 relative pr-8 sm:pr-10">
                        <div className="w-full">
                          <Input label={index === 0 ? t("action_plan") : ""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_title")} inputValue={item?.title} setInput={(val) => handelActionUpdate(val, "title", index)} />
                        </div>
                        {index === 0 ? <Button buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 absolute top-0 right-0"} buttonType={"button"} functions={(e) => addActionRow()} buttonHasLink={false} /> : <Button buttonIcon={"fa-light fa-minus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500 absolute top-0 right-0"} buttonType={"button"} functions={(e) => removeActionRow(index)} buttonHasLink={false} />}
                      </div>
                      {item.subTitle &&
                        item.subTitle.length > 0 &&
                        item.subTitle.map((itm, indx) => {
                          return (
                            <div className="grid sm:grid-cols-2 gap-4 relative pr-8 sm:pr-10">
                              <div className="w-full">
                                <Textarea label={""} labelClasses={"!text-xs"} inputValue={itm?.responsibility} inputPlaceholder={t("enter_responsibility")} setTextarea={(val) => handelSubActionUpdate(index, val, "responsibility", indx)} />
                              </div>
                              <div className="w-full">
                                <Textarea label={""} labelClasses={"!text-xs"} inputValue={itm?.responsible_party} inputPlaceholder={t("enter_responsible_party")} setTextarea={(val) => handelSubActionUpdate(index, val, "responsible_party", indx)} />
                              </div>
                              {indx === 0 ? <Button buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 absolute top-0 right-0"} buttonType={"button"} functions={(e) => addSubActionRow(index)} buttonHasLink={false} /> : <Button buttonIcon={"fa-light fa-minus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500 absolute top-0 right-0"} buttonType={"button"} functions={(e) => removeSubActionRow(index, indx)} buttonHasLink={false} />}
                            </div>
                          );
                        })}
                    </>
                  ))}
                <div className="relative">
                  <Textarea label={"FORM: External Recruitment Plan – President/CEO"} labelClasses={"!text-xs"} inputValue={externalRecruitment} inputPlaceholder={t("enter_details")} setTextarea={setExternalRecruitment} />
                </div>
                {externalRecruitmentOptions &&
                  externalRecruitmentOptions.map((item, index) => (
                    <>
                      <div className="flex items-start gap-4">
                        <div className="w-full">
                          <Input label={""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_title")} inputValue={item?.title} setInput={(val) => handelExternalRecruitmentUpdate(val, "title", index)} />
                        </div>
                        <Checkbox
                          checkboxLabel={""}
                          checkboxLableClass={""}
                          isChecked={item?.isResponsibility}
                          checkboxValue={index}
                          getCheckboxValue={(code, event) => {
                            handelCheckbox(code, event);
                          }}
                        />
                        {index === 0 ? <Button buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0"} buttonType={"button"} functions={(e) => addExternalRecruitmentRow()} buttonHasLink={false} /> : <Button buttonIcon={"fa-light fa-minus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500"} buttonType={"button"} functions={(e) => removeExternalRecruitmentRow(index)} buttonHasLink={false} />}
                      </div>
                      {item?.isResponsibility &&
                        item.subTitle &&
                        item.subTitle.length > 0 &&
                        item.subTitle.map((itm, indx) => {
                          return (
                            <div className="grid sm:grid-cols-2 gap-4 relative pr-8 sm:pr-10">
                              <div className="w-full">
                                <Textarea label={""} labelClasses={"!text-xs"} inputValue={itm?.responsibility} inputPlaceholder={t("enter_responsibility")} setTextarea={(val) => handelSubExternalRecruitmentUpdate(index, val, "responsibility", indx)} />
                              </div>
                              <div className="w-full">
                                <Textarea label={""} labelClasses={"!text-xs"} inputValue={itm?.responsible_party} inputPlaceholder={t("enter_responsible_party")} setTextarea={(val) => handelSubExternalRecruitmentUpdate(index, val, "responsible_party", indx)} />
                              </div>
                              {indx === 0 ? <Button buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 absolute top-0 right-0"} buttonType={"button"} functions={(e) => addSubExternalRecruitmentRow(index)} buttonHasLink={false} /> : <Button buttonIcon={"fa-light fa-minus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500 absolute top-0 right-0"} buttonType={"button"} functions={(e) => removeSubExternalRecruitmentRow(index, indx)} buttonHasLink={false} />}
                            </div>
                          );
                        })}
                    </>
                  ))}
                {guidelines &&
                  guidelines.map((itm, ind) => (
                    <div className="flex items-start gap-4 relative pr-8 sm:pr-10">
                      <div className="w-full relative">
                        <Textarea label={ind === 0 ? t("guidelines_for_selecting_a_search_firm") : ""} labelClasses={"!text-xs"} inputValue={itm} inputPlaceholder={t("enter_details")} setTextarea={(val) => handelGuidelines(val, ind)} />
                      </div>
                      {ind === 0 ? <Button buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 absolute top-0 right-0"} buttonType={"button"} functions={(e) => addGuidelinesRow()} buttonHasLink={false} /> : <Button buttonIcon={"fa-light fa-minus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500 absolute top-0 right-0"} buttonType={"button"} functions={(e) => removeGuidelinesRow(ind)} buttonHasLink={false} />}
                    </div>
                  ))}
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonClasses={""} buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={() => onSubmit()} buttonHasLink={false} buttonDisabled={isManage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageEmergencyReplacementPlan;
