import React, { useEffect, useState } from "react";
import Button from "../components/form/Button";
import NotificationDropdown from "./NotificationDropdown";
import UserDropdown from "./UserDropdown";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../services/api";
import { authLogout } from "../store/authSlice";
import TermsPopup from "../components/popup/TermsPopup";
import ProfilePopup from "../components/popup/ProfilePopup";
import HelpPopup from "../components/popup/HelpPopup";
import { useNavigate } from "react-router-dom";
import Select from "../components/form/Select";
import toastr from "toastr";
import { Tooltip } from 'react-tooltip';

import { useTranslation } from "react-i18next";
import { languages } from "../helpers";

const DefaultHeader = ({ isMenuOpen, setIsMenuOpen }) => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const loginAs = localStorage.getItem("loginAs");
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enabledTermsPopup, setEnabledTermsPopup] = useState(false);
  const [enabledProfilePopup, setEnabledProfilePopup] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [currentLang, setCurrentLang] = useState("");
  const path = window.location.pathname.replace(/[/]/g, "").replace(/\s/g,'');
  const [enabledHelpPopup,setEnabledHelpPopup] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);

  // const handleWindowSizeChange = () => {
  //   setWidth(window.innerWidth);
  // }

  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowSizeChange);
  //   return () => {
  //     window.removeEventListener('resize', handleWindowSizeChange);
  //   }
  // }, []);

  // useEffect(() => {
  //   if(width <= 768){
  //     toastr.error(t('mobile_device_manage'));
  //   }
  // }, []);

  const checkValueInArrayObject = (arr, value) => {
    if (Array.isArray(arr)) {
      return arr.some((obj) => Object.values(obj).includes(value));
    } else {
      return false; // Return false if arr is not an array
    }
  };

  useEffect(() => {
    if (user?.role_details) {
      if (checkValueInArrayObject(user?.role_details, role)) {
        //console.log('1.1');
      } else {
        //console.log('1.2');
        dispatch(authLogout());
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loginAs");
        localStorage.removeItem("role");
        localStorage.removeItem("isTrial");

        localStorage.removeItem("reminderId");
        localStorage.removeItem("reminderDate");
        localStorage.removeItem("reminderFiled");
        return navigate("/");
      }
    }
  }, [user, role]);

  useEffect(() => {
    if (localStorage.getItem("accessToken") && role !== "master-admin" && role !== "basic-administrator" && (!loginAs || user?.isInvitedEmployee === 2)) {
      dispatch(async () => {
        try {
          const results = await postData("subscription/check", {
            sortQuery: { created_at: -1 },
            status: 3,
          });
          if (results.data && !results.data.trans_details) {
            if (role === "customer-admin" && results.data.payment_status && results.data.payment_status === 3) {
              localStorage.setItem("isTrial", 1);
            } else {
              localStorage.removeItem("isTrial");
              const results2 = await postData("check-promo-user", {
                sortQuery: { created_at: -1 },
              });
              if (results2.data && results2.data.promo_code && results2.data.isPaid === 1) {
                try {
                  const results3 = await postData("check-promocode", {
                    promo_code: results2.data.promo_code,
                  });
                  if (results3.status && results3.status === 200) {
                    return navigate("/dashboard");
                  } else {
                    return navigate("/buy-plan");
                    //return navigate("/plans");
                  }
                } catch (error) {
                  console.log(error.message);
                }
              } else {
                //return navigate("/plans");
                return navigate("/buy-plan");
              }
            }
          } else if (results.data && results.data.trans_details) {
            if (results.data.trans_details.status && results.data.trans_details.status !== 1) {
              dispatch(authLogout());
              return navigate("/signin");
            }
            if (role === "customer-admin") {
              if (results.data.payment_status && results.data.payment_status === 3) {
                localStorage.setItem("isTrial", 1);
              } else {
                localStorage.removeItem("isTrial");
              }
              try {
                const result = await postData("check/accecpt-terms", {});
                if (result.status && result.status === 400) {
                  setEnabledTermsPopup(true);
                } else {
                  try {
                    const result2 = await postData("profile/details", {});
                    if (result2.data && (!result2.data.designation || !result2.data.retirement_window)) {
                      setProfileData(result2.data);
                      setEnabledProfilePopup(true);
                    }
                  } catch (err) {
                    console.log(err.message);
                  }
                }
              } catch (err) {
                console.log(err.message);
              }
            } else if (role === "customer-user" || role === "customer-subadmin") {
              try {
                const result2 = await postData("profile/details", {});
                if (result2.data && !result2.data.retirement_window) {
                  setProfileData(result2.data);
                  setEnabledProfilePopup(true);
                }
              } catch (err) {
                console.log(err.message);
              }
            } else if (role === "customer-boardmember") {
              try {
                const result2 = await postData("profile/details", {});
                if (result2.data) {
                  if(path !== 'change-password'){
                    return navigate("/boardroom");
                  }
                }
              } catch (err) {
                console.log(err.message);
              }
            } else if (role === "customer-executive") {
              try {
                const result2 = await postData("profile/details", {});
                if (result2.data) {
                  if(path !== 'change-password'){
                    return navigate("/add-executive");
                  }
                }
              } catch (err) {
                console.log(err.message);
              }
            }
          }
        } catch (err) {
          console.log(err.message);
        }
      });
    }
  }, [dispatch, window.location.pathname]);

  const handleLanguageChange = (e) => {
    const newLang = e.value;
    i18n.changeLanguage(newLang);
    sessionStorage.setItem("sessionLang", newLang);
  };

  useEffect(() => {
    const langs = languages();
    const filterValue = langs.filter((item) => item?.value === i18n?.language);
    if (filterValue && filterValue.length > 0) {
      setCurrentLang(filterValue[0]?._id);
    }
  }, [i18n?.language]);

  return (
    <>
      <header className="sticky top-0 h-16 min-h-[64px] z-50 w-full flex items-center px-2 sm:px-6 bg-white shadow gap-1 sm:gap-4">
        {loginAs && (
          <div className="relative">
            <Button buttonType={""} buttonIcon={"fa-solid fa-chevron-left"} buttonIconPosition={"left"} buttonClasses={"px-0 w-10 !bg-white !text-slate-600 hover:!bg-slate-200"} buttonLink={"/login-as?back=true"} buttonHasLink={true} />
          </div>
        )}
        <div className="relative flex-shrink-0">
          <Button buttonType={"button"} buttonIcon={"fa-solid fa-bars"} buttonIconPosition={"left"} buttonClasses={"px-0 w-10 !bg-slate-200 !text-slate-600 hover:!bg-slate-300 hover:!text-slate-900"} functions={menuToggle} />
        </div>
        {user && role && (window.location.pathname === "/board-members" || window.location.pathname === "/board-member/planning" || window.location.pathname === "/board-member/readiness" || window.location.pathname === "/boardstrength") && (role === "customer-admin" || role === "customer-user") ? (
          <div className="relative flex-shrink line-clamp-2">
            <div className="w-full text-sm sm:text-base lg:text-lg font-bold text-slate-800 leading-tight">{t("potential_successors_for_chairman_of_the_board")}</div>
          </div>
        ) : (
          user &&
          role &&
          window.location.pathname !== "/profiles" &&
          window.location.pathname !== "/dashboard" &&
          window.location.pathname !== "/boardroom" &&
          (role === "customer-admin" || role === "customer-user") && (
            <div className="relative flex-shrink line-clamp-2">
              <div className="w-full text-sm sm:text-base lg:text-lg font-bold text-slate-800 leading-tight">
                {t("portential_successors_for")} {user?.title}
              </div>
            </div>
          )
        )}
        <div className="relative ml-auto flex justify-end items-center sm:gap-3 flex-shrink-0">
          {
            (role && role !== "master-admin") && 
            <div className="relative px-1 border-x border-slate-200">
              <Button
                buttonLabel={t('help')}
                buttonIcon={"fa-light fa-circle-info"}
                buttonIconPosition={"right"}
                buttonClasses={"!bg-teal-500 !text-white"}
                buttonLabelClasses={"!text-white"}
                functions={() => { setEnabledHelpPopup(true)}}
              />
            </div>              
          }          
          {/* <div className="relative px-1 border-x border-slate-200">
            <NotificationDropdown xPlacement={'right'} />
          </div> */}
          <div className="relative sm:px-3 border-r border-slate-200">
            <Select xPlacement={"bottomRight"} transitionClass={"!w-full lg:!w-auto"} dropdownClass={"!w-full lg:!w-24"} dropdownButtonClass={"!bg-white border-none"} dropdownButtonLabelClass={""} selectedValue={currentLang} dropdownData={languages()} getSelectedValue={handleLanguageChange} />
          </div>
          <div className="relative">
            <UserDropdown xPlacement={"bottomRight"} />
          </div>
        </div>
      </header>
      {enabledTermsPopup && <TermsPopup isOpen={enabledTermsPopup} setIsOpen={(val) => setEnabledTermsPopup(val)}></TermsPopup>}
      {enabledProfilePopup && <ProfilePopup isOpen={enabledProfilePopup} setIsOpen={(val) => setEnabledProfilePopup(val)} data={profileData}></ProfilePopup>}
      {enabledHelpPopup && <HelpPopup isOpen={enabledHelpPopup} setIsOpen={(val) => setEnabledHelpPopup(val)}></HelpPopup>}
    </>
  );
};

export default DefaultHeader;
