import React, { useState, useEffect,Fragment } from "react";
import { Dialog, Transition } from '@headlessui/react';
import Button from "../form/Button";

import toastr from 'toastr';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postData } from "../../services/api";

import { useTranslation } from "react-i18next";

const CancelPopup = ({
    isOpen,
    currentId,
    setIsOpen = () => {},
    getActionValue = () => { },
}) => {
    const { i18n,t } = useTranslation();
    const navigate = useNavigate();
    const [isCancel,setIsCancel] = useState(false);
    const closeModal = () => { setIsOpen(false) } 

    const cancelSubscription = async (e) => {
        e.preventDefault();
        setIsCancel(true);
        try {
          const verifyData = await postData("subscription/cancel", {
            id:currentId,
          });
          if (verifyData.data) {
            setIsCancel(false);
            getActionValue({
              button_type: 'cancel',
              row_id: currentId,
              current_status: 2
            })
            return navigate("/subscriptions");
          } else {
            toastr.error(verifyData.message);
            setIsCancel(false);
          }
        } catch (error) {
            toastr.error(error.message);
            setIsCancel(false);
        }
    }

    return (
        <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-xs transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('cancel_subscription')}
                    </Dialog.Title>
                    <div className="py-8 px-4">
                        <p className="text-sm text-gray-500 text-center">
                        {t('cancel_subscription_heading')}
                        </p>
                    </div>

                    <div className="flex items-center justify-center gap-4 mt-2">
                        <Button
                        buttonClasses='!bg-rose-500'
                        buttonLabelClasses='' 
                        buttonType={'button'} 
                        buttonIcon={(isCancel)?'fa-regular fa-spinner fa-spin':'fa-light fa-check'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('confirm')}
                        functions={cancelSubscription}
                        buttonHasLink={false}
                        buttonDisabled={isCancel}
                        />
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('cancel')} 
                        buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
        </>
    )
};
export default CancelPopup;