import React,{useEffect,useState} from 'react';
import { useTranslation } from "react-i18next";
import { postData } from '../../../services/api';

const Contactv2Banner = () => {
    const { i18n,t } = useTranslation();
    const [settings, setSettings] = useState({});
    const [description,setDescription] = useState('');

    const ContactData = {
        title:t('contact_us'),
        description:description,
    }

    useEffect(()=>{
        async function settingsData() {
            try {
                const result = await postData("setting/details", {});
                if (result.data) {
                setSettings(result.data);
                } else {
                console.log("settings list message", result.message);
                }
            } catch (error) {
                console.log("Error settings list catch", error.message);
            }
        }
        settingsData();
    },[])

    useEffect(()=>{
        if(settings && settings.about){
            setDescription(settings?.about?.[i18n?.language])
        }
    },[settings,i18n])

  return (
    <section className="relative w-full py-10 lg:py-14 xl:py-16 2xl:py-20 bg-white mt-24">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
            <div className="max-w-md w-full mx-auto text-center mb-10 lg:mb-20 space-y-5">
                  <h2 className="capitalize text-slate-950 text-2xl xs:text-3xl !leading-tight lg:text-4xl xl:text-5xl  font-extrabold font-PlusJakartaSans" dangerouslySetInnerHTML={{__html:ContactData.title}}></h2>
                  <div className="text-sm xs:text-base text-slate-400 font-PlusJakartaSans" dangerouslySetInnerHTML={{__html:ContactData.description}}></div>
            </div>
            <div className="max-w-3xl relative mx-auto w-full">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                    <div className="relative">
                        <div className="flex items-start gap-5">
                            <div className="text-xl w-14 h-14 rounded-full bg-slate-100 text-dark-teal-500 flex items-center justify-center flex-shrink-0">
                                <i className="fa-solid fa-phone"></i>
                            </div>
                            <div className="relative flex-shrink">
                                <div className="text-lg xs:text-xl lg:text-2xl font-bold font-PlusJakartaSans text-slate-950">{t('call_us')}</div>
                                <div className="text-lg xs:text-xl lg:text-xl font-PlusJakartaSans tracking-tighter text-slate-400">{settings?.phone}</div>
                            </div>
                        </div>
                    </div>
                    <div className="relative">
                        <div className="flex items-start gap-5">
                            <div className="text-xl w-14 h-14 rounded-full bg-slate-100 text-dark-teal-500 flex items-center justify-center flex-shrink-0">
                                <i className="fa-solid fa-envelope"></i>
                            </div>
                            <div className="relative flex-shrink">
                                <div className="text-lg xs:text-xl lg:text-2xl font-bold font-PlusJakartaSans text-slate-950">{t('email_us')}</div>
                                <div className="text-lg xs:text-xl lg:text-2xl font-PlusJakartaSans tracking-tighter text-slate-400">{settings?.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
    </section>
  );
};

export default Contactv2Banner;