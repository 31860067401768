import React from "react";
import Tbody from "./Tbody";
import Thead from "./Thead";
import { Droppable } from "react-beautiful-dnd";

const Table = ({ tableData, tableHeadData, tdClasses, totalRecord, loading, getSortValue = () => {}, getSortKey = () => {}, editUrl, isStatusUpdate, isDeleteEnabled, isDraggable, getActionValue = () => {}, isUser, detailsUrl, othersAction = () => {}, loginAs, extraData, updateCheckboxHandler = () => {} }) => {
  return (
    <>
      <div className="w-full scroll-smooth scrollbar overflow-auto">
        {isDraggable ? (
          <table className="min-w-max w-full table-auto">
            <Thead tableHeadData={tableHeadData} getSortValue={getSortValue} getSortKey={getSortKey} />
            <Droppable droppableId={"table-body"}>
              {(provided, snapshot) => (
                <Tbody
                  tableData={tableData}
                  totalRecord={totalRecord}
                  loading={loading}
                  editUrl={editUrl}
                  isStatusUpdate={isStatusUpdate}
                  isDeleteEnabled={isDeleteEnabled}
                  isDraggable={isDraggable}
                  dropableRef={provided.innerRef}
                  droppableProps={provided.droppableProps}
                  getActionValue={(obj) => {
                    getActionValue(obj);
                  }}
                  isUser={isUser}
                  detailsUrl={detailsUrl}
                  othersAction={othersAction}
                  loginAs={loginAs}
                  extraData={extraData}
                  updateCheckboxHandler={updateCheckboxHandler}
                />
              )}
            </Droppable>
          </table>
        ) : (
          <table className="min-w-max w-full table-auto">
            <Thead tableHeadData={tableHeadData} getSortValue={getSortValue} getSortKey={getSortKey} />
            <Tbody tableData={tableData} totalRecord={totalRecord} tdClasses={tdClasses} loading={loading} editUrl={editUrl} isStatusUpdate={isStatusUpdate} isDeleteEnabled={isDeleteEnabled} isDraggable={isDraggable} getActionValue={(obj) => getActionValue(obj)} isUser={isUser} detailsUrl={detailsUrl} othersAction={othersAction} loginAs={loginAs} extraData={extraData} updateCheckboxHandler={updateCheckboxHandler} />
          </table>
        )}
      </div>
    </>
  );
};

export default Table;
