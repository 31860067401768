import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Textarea from "../../components/form/Textarea";
import Image from "../../components/elements/Image";
import ButtonFile from "../../components/form/ButtonFile";
import Input from "../../components/form/Input";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { postData } from "../../services/api";
import { allowedImageExtensions, languages } from "../../helpers";
import { useTranslation } from "react-i18next";

const ManageLeadership = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Leaderships");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Leaderships");
  }
  const allLang = languages();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [details, setDetails] = useState({});
  const [name, setName] = useState({});
  const [description, setDescription] = useState({});
  const [image, setImage] = useState("");
  const [showLogo, setShowLogo] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const result = await postData("leadership/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    setDescription(details?.description);
    setShowLogo(details?.image);
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setDescription(details.description);
    } else {
      setName("");
      setDescription("");
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in name) {
      if (Object.hasOwnProperty.call(name, key)) {
        formData.append("name[" + key + "]", name[key]);
      }
    }
    for (const key2 in description) {
      if (Object.hasOwnProperty.call(description, key2)) {
        formData.append("description[" + key2 + "]", description[key2]);
      }
    }
    // formData.append("name", name);
    // formData.append("description", description);
    formData.append("image", image);
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "leadership/update";
        formData.append("id", id);
        payload = formData;
      } else {
        path = "leadership/create";
        payload = formData;
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/leaderships");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid logo");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{id ? t("edit_leadership") : t("add_leadership")}</div>
      </div>
      <form>
        <div className="relative grid sm:grid-cols-12 gap-4">
          <div className="sm:col-span-4 lg:col-span-3 space-y-4">
            <div className="relative bg-white rounded shadow">
              <div className="py-6 px-6 flex flex-col space-y-4 relative">
                <div className="w-full h-32 lg:h-52 overflow-hidden rounded-md">
                  <Image src={showLogo} alt={"Admin"} width={"100%"} height={"100%"} effect={"blur"} classes={"object-contain"} id={"image1"} />
                </div>
              </div>
              <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_image")} buttonClasses={"!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"} functions={onImgChange} accepts={"image/*"} />
              </div>
            </div>
          </div>
          <div className="sm:col-span-8 lg:col-span-9">
            <div className="relative bg-white rounded shadow divide-y divide-slate-200 py-4 px-4">
              <ul className="flex flex-wrap text-sm font-medium text-center border-b-4 border-slate-200 gap-4" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
                {allLang &&
                  allLang.length > 0 &&
                  allLang.map((item, index) => (
                    <li className="" role="presentation" key={index}>
                      <button className={"inline-block py-2 border-b-4 rounded-t-lg transition-all duration-200 -mb-1 " + (index === activeTab ? "text-teal-500 hover:text-teal-500 border-teal-500" : "text-slate-400 border-slate-200 hover:text-slate-500 hover:border-slate-300")} id={"tab-" + item?.value} data-tabs-target={"#" + item?.value} type="button" role="tab" aria-controls={item?.value} aria-selected="false" onClick={() => setActiveTab(index)}>
                        {item?.label}
                      </button>
                    </li>
                  ))}
              </ul>
              <div id="default-tab-content">
                {allLang &&
                  allLang.length > 0 &&
                  allLang.map((item, index) => (
                    <div className={index === activeTab ? "show" : "hidden"} id={item?.value} role="tabpanel" aria-labelledby={"tab-" + item?.value} key={index}>
                      <div className="py-4 flex flex-col">
                        <div className="space-y-4">
                          <div className="flex items-start gap-4">
                            <div className="relative w-full">
                              <Input
                                label={t("name")}
                                labelClasses={"!text-xs"}
                                inputType={"text"}
                                inputPlaceholder={t("enter_name")}
                                inputValue={name?.[item?.value]}
                                setInput={(val) =>
                                  setName({
                                    ...name,
                                    [item?.value]: val,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="flex items-start gap-4">
                            <div className="relative w-full">
                              <Textarea
                                label={t("description")}
                                labelClasses={"!text-xs"}
                                inputValue={description?.[item?.value]}
                                inputPlaceholder={t("enter_description")}
                                setTextarea={(val) =>
                                  setDescription({
                                    ...description,
                                    [item?.value]: val,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="py-3 border-t border-slate-200">
                <div className="flex items-center justify-end gap-4">
                  <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                  <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ManageLeadership;
