import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import SecFaq from "../components/Faq/SecFaq";

import { useTranslation } from "react-i18next";

const Faq = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | FAQ");
  const role = localStorage.getItem("role"); 
  const { i18n,t } = useTranslation();

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t('faq')}</div>
        <div className="">
          {role && role === "master-admin" && (
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-plus"}
              buttonIconPosition={"left"}
              buttonLabel={t('add_faq')}
              buttonHasLink={true}
              buttonLink={"/add-faq"}
              buttonClasses={"!text-sm"}
            />
          )}
        </div>
      </div>

      <div className="mx-auto px-5 bg-white rounded-lg">
        <SecFaq/>        
      </div>
    </>
  );
};

export default Faq;
