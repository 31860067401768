import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";
import Button from "../../form/Button";
import { postData } from "../../../services/api";
import moment from "moment";
import { API_URL } from "../../../config/host";
import { useTranslation } from "react-i18next";
import ResourceEnquiryPopup from "../../popup/ResourceEnquiryPopup";

const ResourcesContent = ({notId}) => {  
  const [blogData, setBlogData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const { i18n,t } = useTranslation();
  const [resourceEnquiryPopup,setResourceEnquiryPopup] = useState(false);

  useEffect(() => {
    const resourcesList = async () => {
      setLoading(true);
      try {
        const result = await postData("blog/list", {
          status: 1,
          limit: 9,
          offset: offset,
          notId:notId,
        });
        if (result.data) {
          // setBlogData(data=>[...data,...result.data]);
          setBlogData(result.data);
          setCount(result.count);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };

    resourcesList();
  }, [notId,offset]);
  
  return (
    <>
      <section className="relative w-full bg-slate-100 py-20 xl:px-16 xl:py-20 2xl:py-32 2xl:px-32">
        <div className="max-w-[1400px] mx-auto xl:px-5 px-5">
          <div className="relative">
            <h2 className="mb-6">
              <span className="block xl:text-5xl text-3xl font-bold text-slate-700">
                {" "}
                {t('resources')}
              </span>
              <span className="inline-block text-sm uppercase text-slate-400 font-normal tracking-widest border-t-2 border-teal-300 py-1 my-1">
                Succession Now
              </span>
            </h2>
          </div>
          <div className="grid lg:grid-cols-2 gap-8">
            {loading && blogData.length === 0 ? (
              <div>{t('loading')}</div>
            ) : (
              blogData.map((item, index) => {
                return (
                  <div
                    className="group relative bg-white rounded-lg overflow-hidden lg:flex transition-all !duration-300 hover:shadow-lg"
                    key={index}
                  >
                    <div className="lg:absolute lg:w-1/2 w-full lg:h-full h-48 top-0 left-0 overflow-hidden">
                      <Link to={"/resource-details/" + item.alias}>
                      <Image
                        src={item?.image}
                        alt={item.title}
                        width={"100%"}
                        height={"auto"}
                        effect={"blur"}
                        classes={
                          "object-contain !transition-all !duration-300 group-hover:scale-110"
                        }
                      />
                      </Link>
                    </div>
                    <div className="relative p-5 flex flex-col lg:w-1/2 ml-auto">
                      <Link
                        to={"/resource-details/" + item.alias}
                        className="text-xl font-bold text-slate-800 leading-none mb-2"
                      >
                        {item?.name}
                      </Link>
                      <div className="mb-4 text-sm font-light font-italic text-slate-400">
                        <Link to={"/resource-details/" + item.alias}>
                        {item && item?.created_at
                          ? moment(new Date(item?.created_at)).format(
                              "Do MMM YYYY"
                            )
                          : "N/A"}
                        </Link>
                      </div>
                      <div className="mb-8 text-sm font-light line-clamp-6">
                        <Link to={"/resource-details/" + item.alias}>
                        {item?.short_description}
                        </Link>
                      </div>
                      <div className="!mt-auto flex flex-wrap gap-2">
                        <Button
                          buttonClasses={
                            "!justify-start !bg-transparent !text-sm !text-teal-600 !px-0"
                            // !border-solid !border-2 !border-teal-600
                          }
                          buttonIcon={"fa-regular fa-arrow-right-long"}
                          buttonIconPosition={"right"}
                          buttonLabel={t('read_article')}
                          buttonLabelClasses={
                            "!text-xs uppercase !font-medium tracking-tighter"
                          }
                          buttonHasLink={true}
                          buttonLink={"/resource-details/" + item.alias}
                        />
                        {/* <Button
                          buttonClasses={
                            "!justify-start !bg-dark-teal-400 hover:!bg-dark-teal-600 !rounded-full !text-sm group-hover:gap-4"
                          }
                          buttonIcon={"fa-regular fa-envelope"}
                          buttonIconPosition={"right"}
                          buttonLabel={t('watch_the_demo')}
                          buttonLabelClasses={
                            "!text-xs uppercase !font-medium tracking-tighter"
                          }
                          buttonHasLink={false}
                          functions={()=> {
                            setResourceEnquiryPopup(true);

                          }}
                        /> */}
                      </div>                      
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {count > blogData.length ? (
            <div className="flex justify-center mt-10">
            <Button
              buttonDisabled = {isDisabled}
              buttonIcon={isDisabled?"fa-light fa-spinner fa-spin":"fa-regular fa-arrow-right-long"}
              buttonIconPosition={"right"}
              buttonLabel={t('load_more')}
              buttonHasLink={false}
              functions = {()=> {
                setOffset(offset => offset+9)
              }}
            />
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      {
        resourceEnquiryPopup && 
        <ResourceEnquiryPopup
          isOpen={resourceEnquiryPopup}
          setIsOpen={(val) => setResourceEnquiryPopup(val)}
          data={''}
          getActionValue={(obj) => {
            // setActionValue(obj);
            // setIsUpdate(true);
          }}
        ></ResourceEnquiryPopup>
      }
    </>
  );
};

export default ResourcesContent;
