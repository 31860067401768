import React from 'react';
import TableFilter from './TableFilter';
import TableSort from './TableSort';

const Th = ({
  name,
  width,
  value,
  nameAlign,
  isSort,
  isFilter,
  functions=() => {},
  getSortValue=() => {},
  ...props
}) => {

  const align = {
    left: "justify-start",
    right: "justify-end",
    center: "justify-center",
    "": "justify-start",
  }

  return (
    <>
      <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 first:rounded-tl-md last:rounded-tr-md" style={{minWidth:width+('%')}}>        
        <div className={"flex items-center w-full " + (align[nameAlign])}>
          <div className="text-[11px] uppercase font-normal text-slate-400">{name}</div>
          {(isSort || isFilter) &&
          <div className="ml-auto flex justify-end items-center" onClick={() => { functions(value)}}>
            {isSort &&
              <TableSort 
              sortState={'nostate'}
              getSortValue={getSortValue}
              />
            }
            {isFilter &&
              <TableFilter xPlacement={'right'}/>
            }
          </div>
          }
        </div>
      </th>
    </>
  );
};

export default Th;
