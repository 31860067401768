import React from 'react';
import PageBanner from "../components/section/PageBanner";
import RequestDemoContent from "../components/section/RequestDemo/RequestDemoContent";
import Testimonial from "../components/section/Shared/Testimonial";

const RequestDemo = () => {
  return (
    <>
      <PageBanner title={'Request Demo'}/>
      <RequestDemoContent/>
      <Testimonial />
    </>
  );
};

export default RequestDemo;