import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import Input from "../components/form/Input";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { postData } from "../services/api";

import { useTranslation } from "react-i18next";

const ManageLoyalty = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Loyalty");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Loyalty");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [isPass, setIsPass] = useState();
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const result = await postData("loyalty/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    if (details && Object.keys(details).length) {
      setIsPass(details.isPass ? 1 : 2);
    } else {
      setIsPass("");
    }
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setIsPass(details.isPass ? 1 : 2);
    } else {
      setName("");
      setIsPass("");
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "loyalty/update";
        payload = {
          id: id,
          name: name,
          isPass: isPass,
          type: 3,
        };
      } else {
        path = "loyalty/create";
        payload = {
          name: name,
          isPass: isPass,
          type: 3,
        };
      }

      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/loyalties");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{id ? t("edit_loyalty") : t("add_loyalty")}</div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              <div className="space-y-4 mt-4">
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    <Input label={t("name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_name")} inputValue={name} setInput={setName} />
                  </div>
                  <div className="relative w-full">
                    <Select
                      label={t("pass") + "/" + t("fail")}
                      labelClasses={"!text-xs"}
                      xPlacement={"bottomLeft"}
                      dropdownButtonClass={"!bg-white"}
                      selectedValue={isPass}
                      dropdownData={[
                        { _id: "", label: t("select_pass_fail"), value: "" },
                        ...[
                          { _id: 1, label: t("pass"), value: 1 },
                          { _id: 2, label: t("fail"), value: 2 },
                        ],
                      ]}
                      getSelectedValue={(e) => setIsPass(e.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageLoyalty;
