import React, { useState, useRef, useEffect } from 'react';
import { allowedImageExtensions } from "../../helpers";
import { API_URL } from '../../config/host';
import toastr from 'toastr';
import ButtonFile from '../../components/form/ButtonFile';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import Checkbox from '../../components/form/Checkbox';
import Image from '../../components/elements/Image';
import { postData } from '../../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import { setTitle } from "../../helpers/MetaTag";
import Searching from '../../components/Searching';
import NoDataFound from '../../components/NoDataFound';

import { useTranslation } from "react-i18next";

const ManageAdmin = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Admin");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Admin");
  }
  const { i18n,t } = useTranslation();
  const navigate = useNavigate()
  const editorRef = useRef(null);

  const [details, setDetails] = useState({})
  const [image, setImage] = useState('');
  const [isManage, setIsManage] = useState(false)
  const [showLogo, setShowLogo] = useState("");
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState(''); 
  const [email, setEmail] = useState("");
  const [phone,setPhone] = useState("");
  const [password,setPassword] = useState('');
  const [confirmPassword,setConfirmPassword] = useState('');
  const [commission,setCommission] = useState('');
  const [salesCommission,setSalesCommission] = useState();
  const [consultantCommission,setConsultantCommission] = useState();
  const [appointmentSetterCommission,setAppointmentSetterCommission] = useState();
  const [salesAdminCommission,setSalesAdminCommission] = useState();
  const [role,setRole] =  useState('');
  const [userRole,setUserRole] = useState([]);
  const [rolePermission,setRolePermission] = useState([]);
  const [processing,setProcessing] = useState(false);

  const staffCommissionArray = [
    {
      code: 'sales-staff',
      commission : salesCommission
    },
    {
      code: 'consultant',
      commission : consultantCommission
    },
    {
      code: 'appointment-setter',
      commission : appointmentSetterCommission
    },
    {
      code: 'sales-admin',
      commission : salesAdminCommission
    }
  ]

  useEffect(()=>{
    setCommission(staffCommissionArray)
  },[salesCommission,consultantCommission,appointmentSetterCommission,salesAdminCommission])

  useEffect(() => {
    // Plan details
    const loadDetails = async () => {
      try {
        const result = await postData("usertype/details", {
          id: id
        });
        if (result.data) {
          setDetails(result.data)
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(()=>{
    async function RolePermissionData() {
      setProcessing(true);
      try {
        const results = await postData("role/list", {
          sortQuery:{'ordering':1},
          status : 1,
          codes : ['master-admin','basic-administrator','customer-admin']
        });
        if (results.data) {
          setRolePermission(
            results.data.map((item, key) => {
              let matchCode = Object.keys(details).length > 0 && details?.role?.filter((itm) => itm === item?._id);
              if(matchCode && matchCode.length>0){
                return {
                  _id: item?._id,
                  title : item?.name,
                  code : item?.code,
                  isEnabled : true
                }  
              }else{ 
                return {
                  _id: item?._id,
                  title : item?.name,
                  code : item?.code,
                  isEnabled : false
                } 
              }           
            })
          )
          setProcessing(false);
        } else {
          setProcessing(false);
          console.log('role list message',results.message)
        }
      } catch (error) {
         console.log('Error role list catch',error.message)
         setProcessing(false);
      }
    }
    RolePermissionData();
  },[details])

  const updateHandler = (_id,event) => {
    if(event){
      setUserRole(userRole => [ ...userRole, _id ]);
      setRolePermission(
        rolePermission.map((item, key) => {
          if(item?._id === _id){
            return {
              ...item,
              isEnabled : true
            }
          }else{
            return item
          }
        })
      )
    }else{
      setUserRole(userPermission => userPermission.filter((item) => item !== _id));
      setRolePermission(
        rolePermission.map((item, key) => {
          if(item?._id === _id){
            return {
              ...item,
              isEnabled : false
            }
          }else{
            return item
          }
        })
      )
    }
  }

  

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid image");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0])
      }
    }
  };

  useEffect(() => {
    if(Object.keys(details).length > 0){
      setFirstName(details?.first_name);
      setLastName(details?.last_name);
      setEmail(details?.email);
      setCommission(details?.commission);
      setRole(details?.role);
      if(Array.isArray(details?.role)){
        setUserRole([...details?.role]);
      }else{
        setUserRole([details?.role]);
      }      
      setPhone(details?.phone);
      setShowLogo(details?.image)
    }       
  }, [details]);

  const onSubmit = async (e) => {
    setIsManage(true)
    e.preventDefault();
    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);       
    formData.append("commission",JSON.stringify(commission));
    formData.append("phone", phone);
    formData.append("image", image);
    try { 
      let payload; 
      let path;
      if(id){
        path = 'usertype/adminintrator-update';
        formData.append("id", id);
        for (const key_3 in userRole) {
          formData.append('roles[]', userRole[key_3]);
        }
        //formData.append("code", userRole);
        payload = formData
      }else{
        path = 'usertype/adminintrator-create';
        formData.append("email", email);
        formData.append("password", password);
        formData.append("confirm_password", confirmPassword);
        for (const key in userRole) {
          formData.append('roles[]', userRole[key]);
        }
        //formData.append("code", userRole);
        payload = formData
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message)
        navigate('/admins')
      } else {
        toastr.error(res.message)
      }
    } catch (err) {
      console.log(err.messege)
    }
    setIsManage(false)
  }

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold"> {(id) ? t('edit_admin') : t('add_admin')}</div>
      </div>
      <form>
        <div className="relative flex flex-col-reverse sm:grid grid-cols-4 gap-4">
          <div className="col-span-3">
            <div className="relative bg-white rounded shadow divide-y divide-slate-200">
              <div className="py-8 px-6 flex flex-col">
                <div className="space-y-4 mt-4">
                    <div className="grid sm:grid-cols-2 gap-4">
                      <div className="relative w-full">
                        <Input
                          label={t('first_name')}
                          labelClasses={"!text-xs"}
                          inputType={"input"}
                          inputPlaceholder={t('enter_first_name')}
                          inputValue={firstName}
                          setInput={setFirstName}
                        />
                      </div>
                      <div className="relative w-full">
                        <Input
                          label={t('last_name')}
                          labelClasses={"!text-xs"}
                          inputType={"input"}
                          inputPlaceholder={t('enter_last_name')}
                          inputValue={lastName}
                          setInput={setLastName}
                        />
                      </div>
                    </div>
                    <div className="grid sm:grid-cols-2 gap-4">
                      <div className="relative w-full">
                        <Input
                          label={t('work_email')}
                          labelClasses={"!text-xs"}
                          inputType={"email"}
                          inputPlaceholder={t('work_email_address')}
                          inputValue={email}
                          setInput={setEmail}
                          isDisabled={(id)?true:false}
                        />
                      </div>
                      <div className="relative w-full">
                        <Input
                          label={t('phone')}
                          labelClasses={"!text-xs"}
                          inputType={"number"}
                          inputPlaceholder={t('enter_phone')}
                          inputValue={phone}
                          setInput={setPhone}
                        />
                      </div>
                    </div>
                  <div className="grid sm:grid-cols-2 2xl:grid-cols-4 gap-4">
                      <div className="relative w-full">
                        <Input
                            label={t('sales_commission')}
                            labelClasses={"!text-xs"}
                            inputType={"number"}
                            inputPlaceholder={t('enter_sales_commission')}
                            inputValue={salesCommission}
                            setInput={setSalesCommission}
                        />
                      </div>                   
                      <div className="relative w-full">
                        <Input
                            label={t('consultant_commission')}
                            labelClasses={"!text-xs"}
                            inputType={"number"}
                            inputPlaceholder={t('enter_consultant_commission')}
                            inputValue={consultantCommission}
                            setInput={setConsultantCommission}
                        />
                      </div>
                      <div className="relative w-full">
                        <Input
                            label={t('appointment_setter_commission')}
                            labelClasses={"!text-xs"}
                            inputType={"number"}
                            inputPlaceholder={t('enter_appointment_setter_commission')}
                            inputValue={appointmentSetterCommission}
                            setInput={setAppointmentSetterCommission}
                        />
                      </div>
                      <div className="relative w-full">
                        <Input
                            label={t('sales_admin_commission')}
                            labelClasses={"!text-xs"}
                            inputType={"number"}
                            inputPlaceholder={t('enter_sales_admin_commission')}
                            inputValue={salesAdminCommission}
                            setInput={setSalesAdminCommission}
                        />
                      </div>
                      {/* <div className="relative w-full">
                        <Input
                          label={t('commission')}
                          labelClasses={"!text-xs"}
                          inputType={"number"}
                          inputPlaceholder={t('enter_commission')}
                          inputValue={commission}
                          setInput={setCommission}
                        />
                      </div> */}
                    </div>
                    {
                      rolePermission.length>0?( 
                      rolePermission.map((item,index) => (
                        <div className="relative w-full" key={index}>
                          <Checkbox
                            checkboxLableClass={''}
                            checkboxInputClass={''}
                            checkboxName={item?.code}
                            isChecked={item?.isEnabled}
                            checkboxLabel={item?.title}
                            checkboxValue={item?._id}
                            getCheckboxValue={(_id, event) => updateHandler(_id, event.target.checked)}
                          />          
                        </div>
                      ))
                      ):((processing)?<Searching label={t('searching')}/>:<NoDataFound label={t('no_data_found')}/>)
                    }
                    {
                      !id && 
                      <div className="grid sm:grid-cols-2 gap-4">
                        <div className="relative w-full">
                          <Input
                            label={"Password"}
                            labelClasses={"!text-xs"}
                            inputType={"password"}
                            inputPlaceholder={"Your Password"}
                            inputValue={password}
                            setInput={setPassword}
                          /> 
                        </div>
                        <div className="relative w-full">
                          <Input
                            label={"Confirm Password"}
                            labelClasses={"!text-xs"}
                            inputType={"password"}
                            inputPlaceholder={"Confirm your password"}
                            inputValue={confirmPassword}
                            setInput={setConfirmPassword}
                          />
                        </div>                    
                      </div>
                    }                  
                  </div>
              </div>
              <div className="py-3 px-3 border-t border-slate-200">
                <div className="flex items-center justify-end gap-4">
                  <Button
                    buttonClasses=''
                    buttonLabelClasses=''
                    buttonType={'button'}
                    buttonIcon={(isManage) ? 'fa-light fa-spinner fa-spin' : 'fa-light fa-check'}
                    buttonIconPosition={'left'}
                    buttonLabel={t('save')}
                    functions={onSubmit}
                    buttonHasLink={false}
                    buttonDisabled={isManage}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 space-y-4">
            <div className="relative bg-white rounded shadow">
              <div className="py-6 px-6 flex flex-col space-y-4 relative">
                <div className="w-full overflow-hidden rounded-md flex items-center h-full">
                  <ButtonFile
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-image"}
                    buttonIconPosition={"left"}
                    buttonLabel={t('upload_image')}
                    buttonClasses={"!h-full !w-full !absolute !opacity-0 !z-10 !top-0 !left-0"}
                    functions={onImgChange}
                    accepts={"image/*"}
                  />
                  <Image
                    src={showLogo}
                    alt={"Admin"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-fit"}
                    id={"image1"}
                  />
                </div>
              </div>
              <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                <ButtonFile
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-image"}
                  buttonIconPosition={"left"}
                  buttonLabel={t('upload_image')}
                  buttonClasses={"!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"}
                  functions={onImgChange}
                  accepts={"image/*"}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default ManageAdmin;
