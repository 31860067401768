import React from 'react';
import { setTitle } from "../helpers/MetaTag";
import PageBanner from "../components/section/PageBanner";
import NewContactForm from '../components/Contact/v2/NewContactForm';
import { useTranslation } from "react-i18next";
import Banner from "../components/section/Contactv2/Contactv2Banner";

const ContactV2 = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Contact Us");
  const { i18n,t } = useTranslation();
  return (
    <>
      {/* <PageBanner title={t('contact_us')}/> */}
      <Banner/>
      <NewContactForm />
    </>
  );
};

export default ContactV2;