import React, { useState } from 'react';

const TableSort = ({
    sortState = 'nostate', 
    getSortValue= () => {},
    ...props
}) => {
  const[sortType, setSortType] = useState(sortState);
  return (
    <>
      <button type="button" className="relative w-4 h-4 bg-transparent text-slate-200" onClick={() => {
        document.querySelectorAll('.sortTyps').forEach(opacity => {
          opacity.classList.remove('opacity-100');
          opacity.classList.add('opacity-25');
         });
        setSortType(sortType === 'ASC'? 'DESC':'ASC')
        getSortValue(sortType === 'ASC'? -1:1)
        }}>
        <span className={"sortTyps absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 " + (sortType === 'ASC'?'opacity-100':'opacity-25')}>
          <i className="fa-solid fa-fw fa-sort-up"></i>
        </span>
        <span className={"sortTyps absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 " + (sortType === 'DESC'?'opacity-100':'opacity-25')}>
          <i className="fa-solid fa-fw fa-sort-down"></i>
        </span>
      </button>
    </>
  );
};

export default TableSort;