import React,{ useState, useEffect,Fragment,useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../form/Button';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import axios from "axios";

import { postData } from '../../services/api';
import toastr from 'toastr';
import ApiCalendar from "react-google-calendar-api";
import { Client } from '@microsoft/microsoft-graph-client';
//import { AuthenticationProvider, MsalProvider } from '@microsoft/mgt-react';
import { InteractionType,PublicClientApplication  } from '@azure/msal-browser';

import { useTranslation } from "react-i18next";

const ReminderPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {}, 
  type,
}) => { 
  const { i18n,t } = useTranslation();
  const flatPicker = useRef(null);
  const role = localStorage.getItem("role");  
  const [name,setName] = useState('');
  const [date,setDate] = useState('');
  const [filed,setFiled] = useState('');
  const [loading,setLoading] = useState(false);
  const [isConnectGoogle,setIsConnectGoogle] = useState(2);
  const [events, setEvents] = useState([]);

  // const [accessToken, setAccessToken] = useState('');
  // const [startTime, setStartTime] = useState('');
  // const [endTime, setEndTime] = useState('');
  // const [reminderMinutesBeforeStart, setReminderMinutesBeforeStart] = useState('');

  let gapi = window.gapi;
  const config = {
    clientId: "889893139103-m7j23p0ill29ridmh7ogola48skavtqv.apps.googleusercontent.com",
    apiKey: "AIzaSyCO3kPKuzy5Zg5jv6ZHbqwvNgxQDytK2Ys",
    scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
    offline: true
  };

  // const msalConfig = {
  //   auth: {
  //     grantType :"client_credentials",
  //     clientId: '36442bcb-5a1c-45bc-a924-a7ce067e5ec5',
  //     clientSecret: 'NBb8Q~GuOrKyHrKusMcdrYO9TmfobUfP9jkJ.dv2',
  //     authority: 'https://login.microsoftonline.com/common',
  //     //authority: 'https://login.microsoftonline.com/f8cdef31-a31e-4b4a-93e4-5f571e91255a',
  //     redirectUri: 'http://localhost:3000/development-plan', // Redirect URI configured in Azure AD
  //     scopes: ['openid', 'profile','email', 'Calendars.ReadWrite','36442bcb-5a1c-45bc-a924-a7ce067e5ec5/.default']
  //   },
  //   system: {
  //     loggerOptions: {
  //       loggerCallback(loglevel, message, containsPii) {},
  //       piiLoggingEnabled: false,
  //       //logLevel: msal.LogLevel.Verbose,
  //     },
  //   },
  // };

  const requestBody = new URLSearchParams({
    client_id: '36442bcb-5a1c-45bc-a924-a7ce067e5ec5',
    scope: 'openid profile email Calendars.ReadWrite',
    grant_type: 'authorization_code',
    client_secret: 'NBb8Q~GuOrKyHrKusMcdrYO9TmfobUfP9jkJ.dv2',
    redirect_uri : 'http://localhost:3000/development-plan',
  });

  // const requestBody = new URLSearchParams({
  //   client_id: '36442bcb-5a1c-45bc-a924-a7ce067e5ec5',
  //   scope: ['openid', 'profile','email', 'Calendars.ReadWrite'],
  //   grant_type: 'client_credentials',
  //   client_secret: 'NBb8Q~GuOrKyHrKusMcdrYO9TmfobUfP9jkJ.dv2',
  //   redirect_uri: 'http://localhost:3000/development-plan',
  // });

  const apiCalendar = new ApiCalendar(config);
  //const msalInstance = new PublicClientApplication(msalConfig);  

  const handleItemClick = async (event, name) => {
    if (name === 'sign-in') {
      let res = await apiCalendar.handleAuthClick();
      if(res){
        const result = await postData("update-token", {
          _token : res?.access_token
        });
        if (result.data){
          setIsConnectGoogle(1);
          onSubmit();
        } else {
          console.log("message", result.message);
        }
      }
    } else if (name === 'sign-out') {
      apiCalendar.handleSignoutClick();
    }
  }  

  const closeModal = () =>{ setIsOpen(false) } 

  const onSubmit = async () => {
      try {
        setLoading(true);
        const result = await postData("successor/update", {
          id:data?._id,
          [filed]:date
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);    
          apiCalendar.createEventFromNow({
            summary: "Reminder for "+result?.data?.name,
            time : 480,
          }).then((result) => {
            console.log(result);
          }).catch((error) => {
            console.log(error);
          });
          getActionValue({ button_type : 'edit',row_id : data?._id,current_status : ''});
          setIsOpen(false)
          setLoading(false);
        } else {
          toastr.error(result.message);
          setLoading(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setLoading(false);
      }
  }

  const generateOutlookOauth2Url = async () => {
    try {
      const result = await postData("generate-outlook-oauth2-url", {});
      if (result.status && result.status === 200) {        
        window.open(result?.data);
      } else {
        toastr.error(result.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  }

  // const getToken = async (event) => {
  //   event.preventDefault();    
  //   try {
  //     await msalInstance.initialize();  
  //     const authResponse = await msalInstance.loginPopup();
  //     console.log('authResponse',authResponse)  
  //     const tokenResponse = await msalInstance.acquireTokenSilent({
  //       scope: ['openid', 'profile','email', 'Calendars.ReadWrite'] // Specify required scopes
  //     });
  //     console.log('tokenResponse',tokenResponse)
  //     setAccessToken(tokenResponse.accessToken);
  //     handleAddReminder(event)
  //   } catch (error) {
  //     console.error('Error obtaining access token:', error);
  //   }
  // }

  // console.log('accessToken',accessToken)

  // const handleAddReminder = async (event) => {
  //   event.preventDefault();
  //   try {      
  //     const response = await axios.post('https://login.microsoftonline.com/common/oauth2/v2.0/token', requestBody, {
  //       headers: {
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //     });
  //     // const response = await fetch('https://login.microsoftonline.com/common/oauth2/v2.0/token', {
  //     //   method: 'POST',
  //     //   mode: 'no-cors',
  //     //   headers: {          
  //     //     'Content-Type': 'application/x-www-form-urlencoded'
  //     //   }, 
  //     //   body: new URLSearchParams({
  //     //     client_id: '36442bcb-5a1c-45bc-a924-a7ce067e5ec5',
  //     //     scopes: ['openid', 'profile','email', 'Calendars.ReadWrite'],
  //     //     //scope: 'https://graph.microsoft.com/.default',
  //     //     grant_type: 'client_credentials',
  //     //     client_secret: 'NBb8Q~GuOrKyHrKusMcdrYO9TmfobUfP9jkJ.dv2',
  //     //     redirect_uri: 'http://localhost:3000/development-plan',
  //     //   })
  //     // });
      
  //     //const { access_token } = await response.json();
  //     console.log('response',response)

  //     const client = Client.initWithMiddleware({
  //       authProvider: (done) => {
  //         done(null, accessToken);
  //       }
  //     });

  //     const reminderTime = new Date(startTime);
  //     reminderTime.setMinutes(reminderTime.getMinutes() - parseInt(reminderMinutesBeforeStart));

  //     await client.api('/me/events').post({
  //       subject: 'Reminder test chinmoy',
  //       start: {
  //         dateTime: startTime,
  //         timeZone: 'UTC'
  //       },
  //       end: {
  //         dateTime: endTime,
  //         timeZone: 'UTC'
  //       },
  //       reminderMinutesBeforeStart: reminderMinutesBeforeStart,
  //       reminder: {
  //         minutesBeforeStart: reminderMinutesBeforeStart
  //       }
  //     });

  //     // Optionally, reset form fields or display success message
  //     setStartTime('');
  //     setEndTime('');
  //     setReminderMinutesBeforeStart('');

  //     alert('Reminder added successfully!');
  //   } catch (error) {
  //     console.error('Error adding reminder:', error);
  //     alert('Error adding reminder. Please try again.');
  //   }
  // };

  // const fetchCalendarEvents = async () => {
  //   try {
  //     const client = Client.initWithMiddleware({
  //       authProvider: new AuthenticationProvider({
  //         interactionType: InteractionType.Popup,
  //         clientId: '36442bcb-5a1c-45bc-a924-a7ce067e5ec5',
  //         scopes: ['User.Read', 'Calendars.Read']
  //       })
  //     });

  //     const response = await client.api('/me/calendar/events').get();
  //     setEvents(response.value);
  //   } catch (error) {
  //     console.error('Error fetching calendar events:', error);
  //   }
  // }  
  
  useEffect(() => {     
    setName(data?.name);
    setDate(new Date());
    setFiled(data?.filed)   
    localStorage.setItem("reminderId", data?._id);    
    localStorage.setItem("reminderFiled", data?.filed); 
  }, [data]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={()=>{}}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                    >
                    {t('manage_reminder')} 
                    </Dialog.Title>
                    <div className="relative mt-4">
                      <div className="relative mb-3">
                        <Flatpickr
                          ref={flatPicker}
                          className='w-full h-10 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-slate-600 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200'
                          placeholder={''}
                          value={date}
                          options={{ dateFormat: "Y-m-d",minDate:new Date()}}
                          onChange={(selectedDates, dateStr, instance) => {
                            setDate(dateStr)
                            localStorage.setItem("reminderDate", dateStr);
                          }}
                        />
                      </div>                                   
                    </div>
                    <div className="relative flex justify-center mb-3 mt-6 gap-4">
                        <Button
                          buttonClasses='!bg-teal-500'
                          buttonLabelClasses='' 
                          buttonType={'button'} 
                          buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-brands fa-google'} 
                          buttonIconPosition={'left'} 
                          buttonLabel={t('google')}
                          functions={(e) => 
                            handleItemClick(e, 'sign-in')
                            //onSubmit()
                          }
                          buttonHasLink={false}
                          buttonDisabled={loading}
                        />
                        <Button
                          buttonClasses='!bg-teal-500'
                          buttonLabelClasses='' 
                          buttonType={'button'} 
                          buttonIcon={(loading)?'fa-light fa-spinner fa-spin':'fa-light fa-calendar-days'} 
                          buttonIconPosition={'left'} 
                          buttonLabel={t('outlook')}
                          functions={(e) => 
                            generateOutlookOauth2Url()
                            //getToken(e)
                            //handleAddReminder(e)
                            //onSubmit()
                          }
                          buttonHasLink={false}
                        />
                        <Button 
                        buttonLabelClasses=''
                        buttonType={'button'} 
                        buttonIcon={'fa-light fa-times'} 
                        buttonIconPosition={'left'} 
                        buttonLabel={t('cancel')} 
                        buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'} 
                        functions={closeModal}
                        buttonHasLink={false}
                        />
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                </div>
            </div>
            </Dialog>
        </Transition>
    </>
  );
};

export default ReminderPopup;
