import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import Image from "../../components/elements/Image";
import ButtonFile from "../../components/form/ButtonFile";
import Textarea from "../../components/form/Textarea";
import toastr from "toastr";
import { useNavigate } from "react-router";

import { allowedImageExtensions, languages } from "../../helpers";
import { postData } from "../../services/api";

import { useTranslation } from "react-i18next";

const ManageAbout = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | About Us");
  const { i18n, t } = useTranslation();
  const allLang = languages();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [showLogo, setShowLogo] = useState("");
  const [description, setDescription] = useState({});
  const [subTitle, setSubTitle] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [id, setId] = useState("");
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Banner details
    const loadDetails = async () => {
      try {
        const result = await postData("about-us/details", {});
        if (result.data) {
          setDetails(result.data);
          setServices(
            result.data.ambition || [
              {
                title: "",
                icon: "",
                content: "",
              },
            ]
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, []);

  useEffect(() => {
    setName(details?.name);
    setSubTitle(details?.sub_title);
    //setBenefits(details?.description);
    setDescription(details?.description);
    setShowLogo(details?.image);
    setId(details?._id);
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (id) {
      setName(details?.name);
      setSubTitle(details?.sub_title);
      setDescription(details?.description);
    } else {
      setName("");
      setDescription("");
      setSubTitle("");
      setShowLogo("/no-data-found.jpg");
    }
    setIsCancel(false);
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("sub_title", subTitle);
    for (const key in description) {
      if (Object.hasOwnProperty.call(description, key)) {
        formData.append("description[" + key + "]", description[key]);
      }
    }
    formData.append("image", image);
    formData.append("ambition", services);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "about-us/update";
        formData.append("id", id);
        payload = formData;
      } else {
        path = "about-us/create";
        payload = formData;
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsManage(false);
        return navigate("/about");
      } else {
        toastr.error(result.message);
        setIsManage(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsManage(false);
    }
  };

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid image");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  const addRow = async () => {
    setServices((services) => [
      ...services,
      {
        title: "",
        icon: "",
        content: "",
      },
    ]);
  };
  const removeRow = async (index) => {
    setServices((services) => services.filter((item, i) => i !== index));
  };

  const handelUpdate = async (type, val, index) => {
    if (type === "title") {
      setServices((services) =>
        services.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              title: val,
            };
          } else {
            return item;
          }
        })
      );
    } else if (type === "icon") {
      setServices((services) =>
        services.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              icon: val,
            };
          } else {
            return item;
          }
        })
      );
    } else if (type === "content") {
      setServices((services) =>
        services.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              content: val,
            };
          } else {
            return item;
          }
        })
      );
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{id ? t("edit_about_us") : t("add_about_us")}</div>
      </div>
      <form>
        <div className="relative grid sm:grid-cols-12 gap-4">
          <div className="sm:col-span-4 lg:col-span-3 space-y-4">
            <div className="relative bg-white rounded shadow">
              <div className="py-6 px-6 flex flex-col space-y-4 relative">
                <div className="w-full h-32 lh:h-52 overflow-hidden rounded-md">
                  <Image src={showLogo} alt={"Admin"} width={"100%"} height={"100%"} effect={"blur"} classes={"object-contain"} id={"image1"} />
                </div>
              </div>
              <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_image")} buttonClasses={"!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"} functions={onImgChange} accepts={"image/*"} />
              </div>
            </div>
          </div>
          <div className="sm:col-span-8 lg:col-span-9">
            <div className="relative bg-white rounded shadow divide-y divide-slate-200">
              <div className="py-4 px-4 sm:py-6 sm:px-6 flex flex-col">
                <div className="space-y-4">
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div className="relative w-full">
                      <Input label={t("name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_name")} inputValue={name} setInput={setName} />
                    </div>
                    <div className="relative w-full">
                      <Input label={t("name")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_name")} inputValue={subTitle} setInput={setSubTitle} />
                    </div>
                  </div>
                  <ul className="flex flex-wrap text-sm font-medium text-center border-b-4 border-slate-200 gap-4" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
                    {allLang &&
                      allLang.length > 0 &&
                      allLang.map((item, index) => (
                        <li className="" role="presentation" key={index}>
                          <button className={"inline-block py-2 border-b-4 rounded-t-lg transition-all duration-200 -mb-1 " + (index === activeTab ? "text-teal-500 hover:text-teal-500 border-teal-500" : "text-slate-400 border-slate-200 hover:text-slate-500 hover:border-slate-300")} id={"tab-" + item?.value} data-tabs-target={"#" + item?.value} type="button" role="tab" aria-controls={item?.value} aria-selected="false" onClick={() => setActiveTab(index)}>
                            {item?.label}
                          </button>
                        </li>
                      ))}
                  </ul>
                  <div id="default-tab-content">
                    {allLang &&
                      allLang.length > 0 &&
                      allLang.map((item, index) => (
                        <div className={index === activeTab ? "show" : "hidden"} id={item?.value} role="tabpanel" aria-labelledby={"tab-" + item?.value} key={index}>
                          <div className="flex items-start gap-4">
                            <div className="relative w-full">
                              <Textarea
                                label={t("description")}
                                labelClasses={"!text-xs"}
                                inputValue={description?.[item?.value]}
                                inputPlaceholder={t("enter_description")}
                                setTextarea={(val) =>
                                  setDescription({
                                    ...description,
                                    [item?.value]: val,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <hr />
                  <h5 className="!bg-white !text-slate-500 rounded-none w-full">Services</h5>
                  <div className="divide-y divide-slate-200">
                  {services.map((item, key) => {
                    return (
                      <div className="py-2 space-y-4" key={key}>
                        {/* title&&icon */}
                        <div className="grid sm:grid-cols-2 gap-4 relative pr-8 sm:pr-10">
                          <div className="relative w-full">
                            <Input label={key === 0 ? t("title") : ""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_title")} inputValue={item?.title} setInput={(val) => handelUpdate("title", val, key)} />
                          </div>
                          <div className="relative w-full">
                            <Input label={key === 0 ? t("icon") : ""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_icon")} inputValue={item?.icon} setInput={(val) => handelUpdate("icon", val, key)} />
                          </div>
                          {key === 0 ? <Button buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 absolute top-0 right-0"} buttonType={"button"} functions={(e) => addRow()} buttonHasLink={false} /> : <Button buttonIcon={"fa-light fa-minus"} buttonIconPosition={"left"} buttonClasses={"!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500 absolute top-0 right-0"} buttonType={"button"} functions={(e) => removeRow(key)} buttonHasLink={false} />}
                        </div>
                        <div className="relative pr-8 sm:pr-10">
                          <div className="relative w-full">
                            <Input label={key === 0 ? t("texts") : ""} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_text")} inputValue={item?.content} setInput={(val) => handelUpdate("content", val, key)} />
                          </div>
                          
                        </div>
                      </div>
                    );
                  })}
                  </div>
                </div>
              </div>

              <div className="py-3 px-3 border-t border-slate-200">
                <div className="flex items-center justify-end gap-4">
                  <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                  <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("save")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ManageAbout;
