import React,{useEffect, useState} from 'react';
import {setTitle} from '../helpers/MetaTag';
import Button from '../components/form/Button';
import Input from '../components/form/Input';
import Select from '../components/form/Select';
import Textarea from '../components/form/Textarea';
import Searching from '../components/Searching';
import NoDataFound from '../components/NoDataFound';
import toastr from 'toastr';
import { useSelector } from 'react-redux';

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";

const Successor = () => {
  setTitle('Succession Now | Roster of Potential Successors');
  const { i18n,t } = useTranslation();
  const user = useSelector(state => state.auth?.user);

  const [profileData,setProfileData] = useState({}); 
  const [industry,setIndustry] = useState('');
  const [company_name,setCompanyName] = useState('');
  const [positions,setPositions] = useState([]);
  const [retirementWindow,setRetirementWindow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [successors,setSuccessors] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0); 
  const [isUpdate,setIsUpdate] = useState(false);
  const [isCancel,setIsCancel] = useState(false);

  const [replacementPosition,setReplacementPosition] = useState('');
  const [currentHolder,setCurrentHolder] = useState('');
  const [evaluator,setEvaluator] = useState('');
  const [retirement,setRetirement] = useState('');
  const [positionCompetencies,setPositionCompetencies] = useState("");
  const [generalNotes,setGeneralNotes] = useState('');
  const [lastPlanMonth,setLastPlanMonth] = useState('');
  const [lastPlanYear,setLastPlanYear] = useState('');
  const [users,setUsers] = useState([]);
  const [details,setDetails] = useState({});
  const [id,setId] = useState('');

  const predictorWeight = 30;
  const experienceWeight = 25;
  const concernsWeight = 20;
  const performanceWeight = 15;
  const educationWeight = 10;

  useEffect(() => {
    setIndustry(user?.industry_details[0]?.name);  
    setCompanyName(user?.company_name);  
    if(details){
      setLastPlanMonth(details?.plan_last_month)
      setLastPlanYear(details?.plan_last_year)
      setGeneralNotes(details?.general_notes)
      setId(details?._id)      
    }
    if(profileData){
      setCurrentHolder(profileData?.name);
      setRetirement(profileData?.retirement_window);
    }
  }, [user,details,profileData]);

  useEffect(() => {
    async function profileData() {
      try {
        const result = await postData("profile/details", {});
        if (result.data) {
          setProfileData(result.data);
        } else {
          console.log('Profile Details message',result.message)
        }
      } catch (error) {
        console.log('Error profile details catch',error.message)
      }
    }
    profileData();

    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery:{'ordering':1},
          type:1,
          status:1
        });
        if (result.data) {
          setPositions(result.data);
        } else {
          console.log('position list message',result.message)
        }
      } catch (error) {
        console.log('Error position list catch',error.message)
      }
    }
    positionData(); 
      
    async function retirementWindowData() {
      try {
        const result = await postData("experience/list", {
          sortQuery:{'created_at':1},
          type:3,
          status:1,
          is_visabled:1,
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log('Retirement window list message',result.message)
        }
      } catch (error) {
        console.log('Error retirement window list catch',error.message)
      }
    }
    retirementWindowData();  
    
    const loadSuccessors = async () => {
      setLoading(true);
      try {
        const result = await postData("successor/list",{is_interim:2,});
        if(result.data) {
          setSuccessors(
            result.data.map((value, key) => {              
              let predictorCalculation = (predictorWeight*value?.overall_weighted_score) / 100;              
              let experienceCalculation = (experienceWeight*(value.experience_details?value?.experience_details[0]?.point:0)/ 100);     
              let concernsCalculation = (concernsWeight*value?.overall_score) / 100;              
              let performanceCalculation = (performanceWeight*(value.performance_details?value?.performance_details[0]?.point:0) / 100);
              let educationCalculation = (educationWeight*(value.education_details?value?.education_details[0]?.point:0) / 100);
              let desireResult = (value?.desire_details && value?.desire_details.length > 0)?(value?.desire_details[0]?.isPass)?1:2:null;
              let loyaltyResult = (value?.loyalty_details && value?.loyalty_details.length > 0)?(value?.loyalty_details[0]?.isPass)?1:2:null;
              let RetirementResult = (value?.retirement_details && value?.retirement_details.length > 0)?
              (value?.retirement_details[0]?.isPass)?1:2:null;
              return {
                ...value,
                overallWeightScore: (desireResult === 2 || loyaltyResult === 2 || RetirementResult === 2)?0.00: 
                (                   
                  parseFloat(predictorCalculation) +
                  parseFloat(experienceCalculation) +
                  parseFloat(concernsCalculation) +
                  parseFloat(performanceCalculation) +
                  parseFloat(educationCalculation)
                ).toFixed(2),
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadSuccessors();
  }, []);  

  useEffect(() => {
    const loadSuccessorsDetails = async () => {
      try {
        const result = await postData("successor/details",{
          position:replacementPosition
        });
        if(result.data){
          setUsers(result.data)  
        }else{
          setUsers([])
          //setCurrentHolder('');
          setRetirement('');
          setPositionCompetencies('');
        }
      } catch (err) {
        console.log(err.message);
      }
    };  

    if(replacementPosition){
      loadSuccessorsDetails();
    }else{
      setUsers([])
      //setCurrentHolder('');
      setRetirement('');
      setPositionCompetencies('');
    }
    // if(currentHolder){
    //   const index = users.findIndex((item) => item._id === currentHolder);
    //   if(index !== -1){
    //     setCurrentHolder((users[index]?.name)?users[index]?.name:'');
    //     setRetirement((users[index]?.retirement_window)?users[index]?.retirement_window:'');
    //     setPositionCompetencies((users[index]?.position_details && users[index]?.position_details.length>0 )?users[index]?.position_details[0].position_competencies:'')
    //   }
    // }    
  }, [replacementPosition,currentHolder]);

  useEffect(() => {
    const loadDetails = async () => {
      try {
        const result = await postData("successor-roster/details",{});
        if(result.data){
          setDetails(result.data)  
        }else{
          setDetails({})
        }
      } catch (err) {
        console.log(err.message);
      }
    };  
    loadDetails()
  }, []);

  const onCancel = async (e) => {
    setIsCancel(true);
    setCurrentHolder('');
    setReplacementPosition('');
    setRetirement('');
    setGeneralNotes('');
    setIsCancel(false);
  }

  const onUpdate = async (e) => {
    setIsUpdate(true);    
    try {
      let path ='';
      let payload= {};
      if(id){
        path = 'successor-roster/update';
        payload={
          id:id,
          current_position_holder : currentHolder,
          general_notes:generalNotes,
          last_plan_update_month: lastPlanMonth,
          last_plan_update_year:lastPlanYear
        }
      }else{
        path = 'successor-roster/create';
        payload={ 
          current_position_holder : currentHolder,
          general_notes:generalNotes,
          last_plan_update_month: lastPlanMonth,
          last_plan_update_year:lastPlanYear
        }
      }
      const result = await postData(path,payload );
      if (result.status && result.status === 200) {        
        toastr.success(result.message);
        setIsUpdate(false);
      } else {
        toastr.error(result.message);
        setIsUpdate(false);
      }
    } catch (error) {
        toastr.error(error.message);
        setIsUpdate(false);
    }
  }

  

  return (
    <>
      <div className="relative flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">{t('roster_of_potential_successors')}</div>
      </div>
      <div className="relative bg-white shadow rounded">
        <div className="py-10 px-3">
          <form className="relative flex -mx-6">
            <div className="relative space-y-3 w-3/4 px-6">
              {/* <div className="flex items-center w-full gap-4">
                <div className="w-1/6">
                  <div className="text-sm font-medium text-right text-slate-500">Name of {industry}</div>
                </div>
                <div className="w-3/6">
                  <Input 
                  inputType={'text'} 
                  inputValue={company_name} 
                  isDisabled
                  />
                </div>
              </div> */}
              <div className="flex items-center w-full gap-4">
                <div className="w-1/6">
                  <div className="text-sm font-medium text-right text-slate-500">{t('replacement_position')}</div>
                </div>
                <div className="w-3/6">
                  <Select 
                  xPlacement={'bottomLeft'} 
                  dropdownClass={'!w-full'} 
                  selectedValue={replacementPosition} 
                  dropdownData={[
                    { _id: '', label: t('select'), value: '' }, 
                    ...positions.map((item) => (
                      { _id: item?._id, label: item?.name, value: item?._id }
                    ))
                  ]}
                  getSelectedValue={(e) => {
                    setReplacementPosition(e.value)
                    //setCurrentHolder('');
                    //setRetirement('');
                    setPositionCompetencies('');
                  }}
                  />
                </div>
              </div>
              <div className="flex items-center w-full gap-4">
                <div className="w-1/6">
                  <div className="text-sm font-medium text-right text-slate-500">{t('current_position_holder')}</div>
                </div>
                <div className="w-2/6">
                  <Input 
                  inputType={'text'} 
                  inputValue={ currentHolder } 
                  setInput={setCurrentHolder}
                  isDisabled={true}
                  />
                  {/* <Select 
                  xPlacement={'bottomLeft'} 
                  dropdownClass={'!w-full'} 
                  selectedValue={ currentHolder } 
                  dropdownData={[
                    { _id: '', label: 'Select', value: '' }, 
                    ...users.map((item) => (
                      { _id: item?._id, label: item?.name, value: item?._id }
                    ))
                  ]}
                  getSelectedValue={(e) => setCurrentHolder(e.value)}
                  /> */}
                </div>
              </div>
              <div className="flex items-center w-full gap-4">
                <div className="w-1/6">
                  <div className="text-sm font-medium text-right text-slate-500">{t('retirement_window')}</div>
                </div>
                <div className="w-2/6">
                  <Select 
                  xPlacement={'bottomLeft'} 
                  dropdownClass={'!w-full'} 
                  selectedValue={ retirement } 
                  dropdownData={[
                    { _id: '', label: t('select'), value: '' }, 
                    ...retirementWindow.map((item) => (
                      { _id: item?._id, label: item?.name, value: item?._id }
                    ))
                  ]}
                  getSelectedValue={(e) => setRetirement(e.value)}
                  />
                </div>                
              </div>
              <div className="flex items-center w-full gap-4">
                  <div className="text-sm text-slate-500">{positionCompetencies}</div>
              </div>
              
              <div className="!my-10 !py-6 border-y border-slate-200 space-y-2">
                <div className="flex items-center w-full gap-4">
                  <div className="w-1/6"></div>
                  <div className="w-2/6">
                    <div className="text-xs font-semibold uppercase text-slate-600">{'name'}</div>
                  </div>
                  <div className="w-1/6">
                    <div className="text-xs font-semibold uppercase text-slate-600">{t('current_position')}</div>
                  </div>
                  <div className="w-2/6">
                    <div className="text-xs font-semibold uppercase text-slate-600">{t('notes')}</div>
                  </div>
                </div>
                {
                  totalDataCount > 0 ? (
                  successors.sort((a,b) => b.overallWeightScore - a.overallWeightScore).map((item) => (
                  <div className="flex items-center w-full gap-4" key={item._id}>
                    <div className="w-1/6">
                      <div className="text-sm font-medium text-right text-slate-500">{t('potential_successor')}</div>
                    </div>
                    <div className="w-2/6">
                      <Input 
                      inputType={'text'} 
                      inputValue={item.name} 
                      />
                    </div>
                    <div className="w-1/6">
                      <Input 
                      inputType={'text'} 
                      inputValue={(item?.position_details)?item?.position_details[0]?.short_name:'N/A'} 
                      />
                    </div>
                    <div className="w-2/6">
                      <Input 
                      inputType={item.note}
                      />
                    </div>
                  </div>
                ))
                ):(
                  loading?<Searching label={t('searching')}/>:<NoDataFound label={t('no_data_found')}/>
                )
              }
              </div>
              {/* <div className="flex items-center w-full gap-4">
                <div className="w-1/6">
                  <div className="text-sm font-medium text-right text-slate-500">Plan Last Updated</div>
                </div>
                <div className="w-1/6">
                  <Select 
                  xPlacement={'bottomLeft'} 
                  dropdownClass={'!w-full'} 
                  selectedValue={lastPlanMonth}
                  dropdownData={[
                    { _id: '', label: 'Select Month', value: '' }, 
                    ...[{ _id: 1, label: 'January', value: 1 },
                    { _id: 2, label: 'February', value: 2 },
                    { _id: 3, label: 'March', value: 3 },
                    { _id: 4, label: 'April', value: 4 },
                    { _id: 5, label: 'May', value: 5 },
                    { _id: 6, label: 'June', value: 6 },
                    { _id: 7, label: 'July', value: 7 },
                    { _id: 8, label: 'August', value: 8 },
                    { _id: 9, label: 'September', value: 9 },
                    { _id: 10, label: 'October', value: 10 },
                    { _id: 11, label: 'November', value: 11 },
                    { _id: 12, label: 'December', value: 12 }]
                  ]}
                  getSelectedValue={(e) => setLastPlanMonth(e.value) }
                  />
                </div>
                <div className="w-1/6">
                  <Select 
                  xPlacement={'bottomLeft'} 
                  dropdownClass={'!w-full'} 
                  selectedValue={lastPlanYear}
                  dropdownData={[
                    { _id: '', label: 'Select Year', value: '' }, 
                    ...[
                    { _id: 2022, label: '2022', value: 2022 },
                    { _id: 2023, label: '2023', value: 2023 },
                    { _id: 2024, label: '2024', value: 2024 },
                    { _id: 2025, label: '2025', value: 2025 },
                    { _id: 2026, label: '2026', value: 2026 },
                    { _id: 2027, label: '2027', value: 2027 },
                    { _id: 2028, label: '2028', value: 2028 },
                    { _id: 2029, label: '2029', value: 2029 },
                    { _id: 2030, label: '2030', value: 2030 },
                    { _id: 2031, label: '2031', value: 2031 },
                    { _id: 2032, label: '2032', value: 2032 },
                    { _id: 2033, label: '2033', value: 2033 },
                    ]
                  ]}
                  getSelectedValue={(e) => setLastPlanYear(e.value) }
                  />
                </div>
              </div> */}
            </div>
            <div className="relative space-y-3 w-1/4 px-6 border-l border-slate-200">
              <div className="flex flex-col w-full gap-4">
                <div className="w-full">
                  <Input 
                  inputType={'text'} 
                  inputValue={evaluator} 
                  label={t('name_of_evaluator')} 
                  errorType={'info'} 
                  errorText={t('enter_name_if_multiple_evaluators_are_present')}
                  setInput={setEvaluator}
                  />
                </div>
                <div className="w-full">
                  <Textarea 
                  label={t('general_notes_comments')}
                  inputValue={generalNotes} 
                  setTextarea={setGeneralNotes}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="py-3 px-3 border-t border-slate-200">
          <div className="flex items-center justify-end gap-4">
            <Button 
            buttonType={'button'} 
            buttonIcon={(isCancel)?'fa-light fa-spinner fa-spin':'fa-light fa-times'} 
            buttonIconPosition={'left'} 
            buttonLabel={t('cancel')} 
            buttonClasses={'!bg-slate-200 !text-slate-600 border border-slate-300'}
            functions={onCancel}
            buttonHasLink={false}
            buttonDisabled={isCancel} 
            />
            <Button 
            buttonType={'button'} 
            buttonIcon={(isUpdate)?'fa-light fa-spinner fa-spin':'fa-light fa-check'}
            buttonIconPosition={'left'} 
            buttonLabel={t('save')} 
            functions={onUpdate}
            buttonHasLink={false}
            buttonDisabled={isUpdate}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Successor;