import React, { useState, useEffect } from "react";
import Button from "../form/Button";
import Input from "../form/Input";
import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
import toastr from "toastr";
import Table from "../elements/table/Table";

const EmergencyReplacementPlanContent = ({ data }) => {
  const { i18n, t } = useTranslation();
  const [details, setDetails] = useState([]);
  const [email, setEmail] = useState("");
  const [businessPhone, setBusinessPhone] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [viceChair, setViceChair] = useState("");
  const [secretary, setSecretary] = useState("");
  const [treasurer, setTreasurer] = useState("");
  const [rfp, setRfp] = useState("");
  const [rfp2, setRfp2] = useState("");
  const [rfp3, setRfp3] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    async function loadDetails() {
      try {
        const result = await postData("emergency-replace/details", {});
        if (result.data) {
          setDetails(result.data);
        } else {
          console.log("Details message", result.message);
        }
      } catch (error) {
        console.log("Error details catch", error.message);
      }
    }
    loadDetails();
  }, []);

  useEffect(() => {
    setEmail(details?.email);
    setBusinessPhone(details?.business_phone);
    setCellPhone(details?.cell_phone);
    setViceChair(details?.vice_chair);
    setSecretary(details?.secretary);
    setTreasurer(details?.treasurer);
    setRfp(details?.request_for_proposal);
    setRfp2(details?.request_for_proposal_two);
    setRfp3(details?.request_for_proposal_three);
  }, [details]);

  const onCancel = async (e) => {
    setIsCancel(true);
    if (details) {
      setEmail(details?.email);
      setBusinessPhone(details?.business_phone);
      setCellPhone(details?.cell_phone);
      setViceChair(details?.vice_chair);
      setSecretary(details?.secretary);
      setTreasurer(details?.treasurer);
      setRfp(details?.request_for_proposal);
      setRfp2(details?.request_for_proposal_two);
      setRfp3(details?.request_for_proposal_three);
    } else {
      setEmail("");
      setBusinessPhone("");
      setCellPhone("");
      setViceChair("");
      setSecretary("");
      setTreasurer("");
      setRfp("");
      setRfp2("");
      setRfp3("");
    }
    setIsCancel(false);
  };

  const onSave = async (e) => {
    setIsSave(true);
    if (email || businessPhone || cellPhone || viceChair || secretary || treasurer || rfp || rfp2 || rfp3) {
      try {
        const result = await postData("emergency-replace/update", {
          email: email,
          business_phone: businessPhone,
          cell_phone: cellPhone,
          vice_chair: viceChair,
          secretary: secretary,
          treasurer: treasurer,
          request_for_proposal: rfp,
          request_for_proposal_two: rfp2,
          request_for_proposal_three: rfp3,
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setIsSave(false);
        } else {
          toastr.error(result.message);
          setIsSave(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsSave(false);
      }
    } else {
      toastr.error(t("no_data_found"));
      setIsSave(false);
    }
  };

  return (
    <>
      <div className="py-5 sm:py-10 px-4 sm:px-10 lg:px-20 text-base text-black relative w-full max-w-5xl mx-auto  shadow rounded-lg">
        <div className="flex items-center mb-10">
          <div className="w-full h-12 sm:h-16 min-w-[40px]">
            <img className="w-full h-full object-contain" src={"/logo-succession-now.svg"} alt={"Logo"} />
          </div>
        </div>
        <div className="font-bold text-lg sm:text-2xl mb-6 text-slate-500">
          <h3>Emergency/Interim Replacement Policy &amp; Plan – President/CEO</h3>
        </div>

        <div className="mb-3 pl-3 text-sm !leading-snug sm:!leading-normal text-slate-500">
          <ul className="list-decimal list-outside marker:font-semibold space-y-10">
            <li className="pl-3 sm:pl-2">
              <div className="space-y-3 mb-5">
                <p>
                  <span className="font-semibold underline">Involuntary termination:</span> An unplanned departure of a President/CEO can include death, catastrophic long-term disability, termination
                  without notice, or the CEO accepting a position with another organization.
                </p>
                <p>
                  The Board Chair should be notified immediately of the situation. If the Board Chair is unavailable, notice will be given to the (1) Vice Chair, (2) the Secretary/Treasurer, (3) the
                  Supervisory Committee Chair.
                </p>
                <p>
                  If the involuntary termination is due to the President/CEO engaging in an egregious act, the Board will contact its attorney regarding questions or concerns related to legal issues
                  surrounding termination. The attorney contact information is:
                </p>
              </div>
              <div className="pl-4">
                <ul className="list-disc list-outside space-y-2 sm:space-y-0">
                  <li>
                    <div className="flex items-center space-x-4 mb-3">
                      {/* <div className="text-sm w-28">{t("email")}:</div> */}
                      <div className="relative w-full "> {/* max-w-xs */}
                        <Input
                          inputType={"text"}
                          inputPlaceholder={t("enter_email")}
                          inputValue={email}
                          setInput={setEmail}
                          //inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center space-x-4 mb-3">
                      {/* <div className="text-sm w-28">{t("business_phone")}:</div> */}
                      <div className="relative w-full"> {/* max-w-xs */}
                        <Input
                          inputType={"text"}
                          inputPlaceholder={t("enter_business_phone")}
                          inputValue={businessPhone}
                          setInput={setBusinessPhone}
                          //inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center space-x-4">
                      {/* <div className="text-sm w-28">{t("cell_phone")}:</div> */}
                      <div className="relative w-full"> {/* max-w-xs */}
                        <Input
                          inputType={"text"}
                          inputPlaceholder={t("enter_cell_phone")}
                          inputValue={cellPhone}
                          setInput={setCellPhone}
                          //inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="pl-3 sm:pl-2">
              <div className="space-y-3 mb-5">
                <p>
                  <span className="font-semibold underline">Voluntary termination:</span> If the departure is a voluntary termination (the CEO leaves, accepts a position elsewhere, etc.), The CEO
                  should provide at least 6 months prior notice.
                </p>
                <p>
                  <span className="font-semibold underline">Appointment:</span> In the event of an unplanned departure of the CEO, the Board of Directors authorizes the Board Chair to appoint an
                  interim CEO until the full board can meet and approve the appointment. If for any reason the Board Chair is unable or unavailable to appoint the interim President/CEO, the order of
                  responsibility to appoint an interim President/CEO is as follows:
                </p>
              </div>
              <div className="pl-4 mb-2">
                <ul className="list-decimal list-outside marker:font-normal">
                  <li>
                    <div className="flex items-center space-x-4 mb-3">
                      {/* <div className="text-sm w-28">Vice Chairman:</div> */}
                      <div className="relative w-full"> 
                        <Input
                          inputType={"text"}
                          inputPlaceholder={t('enter_vice_chairman')}
                          inputValue={viceChair}
                          setInput={setViceChair}
                          //inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center space-x-4 mb-3">
                      {/* <div className="text-sm w-28">Secretary:</div> */}
                      <div className="relative w-full"> {/* max-w-xs */}
                        <Input
                          inputType={"text"}
                          inputPlaceholder={t('enter_secretary')}
                          inputValue={secretary}
                          setInput={setSecretary}
                          //inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center space-x-4 mb-3">
                      {/* <div className="text-sm w-28">Treasurer:</div> */}
                      <div className="relative w-full"> {/* max-w-xs */}
                        <Input
                          inputType={"text"}
                          inputPlaceholder={t('enter_treasurer')}
                          inputValue={treasurer}
                          setInput={setTreasurer}
                          //inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                        />
                      </div>
                    </div>
                  </li>
                  {/* <li>
                    <div className="flex items-center space-x-4">
                      <div className="text-sm">or the Supervisory Committee of the Board:</div>
                      <div className="relative w-full max-w-xs">
                        <Input
                          inputType={"text"}
                          inputPlaceholder={t("enter_email")}
                          inputValue={email}
                          setInput={setEmail}
                          inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                        />
                      </div>
                    </div>
                  </li> */}
                  {/* <li>Vice Chairman</li>
                  <li>Secretary,</li>
                  <li>Treasurer,</li>*/}
                  <li>or the Supervisory Committee of the Board.</li> 
                </ul>
              </div>
              <div className="space-y-3 mb-5">
                <p>The Board should act as quickly as possible in naming an internal interim CEO. If there is a named EVP, they typically will assume interim CEO responsibilities.</p>
              </div>
              <div className="space-y-3 mb-5">
                <p>
                  <span className="font-semibold underline">Roles:</span> The interim CEO will rely heavily on other key managers for guidance on running the day-to-day operations. Until a permanent
                  CEO is assigned or hired, the board chair will be designated to provide support and guidance to the interim CEO, meeting regularly (weekly or bi-weekly) until a permanent CEO is
                  selected. The Chair’s role will be as an advisor or counselor, leaving the day-to-day management and operations of the organization to the interim CEO.
                </p>
              </div>
            </li>
            <li className="pl-3 sm:pl-2">
              <div className="space-y-3 mb-5">
                <p>
                  <span className="font-semibold underline">Action Plan:</span>
                </p>
                <p>
                  <span className="underline">Within 24 Hours:</span>
                </p>
              </div>
              <div className="shadow overflow-auto rounded-lg mb-10 -ml-6 sm:ml-0">
                <table className="border-collapse table-auto w-full text-sm !leading-tight sm:!leading-snug">
                  <thead>
                    <tr>
                      <th className="border-b font-medium p-4 text-slate-400 bg-slate-100 text-left">
                        <div>Responsibility</div>
                      </th>
                      <th className="border-b font-medium p-4 text-slate-400 bg-slate-100 text-left sm:min-w-[200px]">
                        <div>Responsible Party</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>1.</div>
                          <div className="space-y-3">
                            <p>Name and contact the Interim CEO.</p>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Board Chair</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>2.</div>
                          <div className="space-y-3">
                            <p>Contact and advise the following as soon as possible informing them of the change/vacancy and the interim CEO</p>
                            <ul className="list-disc list-outside marker:font-normal pl-3">
                              <li>Management Team Members</li>
                              <li>
                                Remaining Members - Board of Directors and all committee members. <span className="font-semibold italic">See Attachment 2 for contact information.</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Board Chair</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>3.</div>
                          <div className="space-y-3">
                            <p>Hold Supervisory Committee meeting:</p>
                            <ul className="list-disc list-outside marker:font-normal pl-3">
                              <li>Execute procedures for securing computer network and execute procedure.</li>
                              <li>
                                <p>Secure records</p>
                                <ul className="list-[circle] list-outside marker:font-normal pl-3">
                                  <li>Employee files</li>
                                  <li>Loan documents</li>
                                </ul>
                              </li>
                              <li>
                                <p>Change locks and security system if deemed necessary.</p>
                                <ul className="list-[circle] list-outside marker:font-normal pl-3">
                                  <li>Disable master key</li>
                                  <li>Notify base security and security monitoring company.</li>
                                  <li>Revise security codes at all facilities</li>
                                </ul>
                              </li>
                              <li>Secure personal property of departing President/CEO.</li>
                              <li>Block corporate credit cards.</li>
                              <li>
                                Delegate staff to contact outside organizations such as vendors, regulators, security companies, police, leagues, etc. to inform them of the change.{" "}
                                <span className="font-semibold italic">See Disaster Recovery Manual for complete list.</span>
                              </li>
                              <li>Stop purchasing authority CEO with appropriate vendors and investors.</li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Supervisory Committee</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>4.</div>
                          <div className="space-y-3">
                            <p>Convene a Board meeting:</p>
                            <ul className="list-disc list-outside marker:font-normal pl-3">
                              <li>Vote to approve the appointment of the interim President/CEO.</li>
                              <li>Confirm the Board Chair’s role as advisor to the interim CEO.</li>
                              <li>
                                <p>Determine content and approach to communicating the situation to the staff. Content can include and will not be limited to the following:</p>
                                <ul className="list-[circle] list-outside marker:font-normal pl-3">
                                  <li>Former President/CEO is no longer responsible for organization.</li>
                                  <li>Reason for departure – if it can be shared.</li>
                                  <li>Clarification of the role of the Interim President/CEO.</li>
                                  <li>Clarify appropriate responses to members, vendors and others who ask.</li>
                                  <li>Designate the appropriate person(s) responsible for providing information to the media, members, vendors and others.</li>
                                  <li>Communicate the process for replacement of the CEO with anticipated time frames.</li>
                                  <li>When and how additional information will be provided.</li>
                                </ul>
                              </li>
                              <li>Determine audit needs (timing & procedures).</li>
                            </ul>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Board Chair</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>5.</div>
                          <div className="space-y-3">
                            <p>Disseminate information to staff explaining the situation and the anticipated actions, appropriate communication & reasoning.</p>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Interim CEO</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="space-y-3 mb-5">
                <p>
                  <span className="underline">Within 10 Business Days:</span>
                </p>
              </div>
              <div className="shadow overflow-auto rounded-lg  -ml-6 sm:ml-0">
                <table className="border-collapse table-auto w-full text-sm !leading-tight sm:!leading-snug">
                  <thead>
                    <tr>
                      <th className="border-b font-medium p-4 text-slate-400 bg-slate-100 text-left">
                        <div>Responsibility</div>
                      </th>
                      <th className="border-b font-medium p-4 text-slate-400 bg-slate-100 text-left sm:min-w-[200px]">
                        <div>Responsible Party</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>1.</div>
                          <div className="space-y-3">
                            <p>Board Chair meeting with the interim CEO to determine appropriate levels of authority.</p>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Board Chair</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>2.</div>
                          <div className="space-y-3">
                            <p>Board Chair in concert with the interim CEO, to determine appropriate course of action in managing the organization.</p>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Board Chair</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>3.</div>
                          <div className="space-y-3">
                            <p>Determine appropriate interim compensation for interim CEO.</p>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Board</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>4.</div>
                          <div className="space-y-3">
                            <p>Implement recruitment plan.</p>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Board</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                        <div className="flex space-x-1.5 sm:space-x-3">
                          <div>5.</div>
                          <div className="space-y-3">
                            <p>Interim CEO will maintain regular communication with the Chair throughout the period he/she is serving as Interim CEO.</p>
                          </div>
                        </div>
                      </td>
                      <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                        <div>Interim CEO</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="py-5 sm:py-10 px-4 sm:px-10 lg:px-20 text-base text-black relative  w-full max-w-5xl mx-auto mt-10 shadow rounded-lg">
        <div className="flex items-center mb-10">
          <div className="w-full h-12 sm:h-16 min-w-[40px]">
            <img className="w-full h-full object-contain" src={"/logo-succession-now.svg"} alt={"Logo"} />
          </div>
        </div>
        <div className="font-bold text-lg sm:text-2xl mb-6 text-slate-500">
          <h3>FORM: External Recruitment Plan – President/CEO</h3>
        </div>

        <div className="mb-3 pl-3 text-sm !leading-snug sm:!leading-normal text-slate-500">
          <div className="space-y-3 mb-5">
            <p>When internal candidates (Potential Successors) are not available or not yet ready to be named the new CEO, the following options and guidelines are recommended:</p>
            <ul className="list-decimal list-outside marker:font-normal pl-4 space-y-2 sm:space-y-0">
              <li className="pl-3 sm:pl-2">Recruit the CEO without the assistance of an external search firm (not recommended).</li>
              <li className="pl-3 sm:pl-2">
                <p>Engage a search firm to either:</p>
                <ul className="list-disc list-outside marker:font-normal pl-5">
                  <li>Manage the search process from beginning to end.</li>
                  <li>Perform agreed upon segments of the search process, or</li>
                  <li>On an hourly basis to assist with the search process on an as-needed basis.</li>
                </ul>
              </li>
              <li className="pl-3 sm:pl-2">
                <p>The Process</p>
                <div className="shadow overflow-hidden rounded-lg mt-2 -ml-6 sm:ml-0">
                  <table className="border-collapse table-auto w-full text-sm !leading-tight sm:!leading-snug">
                    <thead>
                      <tr>
                        <th className="border-b font-medium p-4 text-slate-400 bg-slate-100 text-left">
                          <div>Responsibility</div>
                        </th>
                        <th className="border-b font-medium p-4 text-slate-400 bg-slate-100 text-left sm:min-w-[200px]">
                          <div>Responsible Party</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>1.</div>
                            <div className="space-y-3">
                              <p>Appoint a Search Committee.</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Board</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>2.</div>
                            <div className="space-y-3">
                              <p>Submit Request for Proposal (RFP) to three search firms*</p>
                              <ul className="list-decimal list-outside pl-4">
                                <li className="mb-3">
                                  <Input
                                    inputType={"text"}
                                    inputPlaceholder={''}
                                    inputValue={rfp}
                                    setInput={setRfp}
                                    //inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                                  />
                                </li>
                                <li className="mb-3">
                                  <Input
                                    inputType={"text"}
                                    inputPlaceholder={''}
                                    inputValue={rfp2}
                                    setInput={setRfp2}
                                    //inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                                  />
                                </li>
                                <li className="mb-3">
                                  <Input
                                    inputType={"text"}
                                    inputPlaceholder={''}
                                    inputValue={rfp3}
                                    setInput={setRfp3}
                                    //inputClasses={"!h-6 !border-transparent !border-b-gray-400 !rounded-none !bg-transparent !px-0"}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div className="space-y-4">
                            <p>Search Committee</p>
                            <p className="font-semibold italic">Review guidelines for selecting a search firm (below)</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>3.</div>
                            <div className="space-y-3">
                              <p>Interview search firm and:</p>
                              <ul className="list-[circle] list-outside marker:font-normal pl-4">
                                <li>Agree on search methodology, salary range and employee benefits for new CEO.</li>
                                <li>Confirm desired competencies for the new CEO.</li>
                                <li>Agree on professional fee to be paid to the search firm.</li>
                              </ul>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Search Committee</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>4.</div>
                            <div className="space-y-3">
                              <p>Select search firm based on Search Committee recommendation.</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Board</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>5.</div>
                            <div className="space-y-3">
                              <p>Search firm will search for and present 3-5 highly qualified candidates.</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Search Firm</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>6.</div>
                            <div className="space-y-3">
                              <p>Conduct first interviews of short list (3-5) of highly qualified candidates.</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Search Committee and CEO, if available</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>7.</div>
                            <div className="space-y-3">
                              <p>Conduct second interviews.</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Search Committee and CEO, if available. Board may also participate in these interviews.</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>8.</div>
                            <div className="space-y-3">
                              <p>Make first and second choice selections from among interviewed candidates and submit to Board.</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Search Committee</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>9.</div>
                            <div className="space-y-3">
                              <p>Verification of candidates’ credentials and employability: including, but not be limited to: educational transcripts, reference checks, credit bureau reports (subject to strict Fair Credit Reporting Act (FCRA) regulations regarding notification, lawful procedures if report is negative, etc.) and bond check</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Search Firm</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>10.</div>
                            <div className="space-y-3">
                              <p>Make final selection and submit to board for approval.</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Search Committee</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>11.</div>
                            <div className="space-y-3">
                              <p>Notify selected candidate.</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Board Chair</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>12.</div>
                            <div className="space-y-3">
                              <p>Negotiate compensation, benefits and start date</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Search Committee, selected candidate (and Search Firm if requested)</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>13.</div>
                            <div className="space-y-3">
                              <p>Notify unsuccessful candidates.</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Search Committee Chair</div>
                        </td>
                      </tr>
                      <tr>
                        <td className="align-top border-b border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500">
                          <div className="flex space-x-1.5 sm:space-x-3">
                            <div>14.</div>
                            <div className="space-y-3">
                              <p>Send out the job announcement /introduce selected candidate to all employees and other appropriate stakeholder, including but not limited to:</p>
                            </div>
                          </div>
                        </td>
                        <td className="align-top border-b border-l border-slate-100 py-2 sm:py-4 px-2 sm:px-4 text-slate-500 sm:min-w-[200px]">
                          <div>Board</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ul>
          </div>
          
        </div>
      </div>

      <div className="py-5 sm:py-10 px-4 sm:px-10 lg:px-20 text-base text-black relative  w-full max-w-5xl mx-auto mt-10 shadow rounded-lg">
        <div className="flex items-center mb-10">
          <div className="w-full h-12 sm:h-16 min-w-[40px]">
            <img className="w-full h-full object-contain" src={"/logo-succession-now.svg"} alt={"Logo"} />
          </div>
        </div>
        <div className="font-bold text-lg sm:text-2xl mb-6 text-slate-500">
          <h3>Guidelines for Selecting a Search Firm*</h3>
        </div>

        <div className="mb-3 sm:pl-3 text-sm !leading-tight sm:!leading-normal text-slate-500">
          <ul className="list-decimal list-outside marker:font-normal pl-4 space-y-3">
            <li className="pl-3 sm:pl-2">Determine how many years the search firm has been in business. The longer they have been in business the more likely they have established a proven track record.</li>
            <li className="pl-3 sm:pl-2">Determine how many searches they have done in the last year that are specific to the industry.</li>
            <li className="pl-3 sm:pl-2">Identify the typical compensation levels received by those who the firm places. That level should be comparable to the compensation your organization expects to pay a replacement CEO.</li>
            <li className="pl-3 sm:pl-2">Remember that the search firm is representing your organization so you must be comfortable with its reputation and the professionalism of the people specifically assigned to your search.</li>
            <li className="pl-3 sm:pl-2">Ask to meet the search consultant that will be doing the search. (Often times, the consultant who presents the search proposal is different that the consultant doing the search.)</li>
            <li className="pl-3 sm:pl-2">Request recent references from the search firms submitting proposals.</li>
            <li className="pl-3 sm:pl-2">Request the search firms to share the process that they use to do the search (from start to finish); assuring consistency with your approach and philosophy.</li>
            <li className="pl-3 sm:pl-2">Ask the firm to explain how they source (acquire) candidates; research is more valuable than advertising in acquiring a pool of candidates.</li>
          </ul>
        </div>
      </div>

      <div className="px-4 py-2 sm:px-0 sm:py-0 sm:mt-10 text-base text-black relative  w-full max-w-5xl mx-auto">
        <div className="flex justify-end">
          <div className="flex items-center justify-end gap-4">
            <Button
              buttonLabelClasses=""
              buttonType={"button"}
              buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"}
              buttonIconPosition={"left"}
              buttonLabel={t("cancel")}
              buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
              functions={onCancel}
              buttonHasLink={false}
              buttonDisabled={isCancel}
            />
            <Button
              buttonClasses=""
              buttonLabelClasses=""
              buttonType={"button"}
              buttonIcon={isSave ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"}
              buttonIconPosition={"left"}
              buttonLabel={t("save")}
              functions={onSave}
              buttonHasLink={false}
              buttonDisabled={isSave}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmergencyReplacementPlanContent;

{
  /* <div className="text-lg font-bold underline mb-3">1. Involuntary termination:</div>
        <div className="text-sm font-normal leading-snug whitespace-pre-wrap mb-6 space-y-3">
          <p>An unplanned departure of a President/CEO can include death, catastrophic long-term disability, termination without notice, or the CEO accepting a position with another organization.</p>
          <p>An unplanned departure of a President/CEO can include death, catastrophic long-term disability, termination without notice, or the CEO accepting a position with another organization.</p>
          <p>An unplanned departure of a President/CEO can include death, catastrophic long-term disability, termination without notice, or the CEO accepting a position with another organization.</p>
        </div>
        <div className="space-y-3">

        </div>
        <div className="text-sm font-normal leading-snug whitespace-pre-wrap mb-3">
          {t('email')}: 
          <div className="relative w-full">
            <Input 
            label={''} 
            labelClasses={'!text-xs'} 
            inputType={'text'}                   
            inputPlaceholder={t('enter_email')}
            inputValue={email}  
            setInput={setEmail}
            />
          </div>
        </div>
        <div style={{fontSize: 12,fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          {t('business_phone')}: 
          <div className="relative w-full">
            <Input 
            label={''} 
            labelClasses={'!text-xs'} 
            inputType={'text'}                   
            inputPlaceholder={t('enter_phone')}
            inputValue={businessPhone}  
            setInput={setBusinessPhone}
            />
          </div>
        </div>
        <div style={{fontSize: 12,fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          {t('cell_phone')}: 
          <div className="relative w-full">
            <Input 
            label={''} 
            labelClasses={'!text-xs'} 
            inputType={'text'} 
            inputPlaceholder={t('enter_phone')} 
            inputValue={cellPhone} 
            setInput={setCellPhone}
            />
          </div>
        </div>
      
      
      <div style={{ display: "flex", flexDirection: "column", marginBottom: 12 }}>
          <div style={{fontSize: 18,fontWeight: 700,textDecoration: "underline",marginBottom: 12}}>
            2. Voluntary termination:
          </div>
          <div style={{fontSize: 12,fontWeight: 400,lineHeight: "1.4",marginBottom: 12}}>
          If the departure is a voluntary termination (the CEO leaves, accepts a position elsewhere, etc.), The CEO should provide at least 6 months prior notice.
          </div>
          <div style={{paddingLeft: 20,display: "flex",flexDirection: "column"}}>
              <div style={{ fontSize: 12,fontWeight: 400,lineHeight: "1.4",marginBottom: 12, whiteSpace: "pre-wrap"}}>
                <span style={{fontStyle: "normal",paddingLeft: 5,paddingRight: 5,fontWeight: 700}}>Appointment:</span>
                In the event of an unplanned departure of the CEO, the Board of Directors authorizes the Board Chair to appoint an interim CEO until the full board can meet and approve the appointment. If for any reason the Board Chair is unable or unavailable to appoint the interim President/CEO, the order of responsibility to appoint an interim President/CEO is as follows: 
                <ol>
                  <li>{t('vice_chair')} : 
                    <div className="relative w-full">
                        <Input 
                        label={''} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={t('enter_vice_chair')}
                        inputValue={viceChair}  
                        setInput={setViceChair}
                        />
                    </div>
                  </li>
                  <li>{t('secretary')}:
                    <div className="relative w-full">
                      <Input 
                      label={''} 
                      labelClasses={'!text-xs'} 
                      inputType={'text'}                   
                      inputPlaceholder={t('enter_secretary')}
                      inputValue={secretary}  
                      setInput={setSecretary}
                      />
                    </div>
                  </li>
                  <li>{t('treasurer')}:
                    <div className="relative w-full">
                      <Input 
                      label={''} 
                      labelClasses={'!text-xs'} 
                      inputType={'text'} 
                      inputPlaceholder={t('enter_treasurer')} 
                      inputValue={treasurer} 
                      setInput={setTreasurer}
                      />
                    </div>
                  </li>
                  <li>or the Supervisory Committee of the Board. 
                The Board should act as quickly as possible in naming an internal interim CEO.  If there is a named EVP, they typically will assume interim CEO responsibilities</li>
                </ol>
              </div>
              <div style={{ fontSize: 12,fontWeight: 400,lineHeight: "1.4",marginBottom: 12, whiteSpace: "pre-wrap"}}>
                <span style={{fontStyle: "normal",paddingLeft: 5,paddingRight: 5,fontWeight: 700}}>Roles:</span>
                The interim CEO will rely heavily on other key managers for guidance on running the day-to-day operations.  Until a permanent CEO is assigned or hired, the board chair will be designated to provide support and guidance to the interim CEO, meeting regularly (weekly or bi-weekly) until a permanent CEO is selected. The Chair’s role will be as an advisor or counselor, leaving the day-to-day management and operations of the organization to the interim CEO. 
              </div>
          </div>          
      </div>     
      
      <div style={{ marginBottom: 12, display: "flex", flexDirection: "column" }}>
        <div style={{fontSize: 18,fontWeight: 700,textDecoration: "underline",marginBottom: 12}}>3. Action Plan:</div>
        <div style={{ fontSize: 14, textDecoration: "underline",marginBottom: 16,padding: "12px 0px"}}>
          Within 24 Hours
        </div>
        <div style={{ border: "1px solid rgb(226, 232, 240)",borderRadius: 6,overflow: "hidden"}}>
          <div style={{display: "flex",flexDirection: "row",overflow: "hidden",width: "100%"}}>
            <div style={{ width: "70%", fontSize: 12, lineHeight: 2,textAlign: "left",paddingBottom: 10,paddingTop: 10,paddingLeft: 8}}>
              <div style={{ fontSize: 12, lineHeight: 1 }}>Responsibility</div>
            </div>
            <div style={{ width: "30%", fontSize: 12, lineHeight: 2,textAlign: "left",paddingBottom: 10,paddingTop: 10,paddingLeft: 8,whiteSpace: "nowrap"}}>
              <div style={{ fontSize: 12, lineHeight: 1 }}>Responsible Party</div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              1. Name and contact the Interim CEO
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Board Chair
              </div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              2. Contact and advise the following as soon as possible informing them of the change/vacancy and the interim CEO.Management Team Members Remaining Members – Board of Directors and all committee members. See Attachment 2 for contact information.
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Board Chair
              </div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              2. Contact and advise the following as soon as possible informing them of the change/vacancy and the interim CEO.
              <ul>
                <li>Management Team Members</li> 
                <li>Remaining Members – Board of Directors and all committee members. <strong>See Attachment 2 for contact information.</strong></li>
              </ul>
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Board Chair
              </div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2 }}>
              3. Hold Supervisory Committee meeting:
              <ul>
                <li>Execute procedures for securing computer network and execute procedure.</li> 
                <li>
                  <ul>Secure records
                    <li>Employee files</li>
                    <li>Loan documents</li>
                  </ul>
                </li>
                <li>
                  <ul>Change locks and security system if deemed necessary.
                    <li>Disable master key</li>
                    <li>Notify base security and security monitoring company.</li>
                    <li>Revise security codes at all facilities</li>
                  </ul>
                </li>
                <li>Secure personal property of departing President/CEO</li>
                <li>Block corporate credit cards</li>
                <li>Delegate staff to contact outside organizations such as vendors, regulators, security companies, police, leagues,etc. to inform them of the change. <strong>See Disaster Recovery Manual for complete list.</strong></li>
                <li>Stop purchasing authority CEO with appropriate vendors and investors.</li>
              </ul>
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Supervisory Committee
              </div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2 }}>
              4.Convene a Board meeting:
              <ul>
                <li>Vote to approve the appointment of the interim President/CEO.</li> 
                <li>Confirm the Board Chair’s role as advisor to the interim CEO.</li>
                <li>
                  <ul>Determine content and approach to communicating the situation to the staff. Content can include and will not be limited to the following:
                    <li>Former President/CEO is no longer responsible for organization.</li>
                    <li>Reason for departure – if it can be shared.</li>
                    <li>Clarification of the role of the Interim President/CEO</li>
                    <li>Clarify appropriate responses to members, vendors and others who ask.</li>
                    <li>Designate the appropriate person(s) responsible for providing information to the media, members, vendors and others.</li>
                    <li>Communicate the process for replacement of the CEO with anticipated time frames.</li>
                    <li>When and how additional information will be provided.</li>
                    <li>Determine audit needs (timing & procedures)</li>
                  </ul>
                </li>                
                <li>Determine audit needs (timing & procedures)</li>
              </ul>
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Board Chair
              </div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              5. Disseminate information to staff explaining the situation and the anticipated actions, appropriate communication & reasoning.
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Interim CEO
              </div>
            </div>
          </div>
        </div>
        <div style={{ fontSize: 14, textDecoration: "underline",marginBottom: 16,padding: "12px 0px"}}>
          Within 10 Business Days
        </div>
        <div style={{ border: "1px solid rgb(226, 232, 240)",borderRadius: 6,overflow: "hidden"}}>
          <div style={{display: "flex",flexDirection: "row",overflow: "hidden",width: "100%"}}>
            <div style={{ width: "70%", fontSize: 12, lineHeight: 2,textAlign: "left",paddingBottom: 10,paddingTop: 10,paddingLeft: 8}}>
              <div style={{ fontSize: 12, lineHeight: 1 }}>Responsibility</div>
            </div>
            <div style={{ width: "30%", fontSize: 12, lineHeight: 2,textAlign: "left",paddingBottom: 10,paddingTop: 10,paddingLeft: 8,whiteSpace: "nowrap"}}>
              <div style={{ fontSize: 12, lineHeight: 1 }}>Responsible Party</div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              1. Board Chair meeting with the interim CEO to determine appropriate levels of authority.
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Board Chair
              </div>
            </div>
          </div>          
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              2. Board Chair in concert with the interim CEO, to determine appropriate course of action in managing the organization.
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Board Chair
              </div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              3. Determine appropriate interim compensation for interim CEO
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Board
              </div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              4. Implement recruitment plan
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Board
              </div>
            </div>
          </div>
          <div style={{ display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
            <div style={{ width: "70%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              5. Interim CEO will maintain regular communication with the Chair throughout the period of time he/she is serving as Interim CEO.
              </div>
            </div>
            <div style={{ width: "30%", padding: 8 }}>
              <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
              Interim CEO
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{display: "flex",flexDirection: "column",fontWeight: 600,fontSize: 24}}>
        <div style={{ marginBottom: 16, fontSize: 18 }}>FORM: External Recruitment Plan – President/CEO</div>
        <div style={{whiteSpace: "pre-wrap",fontSize: 12,fontWeight: 400,lineHeight: "1.4",marginBottom: 12}}>
          When internal candidates (Potential Successors) are not available or not yet ready to be named the new CEO, the following options and guidelines are recommended: 
        </div>
        <div style={{ marginBottom: 12, display: "flex", flexDirection: "column" }}>
          <div style={{fontSize: 12,fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
            <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>1.</span>
            Recruit the CEO without the assistance of an external search firm (not recommended)
          </div>
          <div style={{fontSize: 12,fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
            <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>2.</span>
            Engage a search firm to either: 
            <ul>
              <li>Manage the search process from beginning to end.</li>
              <li>Perform agreed upon segments of the search process, or</li>
              <li>On an hourly basis to provide assistance throughout the search process on an as-needed basis.</li>
            </ul>
          </div>
          <div style={{fontSize: 12,fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
            <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>3.</span>
            The Process              
          </div>
          <div style={{border: "1px solid rgb(226, 232, 240)",borderRadius: 6,overflow: "hidden"}}>
            <div style={{ display: "flex",flexDirection: "row",overflow: "hidden", width: "100%"}}>
              <div style={{ width: "70%",fontSize: 12,lineHeight: 2,textAlign: "left",paddingBottom: 10,paddingTop: 10,paddingLeft: 8}}>
                <div style={{ width: "70%", fontSize: 12, lineHeight: 1 }}>Responsibility</div>
              </div>
              <div style={{ width: "30%",fontSize: 12,lineHeight: 2,textAlign: "left",paddingBottom: 10,paddingTop: 10,paddingLeft: 8,whiteSpace: "nowrap"}}>
                <div style={{ fontSize: 12, lineHeight: 1 }}>Responsible Party</div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                1.  Appoint a Search Committee.
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Board
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                2. Submit Request for Proposal (RFP) to three search firms*
                  <ol>
                    <li>
                      <div className="relative w-full">
                        <Input 
                        label={''} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={t('enter_request_for_proposal')}
                        inputValue={rfp}  
                        setInput={setRfp}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="relative w-full">
                        <Input 
                        label={''} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'}                   
                        inputPlaceholder={t('enter_request_for_proposal')}
                        inputValue={rfp2}  
                        setInput={setRfp2}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="relative w-full">
                        <Input 
                        label={''} 
                        labelClasses={'!text-xs'} 
                        inputType={'text'} 
                        inputPlaceholder={t('enter_request_for_proposal')} 
                        inputValue={rfp3} 
                        setInput={setRfp3}
                        />
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Committee
                </div>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap", fontWeight:600 }}>
                Review guidelines for selecting a search firm (below) 
                </div>
              </div>
            </div>  
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  3. Interview search firm and:
                  <span> - Agree on search methodology, salary range and employee benefits for new CEO.</span>
                  <span> - Confirm desired competencies for the new CEO.</span>
                  <span> - Agree on professional fee to be paid to the search firm</span>
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Committee
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  4. Select search firm based on Search Committee recommendation.
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Board
                </div>
              </div>
            </div>   
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  5. Search firm will search for and present 3-5 highly qualified candidates.
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Firm
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  6. Conduct first interviews of short list (3-5) of highly qualified candidates.
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Committee and CEO, if available
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  7. Conduct second interviews.
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Committee and CEO, if available. Board may also participate in these interviews.
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  8. Make first and second choice selections from among interviewed candidates and submit to Board.
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Committee
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  9. Verification of candidates’ credentials and employability: including, but not be limited to: educational transcripts, reference checks, credit bureau reports (subject to strict Fair Credit Reporting Act (FCRA) regulations regarding notification, lawful procedures if report is negative, etc.) and bond check
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Firm
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  10. Make final selection and submit to board for approval.
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Committee
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  11. Notify selected candidate
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Board Chair
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  12. Negotiate compensation, benefits and start date
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Committee, selected candidate (and Search Firm if requested)
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  13. Notify unsuccessful candidates.
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Search Committee Chair
                </div>
              </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",borderTop: "1px solid rgb(226, 232, 240)",width: "100%"}}>
              <div style={{ width: "70%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                  14. Send out the job announcement /introduce selected candidate to all employees and other appropriate stakeholder, including but not limited to:
                </div>
              </div>
              <div style={{ width: "30%", padding: 8 }}>
                <div style={{ fontSize: 10, marginTop: 2, whiteSpace: "pre-wrap" }}>
                Board
                </div>
              </div>
            </div>

          </div>            
        </div>
      </div>      
      <div style={{ display: "flex", flexDirection: "column",fontWeight: 600,fontSize: 24}}>
        <div style={{ marginBottom: 16, fontSize: 18 }}>Guidelines for Selecting a Search Firm*</div>
        <div style={{ fontSize: 12, fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>1.</span>
          Determine how many years the search firm has been in business. The longer they have been in business the more likely they have established a proven track record.
        </div> 
        <div style={{ fontSize: 12, fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>2.</span>
          Determine how many searches they have done in the last year that are specific to the industry.
        </div>
        <div style={{ fontSize: 12, fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>3.</span>
          Identify the typical compensation levels received by those who the firm places. That level should be similar to the compensation your organization expects to pay a replacement CEO.
        </div>
        <div style={{ fontSize: 12, fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>4.</span>
          Remember that the search firm is representing your organization so you must be comfortable with its reputation and the professionalism of the people specifically assigned to your search.
        </div>
        <div style={{ fontSize: 12, fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>5.</span>
          Ask to meet the search consultant that will actually be doing the search. (Often times, the consultant who presents the search proposal is different that the consultant actually doing the search.)
        </div>
        <div style={{ fontSize: 12, fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>6.</span>
          Request recent references from the search firms submitting proposals
        </div>
        <div style={{ fontSize: 12, fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>7.</span>
          Request the search firms to share the process that they use to do the search (from start to finish); assuring consistency with your approach and philosophy.
        </div>
        <div style={{ fontSize: 12, fontWeight: 400,lineHeight: "1.4",marginBottom: 12,whiteSpace: "pre-wrap"}}>
          <span style={{ fontStyle: "normal", paddingLeft: 5, paddingRight: 5 }}>8.</span>
          Ask the firm to explain how they source (acquire) candidates; research is more valuable than advertising in acquiring a pool of candidates.
        </div>
      </div> */
}
