import React, { useState, useRef, useEffect } from "react";

import { allowedImageExtensions } from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import Input from "../../components/form/Input";
import Checkbox from "../../components/form/Checkbox";
import Button from "../../components/form/Button";
import { postData } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import ButtonFile from "../../components/form/ButtonFile";
import Image from "../../components/elements/Image";
import { Tooltip } from 'react-tooltip';
import { questionTiers } from "../../helpers";

import { useTranslation } from "react-i18next";

const AccountCreation = () => {
  const { id } = useParams();
  const tires = questionTiers();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Company");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | New Account Creation");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [plans, setPlans] = useState([]);
  const [staff, setStaff] = useState([]);
  const [sales, setSales] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [appointmentSetter, setAppointmentSetter] = useState([]);
  const [salesAdmin, setalesAdmin] = useState([]);
  const [isManage, setIsManage] = useState(false);
  const [name, setName] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [number_of_employees, setNumberOfEmployees] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [promoCode, setPrromoCode] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [price, setPrice] = useState("");
  //const [staffId,setStaffId] = useState('');
  const [salesId, setSalesId] = useState("");
  const [consultantId, setConsultantId] = useState("");
  const [appointmentSetterId, setAppointmentSetterId] = useState("");
  const [salesAdminId, setSalesAdminId] = useState("");
  const [salesCommission, setSalesCommission] = useState(0);
  const [consultantCommission, setConsultantCommission] = useState(0);
  const [appointmentSetterCommission, setAppointmentSetterCommission] = useState(0);
  const [salesAdminCommission, setSalesAdminCommission] = useState(0);
  const [showLogo, setShowLogo] = useState("");
  const [image, setImage] = useState("");
  const [accountType, setAccountType] = useState("");
  const [concerns,setConcerns] = useState([]);
  const [predictors,setPredictors] = useState([]);
  const [totalSelectedConcerns,setTotalSelectedConcerns] = useState(0);
  const [totalSelectedPredictors,setTotalSelectedPredictors] = useState(0);
  const [interval,setInterval] = useState('month');
  const [purchasePlans,setPurchasePlans] = useState([]);
  const [priceId,setPriceId] = useState('');

  const staffArray = [
    {
      code: "sales-staff",
      role_id: null,
      staff_id: salesId ? salesId : null,
      commission: salesCommission,
    },
    {
      code: "consultant",
      role_id: null,
      staff_id: consultantId ? consultantId : null,
      commission: consultantCommission,
    },
    {
      code: "appointment-setter",
      role_id: null,
      staff_id: appointmentSetterId ? appointmentSetterId : null,
      commission: appointmentSetterCommission,
    },
    {
      code: "sales-admin",
      role_id: null,
      staff_id: salesAdminId ? salesAdminId : null,
      commission: salesAdminCommission,
    },
  ];

  const capitalizeString = (str) => {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  };

  useEffect(() => {
    // Plan list
    const loadPlans = async () => {
      try {
        const result = await postData("plans/list", {
          sortQuery: { ordering: 1 },
        });
        if (result.data) {
          setPlans(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPlans();

    const loadStaffes = async () => {
      try {
        const result = await postData("usertype/list", {
          sortQuery: { ordering: 1 },
          isStaff: 1,
          status: 1,
        });
        if (result.status === 200) {
          let sales = [];
          let consultants = [];
          let appointmentSetter = [];
          let salesAdmin = [];
          result.data.map((value) => {
            sales.push(value);
            consultants.push(value);
            appointmentSetter.push(value);
            salesAdmin.push(value);
          });
          setSales(sales);
          setConsultants(consultants);
          setAppointmentSetter(appointmentSetter);
          setalesAdmin(salesAdmin);
          setStaff(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadStaffes();

    // Heading list
    const loadHeadingList = async () => {
      try {
        const result = await postData("heading/list", {
          sortQuery: { ordering: 1 },
        });
        if (result.data) {
          let _concerns = [];
          let _predictors = [];
          result.data.map((item) => {
            if(item?.type === 'concern'){
              _concerns.push({
                _id : item?._id,
                name : item?.name,
                description:item?.description,
                alias : item?.alias,
                weight : item?.weight,
                created_at: new Date(),
                isEnabled : false,
                disabled : false,
              })
            }else if(item?.type === 'predictors'){
              _predictors.push({
                _id : item?._id,
                name : item?.name,
                description:item?.description,
                alias : item?.alias,
                weight : item?.weight,
                created_at: new Date(),
                isEnabled : false,
                disabled : false,
              })
            }
          })
          setConcerns(_concerns);
          setPredictors(_predictors);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadHeadingList();
  }, []);

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid logo");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    const loadPurchasePlans = async () => {
      try {
        const result = await postData("plans/list", {
          sortQuery: { ordering: 1 },
          sortQuery:{'ordering':1},
          isView : 1
        });
        if (result.data) {
          setPurchasePlans(
            result.data.map((itm)=> {
              let price;
              if(itm.pricing?.rates.length>0){
                price = itm.pricing?.rates?.filter((it) => it?.interval === interval)[0]?.amount;
              }
              return {
                _id : itm?._id,
                title : itm?.name,
                price : price
              }
            })
          );
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPurchasePlans();
  },[interval])  

  const onSubmit = async (e) => {
    setIsManage(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("company_name", company_name);
    formData.append("number_of_employees", number_of_employees);
    formData.append("code", "customer-admin");
    formData.append("payment_status", paymentStatus);
    formData.append("account_type", accountType);
    staffArray.forEach((obj, index) => {
      const objString = JSON.stringify(obj);
      formData.append(`staffs[${index}]`, objString);
    });    
    formData.append("interval", interval);   
    formData.append("price", price);    
    formData.append("priceId",priceId);
    formData.append("sales_id", salesId ? salesId : null);
    formData.append("consultant_id", consultantId ? consultantId : null);
    formData.append("appointment_setter_id", appointmentSetterId ? appointmentSetterId : null);
    formData.append("image", image);
    concerns.forEach((obj, index) => {
      if(obj.isEnabled === true && obj._id){
        const objString = JSON.stringify(obj._id);
        formData.append(`concerns[${index}].headingId`, objString);
      }      
    });
    predictors.forEach((obj, index) => {
      if(obj.isEnabled === true && obj._id){
        const objString = JSON.stringify(obj._id);
        formData.append(`predictors[${index}].headingId`, objString);
      }
    });
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "usertype/update";
        formData.append("id", id);
        payload = formData;
      } else {
        path = "usertype/create";
        formData.append("phone", phone);
        formData.append("promo_code", promoCode);
        payload = formData;
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message);
        navigate("/subscriptions");
        setIsManage(false);
      } else {
        toastr.error(res.message);
        setIsManage(false);
      }
    } catch (err) {
      console.log(err.messege);
      setIsManage(false);
    }
  };

  const updateHandler = (heading_id, event,type) => {
    if(event){   
      if(type === 'concern'){
        setConcerns(
          concern => concern.map((item, i) => {
            if(item?._id === heading_id){
              return {
                ...item,
                isEnabled : true
              }
            } else {
              return item;
            }
          }
        )); 
      }else if(type === 'predictors'){
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(item?._id === heading_id){
              return {
                ...item,
                isEnabled : true
              }
            } else {
              return item;
            }
          }
        ));
      }
     
    } else {
      if(type === 'concern'){
        setConcerns(
          concern => concern.map((item, i) => {
            if(item?._id === heading_id){
              return {
                ...item,
                isEnabled : false
              }
            } else {
              return item;
            }
          }
        )); 
      }else if(type === 'predictors'){
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(item?._id === heading_id){
              return {
                ...item,
                isEnabled : false
              }
            } else {
              return item;
            }
          }
        ));
      }
    }
  };

  useEffect(() => {
    let enabledConcerns = concerns.length>0 && concerns.filter(it => it?.isEnabled)?.length;
    let enabledPredictors = predictors.length>0 && predictors.filter(it => it?.isEnabled)?.length;
    if(enabledConcerns >0 ){      
      setTotalSelectedConcerns(enabledConcerns)
      if(enabledConcerns === 8){
        setConcerns(
          concern => concern.map((item, i) => {
            if(item?.isEnabled === false){
              return {
                ...item,
                disabled : true
              }
            } else {
              return item;
            }
          }
        )); 
      }else{
        setConcerns(
          concern => concern.map((item, i) => {
            if(item?.isEnabled === false){
              return {
                ...item,
                disabled : false
              }
            } else {
              return item;
            }
          }
        ));
      }
    }
    if(enabledPredictors>0){
      setTotalSelectedPredictors(enabledPredictors)
      if(enabledPredictors === 8){
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(item?.isEnabled === false){
              return {
                ...item,
                disabled : true
              }
            } else {
              return item;
            }
          }
        ));
      }else{
        setPredictors(
          predictors => predictors.map((item, i) => {
            if(item?.isEnabled === false){
              return {
                ...item,
                disabled : false
              }
            } else {
              return item;
            }
          }
        ));
      }
    }    
  },[concerns,predictors])

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("new_account_creation")}</div>
      </div>
      <form>
        <div className="relative flex flex-col-reverse sm:grid grid-cols-4 gap-4">
          <div className="col-span-3">
            <div className="relative bg-white rounded shadow divide-y divide-slate-200">
              <div className="py-4 px-4 sm:py-8 sm:px-6 flex flex-col">
                <div className="space-y-4 mt-4">
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div className="relative w-full">
                      <Input label={t("company_name")} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("company_name")} inputValue={company_name} setInput={setCompanyName} />
                    </div>
                    <div className="relative w-full">
                      <Input label={t("company_administrator_name")} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("enter_company_administrator_name")} inputValue={name} setInput={setName} />
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div className="relative w-full">
                      <Input label={t("administrator_email")} labelClasses={"!text-xs"} inputType={"email"} inputPlaceholder={t("enter_administrator_email")} inputValue={email} setInput={setEmail} />
                    </div>
                    <div className="relative w-full">
                      <Input label={t("administrator_phone_number") + ":"} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("enter_administrator_phone_number") + ":"} inputValue={phone} setInput={setPhone} />
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("number_of_employees")}
                        labelClasses={"!text-xs"}
                        xPlacement={"bottomLeft"}
                        selectedValue={number_of_employees}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_employees"), value: "" },
                          ...plans.map((item) => ({
                            _id: item?._id,
                            label: item?.name,
                            value: item?.price,
                          })),
                        ]}
                        getSelectedValue={(e) => {
                          setNumberOfEmployees(e._id);
                          //setPrice(e.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("account_type")}
                        labelClasses={"!text-xs"}
                        xPlacement={"bottomLeft"}
                        selectedValue={accountType}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: "", label: t("select_account_type"), value: "" },
                          { _id: "business", label: capitalizeString("business"), value: "business" },
                          { _id: "demonstration", label: capitalizeString("demonstration"), value: "demonstration" },
                          { _id: "consultant", label: capitalizeString("consultant"), value: "consultant" },
                          { _id: "developer", label: capitalizeString("developer"), value: "developer" },
                          { _id: "training", label: capitalizeString("training"), value: "training" },
                        ]}
                        dropdownOptionBold={true}
                        getSelectedValue={(e) => setAccountType(e._id)}
                      />
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-3 gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("interval")}
                        labelClasses={"!text-xs"}
                        xPlacement={"bottomLeft"}
                        selectedValue={interval}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_interval"), value: "" },
                          { _id: 'month', label: t("month"), value: 'month' },
                          { _id: 'year', label: t("year"), value: 'year' },
                        ]}
                        getSelectedValue={(e) => setInterval(e._id)}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("plans")}
                        labelClasses={"!text-xs"}
                        xPlacement={"bottomLeft"}
                        selectedValue={price}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_plans"), value: "" },
                          ...purchasePlans.map((item) => ({
                            _id: item?._id,
                            label: item?.title+' - $'+Number(item?.price).toFixed(2),
                            value: item?.price,
                          })),
                        ]}
                        getSelectedValue={(e) => {
                          setPriceId(e._id);
                          setPrice(e.value)
                        }}
                      />
                      {/* <Input
                        label={t("price")}
                        labelClasses={"!text-xs"}
                        inputType={"number"}
                        inputPlaceholder={t("enter_price")}
                        inputValue={price}
                        setInput={setPrice}
                        //isDisabled={true}
                      /> */}
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("payment_status")}
                        labelClasses={"!text-xs"}
                        xPlacement={"bottomLeft"}
                        selectedValue={paymentStatus}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_payment_status"), value: "" },
                          { _id: 1, label: t("paid"), value: 1 },
                          { _id: 2, label: t("unpaid"), value: 2 },
                          { _id: 3, label: t("trial"), value: 3 },
                          // { _id: 4, label: t('inactive'),value:4},
                          // { _id: 5, label: t('sales_demo'),value:5}
                        ]}
                        getSelectedValue={(e) => setPaymentStatus(e._id)}
                      />
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("sales")}
                        labelClasses={"!text-xs"}
                        xPlacement={"bottomLeft"}
                        selectedValue={salesId}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_sales"), value: "" },
                          ...sales.map((item) => {
                            let sales = item?.commission.filter((itm) => itm.code === "sales-staff");
                            return {
                              _id: item?._id,
                              label: item?.last_name + "," + item?.first_name + ", " + t("salesperson") + " " + (sales[0].commission ? sales[0].commission : 0) + "%",
                              value: sales[0].commission ? sales[0].commission : 0,
                            };
                          }),
                          { _id: null, label: t("none"), value: null },
                        ]}
                        getSelectedValue={(e) => {
                          setSalesId(e._id);
                          setSalesCommission(e.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("consultant")}
                        labelClasses={"!text-xs"}
                        xPlacement={"bottomLeft"}
                        selectedValue={consultantId}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_consultant"), value: "" },
                          ...consultants.map((item) => {
                            let consultant = item?.commission.filter((itm) => itm.code === "consultant");
                            return {
                              _id: item?._id,
                              label: item?.last_name + "," + item?.first_name + ", " + t("consultant") + " " + (consultant[0].commission ? consultant[0].commission : 0) + "%",
                              value: consultant[0].commission ? consultant[0].commission : 0,
                            };
                          }),
                          { _id: null, label: t("none"), value: null },
                        ]}
                        getSelectedValue={(e) => {
                          setConsultantId(e._id);
                          setConsultantCommission(e.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("appointment_setter")}
                        labelClasses={"!text-xs"}
                        xPlacement={"bottomLeft"}
                        selectedValue={appointmentSetterId}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_appointment_setter"), value: "" },
                          ...appointmentSetter.map((item) => {
                            let appointmentSetter = item?.commission.filter((itm) => itm.code === "appointment-setter");
                            return {
                              _id: item?._id,
                              label: item?.last_name + "," + item?.first_name + ", " + t("appt_setter") + " " + (appointmentSetter[0].commission ? appointmentSetter[0].commission : 0) + "%",
                              value: appointmentSetter[0].commission ? appointmentSetter[0].commission : 0,
                            };
                          }),
                          { _id: null, label: t("none"), value: null },
                        ]}
                        getSelectedValue={(e) => {
                          setAppointmentSetterId(e._id);
                          setAppointmentSetterCommission(e.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("sales_admin")}
                        labelClasses={"!text-xs"}
                        xPlacement={"bottomLeft"}
                        selectedValue={salesAdminId}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_sales_admin"), value: "" },
                          ...salesAdmin.map((item) => {
                            let salesAdmin = item?.commission.filter((itm) => itm.code === "sales-admin");
                            return {
                              _id: item?._id,
                              label: item?.last_name + "," + item?.first_name + ", " + t("sales_admin") + " " + (salesAdmin[0].commission ? salesAdmin[0].commission : 0) + "%",
                              value: salesAdmin[0].commission ? salesAdmin[0].commission : 0,
                            };
                          }),
                          { _id: null, label: t("none"), value: null },
                        ]}
                        getSelectedValue={(e) => {
                          setSalesAdminId(e._id);
                          setSalesAdminCommission(e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4 px-4 sm:py-8 sm:px-6 flex flex-col">
                <div className="grid grid-cols-2 gap-10">
                  <div className="relative">
                    <div className="flex items-center justify-start">
                      <div className="text-sm font-bold text-slate-800">{t('conditions_of_concern_library')}</div>
                    </div>
                    <div className="space-y-4 mt-4">
                      {
                        concerns.length> 0 && concerns.map((it,idx) => {
                          return (
                            <div className="flex items-center justify-between gap-2">
                              <Checkbox 
                                checkboxLableClass={"text-slate-800"} 
                                checkboxInputClass={""} 
                                checkboxClass={"!rounded-full"}
                                divClass={"!items-start"}
                                checkboxName={it?._id} 
                                isChecked={it?.isEnabled} 
                                checkboxLabel={it?.name} 
                                checkboxValue={it?._id} 
                                getCheckboxValue={(val, event) => updateHandler(val,event.target.checked,"concern")} 
                                isDisabled={it?.disabled}
                            />
                            <div className="flex-shrink-0" data-tooltip-id={"info-tooltip-"+idx} data-tooltip-content={it?.description} data-tooltip-position-strategy="fixed">
                            {
                              (it?.description) && (
                                <>
                                <Tooltip id={'info-tooltip-'+idx} place="top" style={{
                                  maxWidth: "400px",
                                  wordBreak: "break-word",
                                  overflowWrap: "break-word",
                                  whiteSpace: "normal",
                                  padding: "5px",
                                  backgroundColor: "#333",
                                  color: "#fff",
                                  borderRadius: "4px",
                                  zIndex:2
                                }}></Tooltip>
                                <i className="fa-light fa-circle-info" style={{color:"#14b8a6"}}></i>
                                </>
                              )
                            }
                            </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className="relative">
                    <div className="flex items-center justify-start">
                      <div className="text-sm font-bold text-slate-800">{t('potential_predictor_library')}</div>
                    </div>
                    <div className="space-y-4 mt-4">
                      {
                        predictors.length> 0 && predictors.map((itm,inx) => {
                          return (
                            <div className="flex items-center justify-between gap-2">
                              <Checkbox 
                                checkboxLableClass={"text-slate-800"} 
                                checkboxInputClass={""} 
                                checkboxClass={"!rounded-full"}
                                divClass={"!items-start"}
                                checkboxName={itm?._id} 
                                isChecked={itm?.isEnabled} 
                                checkboxLabel={itm?.name} 
                                checkboxValue={itm?._id} 
                                getCheckboxValue={(val, event) => updateHandler(val,event.target.checked,'predictors')} 
                                isDisabled={itm?.disabled}
                              />
                              <div className="flex-shrink-0" data-tooltip-id={"info-tooltip-"+inx} data-tooltip-content={itm?.description} data-tooltip-position-strategy="fixed">
                                {
                                  (itm?.description) && (
                                    <>
                                    <Tooltip id={'info-tooltip-'+inx} place="top" style={{
                                      maxWidth: "400px",
                                      wordBreak: "break-word",
                                      overflowWrap: "break-word",
                                      whiteSpace: "normal",
                                      padding: "5px",
                                      backgroundColor: "#333",
                                      color: "#fff",
                                      borderRadius: "4px",
                                      zIndex:2
                                    }}></Tooltip>
                                    <i className="fa-light fa-circle-info" style={{color:"#14b8a6"}}></i>
                                    </>
                                  )
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-3 px-3 border-t border-slate-200">
                <div className="flex items-center justify-end gap-4">
                  <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("send")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
                </div>
              </div>              
            </div>
          </div>
          <div className="col-span-1 space-y-4">
            <div className="relative bg-white rounded shadow flex flex-col">
              <div className="py-6 px-6 flex flex-col space-y-4 relative h-full max-h-[calc(100% - 40px)]">
                <div className="text-sm font-medium text-slate-500 mb-1">{t("add_a_profile_picture_for_this_account_optional")}</div>
                <div className="w-full overflow-hidden rounded-md flex items-center h-full">
                  <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_image")} buttonClasses={"!h-full !w-full !absolute !opacity-0 !z-10 !top-0 !left-0"} functions={onImgChange} accepts={"image/*"} />
                  <Image src={showLogo} alt={"Admin"} width={"100%"} effect={"blur"} classes={"object-fit"} id={"image1"} />
                </div>
              </div>
              <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_image")} buttonClasses={"!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"} functions={onImgChange} accepts={"image/*"} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AccountCreation;
