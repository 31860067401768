import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import toastr from "toastr";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { postData } from "../services/api";
import AlertPopup from "../components/popup/AlertPopup";
import { useTranslation } from "react-i18next";

const AddPosition = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Position");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Position");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState();
  const [isAdd, setIsAdd] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const role = localStorage.getItem("role");
  const [enabledAlertPopup, setEnabledAlertPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});  

  useEffect(() => {
    // Position details
    const loadPositionDetails = async () => {
      try {
        const result = await postData("position/details", {
          id: id,
        });
        if (result.status === 403) {
          if (role && (role === "customer-admin" || role === "customer-subadmin")) {
            return navigate("/position-alt");
          } else {
            return navigate("/positions");
          }
        } else {
          if (result.data) {
            setDetails(result.data);
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPositionDetails();
  }, [id]);

  useEffect(() => {
    setName(details?.name);
    setShortName(details?.short_name);
  }, [details]);

  const onCancel = async (e) => {
    if (role && (role === "customer-admin" || role === "customer-subadmin")) {
      return navigate("/position-alt");
    } else {
      return navigate("/positions");
    }
    // setIsCancel(true);
    // if(id){
    //     setName(details?.name);
    //     setShortName(details?.short_name);
    // }else{
    //     setName('');
    //     setShortName('');
    // }
    // setIsCancel(false);
  };

  useEffect(() => {
    const onSubmit = async (e) => {
      setIsAdd(true);
      try {
        let path = "";
        let payload = "";
        if (id) {
          path = "position/update";
          payload = {
            id: id,
            name: name,
            short_name: shortName,
          };
        } else {
          path = "position/create";
          payload = {
            name: name,
            short_name: shortName,
          };
        }

        const result = await postData(path, payload);
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setIsAdd(false);
          if ((role && role === "customer-admin") || role === "customer-subadmin") {
            return navigate("/position-alt");
          } else {
            return navigate("/positions");
          }
        } else {
          toastr.error(result.message);
          setIsAdd(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsAdd(false);
      }
    };
    if (actionValue && actionValue.button_type === "confirm") {
      onSubmit();
    }
  }, [actionValue]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{id ? t("edit_position") : t("add_position")}</div>
      </div>
      <div className="relative grid grid-cols-2 gap-4">
        <div className="col-span-2">
          <div className="relative bg-white rounded shadow divide-y divide-slate-200">
            <div className="py-8 px-6 flex flex-col">
              {/* <div className="text-xl font-bold text-slate-900">Successor Information</div> */}
              <div className="space-y-4 mt-4">
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="relative w-full">
                    <Input label={t("position")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_position")} inputValue={name} setInput={setName} />
                  </div>
                  <div className="relative w-full">
                    <Input label={t("position_abbreviation")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_position_abreviation")} inputValue={shortName} setInput={setShortName} />
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3 px-3 border-t border-slate-200">
              <div className="flex items-center justify-end gap-4">
                <Button buttonLabelClasses="" buttonType={"button"} buttonIcon={isCancel ? "fa-light fa-spinner fa-spin" : "fa-light fa-times"} buttonIconPosition={"left"} buttonLabel={t("cancel")} buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"} functions={onCancel} buttonHasLink={false} buttonDisabled={isCancel} />
                <Button
                  buttonClasses=""
                  buttonLabelClasses=""
                  buttonType={"button"}
                  buttonIcon={isAdd ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"}
                  buttonIconPosition={"left"}
                  buttonLabel={t("save")}
                  //functions={onSubmit}
                  functions={() => {
                    if (!name) {
                      toastr.error(t("position_field_is_required"));
                    } else if (!shortName) {
                      toastr.error(t("position_abreviation_field_is_required"));
                    } else {
                      setEnabledAlertPopup(true);
                    }
                  }}
                  buttonHasLink={false}
                  buttonDisabled={isAdd}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {enabledAlertPopup && (
        <AlertPopup
          isOpen={enabledAlertPopup}
          setIsOpen={(val) => setEnabledAlertPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></AlertPopup>
      )}
    </>
  );
};

export default AddPosition;
