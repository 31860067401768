import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";

import { postData } from "../../services/api";
import toastr from "toastr";

import { useTranslation } from "react-i18next";

const CompanyDetailsPopup = ({
  isOpen,
  setIsOpen = () => {},
  data,
  getActionValue = () => {},
}) => {
  const { i18n,t } = useTranslation();
  const role = localStorage.getItem("role");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState('');
  const [phone,setPhone] = useState('');
  const [email,setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setName(data?.name);
    setCompanyName(data?.company_name);
    setPhone(data?.phone);
    setEmail(data?.email);
  }, [data]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 text-center"
                  >
                    {t('company_information')}
                  </Dialog.Title>
                  <div className="absolute top-2 right-2 z-10">
                      <Button 
                      buttonLabelClasses=''
                      buttonType={'button'} 
                      buttonIcon={'fa-light fa-times'} 
                      buttonIconPosition={'left'} 
                      buttonLabel={''} 
                      buttonClasses={'!bg-black/10 !text-white !w-10 !h-10 !p-0 !rounded-full border border-white/20'} 
                      functions={closeModal}
                      buttonHasLink={false}
                      />
                  </div>
                  <div className="relative mt-4 space-y-2">
                    <div className="text-md flex items-center">
                      <div className="sm font-bold">{t('company_name')}</div>
                      <div className="sm ml-2">{companyName}</div>
                    </div>
                    <div className="text-md flex items-center">
                      <div className="sm font-bold">{t('company_administrator_name')}</div>
                      <div className="sm ml-2">{name}</div>
                    </div>
                    <div className="text-md flex items-center">
                      <div className="sm font-bold">{t('administrator_email')}</div>
                      <div className="sm ml-2">{email}</div>
                    </div>
                    <div className="text-md flex items-center">
                      <div className="sm font-bold">{t('administrator_phone_number')}</div>
                      <div className="sm ml-2">{phone}</div>
                    </div>
                  </div>
                  <div className="relative flex justify-center mb-3 mt-6 gap-4"></div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CompanyDetailsPopup;
