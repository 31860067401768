import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";
import moment from "moment/moment";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const ProfileReport = ({ successors, user }) => {
  const profileHeading = [
    {
      name : "Years of Senior Management Experience"
    },
    {
      name : "Years with CU or Company"
    },
    {
      name : "Education"
    },
    {
      name :"Performance"
    },
    {
      name :"Loyalty"
    },
    {
      name :"Desire to Advance"
    },
    {
      name :"Retirement Window"
    },
    {
      name :"Additional Comments"
    }
  ]
  // const styles = StyleSheet.create({
  //   page: {
  //     padding: 20,
  //     fontFamily: "RobotoRegular",
  //     lineHeight: 1.2,
  //     fontSize: 9,
  //   },
  //   tableHeading: {
  //     fontSize: 8,
  //     width: "14%",
  //     paddingHorizontal: 4,
  //     paddingVertical: 8,
  //     display: "flex",
  //     flexDirection: "row",
  //     flexWrap: "wrap",
  //   },
  //   subText: {
  //     fontSize: 8,
  //     marginTop: 2,
  //   },
  //   tableData: {
  //     fontSize: 9,
  //     width: "14%",
  //     padding: 4,
  //     display: "flex",
  //     flexDirection: "row",
  //     flexWrap: "wrap",
  //   },
  //   tableText: {
  //     fontSize: 7,
  //   },
  //   image: {
  //     width: 200,
  //     height: 40,
  //     objectFit: "contain",
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //   },
  //   tableHeadingText: {
  //     width: "100%",
  //     fontFamily: "RobotoRegular",
  //     fontSize: 8,
  //     lineHeight: 1,
  //   },
  //   imageSignature: {
  //     width: 100,
  //     height: 60,
  //     objectFit: "contain",
  //   },
  //   tablePart: {
  //     display: "flex",
  //     flexDirection: "row",
  //     overflow: "hidden",
  //   },
  //   section: { textAlign: "center" },
  // });
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'RobotoRegular',
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: '100%',
      padding: 8,

    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: '100%',
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });

  return (
    <Document>
      <Page size="A4"  orientation='landscape' style={styles.page}>
        {/* <View style={[styles.section, { color: "white" }]}>
          <Text>Section #1</Text>
        </View> */}
        <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: 16,
            }}
          >
            <Image
              style={styles.image}
              src={logo}
              // src='https://successionnow.iosx.in/logo-succession-now.png'
            />
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 20 , textTransform:'capitalize'}]}>Company Name: {user?.company_name}</Text>
          </View>
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 15 }]}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 16 , textTransform:'capitalize'}]}>Succession Plan for {user?.title}</Text>
          </View>
          <View style={[ { display: "flex", flexDirection:'row', marginTop: 5, textAlign: "center", justifyContent: "center", }]}>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center",marginRight: 12 }]}>
              <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center", fontSize: 10 }]}>Created by:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>
                {user?.name}({user?.title})
              </Text>
            </View>
            <View style={[styles.tableOther, { display: "flex", flexDirection: "row", marginTop: 5 }]}>
              <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
              <Text style={{ fontWeight: 700, fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
            </View>
          </View>
        </View>
        {successors && successors.length > 0 &&
        successors.map((row,key) => {
        return (
        <>
        {(row.successors_details && row.successors_details.length > 0) && 
          <View style={[styles.tableOther, { display: "flex", flexDirection: "row", columnGap:20, marginTop: 5,  marginBottom:5, }]} key={key}>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12 }]}>{row.position} : {row.name}</Text>
            <Text style={[styles.tableOtherData, { display: "flex", flexDirection: "row", fontFamily: "RobotoMedium", fontSize: 12 }]}>Retirement Window : {(row?.retirement)?row?.retirement?.replace(/(Eliminates this candidate)/gi,'').replace(/[{()}]/g, ''):''}</Text>
          </View>
        }
         {row.successors_details && row.successors_details.length > 0 &&
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 6, overflow: "hidden", marginBottom:30 }} key={key}>
            <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color:'#ffffff', overflow: "hidden" }}>
              <Text style={[styles.tableHeading, { fontFamily: 'RobotoMedium', fontSize: 10 }]}>&nbsp;</Text>
              {row.successors_details.map((item,index) => (
                <View key={index} style={[styles.tableHeading, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                  <Text style={{ width: "100%", fontFamily: 'RobotoMedium', fontSize: 10 }}>{item.name}</Text>
                  <Text style={{ fontSize: 8, marginTop: 2 }}>{item?.position_details? item?.position_details[0]?.short_name: "N/A"}</Text>
                </View>
              ))}
            </View>          
            { profileHeading.map((items, index) => (
              <View key={index} style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                <View style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                  <Text style={[{ fontWeight: 500, width: '100%', fontSize: 10, lineHeight: 1 }]}>{items.name}</Text>
                </View>

                {row.successors_details.map((item,index2) => (
                  <View key={index+'_'+index2} style={[styles.tableData, { display: "flex", flexDirection: "row", flexWrap: "wrap" }]}>
                    {index === 0 &&
                      <Text style={{ fontSize: 9 }}>{item?.experience_details && item?.experience_details.length > 0 ? item?.experience_details[0]?.name : "N/A"}                      
                      </Text>
                    }
                    {index === 1 &&
                      <Text style={{ fontSize: 9 }}>
                      {item?.cu_details && item?.cu_details.length > 0 ? item?.cu_details[0]?.name : "N/A"}
                      </Text>
                    }
                    {index === 2 &&
                      <Text style={{ fontSize: 9 }}>
                      {item?.education_details && item?.education_details.length > 0 ? item?.education_details[0]?.name : "N/A"}
                      </Text>
                    }
                    {index === 3 &&
                      <Text style={{ fontSize: 9 }}>
                        {item?.performance_details && item?.performance_details.length > 0
                        ? item?.performance_details[0]?.name + " " + (item?.performance_details[0].number ? "(" + item?.performance_details[0].number + ")" : "")
                        : "N/A"}
                      </Text>
                    }
                    {index === 4 &&
                      ((item?.loyalty_details.length > 0 && item?.loyalty_details[0].isPass)?(
                        <Text style={{ fontSize: 9 }}>
                          {item?.performance_details && item?.performance_details.length > 0
                          ? item?.performance_details[0]?.name + " " + (item?.performance_details[0].number ? "(" + item?.performance_details[0].number + ")" : "")
                          : "N/A"}
                        </Text>
                      ):(
                        <Text style={{ fontSize: 9, color: 'red' }}>{(Array.isArray(item?.loyalty_details) && item?.loyalty_details.length > 0)?
                      (item?.loyalty_details[0]?.isPass)?'Pass: ':'Fail: ': ""} 
                      {item?.loyalty_details && item?.loyalty_details.length > 0
                          ? item?.loyalty_details[0]?.name
                          : "N/A"}</Text>
                      ))
                    }
                    {index === 5 &&
                      ((item.desire_details.length > 0 && item?.desire_details[0].isPass)?(
                        <Text style={{ fontSize: 9 }}>{(Array.isArray(item?.desire_details) && item?.desire_details.length > 0)?
                        (item?.desire_details[0]?.isPass)?'Pass: ':'Fail: ': ""} 
                          {item?.desire_details && item?.desire_details.length > 0
                            ? item?.desire_details[0]?.name
                            : "N/A"}</Text>
                      ):(
                        <Text style={{ fontSize: 9, color: 'red' }}>{(Array.isArray(item?.desire_details) && item?.desire_details.length > 0)?
                        (item?.desire_details[0]?.isPass)?'Pass: ':'Fail: ': ""} 
                          {item?.desire_details && item?.desire_details.length > 0
                            ? item?.desire_details[0]?.name
                            : "N/A"}</Text>
                      ))                      
                    }
                    {index === 6 &&
                      ((item.retirement_details.length > 0 && item?.retirement_details[0].isPass)?(
                        <Text style={{ fontSize: 9 }}>{(Array.isArray(item?.retirement_details) && item?.retirement_details.length > 0)?
                      (item?.retirement_details[0]?.isPass)?'Pass: ':'Fail: ': ""}
                        {item?.retirement_details &&
                        item?.retirement_details.length > 0
                          ? item?.retirement_details[0]?.name
                          : "N/A"}</Text>
                      ):(
                        <Text style={{ fontSize: 9, color: 'red' }}>{(Array.isArray(item?.retirement_details) && item?.retirement_details.length > 0)?
                      (item?.retirement_details[0]?.isPass)?'Pass: ':'Fail: ': ""}
                        {item?.retirement_details &&
                        item?.retirement_details.length > 0
                          ? item?.retirement_details[0]?.name
                          : "N/A"}</Text>
                      ))
                    }
                    {index === 7 &&
                      <Text style={{ fontSize: 9 }}>
                        {item?.additional_comments ? item?.additional_comments : ""}
                      </Text>
                    }
                  </View>
                ))}

              </View>
            )) }

          </View>
        }
        {/* {row.successors_details && row.successors_details.length > 0 &&
        <View style={{ border: "1px solid #e2e8f0", borderRadius: 4, overflow: "hidden" }} key={key}>
          <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#e2e8f0", overflow: "hidden" }}>
            <Text style={[styles.tableHeading, { width: "20%" }]}>Potential Successor</Text>
            <View style={[styles.tableHeading, {}]}>
              <Text style={[styles.tableHeadingText, {}]}>Years of Executive Experience</Text>
            </View>
            <View style={[styles.tableHeading, {}]}>
              <Text style={[styles.tableHeadingText, {}]}>Years with CU or Company</Text>
            </View>
            <View style={[styles.tableHeading, {}]}>
              <Text style={[styles.tableHeadingText, {}]}>Education</Text>
            </View>
            <View style={[styles.tableHeading, {}]}>
              <Text style={[styles.tableHeadingText, {}]}>Performance</Text>
            </View>
            <View style={[styles.tableHeading, {}]}>
              <Text style={[styles.tableHeadingText, {}]}>Loyalty</Text>
            </View>
            <View style={[styles.tableHeading, {}]}>
              <Text style={[styles.tableHeadingText, {}]}>Desire to Advance</Text>
            </View>
            <View style={[styles.tableHeading, {}]}>
              <Text style={[styles.tableHeadingText, {}]}>Retirement Window</Text>
            </View>
          </View>

          { row.successors_details.map((item, index) => (
              <View key={index} style={[styles.tablePart, { borderBottom: "1px solid #dddddd" }]}>
                <View style={[styles.tableData, { width: "20%" }]}>
                  <Text style={[styles.tableText, { width: "100%", lineHeight: 1 }]}>{item?.name}</Text>
                  <Text style={[styles.subText, { color: "#a7a9ac", marginTop: 2 }]}>{item?.position_details ? item?.position_details[0]?.short_name : "N/A"}</Text>
                </View>

                <View style={[styles.tableData]}>
                  <Text style={[styles.tableText]}>{item?.experience_details && item?.experience_details.length > 0 ? item?.experience_details[0]?.name : "N/A"}</Text>
                </View>
                <View style={[styles.tableData]}>
                  <Text style={[styles.tableText]}>{item?.cu_details && item?.cu_details.length > 0 ? item?.cu_details[0]?.name : "N/A"}</Text>
                </View>
                <View style={[styles.tableData]}>
                  <Text style={[styles.tableText]}>{item?.education_details && item?.education_details.length > 0 ? item?.education_details[0]?.name : "N/A"}</Text>
                </View>
                <View style={[styles.tableData]}>
                  <Text style={[styles.tableText]}>
                    {item?.performance_details && item?.performance_details.length > 0
                      ? item?.performance_details[0]?.name + " " + (item?.performance_details[0].number ? "(" + item?.performance_details[0].number + ")" : "")
                      : "N/A"}
                  </Text>
                </View>
                <View style={[styles.tableData]}>
                  {
                    ((item?.loyalty_details.length > 0 && item?.loyalty_details[0].isPass)?(
                      <Text style={[styles.tableText] }>{item?.loyalty_details && item?.loyalty_details.length > 0 ? item?.loyalty_details[0]?.name : "N/A"}</Text>
                    ):(
                      <Text style={[styles.tableText,{color:'red'}] }>{item?.loyalty_details && item?.loyalty_details.length > 0 ? item?.loyalty_details[0]?.name : "N/A"}</Text>
                    ))
                  }
                </View>
                <View style={[styles.tableData]}>
                  {
                    ((item.desire_details.length > 0 && item?.desire_details[0].isPass)?(
                      <Text style={[styles.tableText]}>{item?.desire_details && item?.desire_details.length > 0 ? item?.desire_details[0]?.name : "N/A"}</Text>
                    ):(
                      <Text style={[styles.tableText,{color:'red'}]}>{item?.desire_details && item?.desire_details.length > 0 ? item?.desire_details[0]?.name : "N/A"}</Text>
                    ))
                  }
                </View>
                <View style={[styles.tableData]}>
                  {
                    ((item.retirement_details.length > 0 && item?.retirement_details[0].isPass)?(
                      <Text style={[styles.tableText]}>{item?.retirement_details && item?.retirement_details.length > 0 ? item?.retirement_details[0]?.name : "N/A"}</Text>
                    ):(
                      <Text style={[styles.tableText,{color:'red'}]}>{item?.retirement_details && item?.retirement_details.length > 0 ? item?.retirement_details[0]?.name : "N/A"}</Text>
                    ))
                  }
                </View>
              </View>
            ))}
        </View>
        } */}
        </>
        )
        })
        }
      </Page>
    </Document>
  );
};

export default ProfileReport;
