import React from 'react';
import Button from '../components/form/Button';
import { useTranslation } from "react-i18next";

const AccessDenied = () => {
  const { i18n,t } = useTranslation();
  return (
    <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
            <span className="sr-only">{t('error')}</span>403
          </h2>
          {/* <p className="text-2xl font-semibold md:text-3xl">{t('access_denied')}</p> */}
          <p className="mt-4 mb-8 dark:text-gray-400">{t('access_denied')}</p>
          <Button
            buttonClasses={''}
            buttonType={"button"}
            buttonIcon={"fa-regular fa-arrow-right-to-arc"}
            buttonIconPosition={"left"}
            buttonLabel={t('back_to_home')}
            buttonLabelClasses={""}
            buttonHasLink={true}
            buttonLink={'/'}
          />
        </div>
      </div>
    </section>
  );
};

export default AccessDenied;