import React, { useEffect, useState } from "react";
import SuccessionPlanAllReport from "./SuccessionPlanAllReport";
import ChartViewReport from "./ChartViewReport";
import { Document } from "@react-pdf/renderer";
import EmergencyReplacementPlanReport from "./EmergencyReplacementPlanReport";

const SuccessionPlanAllReportMain = ({
  successors,
  heading,
  readinessHeading,
  concernHeadings,
  predictorsHeading,
  user,
  details
}) => {
  // const [chatData,setChatData] = useState([]);
  // useEffect(()=>{
  //   successors.length > 0 &&
  //     successors.map((item, index) => {
  //       return item.successors_details.length > 0 ?(
  //         <ChartViewReport
  //           key={index}
  //           successors={[item]}
  //           getImage={(img) => {
  //             //return {_id:item?._id,image: img}
  //             console.log('img',img)
  //           }}
  //         />
  //       ):null
  //   })
  // },[successors])
  return (
    <Document>
      {successors &&
        successors.length > 0 &&
        successors.map((item, index) => { 
          return item.successors_details.length > 0 ?
          (
            <>
            <SuccessionPlanAllReport
              key={index}
              successors={[item]}
              heading={heading}
              readinessHeading={readinessHeading}
              concernHeadings={concernHeadings}
              predictorsHeading={predictorsHeading}
              conclusion={item.conclusion ? item?.conclusion : []}
              planApprove={item?.plan_approve}
              name={item?.conclusion_name}
              date={item?.date}
              trimmedDataURL={item?.signature}
              user={user}
            />
            {
              (item?.pos_details && item?.pos_details[0]?.short_name === 'CEO') && 
              <EmergencyReplacementPlanReport data={details} details={item?.emergency_replacement_details}/>
            }            
            </>
          ): null;
        })}
    </Document>
  );
};

export default SuccessionPlanAllReportMain;
